import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'
import { rootReducer } from '../reducers'

const rootPersistConfig = {
    key: 'root',
    storage,
    blacklist: []
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export default () => {
    let store = createStore(persistedReducer, applyMiddleware(thunk))
    let persistor = persistStore(store)
    return { store, persistor }
}