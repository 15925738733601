import LocalizedStrings from 'react-localization';
import {th} from '../language/th'
import {en} from '../language/en'
import {cn} from '../language/cn'

const  strings = new LocalizedStrings({
 th:th,
 en:en,
 cn:cn
});
let curLang = localStorage.getItem("lang");
if(!curLang)
{
	curLang = 'th';
}
strings.setLanguage(curLang);
export {strings};