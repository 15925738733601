import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/form.css";
import Filter from "../../Filter";



export default class Fail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            preFixImg: "",
            iframe_url:""
        };
        
        
    }
    componentDidMount() {
        const $ = window.$;
        window.scrollTo(0, 0);
        $("#preloader").hide();



        // this.setState({
        //     isExtra: this.props.location.state.option_price,
        //     isVat: this.props.location.state.vat_price,
        //     isVoucher: this.props.location.state.voucher_price,
        //     totalPrice: this.props.location.state.total_price
        // });
        // console.log(this.props.location.state.option_price);
    }


    render() {
        return (
            <div>
                <Filter />
                <div className="wrapper">
                    <div
                        className="content-area"
                        style={{ paddingBottom: "30px" }}
                    >
                        <div className="container BookHeaderStatus-repad-statusbar set-container-mobile">
                            
                        </div>
                        <div
                            className="container BookingStep3-re-contain"
                            style={{
                                marginTop: "40px"
                            }}
                        >
                            <div
                                className="row"
                                style={{
                                    margin: "0 0",
                                    paddingBottom: "30px"
                                }}
                            >
                                <div className="col-md-9 BookingStep3-set-mar BookingStep3-re-padl BookingStep3-mobile-padl BookingStep3-repadl-contain">
                                    
                                    
                              
                                        <div className="Book3ContentA-head-option">
                                            
                                        </div>
                                        <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                                            <div className="row BookingStep4-rowmar-default">
                                                <div className="col-sm-12 BookingStep4-font-default">
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">

                                                         <h2 style={{marginBottom: "40px"}}>การดำเนินการผิดพลาด กรุณาลองใหม่อีกครั้ง</h2>

                                                         <div className="text-center"><Link
                                            to={{
                                                pathname: "/bookingstep3"
                                              }}
                                        >
                                            <input
                                                type="button"
                                                value="Back"
                                                className="Book3ContentA-con-btnb Book3ContentA-mo-btna"
                                            />
                                        </Link>
                                        </div>
                                              
                                                        </div>
                                             
                                                    </div>
                                           
                                                </div>
                                            </div>
                                        </div>
                             
                                
                                        
                             
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
