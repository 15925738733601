import { strings } from "../../language/language";
let curLang = localStorage.getItem("lang");

export const BANNER_IMGAES = {
  th: {
    "short-term": "/images/BannerService/short-term.png",
    "long-term": "/images/BannerService/long-term.png",
    enterprise: "/images/BannerService/corporate.png",
    chaffeur: "/images/BannerService/chaffeur.png",
  },
  en: {
    "short-term": "/images/BannerService/short-term.png",
    "long-term": "/images/BannerService/long-term.png",
    enterprise: "/images/BannerService/corporate.png",
    chaffeur: "/images/BannerService/chaffeur.png",
  },
  cn: {
    "short-term": "/images/BannerService/Chinese/short-term.png",
    "long-term": "/images/BannerService/Chinese/long-term.png",
    enterprise: "/images/BannerService/Chinese/corporate.png",
    chaffeur: "/images/BannerService/Chinese/chaffeur.png",
  },
};

export const SHOW_LUXURY_FLEET = ["enterprise", "chaffeur"];

export const WHAT_WE_OFFER = [
  {
    title: "offer_1_title",
    img: "/images/WhatWeOffer/guarantee-certificate.png",
  },
  {
    title: "offer_2_title",
    img: "/images/WhatWeOffer/car-wash.png",
  },
  {
    title: "offer_3_title",
    img: "/images/WhatWeOffer/customer-service.png",
  },
];

export const CORPORATE_WHAT_WE_OFFER = [
  {
    title: "offer_1_title_corporate",
    img: "/images/WhatWeOffer/guarantee-certificate.png",
  },
  {
    title: "offer_2_title_corporate",
    img: "/images/WhatWeOffer/car-wash.png",
  },
  {
    title: "offer_3_title_corporate",
    img: "/images/WhatWeOffer/customer-service.png",
  },
];

export const TITLE_TEXT = {
  "short-term": strings.service.short_term_title,
  "long-term": strings.service.long_term_title,
  enterprise: strings.service.enterprise_title,
  chaffeur: strings.service.chaffeur_title,
};

export const DESCRIPTION_TEXT = {
  "short-term": strings.service.short_term_description,
  "long-term": strings.service.long_term_description,
  enterprise: strings.service.enterprise_description,
  chaffeur: strings.service.chaffeur_description,
};

export const SHOW_BANNER_RENT_TYPE = ["long-term", "enterprise", "chaffeur"];

export const SHOW_WHAT_WE_OFFER_RENT_TYPE = [
  "short-term",
  "long-term",
  "enterprise",
];

export const SHOW_TABLE_RENT_TYPE = ["short-term", "long-term"];

export const SHOW_BOOK_NOW_RENT_TYPE = ["enterprise", "chaffeur"];

export const SHORT_TERM_TABLE = [
  {
    label: strings.ourservice_table.short_term[1],
    ldw: false,
    sldw: true,
    pldw: true,
  },
  {
    label: strings.ourservice_table.short_term[2],
    ldw: false,
    sldw: false,
    pldw: true,
  },
  {
    label: strings.ourservice_table.short_term[3],
    ldw: "-",
    sldw: "200",
    pldw: `300  (${
      curLang === "th" ? "รถไซส์" : "Car size"
    } : S,M) <br /> 400 (${curLang === "th" ? "รถไซส์" : "Car size"} : L)`,
  },
  {
    label: strings.ourservice_table.short_term[4],
    ldw: "-",
    sldw: "3,000",
    pldw: "4,500",
  },
];

export const LONG_TERM_TABLE = [
  {
    label: strings.ourservice_table.long_term[1],
    ldw: false,
    sldw: true,
    pldw: true,
  },
  {
    label: strings.ourservice_table.long_term[2],
    ldw: false,
    sldw: false,
    pldw: true,
  },
  {
    label: strings.ourservice_table.long_term[3],
    ldw: "-",
    sldw: "3,000",
    pldw: "4,500",
  },
];
