import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/Profile.css";
import "./css/BookingHistory.css";
import axios from 'axios';
import Filter from "../../Filter";
import MenuProfile from "./MenuProfile";
import BookHisContentAsub from "./BookHisContentAsubA";

import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";

// const apiUrl = PREFIX_ADDRESS + "api/member/details";
// const apiUrl = "http://localhost:8000/api/member/details";
// const apiUrlPwd = "http://localhost:8000/api/member/changePassword";

const apiUrl = PREFIX_ADDRESS + "api/member/history";

let token = localStorage.login_token;
export default class ProfileContentA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            history: [],
            isBranches: "",
        };
    }

    fetchLocation() {
        axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then(res => {
            if (res.data.Success === "Y") {
                const { data } = res;
                // console.log(data.Branch[10]);
                this.setState({ isBranches: data.Branch })
            }
        })
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (!token) {
            this.props.history.push({ pathname: "/login", state: {} });
        }

        this.fetchLocation();
        this.getData();
    }

    getData = (e) => {

        if (token) {
            axios
                .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    this.setState({ history: data.success })
                })
                .catch(errs => {
                    console.error(errs);
                    this.props.history.push({ pathname: "/login", state: {} });
                });
        }

    }

    render() {

        let dayItem = [];
        let monthItem = [];
        let yearItem = [];
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        for (let i = 1; i <= 31; i++) {
            dayItem.push(<option key={i}>{i}</option>)
        }

        for (let i = 0; i <= 11; i++) {
            monthItem.push(<option key={i}>{monthNames[i]}</option>)
        }

        for (let i = 2020; i >= 1950; i--) {
            yearItem.push(<option key={i}>{i}</option>)
        }

        return (
            <div style={{ background: "#EAEAEA" }}>
                <Filter />
                <div className="wrapper">
                    <div className="content-area">
                        <div className="container">
                            <div className="container-title ProfileContentA-contain-default">
                                <h1
                                    className="ProfileContentA-confont-header"
                                    style={{ letterSpacing: "5px" }}
                                >
                                    <strong>BOOKING HISTORY</strong>
                                </h1>
                                <div className="ProfileContentA-hr-promo ProfileContentA-conhr-head" />
                            </div>
                            <div className="ProfileContentA-setmarlr-de">
                                <div className="row ProfileContentA-setmarb-fform">
                                    <MenuProfile active="history" />


                                </div>
                                <div style={{ minHeight: "30vh", paddingTop: "30px" }}>
                                    {
                                        Object.keys(this.state.history).map((key, i) => <BookHisContentAsub detail={this.state.history[key]} branch={this.state.isBranches} />)
                                    }

                                    {this.state.history.length === 0 && (<div className="alert alert-warning text-center">คุณยังไม่มีรายการจอง</div>)}
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
