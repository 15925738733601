import React, { Component } from "react";
import Filter from "../../Filter";
import { Link } from "react-router-dom";
import "./css/BookingStep3.css";
import axios from "axios";
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";
import { strings } from "../../language/language";
import ConfirmModal from "../Modal/ConfirmModal";
import PoItem from "./PoItem";
// import update from 'react-addons-update';
let booking_temp;
let booking_voucher;
let booking_option;
let curLang = localStorage.getItem("lang");
export default class Book3HeaderStatus extends Component {
  constructor(props) {
    super(props);
    let voucher_list;

    voucher_list = [{ Code: "", Value: 0, Status: "" }];
    localStorage.removeItem("booking_voucher");
    localStorage.removeItem("booking_option");
    localStorage.removeItem("po_no");

    this.handleCloseClick = this.handleCloseClick.bind(this);

    this.state = {
      preFixPath: "",
      preFixImg: "",
      chkAccept: false,
      isCodea: "",
      isCodeb: "",
      chkCodea: "",
      chkCodeb: "",
      isVouchera: 0,
      isVoucherb: 0,
      isShowRowa: true,
      isShowRowb: true,
      partial: "",
      total_option: 0,
      isSumVoucher: 0,
      isValuea: "",
      isValueb: "",
      customerConfirm: false,
      outBranch: "",
      outDate: "",
      isHrTimea: "00",
      isMinTimea: "00",
      inBranch: "",
      inDate: "",
      cdp: "",
      isHrTimeb: "00",
      isMinTimeb: "00",
      isParam: "",
      isBranches: [],
      isTotal: 0,
      isSumExtra: 0,
      isVat: 0,
      isCarPrice: 0,
      isRateNo: "",

      isRatePackage: "",
      isDropOff: 0,
      isDebitor: false,
      showModal: true,
      voucher: voucher_list,
      testv: voucher_list,
      isValues: [],
      book3Obj: {
        detail: {
          title: "",
          size: "",
          type: "",
          engine: "",
          seat: "",
          door: "",
          air_condition: "",
          tranmission: "",
          luggage: "",
          radio_type: "",
          rateNo: "",
        },
        rate: {
          total: 0,
          vat: 0,
          extra: 0,
          insurance: 0,
          amount: 0,
          car_price: 0,
          partial: "",
        },
      },
      extraObj: {},
      insuranceObj: {},
      isBabySeat: 1,
      isExtraBabySeat: 0,
      isExtraGps: 0,
      isTotalDay: 1,
      isBsActive: false,
      isGpsActive: false,
      isValidPO: null,
      validPO: null,
      termContent: "",
    };

    this.handleCheckCode = this.handleCheckCode.bind(this);
  }

  componentDidMount() {
    const $ = window.$;
    window.scrollTo(0, 0);
    $("#preloader").show();

    const prefiximg = SITE_URL + "/";
    this.setState({ preFixImg: prefiximg });

    this.fecthBook3();
    this.fetchLocation();
    this.fetchTermContent();
  }
  handleCloseClick() {
    const $ = window.$;
    const { handleModalCloseClick } = this.props;
    $(this.modal).modal("hide");
    handleModalCloseClick();
  }

  handleBSChange(evt) {
    this.setState({ isBabySeat: parseInt(evt.target.value) }, function () {
      this.handleRate();
    });
  }
  fetchTermContent() {
    axios
      .get(
        `https://backoffice.thairentacar.com/api/content/term/140?lang=${curLang}`
      )
      .then((res) => {
        this.setState({ termContent: res.data.detail });
      });
  }

  fecthBook3() {
    const $ = window.$;
    let query = new URLSearchParams(this.props.location.search);
    let outBranch = query.get("OutBranch");
    let outDate = query.get("OutDate");
    let outTime = query.get("OutTime");
    let inBranch = query.get("InBranch");
    let inDate = query.get("InDate");
    let inTime = query.get("InTime");
    let code = query.get("Code");
    let cdp = query.get("CDP");
    let carGroup = query.get("CarGroup");
    let rateNo = query.get("RateNo");
    let ratePackage = query.get("RatePackage");

    if (!cdp) {
      cdp = "PAYLATER";
    }
    if (inBranch) {
      booking_temp = {
        RatePackage: ratePackage,
        RateNo: rateNo,
        OutBranch: outBranch,
        OutDate: outDate,
        OutTime: outTime,
        InBranch: inBranch,
        InDate: inDate,
        InTime: inTime,
        Code: code,
        CDP: cdp,
        CarGroup: carGroup,
        option: {},
      };
      localStorage.setItem("booking_temp", JSON.stringify(booking_temp));
    } else {
      booking_temp = localStorage.getItem("booking_temp");
      if (!booking_temp) {
        this.props.history.push({ pathname: "/" });
        return false;
      }
      booking_temp = JSON.parse(booking_temp);
      outBranch = booking_temp.OutBranch;
      outDate = booking_temp.OutDate;
      outTime = booking_temp.OutTime;
      inBranch = booking_temp.InBranch;
      inDate = booking_temp.InDate;
      inTime = booking_temp.InTime;
      code = booking_temp.Code;
      cdp = booking_temp.CDP;
      carGroup = booking_temp.CarGroup;
      rateNo = booking_temp.RateNo;
      ratePackage = booking_temp.RatePackage;
    }

    if (localStorage.getItem("booking_option")) {
      booking_option = JSON.parse(localStorage.getItem("booking_option"));
    } else {
      booking_option = { extra: {}, insurance: {} };
    }

    if (code !== "") {
      this.setState({ chkCode: true });
    }

    this.setState({
      isRatePackage: ratePackage,
      isRateNo: rateNo,
      cdp: cdp,
      isCode: code,
      inBranch: inBranch,
      outBranch: outBranch,
      outDate: outDate,
      inDate: inDate,
      isMinTimeb: inTime,
      isMinTimea: outTime,
      isCarGrop: carGroup,
      isBabySeat: booking_option.extra.BS ? booking_option.extra.BS : 1,
    });

    // console.log(booking_option);
    let param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&CarGroup=${carGroup}&CDP=${cdp}&RateNo=${rateNo}&RatePackage=${ratePackage}`;

    Object.keys(booking_option.extra).map((item) => {
      param =
        param + "&Options[extra][" + item + "]=" + booking_option.extra[item];
      return null;
    });

    Object.keys(booking_option.insurance).map((item) => {
      param =
        param +
        "&Options[insurance][" +
        item +
        "]=" +
        booking_option.insurance[item];
      return null;
    });

    this.setState({ isParam: param });

    // console.log(param);
    axios
      .get(`${PREFIX_ADDRESS}api/booking/cardetails${param}`)
      .then((res) => {
        const { data } = res;

        this.SumVoucher();

        if (data.Success === "Y") {
          // console.log(data);
          var setFormatTotal = data.rate.total.replace(",", "");
          var defaultTotal = parseFloat(setFormatTotal);
          var setFormatCarprice = data.rate.car_price.replace(",", "");

          var defaultCarprice = parseFloat(setFormatCarprice);

          this.setState({
            book3Obj: data,
            extraObj: data.option.extra,
            isTotal: defaultTotal,
            isCarPrice: defaultCarprice,
            isVat: data.rate.vat,
            isDebitor: data.is_debitor,
            isDropOff: parseFloat(data.rate.drop_off),
            partial: data.rate.partial,
            insuranceObj: data.option.insurance,
            isTotalDay: parseInt(data.rate.days),
          });

          booking_temp.RateNo = data.detail.carrate;
          localStorage.setItem("booking_temp", JSON.stringify(booking_temp));
          // console.log(booking_temp.carrate);
        } else {
          window.location.href = "/";
        }
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchLocation() {
    axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then((res) => {
      if (res.data.Success === "Y") {
        const { data } = res;
        // console.log(data.Branch[10]);
        this.setState({ isBranches: data.Branch });
      }
    });
  }
  checkPo(poCode) {
    let estimateCost = parseFloat(this.state.isTotal).toFixed(2);
    axios
      .get(
        `${PREFIX_ADDRESS}api/checkPOCode?code=${poCode}&cost=${estimateCost}&cdp=${this.state.cdp}&out=${this.state.outDate} ${this.state.isMinTimea}&in=${this.state.inDate} ${this.state.isMinTimeb}`
      )
      .then((res) => {
        if (res.data.success === "Y") {
          this.setState({ isValidPO: true, validPO: poCode });
        } else {
          this.setState({ isValidPO: false });
          handleError(strings.error[res.data.message]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  clearPo(poCode) {
    this.setState({ isValidPO: null, validPO: null });
  }
  handleCheckCode(i, currentCode, currentValue) {
    for (let index = 0; index < this.state.voucher.length; index++) {
      if (i !== index) {
        if (this.state.voucher[index].Code === currentCode) {
          handleError("ใช้แล้ว");
          return;
        }
      }
    }

    axios
      .get(
        `${PREFIX_ADDRESS}api/booking/voucher?Code=${this.state.voucher[i].Code}&cargroup=${this.state.isCarGrop}&cdp=${this.state.cdp}&pickup_date=${this.state.outDate}&return_date=${this.state.inDate}`
      )
      .then((res) => {
        const { data } = res;

        if ("Y" === data.Success) {
          if (this.state.voucher[i].Value !== 0) {
            handleError("ใช้อยู่");

            if (this.state.isSumVoucher > 0) {
              this.setState({
                isSumVoucher: this.state.isSumVoucher - currentValue,
              });
            }
            this.forceUpdate();
            return;
          }
          let voucher = [...this.state.voucher];
          let item = { ...voucher[i] };

          item = {
            Code: voucher[i].Code,
            Value: parseFloat(data.promotion.price),
            Status: "success",
          };
          voucher[i] = item;
          // this.state.voucher[i].Value = parseInt(data.promotion.price);
          // this.state.voucher[i].Status = "success";
          this.setState({ voucher });
          // this.setState({
          //     voucher: {i : { Value: parseInt(data.promotion.price) , Status : "success" }}

          // });

          booking_voucher = {};
          booking_voucher = this.state.voucher;

          localStorage.setItem(
            "booking_voucher",
            JSON.stringify(booking_voucher)
          );
          this.setState((prevState) => ({
            voucher: [...prevState.voucher, { Code: "", Value: 0, Status: "" }],
          }));

          // this.setState({
          //     voucher: update(this.state.voucher, {i: {Value: data.promotion.price}})
          // })
          this.forceUpdate();
          this.SumVoucher();
          // console.log("Value = "+this.state.voucher[0].Value);
        } else {
          // this.state.voucher[i].Value = 0;
          // this.state.voucher[i].Status = "fail";
          if (this.state.isSumVoucher > 0) {
            this.setState({
              isSumVoucher: this.state.isSumVoucher - currentValue,
            });
          }
          this.setState((prevState) => ({
            voucher: [...prevState.voucher],
            // isValues : [prevState.isValues, {Value: data.promotion.price}]
          }));
          this.forceUpdate();

          handleError(data.message);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  customerConfirm = () => {
    const $ = window.$;
    $("#confirmmodal").modal("hide");
    this.setState({ customerConfirm: true }, () => {
      this.handleSubmit();
    });
  };

  validateVoucherCargroup = () => {
    let x = 0;
    this.state.voucher.map((e) => {
      if (e.Value) {
        x++;
      }
    });
    // console.log(x, this.state.book3Obj.rate.days)
    if (x !== parseInt(this.state.book3Obj.rate.days)) {
      return false;
    } else {
      return true;
    }
  };
  handleSubmit() {
    console.log("handleSubmit");
    const $ = window.$;
    const acceptstatus = this.state.chkAccept;
    if (false === acceptstatus) {
      handleError(strings.term.alert);
      return;
    }
    if (!this.state.customerConfirm) {
      $("#confirmmodal").modal("show");
      return;
    }

    if (
      this.state.book3Obj.voucher_usable === "cargroup" &&
      !this.validateVoucherCargroup()
    ) {
      handleError("กรุณากรอก Voucher ให้ครบถ้วน");
      return;
    }

    //   console.log(this.state.isBsActive);
    //   console.log(this.state.isGpsActive);
    if (this.state.isDebitor && !this.state.validPO) {
      handleError("กรุณากรอกเลขที่ใบ PO ให้ถูกต้อง");
      return;
    }
    if (this.state.validPO) {
      localStorage.setItem("po_no", this.state.validPO);
    }

    var arrExtraSelected = [];
    var bs = 0;
    var selectedBs = "";
    if (this.state.isBsActive === true) {
      bs = 1;
      selectedBs = (bs * this.state.isBabySeat).toString();
      arrExtraSelected[arrExtraSelected.length] = selectedBs;
    } else {
      arrExtraSelected[arrExtraSelected.length] = selectedBs;
    }

    var extraGps = "";
    if (this.state.isGpsActive === true) {
      extraGps = "1";
      arrExtraSelected[arrExtraSelected.length] = extraGps;
    } else {
      arrExtraSelected[arrExtraSelected.length] = extraGps;
    }

    var paramExtraLoop = [];
    //   Object.keys(this.state.extraObj).map((key, i) => {
    //       paramExtraLoop[i] = key
    //   });

    Object.keys(this.state.extraObj).map((key, i) => (paramExtraLoop[i] = key));

    var eachVoucher = "";
    // console.log(this.state.voucher.length);
    if (this.state.voucher.length > 0) {
      for (let index = 0; index < this.state.voucher.length; index++) {
        if (index === 0) {
          eachVoucher += this.state.voucher[index].Code;
        } else {
          eachVoucher += `,${this.state.voucher[index].Code}`;
        }
      }
    }

    var paramVoucher = `&Voucher=${eachVoucher}`;
    var lastString = paramVoucher[paramVoucher.length - 1];
    if (lastString === ",") {
      paramVoucher = paramVoucher.substr(0, paramVoucher.length - 1);
    }

    // console.log(this.state.total_option.toFixed(2));

    // if(!localStorage.login_token || !localStorage.member_name)
    // {
    //   this.props.history.push({pathname:"/login-booking",state: {}});
    // }
    // else
    // {
    //   this.props.history.push({pathname:"/bookingstep4",state: {}});
    // }

    this.props.history.push({ pathname: "/bookingstep4", state: {} });
  }
  openTermModal = () => {
    const $ = window.$;
    $("#myModal").modal("show");
  };
  closeTermModal = () => {
    const $ = window.$;
    $("#myModal").modal("hide");
  };

  createVoucherUI() {
    return this.state.voucher.map((el, i) => (
      <div
        key={i}
        className="row Book3ContentA-mo-padr-vouc"
        style={{
          marginTop: "0",
          marginLeft: "0",
        }}
      >
        <div className="col-md-3 Book3ContentA-repad-movouc">
          <input
            type="text"
            className="Book3ContentA-input-code"
            placeholder={strings.voucher.fill}
            name="Code"
            value={el.Code || ""}
            onChange={this.handleChange.bind(this, i)}
            autoComplete="off"
            disabled={this.state.voucher[i].Value > 0 ? true : false}
            style={{ background: "#fff" }}
          />
        </div>
        <div className="col-md-3 Book3ContentA-txt-mar Book3ContentA-wtxt-vouc">
          <div className="Book3ContentA-float-mobile">
            <input
              type="text"
              className="Book3ContentA-input-value"
              placeholder={strings.voucher.value}
              name="Value"
              value={el.Value || ""}
              onChange={this.handleChange.bind(this, i)}
              readOnly
            />
          </div>
          <div className="Book3ContentA-conchk-mobile Book3ContentA-mobile-350">
            <img
              // style={{display: this.state.voucher[i].Value !== "" ? 'initial' : "none"}}
              src={this.state.preFixImg + "images/BookingStep3/Correct@2x.png"}
              width="30px"
              className="Book3ContentA-chk-mobile"
              alt="Logo"
            />
            <img
              // style={{display: this.state.voucher[i].Value !== "" ? 'none' : "initial"}}
              src={
                this.state.preFixImg + "images/BookingStep3/UnCorrect@2x.png"
              }
              width="25px"
              className="Book3ContentA-chk-mobile"
              alt="Logo"
            />
          </div>
        </div>
        <div className="col-md-4 Book3ContentA-mobile-chk Book3ContentA-repadl-voucV2">
          <div>
            <input
              type="button"
              value={strings.voucher.check}
              className="Book3ContentA-con-btna Book3ContentA-mobtna-vouc"
              onClick={this.handleCheckCode.bind(
                this,
                i,
                this.state.voucher[i].Code,
                this.state.voucher[i].Value
              )}
              disabled={this.state.voucher[i].Value > 0 ? true : false}
              style={{
                background:
                  this.state.voucher[i].Value > 0 ? "#e9e9e9" : "#01adef",
                borderColor:
                  this.state.voucher[i].Value > 0 ? "#e9e9e9" : "#01adef",
              }}
            />
            <input
              type="button"
              value={strings.voucher.delete}
              className="Book3ContentA-con-btnb Book3ContentA-mobtna-vouc"
              onClick={this.removeClick.bind(this, i)}
            />

            {(() => {
              switch (this.state.voucher[i].Status) {
                case "success":
                  return (
                    <img
                      src={
                        this.state.preFixImg +
                        "images/BookingStep3/Correct@2x.png"
                      }
                      width="30px"
                      className="Book3ContentA-chk-voucher"
                      alt="Logo"
                    />
                  );
                case "fail":
                  return (
                    <img
                      src={
                        this.state.preFixImg +
                        "images/BookingStep3/UnCorrect@2x.png"
                      }
                      // style={{opacity: this.state.voucher[i].Value > 0 ? "1" : "0"}}
                      width="25px"
                      className="Book3ContentA-chk-voucher"
                      alt="Logo"
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
          <div />
        </div>
      </div>
    ));
  }

  createVoucherCarGroupUI() {
    let menuItems = [];
    let html;
    for (var i = 0; i <= this.state.book3Obj.rate.days - 1; i++) {
      html = (
        <div
          key={i}
          className="row Book3ContentA-mo-padr-vouc"
          style={{
            marginTop: "0",
            marginLeft: "0",
          }}
        >
          <div className="col-md-3 Book3ContentA-repad-movouc">
            <input
              type="text"
              className="Book3ContentA-input-code"
              placeholder={strings.voucher.fill}
              name="Code"
              value={this.state.voucher[i] ? this.state.voucher[i].Code : ""}
              onChange={this.handleChange.bind(this, i)}
              autoComplete="off"
              disabled={
                this.state.voucher[i] && this.state.voucher[i].Value > 0
                  ? true
                  : false
              }
              style={{ background: "#fff" }}
            />
          </div>
          <div className="col-md-3 Book3ContentA-txt-mar Book3ContentA-wtxt-vouc">
            <div className="Book3ContentA-float-mobile">
              <input
                type="text"
                className="Book3ContentA-input-value"
                placeholder={strings.voucher.value}
                name="Value"
                value={
                  this.state.voucher[i] && this.state.voucher[i].Value !== 0
                    ? this.state.voucher[i].Value
                    : ""
                }
                onChange={this.handleChange.bind(this, i)}
                readOnly
              />
            </div>
            <div className="Book3ContentA-conchk-mobile Book3ContentA-mobile-350">
              <img
                // style={{display: this.state.voucher[i].Value !== "" ? 'initial' : "none"}}
                src={
                  this.state.preFixImg + "images/BookingStep3/Correct@2x.png"
                }
                width="30px"
                className="Book3ContentA-chk-mobile"
                alt="Logo"
              />
              <img
                // style={{display: this.state.voucher[i].Value !== "" ? 'none' : "initial"}}
                src={
                  this.state.preFixImg + "images/BookingStep3/UnCorrect@2x.png"
                }
                width="25px"
                className="Book3ContentA-chk-mobile"
                alt="Logo"
              />
            </div>
          </div>
          <div className="col-md-4 Book3ContentA-mobile-chk Book3ContentA-repadl-voucV2">
            <div>
              <input
                type="button"
                value={strings.voucher.check}
                className="Book3ContentA-con-btna Book3ContentA-mobtna-vouc"
                onClick={
                  this.state.voucher[i]
                    ? this.handleCheckCode.bind(
                        this,
                        i,
                        this.state.voucher[i].Code,
                        this.state.voucher[i].Value
                      )
                    : null
                }
                disabled={
                  this.state.voucher[i] && this.state.voucher[i].Value > 0
                    ? true
                    : false
                }
                style={{
                  background:
                    this.state.voucher[i] && this.state.voucher[i].Value > 0
                      ? "#e9e9e9"
                      : "#01adef",
                  borderColor:
                    this.state.voucher[i] && this.state.voucher[i].Value > 0
                      ? "#e9e9e9"
                      : "#01adef",
                }}
              />

              {this.state.voucher[i] &&
                (() => {
                  switch (this.state.voucher[i].Status) {
                    case "success":
                      return (
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep3/Correct@2x.png"
                          }
                          width="30px"
                          className="Book3ContentA-chk-voucher"
                          alt="Logo"
                        />
                      );
                    case "fail":
                      return (
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep3/UnCorrect@2x.png"
                          }
                          // style={{opacity: this.state.voucher[i].Value > 0 ? "1" : "0"}}
                          width="25px"
                          className="Book3ContentA-chk-voucher"
                          alt="Logo"
                        />
                      );
                    default:
                      return null;
                  }
                })()}
            </div>
            <div />
          </div>
        </div>
      );
      menuItems.push(html);
    }
    return menuItems;
  }

  handleChange(i, e) {
    const { name, value } = e.target;
    let voucher = [...this.state.voucher];
    voucher[i] = { ...voucher[i], [name]: value };
    this.setState({ voucher });
  }
  removeClick(i) {
    this.state.voucher.splice(i, 1);

    booking_voucher = {};
    booking_voucher = this.state.voucher;

    this.SumVoucher();
    booking_voucher.forEach(function (item, key) {
      if (item.Code === "") {
        booking_voucher.splice(key, 1);
      }
    });

    localStorage.setItem("booking_voucher", JSON.stringify(booking_voucher));
    // console.log(this.state.testv);
    //     this.setState({
    //        testv: {...this.state.testv, Code: "", Value: 5555 , Status: ""}
    //     })
    // return false;
    if (this.state.voucher.length < 1) {
      this.setState({
        voucher: [{ Code: "", Value: 0, Status: "" }],
      });
    }

    // let voucher = [...this.state.voucher];
    // voucher.splice(i, 1);
    // this.setState({ voucher });

    // this.SumVoucher(this.state.voucher[i].Value);
  }

  handleRate(e) {
    const $ = window.$;
    // let total_option = 0;
    let isBabySeat = this.state.isBabySeat;
    // let data_type = e.target.dataset.type;
    if (e) {
      booking_option["extra"] = {};
      booking_option["insurance"] = {};
      booking_temp["option"]["extra"] = {};
      booking_temp["option"]["insurance"] = {};
      if (e.target.dataset.type === "insurance") {
        $(".chk_insurance").not(e.target).prop("checked", false);
      }
    }

    $(".chk_option:checked").each(function () {
      let data_type_temp = $(this).data("type");

      if (this.name === "BS") {
        // booking_temp["option"][data_type_temp][this.name] = isBabySeat;
        booking_option[data_type_temp][this.name] = isBabySeat;
      } else {
        // booking_temp["option"][data_type_temp][this.name] = 1;
        booking_option[data_type_temp][this.name] = 1;
      }
    });

    localStorage.setItem("booking_temp", JSON.stringify(booking_temp));
    localStorage.setItem("booking_option", JSON.stringify(booking_option));
    this.setState({
      // total_option: total_option * this.state.isTotalDay
    });

    if (e && e.target.name === "BS") {
      if (e.target.checked === true) {
        this.setState({
          isBsActive: 1,
        });
      } else {
        this.setState({
          isBsActive: 0,
        });
      }
    }

    this.fecthBook3();
  }

  SumVoucher(value) {
    var arr = [];
    var sum = 0;
    var assum = 0;

    this.state.voucher.map((item, i) => {
      if (item.Value === "") {
        item.Value = 0;
      }
      arr[i] = item.Value;
      return item;
    });

    for (let index = 0; index < arr.length; index++) {
      sum += arr[index];
    }
    if (value === undefined) {
      value = 0;
    }
    assum = sum - value;
    // console.log(this.state.cdp + "----------")

    this.setState({
      isSumVoucher: this.state.cdp === "PAYLATER" ? 0 : assum,
    });
  }

  RenderExtra() {
    if (this.state.extraObj instanceof Object) {
      return Object.keys(this.state.extraObj).map((key, i) => {
        var element = "";
        var addClass = "";
        if (key !== "BS")
        return;
        if (key === "BS") {
          element = (
            <select
              name="baby-seat"
              className="Book3ContentA-set-select"
              value={this.state.isBabySeat}
              onChange={(e) => {
                this.handleBSChange(e);
              }}
            >
              <option value="1">01</option>
              <option value="2">02</option>
              <option value="3">03</option>
            </select>
          );
        }
        if (i > 0) {
          addClass = " Book3ContentA-chk-condot-ab";
        }

        return (
          <div key={i}>
            <div className="Book3ContentA-flo-mar">
              <span className="Book3ContentA-span-blue">
                {this.state.extraObj[key].value}{" "}
                {strings.booking_step.thb_per_day}
              </span>
            </div>

            <div className="checkbox Filter-conmart-same Book3ContentA-concheck">
              <label style={{ right: "25px" }}>
                <input
                  type="checkbox"
                  className="chk_option"
                  data-type="extra"
                  defaultChecked={booking_option["extra"][key] ? "true" : ""}
                  value={this.state.extraObj[key].value}
                  name={key}
                  onChange={(e) => {
                    this.handleRate(e);
                  }}
                />
                <span className="cr">
                  <i
                    className={
                      "fa fa-circle cr-icon Book3ContentA-chk-condot Book3ContentA-chk-condot-a" +
                      addClass
                    }
                    style={{}}
                  />
                </span>
                <img
                  // src={this.state.preFixImg+"images/BookingStep3/.png"}
                  src={`${this.state.preFixImg}images/BookingStep3/${key}.png`}
                  width="35px"
                  className="Book3ContentA-img-marr"
                  alt="Logo"
                />
                <span className="Filter-confont-same Book3ContentA-confont-same">
                  {this.state.extraObj[key].desc}
                </span>
              </label>
              {this.state.isBsActive || booking_option.extra["BS"]
                ? element
                : ""}
            </div>
          </div>
        );
      });
    }
  }

  RenderInsurance() {
    if (this.state.insuranceObj instanceof Object) {
      return Object.keys(this.state.insuranceObj).map((key, i) => {
        // console.log(this.state.insuranceObj[key].included);

        let result;

        if (this.state.insuranceObj[key].included === "Y") {
          //   		booking_option['extra'] = {};
          //    booking_option['insurance'] = {};
          //    booking_temp["option"]['extra'] = {};
          //    booking_temp["option"]['insurance'] = {};
          //    booking_option['insurance'][key] = 1;
          // localStorage.setItem("booking_option", JSON.stringify(booking_option));
        }

        if (
          this.state.insuranceObj[key].included !== "Y" &&
          this.state.insuranceObj[key].included !== "N" &&
          parseFloat(this.state.insuranceObj[key].value) !== 0
        ) {
          result = (
            <div key={i}>
              <div className="Book3ContentA-flo-mar">
                <span className="Book3ContentA-span-blue">
                  {this.state.insuranceObj[key].value}{" "}
                  {strings.booking_step.thb_per_day}
                </span>
              </div>

              <div className="checkbox Filter-conmart-same Book3ContentA-concheck">
                <label style={{ right: "25px" }}>
                  <input
                    type="checkbox"
                    name={key}
                    data-type="insurance"
                    className="chk_option chk_insurance"
                    defaultChecked={
                      booking_option["insurance"][key] ? "true" : ""
                    }
                    value={this.state.insuranceObj[key].value}
                    onChange={(e) => {
                      this.handleRate(e);
                    }}
                  />
                  <span className="cr">
                    <i
                      className={
                        "fa fa-circle cr-icon Book3ContentA-chk-condot Book3ContentA-chk-condot-a"
                      }
                      style={{}}
                    />
                  </span>
                  <img
                    src={
                      this.state.preFixImg +
                      "images/BookingStep3/icon-insurance.png"
                    }
                    width="35px"
                    className="Book3ContentA-img-marr"
                    alt="Logo"
                  />
                  <span className="Filter-confont-same Book3ContentA-confont-same">
                    {key}
                  </span>
                </label>
              </div>
            </div>
          );
        }

        return result;
      });
    }
  }

  render() {
    let total_amount = this.state.book3Obj.rate.total - this.state.isSumVoucher;
    total_amount = total_amount < 0 ? 0 : total_amount;
    return (
      <div>
        <Filter urlData={this.props.location.search} />

        <div className="wrapper">
          <div className="content-area BookHeaderStatus-setpadb-conarea">
            <div className="container BookHeaderStatus-repad-statusbar set-container-mobile">
              <div
                className="row BookHeaderStatus-row"
                style={{ height: "110px" }}
              >
                <div className="col-xs-1 BookHeaderStatus-hidemo" />
                <div
                  className="col-xs-10 BookHeaderStatus-reset-col"
                  style={{ marginTop: "8px" }}
                >
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <div className="BookHeaderStatus-circle">
                      {/* <i class="fa fa-check-circle" /> */}
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/AlreayStatus@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      1
                    </span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <Link
                      to={{
                        pathname: "/bookingstep2",
                        search: this.state.isParam,
                      }}
                    >
                      <i className="fa fa-chevron-right" />
                      <div className="BookHeaderStatus-circle">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep2/AlreayStatus@2x.png"
                          }
                          width="100%"
                          className="BookHeaderStatus-img-mobile"
                          alt="Status"
                        />
                      </div>
                      <span className="BookHeaderStatus-con-span">
                        <span className="wrap_step_txt">
                          {strings.booking_step.step}
                        </span>{" "}
                        2
                      </span>
                    </Link>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ color: "#fff" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/CurrentStatus-3@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      3
                    </span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ opacity: "0.4" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/UnReadyStatus-4@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      4
                    </span>
                  </div>
                </div>
                <div className="col-xs-1" />
              </div>
            </div>
            <div
              className="container BookingStep3-re-contain"
              style={{
                marginTop: "0",
              }}
            >
              <div
                className="row"
                style={{
                  margin: "0 0",
                  paddingBottom: "30px",
                }}
              >
                <div className="col-md-9 BookingStep3-set-mar BookingStep3-re-padl BookingStep3-mobile-padl BookingStep3-repadl-contain">
                  <div className="col-md-8 BookingStep3-set-mar BookingStep3-re-padl BookingStep3-repad-contain">
                    <img
                      src={this.state.book3Obj.detail.picture_cover}
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="col-md-4 BookingStep3-set-mar BookingStep3-repadrl-pro BookingStep3-mobile-marl BookingStep3-setgbw-make">
                    <div className="BookingStep3-marb-pro">
                      <span className="BookingStep3-span-head">
                        {this.state.book3Obj.detail.title}
                      </span>
                      <br />
                      <span className="BookingStep3-con-spanh">
                        {this.state.book3Obj.detail.engine}{" "}
                        {this.state.book3Obj.detail.type}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        alt="Status"
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                      />
                      <span className="BookingStep3-con-span">
                        {this.state.book3Obj.detail.size}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {strings.car_details.seat} :{" "}
                        {this.state.book3Obj.detail.seat}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {strings.car_details.door} :{" "}
                        {this.state.book3Obj.detail.door}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {strings.car_details.air_condition} :{" "}
                        {this.state.book3Obj.detail.air_condition === "Y" ? (
                          <i className="fa fa-check"></i>
                        ) : (
                          <i className="fa fa-times"></i>
                        )}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {strings.car_details.transmission} :{" "}
                        {this.state.book3Obj.detail.tranmission}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {strings.car_details.luggage} :{" "}
                        {this.state.book3Obj.detail.luggage}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                    <div>
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep3/Check-red@2x.png"
                        }
                        className="BookingStep3-marr"
                        alt="Status"
                      />
                      <span className="BookingStep3-con-span">
                        {this.state.book3Obj.detail.radio_type}
                      </span>
                    </div>
                    <div className="BookingStep3-hr" />
                  </div>
                  <div className="col-md-12 Book3ContentA-repad-infos">
                    {!this.state.isDebitor && (
                      <div className="Book3ContentA-setbgW Book3ContentA-setbgW-mopad">
                        <div className="Book3ContentA-head-option">
                          {strings.booking_step.choose_your_extra_option}{" "}
                          <a
                            href="#optionModal"
                            className="info-icon"
                            data-toggle="modal"
                            data-target="#optionModal"
                            style={{ color: "#FFF", marginLeft: "20px" }}
                          >
                            i
                          </a>
                        </div>
                        <div className="Book3ContentA-pad-info">
                          <div className="col-md-6 Book3ContentA-repadchoos-info">
                            {this.RenderExtra()}
                          </div>
                          <div className="col-md-6 Book3ContentA-repadchoos-info">
                            {this.RenderInsurance()}
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ clear: "both" }}></div>

                    <div
                      className="Book3ContentA-setbgW Book3ContentA-setbgW-mopad"
                      style={{ marginTop: "30px" }}
                    >
                      <div className="Book3ContentA-head-option">
                        {strings.booking_step.extra_charge}{" "}
                        <a
                          href="#extraChrModal"
                          data-toggle="modal"
                          className="info-icon"
                          data-target="#extraChrModal"
                          style={{ color: "#FFF", marginLeft: "30px" }}
                        >
                          i
                        </a>
                      </div>
                      <div className="col-md-12 Book3ContentA-pad-info2">
                        <div className="Book3ContentA-size-dot" /> &ensp;&ensp;
                        <span className="Book3ContentA-span-gray">
                          Drop Off Fee
                        </span>
                        <br />
                        <div className="Book3ContentA-size-dot" /> &ensp;&ensp;
                        <span className="Book3ContentA-span-gray">
                          Weekend Charge
                        </span>
                        <br />
                        <div className="Book3ContentA-size-dot" /> &ensp;&ensp;
                        <span className="Book3ContentA-span-gray">
                          Peak Period Charge
                        </span>
                      </div>
                    </div>

                    {!this.state.isDebitor ? (
                      <div
                        id="section_voucher"
                        className="Book3ContentA-setbgW Book3ContentA-setbgW-mopad"
                        style={{
                          display:
                            this.state.book3Obj.voucher_usable !== false
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="Book3ContentA-head-option">
                          {strings.voucher.voucher}
                        </div>
                        <div className="Book3ContentA-pad-info">
                          {this.state.book3Obj.voucher_usable === "cargroup"
                            ? this.createVoucherCarGroupUI()
                            : this.createVoucherUI()}

                          <div
                            className="row"
                            style={{
                              marginTop: "0",
                              marginLeft: "0",
                            }}
                          >
                            <div
                              className="col-md-12 Book3ContentA-con-remark"
                              style={{ color: "red" }}
                            >
                              {strings.voucher.remark} :{" "}
                              {strings.booking_step.alert_voucher}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        id="section_po"
                        className="Book3ContentA-setbgW Book3ContentA-setbgW-mopad"
                      >
                        <div className="Book3ContentA-head-option">PO Code</div>
                        <div className="Book3ContentA-pad-info">
                          <PoItem
                            active={this.state.isValidPO}
                            checkClicked={(po) => {
                              this.checkPo(po);
                            }}
                            clearClicked={(po) => {
                              this.clearPo();
                            }}
                          />

                          <div
                            className="row"
                            style={{
                              marginTop: "0",
                              marginLeft: "0",
                            }}
                          ></div>
                        </div>
                      </div>
                    )}

                    <div className="Book3ContentA-setbgW Book3ContentA-setbgW-mopad">
                      <div className="Book3ContentA-head-option">
                        {strings.term.term}
                      </div>
                      <div className="col-md-12 Book3ContentA-pad-info2">
                        <div></div>
                        <div className="Book3ContentA-martb-dont">
                          {strings.term.alert}
                        </div>

                        <div className="Book3ContentA-widchkb">
                          <div className="checkbox">
                            <label style={{ right: "25px" }}>
                              <input
                                type="checkbox"
                                value=""
                                checked={this.state.chkAccept ? "checked" : ""}
                                onClick={() => this.openTermModal()}
                                // defaultChecked={this.state.chkAccept ? true : false}
                                readOnly
                              />
                              <span className="cr">
                                <i
                                  className="cr-icon glyphicon glyphicon-ok"
                                  style={{
                                    top: "0",
                                  }}
                                />
                              </span>
                              <span className="Book3ContentA-span-chkb">
                                {strings.term.accept_information}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="Book3ContentA-linkfoot-cont">
                          <button
                            data-toggle="modal"
                            data-target="#myModal"
                            id="myInput"
                          >
                            {strings.term.more_information} :{" "}
                            <b>{strings.term.term}</b>
                          </button>
                        </div>
                        <br />
                        {this.state.isParam && (
                          <div className="Book3ContentA-btnfoot-content">
                            <Link
                              to={{
                                pathname: "/bookingstep2",
                                search: this.state.isParam,
                              }}
                            >
                              <input
                                type="button"
                                value={strings.booking_step.back}
                                className="Book3ContentA-con-btnb Book3ContentA-mo-btna"
                              />
                            </Link>
                            <button
                              style={{ borderWidth: "0" }}
                              onClick={() => this.handleSubmit()}
                              className="Book3ContentA-con-btna Book3ContentA-mo-btnb"
                            >
                              {strings.booking_step.next}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 BookingStep3-set-mar Book3ContentA-repad-conside">
                  <div className="Book3ContentA-setbgW Book3ContentA-mo-setbgW">
                    <div className="Book3ContentA-head-side-car">
                      <span>{strings.booking_step.car_rental_summary}</span>
                    </div>
                    <div className="Book3ContentA-mar-info">
                      <span className="Book3ContentA-span-blue">
                        {strings.car_details.size}:{" "}
                        {this.state.book3Obj.detail.size}
                      </span>
                      <br />
                      <span>{this.state.book3Obj.detail.title}</span>
                    </div>
                  </div>
                  <div className="Book3ContentA-setbgW Book3ContentA-mo-setbgW">
                    <div className="Book3ContentA-head-side-ren">
                      <span>{strings.booking_step.rental_information}</span>
                    </div>
                    <div className="Book3ContentA-mar-info">
                      <span className="Book3ContentA-span-blue">
                        {strings.booking_step.pick_up}
                      </span>
                      <br />
                      <span>
                        {
                          this.state.isBranches[
                            "'" + this.state.outBranch + "'"
                          ]
                        }
                      </span>
                      <br />
                      {/* <span>27 เม.ย. 2019. 8:30</span> */}
                      <span>
                        {this.state.outDate}
                        {"  "}
                        {this.state.isMinTimea}
                      </span>
                    </div>
                    <div
                      className="Book3ContentA-mar-info"
                      style={{ paddingTop: "0" }}
                    >
                      <span className="Book3ContentA-span-blue">
                        {strings.booking_step.return}
                      </span>
                      <br />
                      <span>
                        {this.state.isBranches["'" + this.state.inBranch + "'"]}
                      </span>
                      <br />
                      {/* <span>28 เม.ย. 2019. 8:30</span> */}
                      <span>
                        {this.state.inDate}
                        {"  "}
                        {this.state.isMinTimeb}
                      </span>
                    </div>
                  </div>
                  <div className="Book3ContentA-setbgW Book3ContentA-mo-setbgW">
                    <div className="Book3ContentA-head-side-rate">
                      <span>{strings.booking_step.price_detail}</span>
                    </div>
                    <div className="Book3ContentA-mar-info Book3ContentA-span-blue">
                      <table>
                        <tbody>
                          <tr>
                            <td>{strings.booking_step.car_price}</td>
                            <td className="Book3ContentA-td-price">
                              {parseFloat(this.state.book3Obj.rate.car_price)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="Book3ContentA-td-unit">
                              {strings.booking_step.thb}
                            </td>
                          </tr>
                          {this.state.partial ? (
                            <tr>
                              <td>{strings.booking_step.partial}</td>
                              <td className="Book3ContentA-td-price">
                                {this.state.partial}
                              </td>
                              <td className="Book3ContentA-td-unit">
                                {strings.booking_step.thb}
                              </td>
                            </tr>
                          ) : null}
                          {this.state.book3Obj.rate.extra &&
                          this.state.book3Obj.rate.extra > 0 ? (
                            <tr>
                              <td>{strings.booking_step.extra_option_price}</td>
                              <td className="Book3ContentA-td-price">
                                {parseFloat(this.state.book3Obj.rate.extra)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                <br />
                              </td>
                              <td className="Book3ContentA-td-unit">
                                {strings.booking_step.thb}
                              </td>
                            </tr>
                          ) : null}

                          {this.state.book3Obj.rate.insurance &&
                          this.state.book3Obj.rate.insurance > 0 ? (
                            <tr>
                              <td>Insurance</td>
                              <td className="Book3ContentA-td-price">
                                {parseFloat(this.state.book3Obj.rate.insurance)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                <br />
                              </td>
                              <td className="Book3ContentA-td-unit">
                                {strings.booking_step.thb}
                              </td>
                            </tr>
                          ) : null}

                          <tr>
                            <td>{strings.booking_step.drop_off_fee}</td>
                            <td className="Book3ContentA-td-price">
                              {parseFloat(this.state.book3Obj.rate.drop_off)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="Book3ContentA-td-unit">
                              {strings.booking_step.thb}
                            </td>
                          </tr>

                          <tr>
                            <td>{strings.booking_step.vat}</td>
                            <td className="Book3ContentA-td-price">
                              {parseFloat(this.state.book3Obj.rate.vat)
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="Book3ContentA-td-unit">
                              {strings.booking_step.thb}
                            </td>
                          </tr>
                          <tr>
                            <td>{strings.voucher.voucher}</td>
                            <td className="Book3ContentA-td-price">
                              - {this.state.isSumVoucher.toFixed(2)}
                            </td>
                            <td className="Book3ContentA-td-unit">
                              {strings.booking_step.thb}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div>
                    <div className="Book3ContentA-head-side-total">
                      <div style={{ float: "right" }}>
                        <span>
                          {parseFloat(total_amount)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          {strings.booking_step.thb}
                        </span>
                      </div>
                      <div>
                        <span>{strings.booking_step.total}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="myModal"
                tabIndex="-2"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{ zIndex: "99999" }}
              >
                <div
                  className="modal-dialog"
                  role="document"
                  style={{ minWidth: "60vw" }}
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close Book3ContentA-conbtn-closemodal"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h4
                        className="modal-title"
                        style={{
                          fontSize: "20px",
                          fontFamily: "Prompt",
                        }}
                      >
                        {strings.term.popup_title}
                      </h4>
                    </div>
                    <div className="modal-body Book3ContentA-modal-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.termContent,
                        }}
                      />
                      <br />
                      <div className="Book3ContentA-repad-infos">
                        <div className="checkbox">
                          <label style={{ right: "25px" }}>
                            <input
                              type="checkbox"
                              value=""
                              onChange={() =>
                                this.setState(
                                  { chkAccept: !this.state.chkAccept },
                                  () => {
                                    this.closeTermModal();
                                  }
                                )
                              }
                            />
                            <span className="cr">
                              <i
                                className="cr-icon glyphicon glyphicon-ok"
                                style={{
                                  top: "0",
                                }}
                              />
                            </span>
                            <span className="Book3ContentA-span-chkb">
                              {strings.term.accept_information}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
                style={{ zIndex: "99999" }}
                id="alert-fail-accept"
              >
                <div className="modal-dialog modal-sm">
                  <div className="modal-content">
                    <div className="modal-header Book3ContentA-rebg-modalh-fail">
                      <button
                        type="button"
                        className="close Book3ContentA-conbtn-closemodal-fail"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body Book3ContentA-setpad-modal-b-fail">
                      <span className="Book3ContentA-conspan-modal-b-fail">
                        <strong>{strings.term.alert}</strong>
                      </span>
                    </div>
                    <div className="modal-footer Book3ContentA-setpad-modal-f-fail">
                      <button
                        type="button"
                        className="btn btn-secondary Book3ContentA-rebuild-btn-modal-f-fail"
                        data-dismiss="modal"
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="extraChrModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="">
                  Extra Charge
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {/*<div className="modal-body">{strings.booking_step.modal_extra_charge}</div>*/}
              <img
                src="images/DROP OFF CHART-02.jpg"
                alt="DROP OFF CHART"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="optionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="">
                  Extra option
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: strings.booking_step.modal_extra_option,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {
          <ConfirmModal
            customerConfirm={() => this.customerConfirm()}
            branch={this.state.isBranches}
            inBranch={this.state.inBranch}
            inDate={this.state.inDate}
            inTime={this.state.isMinTimeb}
            outBranch={this.state.outBranch}
            outTime={this.state.isMinTimea}
            outDate={this.state.outDate}
            total={parseFloat(total_amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          />
        }
      </div>
    );
  }
}
