import React from "react";
import moment from "moment";
import { addDays, addHours } from "date-fns";
// export const TOTAL_TEST = "ssss";
// export let DefaultLang = "th";
let url;
let env;
let siteUrl;
let disable_membership;
if (window.location.hostname === "localhost") {
  url = "https://prod-backoffice.thairentacar.com/index.php/";
  // url = 'http://localhost:8000/';
  env = "uat";
  siteUrl = "http://localhost:3000";
  disable_membership = false;
} else if (
  window.location.hostname === "trac.keeneye.co.th" ||
  window.location.hostname === "prod.thairentacar.com"
) {
  url = "https://prod-backoffice.thairentacar.com/index.php/";
  env = "uat";
  siteUrl = "https://prod.thairentacar.com";
  disable_membership = false;
} else if (window.location.hostname === "thairentacar.com") {
  url = "https://backoffice.thairentacar.com/index.php/";
  env = "production";
  siteUrl = "https://thairentacar.com";
  disable_membership = true;
}

export const is_disable_membership = disable_membership;
export const PREFIX_ADDRESS = url;
export const PREFIX_CONTENT_ADDRESS =
  "https://backoffice.thairentacar.com/index.php/";
export const SITE_ENV = env;
export const SITE_URL = siteUrl;
export const rangeMaxReserveDay = 360;
export const ADVANCE_BOOKING_HOUR = 3;
export let SetDefaultLang = () => {
  let DefaultLang = "th";
  const query = new URLSearchParams(window.location.search);
  // console.log(query.get("lang"));
  if (
    query.get("lang") === "en" ||
    query.get("lang") === "th" ||
    query.get("lang") === "cn"
  ) {
    localStorage.setItem("lang", query.get("lang"));
  }

  if (localStorage.getItem("lang") === null) {
    localStorage.setItem("lang", DefaultLang);
  }
};

export const RenderOptionSelectMin = () => {
  var arr = [];

  for (let i = 0; i <= 59; i += 15) {
    if (i < 10) {
      arr.push(
        <option key={i} value={"0" + i}>
          0{i}
        </option>
      );
    } else {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }
  return arr;
};
export const calculatePickerTime = ({
  restrict_time = 0,
  pickup_end_time = 20,
}) => {
  let today = new Date();
  let startTime = moment().add(restrict_time, "hours");
  let startDate = today;
  let startHour = "08";
  let startMinute = "00";
  if (
    startTime.hour() >= pickup_end_time + 1 ||
    (startTime.hour() === pickup_end_time + 1 && startTime.minute() > 45)
  ) {
    startDate = addDays(today, 1);
    // console.log("calculatePickerTime", "next day")
  } else {
    startDate = today;
    // console.log("calculatePickerTime", "today", pickup_end_time)
    startHour = startTime.hour();
    if (startTime.minute() > 45) {
      startMinute = "00";
    } else if (startTime.minute() > 30) {
      startMinute = "45";
    } else if (startTime.minute() > 15) {
      startMinute = "30";
    } else if (startTime.minute() > 0) {
      startMinute = "15";
    }
  }
  return { startDate, startHour, startMinute };
};
export const RenderOptionSelectHr = ({ min = 0, max = 23 }) => {
  var arr = [];

  for (let i = min; i <= max; i++) {
    if (i < 10) {
      arr.push(
        <option key={i} value={"0" + i}>
          0{i}
        </option>
      );
    } else {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }
  return arr;
};

export const handleError = (string) => {
  const $ = window.$;
  $("#alertmodal").modal("show");
  $("#alertmodal .modal-body").html(string);
  // alert(333);
  return;
};

export const handleProfileError = (string) => {
  const $ = window.$;
  $("#alertProfilemodal").modal("show");
  $("#alertProfilemodal .modal-body").html(string);

  return;
};
