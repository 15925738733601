import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./css/FAQ.css";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS } from "../../constants";
let curLang = localStorage.getItem("lang");
const AnyReactComponent = ({ text }) => (
  <div>
    <img className="myMarker" alt="marker" src="images/marker-blue.png" />
  </div>
);
export default class CookiePolicy extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      Loading: false,
      branchApi: PREFIX_ADDRESS + "api/branch?lang=" + curLang,
      content: {},
    };
  }

  componentDidMount() {
    // const $ = window.$;
    this._isMounted = true;
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.fetchContent();
  }

  fetchContent() {
    const apiurl = PREFIX_ADDRESS + "api/cookie_policy?lang=" + curLang;
    console.log("apiurl", apiurl);
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        // console.log(data)
        this.setState({
          content: data,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  componentWillUnmount() {
    // Scroll
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div style={{ background: "#fff" }}>
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="FAQContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 FAQContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div className="container-title FAQContentA-contain-default">
                        <h1 style={{ fontSize: "50px" }}>
                          <strong>{this.state.content.title ?? ""}</strong>
                        </h1>
                        <div className="hr-promo FAQContentA-conhr-head" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container">
                {this.state.content.detail && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.content.detail.replace(
                        /(<? *script)/gi,
                        ""
                      ),
                    }}
                  ></div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
