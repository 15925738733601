import React, { Component } from "react";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import FooterForm from "./FooterForm";
import AlertModal from "./components/Modal/AlertModal";
import AlertProfileModal from "./components/Modal/AlertProfileModal";
import { SITE_URL } from "./constants";
import { initGA } from "./ga-utils";
import * as ReactGA from "react-ga";
import MessengerCustomerChat from "react-messenger-customer-chat";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { PREFIX_ADDRESS } from "./constants";
import "./css/footer.css";
import axios from "axios";
dom.watch();
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      transform: "-100px",
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleToTop = this.handleToTop.bind(this);
  }

  // Scroll
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const isConsent = getCookieConsentValue("consent-cookie");
    if (isConsent === "true") {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  handleLang(lang) {
    localStorage.setItem("lang", lang);
    var re = new RegExp("lang=(\\w{2})");
    var urlRedirect = window.location.href.replace(re, "");
    window.location.href = urlRedirect;

    this.setState({
      isLang: lang,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    var element = document.getElementById("undefined-sticky-wrapper");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      element.classList.add("is-sticky");
      this.setState({
        transform: "15px",
      });
    } else {
      element.classList.remove("is-sticky");
      this.setState({
        transform: "-100px",
      });
    }
  }
  // !Scroll

  // Click
  handleToTop() {
    window.scrollTo(0, 0);
  }

  async storeConsentResult(result) {
    await axios.get("https://geolocation-db.com/json/").then((res) => {
      const ip = res.data.IPv4;
      const apiurl = PREFIX_ADDRESS + "api/cookieConsent";
      if (result === "accept") {
        if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
          ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      } else {
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid");
      }
      axios.post(apiurl, { ipAddress: ip, result });
    });
  }
  // !Click

  render() {
    return (
      <div className="wrapper">
        <CookieConsent
          onAccept={() => this.storeConsentResult("accept")}
          onDecline={() => this.storeConsentResult("decline")}
          enableDeclineButton
          location="bottom"
          buttonText={this.props.localize.consent_accept}
          declineButtonText={this.props.localize.consent_decline}
          containerClasses="col-lg-12 consent_cookie_container"
          contentClasses="col-lg-12 consent_cookie_content"
          cookieName="consent-cookie"
          style={{
            background: "#F2F2F2",
            color: "#555",
            paddingBottom: 0,
          }}
          buttonStyle={{
            color: "#FFF",
            fontSize: "13px",
            backgroundColor: "#023a72",
            // position: "absolute",
            // bottom: 10,
            // left: "40%",
          }}
          declineButtonStyle={{
            color: "#FFF",
            fontSize: "13px",
            backgroundColor: "#023a72",
          }}
          buttonWrapperClasses="wrapper_consent_button"
          expires={150}
        >
          {this.props.localize.consent_cookie}

          <Link
            to={this.state.preFixPath + "/cookie-policy"}
            replace
            onClick={this.handleCurPosition}
          >
            {" "}
            {this.props.localize.link}
          </Link>
        </CookieConsent>
        {process.env.NODE_ENV === "production" ? (
          <MessengerCustomerChat
            pageId="142558015760298"
            appId="181006856480214"
            htmlRef="Test"
          />
        ) : null}

        <div className="content-area content-area-setindex">
          <section className="page-section dark">
            <div className="container-fluid Footer-fluid">
              <div className="row">
                <div
                  className="col-md-4 wow fadeInRight copy-footer"
                  data-wow-offset="200"
                  data-wow-delay="200ms"
                >
                  <Link
                    to={this.state.preFixPath + "/"}
                    replace
                    onClick={this.handleCurPosition}
                  >
                    <img
                      alt="logo"
                      src={this.state.preFixImg + "images/Image-footer.png"}
                      className="logo-footer-th"
                    />
                  </Link>
                  <p style={{ marginBottom: "15px" }}>
                    Copyrights © 2015 All rights reserved,
                    <br />
                    THAI RENT A CAR CORPORATION CO.,LTD.
                    <br />
                    288/8 Romklao Rd., Klongsamprawet Latkrabang, Bangkok 10520
                    Thailand
                  </p>
                  <div style={{ marginBottom: "15px" }}>
                    <div>
                      <Link to={"/cookie-policy"}>Cookie policy</Link>
                    </div>
                    <div>
                      <Link to={"/policy-notice"}>Privacy policy</Link>
                    </div>
                    {/* <div>
                      <Link to={"/privacy-policy-and-term"}>
                        Term and Condition
                      </Link>
                    </div> */}
                  </div>
                  <ul className="social-icons">
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => this.handleLang("th")}
                        className="facebook"
                      >
                        <img
                          alt="logo-th"
                          src={this.state.preFixImg + "images/logo-th@2x.png"}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => this.handleLang("en")}
                        className="facebook"
                      >
                        <img
                          alt="logo-usa"
                          src={this.state.preFixImg + "images/logo-usa@2x.png"}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => this.handleLang("cn")}
                        className="facebook"
                      >
                        <img
                          alt="logo-chinese"
                          src={this.state.preFixImg + "images/logo-chi@2x.png"}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="col-md-4 wow fadeInLeft menu-footer"
                  data-wow-offset="200"
                  data-wow-delay="200ms"
                  // style={{ paddingLeft: "50px" }}
                >
                  <h2 style={{ marginRight: "28px" }}>
                    {this.props.localize.menu}
                  </h2>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        {/*<li>
                                                    <Link
                                                        to={
                                                            this.state
                                                                .preFixPath +
                                                            "/ourservice"
                                                        }
                                                        replace
                                                    >
                                                        {this.props.localize.fleet_service}
                                                    </Link>
                                                </li>*/}
                        <li>
                          <Link
                            to={this.state.preFixPath + "/enterprise"}
                            replace
                          >
                            {this.props.localize.coporate_solution}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={this.state.preFixPath + "/promotion"}
                            replace
                          >
                            {this.props.localize.promotion}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/branch"} replace>
                            {this.props.localize.branches}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/about"} replace>
                            {this.props.localize.about_us}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6" style={{ padding: "0" }}>
                      <ul>
                        <li>
                          <Link to={this.state.preFixPath + "/review"} replace>
                            {this.props.localize.news_activities}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/partner"} replace>
                            {this.props.localize.partnership}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/faq"} replace>
                            {this.props.localize.faq}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/career"} replace>
                            {this.props.localize.career}
                          </Link>
                        </li>
                        <li>
                          <Link to={this.state.preFixPath + "/contact"} replace>
                            {this.props.localize.contact_us}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="hr-for-mobile-footer" />

                <div
                  className="col-md-4 wow fadeInLeft copy-footer"
                  data-wow-offset="200"
                  data-wow-delay="200ms"
                >
                  <h2
                    style={{
                      marginRight: "28px",
                      marginBottom: "30px",
                      marginTop: "0px",
                    }}
                  >
                    {this.props.localize.follow_us}
                  </h2>
                  <div className="row p-5" style={{ marginTop: "20px" }}>
                    <div
                      className=""
                      style={{ display: "inline-block", width: "auto" }}
                    >
                      <Link
                        to={{
                          pathname: "https://www.facebook.com/ThaiRentACar",
                        }}
                        target="_blank"
                      >
                        <img
                          src="/images/Footer/facebook.png"
                          alt="Facebook Icon Footer"
                          width={52}
                          height={52}
                          style={{
                            marginRight: "17px",
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className=""
                      style={{ display: "inline-block", width: "auto" }}
                    >
                      <Link
                        to={{
                          pathname:
                            "https://www.instagram.com/thairentacarclub",
                        }}
                        target="_blank"
                      >
                        <img
                          src="/images/Footer/instagram.png"
                          alt="Instagram Icon Footer"
                          width={52}
                          height={52}
                          style={{
                            marginRight: "17px",
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className=""
                      style={{ display: "inline-block", width: "auto" }}
                    >
                      <Link
                        to={{
                          pathname: "https://twitter.com/thairentacar",
                        }}
                        target="_blank"
                      >
                        <img
                          src="/images/Footer/twitter.png"
                          alt="Twiiter Icon Footer"
                          width={52}
                          height={52}
                          style={{
                            marginRight: "17px",
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className=""
                      style={{ display: "inline-block", width: "auto" }}
                    >
                      <Link
                        to={{
                          pathname:
                            "https://www.youtube.com/channel/UCLEqCwv4eCT_pYkiLX534Zw",
                        }}
                        target="_blank"
                      >
                        <img
                          src="/images/Footer/youtube.png"
                          alt="Youtube Icon Footer"
                          width={52}
                          height={52}
                          style={{
                            marginRight: "17px",
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      className=""
                      style={{ display: "inline-block", width: "auto" }}
                    >
                      <Link
                        to={{
                          pathname: "https://www.tiktok.com/@thairentacarclub",
                        }}
                        target="_blank"
                      >
                        <img
                          src="/images/Footer/tiktok.png"
                          alt="Tiktok Icon Footer"
                          width={52}
                          height={52}
                          style={{
                            marginRight: "17px",
                          }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <FooterForm /> */}
              </div>
            </div>
          </section>
          <AlertModal />
          <AlertProfileModal />
        </div>
        <div
          id="to-top"
          className="to-top"
          style={{ bottom: this.state.transform }}
          onClick={this.handleToTop}
        >
          <i className="fa fa-angle-up" />
        </div>
      </div>
    );
  }
}
