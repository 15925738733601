import React, { Component } from "react";
import "./css/Forgot.css";
import axios from 'axios';
import {strings} from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";
const forgotApi = PREFIX_ADDRESS + "api/member/forgot";

export default class ForgotModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email : ""
    };
  }
  sendForgot = (e) => {
    axios
        .post(forgotApi,{
            email: this.state.email
        },{
          headers: {
            'Access-Control-Allow-Origin': '*',
          }})
        .then(res => {
            const { data } = res;
            console.log(data)
            if(data.status==="success")
            {
              alert("กรุณาตรวจสอบ inbox ของท่านเพื่อสร้างรหัสผ่านใหม่");
            }
            

        })
        .catch(errs => {
            alert("error")
            console.error(errs);
        });
  }
  // componentDidMount() {
  //     setTimeout(() => {
  //         var element = document.getElementById("home");
  //         element.style.paddingRight = "0";
  //     }, 1000);
  // }
  render() {
    return (
      <div
        className="modal fade"
        id="forgotmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        style={{ overflow: "scroll", zIndex: "99999" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-txtheadcen">
              <button
                type="button"
                className="close LoginModal-conbtn-closemodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title LoginModal-title" id="myModalLabel" style={{textTransform:"uppercase"}}>
                {strings.log_in.forgot}
              </h4>
            </div>
            <div className="modal-body Book3ContentA-setpad-modal-b-fail">
              <div className="ForgotModal-setpadmar-default">
                <div
                  className="ForgotModal-confont-info"
                  style={{
                    textAlign: "center",
                    fontSize: "12px",
                    color: "#D2D3D5"
                  }}
                >
                  <span className="" style={{whiteSpace:"pre"}}>{strings.log_in.forgot_message}</span>
                </div>

                <form>
                  <div>
                    <div className="input-group LoginModal-con-inpute">
                      <span
                        className="input-group-addon LoginModal-logo-email"
                        id="basic-addon1"
                      >
                        <img
                          className="LoginModal-logomail-w"
                          src="images/LoginModal/logo-input-mail@2x.png"
                          alt="logo-input-mail"
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control LoginModal-con-border"
                        onChange={(e) => {
                          let value = e.target.value;
                          this.setState({email: value })
                      }}
                        placeholder={strings.log_in.email}
                        aria-describedby="basic-addon1"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        onClick={this.sendForgot}
                        className="btn btn-danger ForgotModal-rebuild-btn-modal-sub"
                        data-dismiss="modal"
                      >
                        {strings.log_in.reset_password}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
