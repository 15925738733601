import React, { Component } from "react";

import "./css/testimonial.css";

class TestimonialBox extends Component {
  constructor(props) {
    super(props);

    let curLang = localStorage.getItem("lang");
    this.state = {
      reviews:
        props.reviews !== undefined ? Math.floor(parseInt(props.reviews)) : 0,
      description: props.description ?? "",
      name: props.name ?? "",
      title: props.title ?? "",
      detail: props.detail ?? "",
      position: props.position ?? "",
      picture_cover: props.picture_cover ?? null,
    };
  }

  render() {
    return (
      <div className="Home-testimonial-box">
        <div className="row">
          <div className="col-sm-12">
            <div className="rating w-100" style={{ direction: "initial" }}>
              {[...Array(5)].map((_, i) => (
                <div
                  key={`rating-${i}`}
                  className={`rating-label ${
                    Math.round(this.state.reviews) >= i + 1 ? "checked" : ""
                  }`}
                  style={{ width: "18px", height: "18px" }}
                >
                  {Math.round(this.state.reviews) >= i + 1 ? (
                    <img
                      key={`star-${i}`}
                      src="/images/star.png"
                      alt="Star Active Icon"
                      width={18}
                      height={18}
                    />
                  ) : (
                    <img
                      key={`star-${i}`}
                      src="/images/star-disabled.png"
                      alt="Star Black Active Icon"
                      width={18}
                      height={18}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-sm-12 description"
            style={{ minHeight: "100px" }}
            dangerouslySetInnerHTML={{ __html: this.state.detail }}
          ></div>
          <div className="col-sm-12">
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="col-xs-3">
                <img
                  className="profile-pic"
                  src={
                    this.state.picture_cover || this.state.picture_cover !== ""
                      ? this.state.picture_cover
                      : "/images/default-placeholder.png"
                  }
                  alt={this.state.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/images/default-placeholder.png";
                  }}
                />
              </div>
              <div
                className="col-xs-9"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div className="name">{this.state.title}</div>
                <div className="position">{this.state.position}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialBox;
