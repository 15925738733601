import React, { useState,useEffect } from 'react';
import {Modal, Button } from 'react-bootstrap';

var AlertModal = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {alert(1)},[])

  return (
    <div
        className="modal fade"
        id="alertmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        style={{ zIndex: "99999" }}
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header modal-txtheadcen">
              <button
                type="button"
                className="close LoginModal-conbtn-closemodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title LoginModal-title" id="myModalLabel">
        
              </h4>
            </div>
            <div className="modal-body" style={{textAlign:"center",padding:"40px 20px"}}>
            </div>
          </div>
        </div>
      </div>
  );
}

export default AlertModal;