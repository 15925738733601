import React, { Component } from "react";
import Modal from "react-modal";
import "./css/promotion.css";
import "./css/home.css";
import { Link } from "react-router-dom";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { addDays } from "date-fns";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  PREFIX_ADDRESS,
  PREFIX_CONTENT_ADDRESS,
  calculatePickerTime,
  RenderOptionSelectMin,
  RenderOptionSelectHr,
  SITE_URL,
  handleError,
  handleProfileError,
} from "../../constants";
import { strings } from "../../language/language";
import OwlCarousel from "react-owl-carousel2";
import {
  fetchSettings,
  fetchBranchTime,
  incrementAsync,
} from "../../actions/settingAction";
import YoutubeBackground from "react-youtube-background";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import TestimonialBox from "./TestimonialBox";
import ServiceBox from "../ServiceBox/ServiceBox";

const token = localStorage.login_token;
const curLang = localStorage.getItem("lang");

dom.watch();

const options = {
  items: 1,
  nav: false,
  autoplay: true,
  loop: true,
};
Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
};
const events = {};

let countz = 0;
const dayofweek = moment().format("E");

class HomeFilter extends Component {
  constructor(props) {
    super(props);

    let curLang = localStorage.getItem("lang");
    let { startDate, startHour, startMinute } = calculatePickerTime(0, 20);
    this.state = {
      loading: false,
      showVideoModal: false,
      preFixAddress: "",
      preFixImg: SITE_URL + "/",
      isCode: "",
      isBranches: "",
      startDatea: startDate,
      currentDatea: "",
      isHrTimea: startHour,
      isMinTimea: startMinute,
      isSameLocation: true,
      minBookingTime: 12,
      isReturn: "",
      startDateb: startDate,
      isHrTimeb: startHour,
      isMinTimeb: startMinute,
      isPromoCode: "",
      selectedPickupLocation: "DEFAULT",
      selectedReturnLocation: "DEFAULT",
      bannerApi: PREFIX_CONTENT_ADDRESS + "api/gallery/home/67?lang=" + curLang,
      promiseIsResolved: null,
      homeObj: {
        picture_cover: "images/bannerHome/1.jpg",
        picture_promotion: "images/ICON-536x371-01.jpg",
        picture_faq: "images/ICON-536x371-02.jpg",
        picture_enterprise: "images/ICON-536x371-03.jpg",
        picture_service: "images/a5719ee681d3979b766f79d640ff8ecd.jpg",
      },
      bannerObj: [],
      content: [],
      isStatusBookNow: "N",
      select_pickup_start_time: 0,
      select_pickup_end_time: 23,
      select_return_start_time: 0,
      select_return_end_time: 23,
      promotions: [],
      partners: [],
      journey: [],
      testimonials: [],
    };
    /// หน้านี้ เหลือ ยิง Api , setState ในส่วนของ Text Our Service ด้านล่างฝั่งซ้าย

    this.handleScroll = this.handleScroll.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onStartVideo = () => {
    this.setState({
      showVideoModal: true,
    });
  };

  onCloseVideo = () => {
    this.setState({
      showVideoModal: false,
    });
  };

  async componentDidMount() {
    const $ = window.$;

    $("#preloader").show();

    // await this.props.fetchSettings()
    // if (!this.props.web_settings) {
    //     this.props.fetchSettings()
    // }
    let g = await this.props.fetchSettings();

    await this.props.fetchBranchTime();
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    // this.fetchHome()
    await this.fetchMainBanner();

    await Promise.all([
      this.fetchLocation(),
      this.fetchContent(),
      this.fetchPromotions(),
      this.fetchPartners(),
      this.fetchJourney(),
      this.fetchTestimonials(),
    ]).then(() => {
      $("#preloader").hide();
    });

    if (this.props.web_settings) {
      let { restrict_time, pickup_end_time } = this.props.web_settings;
      let { startDate, startHour, startMinute } = calculatePickerTime(
        restrict_time,
        pickup_end_time
      );

      this.setState({
        startDatea: startDate,
        isHrTimea: startHour,
        isMinTimea: startMinute,
        startDateb: startDate,
        isHrTimeb: startHour,
        isMinTimeb: startMinute,
      });
    }

    $(".main-slider").owlCarousel({
      items: 1,
      autoplay: true,
      autoplayTimeout: 5000,
    });

    // setTimeout(() => {
    //   this.setState({
    //     loading: false,
    //   });
    // }, 500);
  }

  async fetchContent() {
    const apiurl = PREFIX_CONTENT_ADDRESS + "api/content/home?lang=" + curLang;
    return axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        // console.log(data.content._71)
        this.setState({
          content: { ...this.state.content, ourservice: data.content._71 },
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  openCalendar(type) {
    if (type === "start_date") {
      document.getElementById("pickupdate").click();
    } else if (type === "return_date") {
      document.getElementById("returndate").click();
    }
  }
  async fetchLocation() {
    return axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then((res) => {
      if (res.data.Success === "Y") {
        const { data } = res;
        // console.log(data.Branch[10]);
        this.setState({ isBranches: data.Branch });
      }
    });
  }
  async fetchMainBanner() {
    const uriapi = this.state.bannerApi;
    return axios
      .get(uriapi)
      .then((res) => {
        // console.log("fetchMainBanner")
        let banner = res.data;
        this.setState({ bannerObj: banner, promiseIsResolved: true });
      })
      .catch((err) => {
        console.log(err);
      });

    // return (<div className="main-slider">{bannerItem}</div>);
  }
  async fetchPromotions() {
    const uriapi = `${PREFIX_CONTENT_ADDRESS}api/content/promotion?itemPerPage=3&lang=${curLang}&page=1`;
    return axios
      .get(uriapi)
      .then((res) => {
        // console.log("fetchPromotions")
        let promotions = Object.keys(res.data.content).map((key) => {
          return res.data.content[key];
        });
        this.setState({ promotions: promotions });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async fetchPartners() {
    // FIXME: Currently, this endpoint is promotion endpoint
    const uriapi = `${PREFIX_CONTENT_ADDRESS}api/content/partner?itemPerPage=3&lang=${curLang}&page=1`;
    return axios
      .get(uriapi)
      .then((res) => {
        // console.log("fetchPromotions")
        let partners = Object.keys(res.data.content).map((key) => {
          return res.data.content[key];
        });
        this.setState({ partners: partners });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async fetchJourney() {
    const uriapi = `${PREFIX_CONTENT_ADDRESS}api/content/journey?itemPerPage=3&lang=${curLang}&page=1`;
    return axios
      .get(uriapi)
      .then((res) => {
        // console.log("fetchPromotions")
        let journey = Object.keys(res.data.content).map((key) => {
          return res.data.content[key];
        });
        this.setState({ journey: journey });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async fetchTestimonials() {
    const uriapi = `${PREFIX_CONTENT_ADDRESS}api/content/testimonial?itemPerPage=50&lang=${curLang}&page=1`;
    // const uriapi = `${PREFIX_CONTENT_ADDRESS}api/content/journey?itemPerPage=3&lang=${curLang}&page=1`;
    return axios
      .get(uriapi)
      .then((res) => {
        // console.log("fetchPromotions")
        let testimonials = Object.keys(res.data.content).map((key) => {
          return res.data.content[key];
        });
        this.setState({ testimonials: testimonials });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleScroll() {
    // OnScroll For Sticky Header
    var element = document.getElementById("conanimatetxt-our");
    if (
      document.documentElement.scrollTop > 80 &&
      document.documentElement.clientWidth > 992
    ) {
      element.classList.add("HomeFilter-conanimatetxt-our");
    }
    if (
      document.documentElement.scrollTop > 1000 &&
      document.documentElement.clientWidth < 992
    ) {
      element.classList.add("HomeFilter-conanimatetxt-our");
    }
  }
  componentWillUnmount() {
    // Scroll
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleSameLocation(e) {
    // console.log("in handleSameLocation");
    this.setState({
      isSameLocation: !this.state.isSameLocation,
    });
    const outBranch = this.state.selectedPickupLocation;
    var sameactive = e.target.checked;
    // console.log(sameactive);

    if (sameactive === true) {
      this.setState({
        selectedReturnLocation: outBranch,
        selectedReturnLocationInfo: this.state.selectedPickupLocationInfo,
        select_return_start_time: this.state.select_pickup_start_time,
        select_return_end_time: this.state.select_pickup_end_time,
      });
    } else {
      this.setState({ selectedReturnLocation: "DEFAULT" });
    }
  }

  handleSubmit(e) {
    const $ = window.$;
    $("#preloader").show();
    e.preventDefault();

    var outBranch = this.state.selectedPickupLocation;
    var pickupdate = this.state.startDatea;
    var outTime = `${this.state.isHrTimea}:${this.state.isMinTimea}`;
    var samelo = this.state.isSameLocation;
    var inBranch = this.state.selectedReturnLocation;
    var returndate = this.state.startDateb;
    var inTime = `${this.state.isHrTimeb}:${this.state.isMinTimeb}`;
    var code = this.state.isCode;
    var outDate = moment(pickupdate).format(`DD/MM/YYYY`);
    var inDate = moment(returndate).format(`DD/MM/YYYY`);

    var d1 = new Date(moment(pickupdate).format(`YYYY-MM-DD`));
    var d2 = new Date(moment(returndate).format(`YYYY-MM-DD`));

    if ("DEFAULT" === outBranch) {
      // alert("Please Select Pickup Location");
      //
      handleError("Please Select Pickup Location");
      $("#preloader").hide();
      return;
    }
    if (+d1 > +d2) {
      handleError("Please Check Return Date Again");
      $("#preloader").hide();
      return;
    }
    // if (+d1 === +d2 && (this.state.isHrTimeb - this.state.isHrTimea) < this.props.web_settings.restrict_time) {

    //     handleError(`ระยะเวลาการเช่ารถต้องมากกว่า ${this.props.web_settings.restrict_time} ชั่วโมง`);

    //     $("#preloader").hide();
    //     return
    // }

    if ("DEFAULT" === inBranch) {
      handleError("Please Select Return Location");
      $("#preloader").hide();
      return;
    }
    if (true === samelo) {
      inBranch = outBranch;
      if ("" !== outBranch) {
        inBranch = this.state.selectedPickupLocation;
        this.setState({ selectedReturnLocation: inBranch });
      }
    }

    var picktimeMoment = moment(outTime, "HH:mm");
    var returnMoment = moment(inTime, "HH:mm");
    var branchPickStartTime = moment(
      this.state.selectedPickupLocationInfo.StartTime,
      "HH:mm"
    );
    var branchPickEndTime = moment(
      this.state.selectedPickupLocationInfo.EndTime,
      "HH:mm"
    );
    var branchReturnStartTime = moment(
      this.state.selectedReturnLocationInfo.StartTime,
      "HH:mm"
    );
    var branchReturnEndTime = moment(
      this.state.selectedReturnLocationInfo.EndTime,
      "HH:mm"
    );

    if (
      branchPickEndTime.isBefore(picktimeMoment) ||
      picktimeMoment.isBefore(branchPickStartTime)
    ) {
      handleError(
        `สถานที่รับรถเปิดทำการเวลา ${this.state.selectedPickupLocationInfo.StartTime} - ${this.state.selectedPickupLocationInfo.EndTime}`
      );
      $("#preloader").hide();
      return;
    }

    if (
      branchReturnEndTime.isBefore(returnMoment) ||
      returnMoment.isBefore(branchReturnStartTime)
    ) {
      handleError(
        `สถานที่คืนรถเปิดทำการเวลา ${this.state.selectedReturnLocationInfo.StartTime} - ${this.state.selectedReturnLocationInfo.EndTime}`
      );
      $("#preloader").hide();
      return;
    }

    var difreversecar = addDays(pickupdate, this.props.web_settings.max_period);
    if (returndate > difreversecar) {
      handleError(
        "ระยะเวลาจองรถและคืนรถต้องไม่ห่างกันเกิน " +
          this.props.web_settings.max_period +
          " วัน"
      );
      $("#preloader").hide();
      return;
    }

    const param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&Code=${code}`;

    // console.log(this.props);
    let callback;
    axios
      .get(`${PREFIX_ADDRESS}api/booking/carlist${param}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.data.Success);
        callback = res;
        if (res.data.Success !== undefined) {
          this.setState({
            isStatusBookNow: res.data.Success,
            isMessage: res.data.message,
          });
        }
      })
      .then(() => {
        // console.log("dd");
        // console.log(callback);

        if (callback.data.Success === "Y") {
          // window.location.href = `/bookingstep2${param}`;
          this.props.history.push({ pathname: "/bookingstep2", search: param });
        } else {
          let str_error = strings.error[callback.data.error.message[0]];
          if (str_error === undefined) {
            str_error = callback.data.error.message[0];
          }

          if (callback.data.error.message[0] === "error_start_time_period") {
            str_error = str_error.replace(
              "$1",
              this.props.web_settings.restrict_time
            );
          }

          if (
            callback.data.error.message[0] ===
            "required field need to be filled"
          ) {
            handleProfileError(str_error);
          } else {
            handleError(str_error);
          }

          //
          this.setState({ isCode: "" });
          $("#preloader").hide();
          return;
        }
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  pickupPlaceChange(e) {
    let branchInfo = this.props.branch_time[e.target.value].sch[dayofweek];
    let starthour = moment(branchInfo.StartTime, "hh:mm").hour();
    let endhour = moment(branchInfo.EndTime, "hh:mm").hour();
    this.setState({
      selectedPickupLocation: e.target.value,
      selectedPickupLocationInfo: branchInfo,
      select_pickup_start_time: starthour,
      select_pickup_end_time: endhour,
      isHrTimea: starthour.pad(2),
      isHrTimeb: starthour.pad(2),
    });
    if (this.state.isSameLocation === true) {
      this.setState({
        selectedReturnLocation: e.target.value,
        selectedReturnLocationInfo: branchInfo,
        select_return_start_time: starthour,
        select_return_end_time: endhour,
      });
    }
  }
  dropPlaceChange(e) {
    let branchInfo = this.props.branch_time[e.target.value].sch[dayofweek];
    let starthour = moment(branchInfo.StartTime, "hh:mm").hour();
    let endhour = moment(branchInfo.EndTime, "hh:mm").hour();
    this.setState({
      selectedReturnLocation: e.target.value,
      selectedReturnLocationInfo: branchInfo,
      select_return_start_time: starthour,
      select_return_end_time: endhour,
    });
    if (this.state.isSameLocation === true) {
      this.setState({
        selectedPickupLocation: e.target.value,
        selectedPickupLocationInfo: branchInfo,
        select_pickup_start_time: starthour,
        select_pickup_end_time: endhour,
      });
    }
  }
  RenderPickLocation() {
    var branches = this.state.isBranches;
    const branchOptions = Object.keys(branches).map(
      (key) => (
        <option key={key} value={key.replace(/[^0-9.]/g, "")}>
          {branches[key]}
        </option>
      )
      // <option value={branches[this.state.selectedPickupLocation]} key={key}>{branches[key]}</option>
    );
    return branchOptions;
  }

  mainBanner() {
    countz++;
    if (countz === 0) {
      return null;
    }
    // if(this.state.bannerObj.length>0)
    // {
    //     return null;
    // }

    let banner = this.state.bannerObj;

    let bannerItem2 = [];
    let bz;
    let stylez;
    if (banner.length > 0) {
      console.log("Fire");
      console.log(banner.length);

      for (let i = 0; i <= banner.length; i++) {
        bz = banner[i] ? banner[i]["pic"] : "";

        bannerItem2.push(
          <div className="item slide3 ver3 item-bg" style={stylez} />
        );
      }
    }
    return <div className="main-slider">{bannerItem2}</div>;
  }
  convert_youtube_url = (url) => {
    let patt = /\/(\w*)$/;
    return url.match(patt)[1];
  };

  bannerDisplay = (bannerItem2) => {
    let banner;

    if (
      this.props.web_settings &&
      this.props.web_settings.enable_video &&
      this.props.web_settings.home_video_banner
    ) {
      banner = (
        <YoutubeBackground
          videoId={this.convert_youtube_url(
            this.props.web_settings.home_video_banner
          )}
          className="iframe_banner"
        ></YoutubeBackground>
      );
    } else if (
      this.props.web_settings &&
      !this.props.web_settings.enable_video &&
      this.state.bannerObj.length > 0
    ) {
      banner = (
        <OwlCarousel
          id="home-banner"
          ref="car"
          options={options}
          events={events}
        >
          {bannerItem2}
        </OwlCarousel>
      );
    }
    return banner;
  };

  render() {
    let curLang = localStorage.getItem("lang");
    let folder_banner = curLang === "th" ? "th" : "en";
    // if(this.state.bannerObj.length>0)
    // {
    //     return null;
    // }

    let banner = this.state.bannerObj;

    let bannerItem2 = [];
    let bz;
    let stylez;
    if (banner.length > 0) {
      for (let i = 0; i <= banner.length - 1; i++) {
        bz = banner[i] ? banner[i]["pic"].replace(" ", "%20") : "";

        stylez = {
          backgroundImage: "url(" + bz + ")",
        };

        bannerItem2.push(
          <a
            key={i}
            href={banner[i] && banner[i]["link"] ? banner[i]["link"] : ""}
          >
            <div className="item slide3 ver3 item-bg" style={stylez} />
          </a>
        );
        // console.log(i)
      }
    }

    // if(!this.state.promiseIsResolved){return null}

    return !this.state.loading ? (
      <div>
        <Modal
          center
          isOpen={this.state.showVideoModal}
          onRequestClose={this.onCloseVideo}
          style={{
            overlay: {
              zIndex: 10000,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              overflow: "visible",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              backgroundColor: "#000",
              border: "none",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <div
            className="Home-close-video-popup-button"
            onClick={this.onCloseVideo}
          >
            <img
              src="/images/Home/Close.png"
              alt="Close Button Icon"
              width={40}
            />
          </div>

          <ReactPlayer
            controls
            url={
              this.state.content?.ourservice?.video_url ??
              "https://www.youtube.com/watch?v=Uhv6z32qc8s"
            }
            playing={this.state.showVideoModal}
            width={isMobile ? "auto" : "50vw"}
            height={isMobile ? "40vh" : "50vh"}
          />
        </Modal>

        {/* Banner */}
        <div className="wrapper">
          <div className="content-area">
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <img
                  style={{ width: "100%" }}
                  src="/images/bannerHome/banner_homepage.png"
                  alt="Banner Homepage"
                />
                {/* {this.bannerDisplay(bannerItem2)} */}
              </div>
            </section>
          </div>
        </div>
        {/* Banner */}

        {/* Booking Form */}
        <div className="wrapper">
          <div className="content-area" style={{ background: "#fff" }}>
            <section
              className="page-section sec-a"
              style={{ overflow: "visible" }}
            >
              <div className="container">
                {this.props.web_settings && (
                  <div className="caption main-booking-form">
                    <div
                      className="container"
                      style={{
                        marginTop: "0px",
                      }}
                    >
                      <div className="div-table reset-table">
                        <div
                          className="div-cell HomeFilter-conanimate"
                          style={{ paddingBottom: "25px" }}
                        >
                          <div className="caption-content HomeFilter-conanimate-opa">
                            <div
                              className="form-search light reset-form"
                              style={{ borderRadius: "32px" }}
                            >
                              <form action="">
                                {/* <div className="form-title">
                                  <h2
                                    className="con-book"
                                    style={{ fontFamily: "Prompt" }}
                                  >
                                    BOOK NOW
                                  </h2>
                                </div> */}
                                <div
                                  className="row row-inputs reset-row"
                                  style={{
                                    backgroundColor: "#f8f7f9",
                                    borderRadius: "32px",
                                  }}
                                >
                                  <div className="container-fluid">
                                    {/* Pick Up Location */}
                                    <div
                                      className="col-sm-5 reset-col"
                                      style={{
                                        marginTop: "30px",
                                      }}
                                    >
                                      <div className="form-group has-icon has-label reset-group">
                                        <label
                                          htmlFor="formSearchUpLocation3"
                                          style={{
                                            color: "#01adef",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {strings.book_now.pick_up_location}
                                        </label>
                                        {/* selected={this.state.isPickUp}  */}
                                        <select
                                          name="location"
                                          className="form-control set-placehol"
                                          id="formSearchUpLocation3"
                                          value={
                                            this.state.selectedPickupLocation
                                          }
                                          onChange={(e) =>
                                            this.pickupPlaceChange(e)
                                          }
                                        >
                                          <option
                                            value="DEFAULT"
                                            disabled
                                            hidden
                                          >
                                            Airport or Anywhere
                                          </option>
                                          {this.RenderPickLocation()}
                                        </select>
                                      </div>
                                    </div>
                                    {/* Drop Off Location */}
                                    <div
                                      className="col-sm-5 reset-col"
                                      style={{
                                        marginTop: "30px",
                                      }}
                                    >
                                      <div className="form-group has-icon has-label reset-group">
                                        <div
                                          className="Home-setfloatl-same"
                                          style={{ marginBottom: "5px" }}
                                        >
                                          <label
                                            htmlFor="formSearchUpLocation3"
                                            style={{
                                              color: "#01adef",
                                              fontSize: "16px",
                                            }}
                                          >
                                            {strings.book_now.return_location}
                                          </label>
                                        </div>
                                        <div className="checkbox Home-conmart-same">
                                          <label className="label-same-location">
                                            <input
                                              type="checkbox"
                                              checked={
                                                this.state.isSameLocation
                                              }
                                              value={
                                                this.state.isSameLocation
                                                  ? "1"
                                                  : "0"
                                              }
                                              onChange={(e) =>
                                                this.handleSameLocation(e)
                                              }
                                            />
                                            <span className="cr">
                                              <i
                                                className="cr-icon glyphicon glyphicon-ok"
                                                style={{
                                                  top: "0",
                                                }}
                                              />
                                            </span>
                                            <span className="Home-confont-same">
                                              {
                                                strings.book_now
                                                  .same_location_as_pick_up
                                              }
                                            </span>
                                          </label>
                                        </div>
                                        <div>
                                          {/* disabled={this.state.isSameLocation} style={{background: this.state.isSameLocation ? "#E0DFDB" : "", color: this.state.isSameLocation ? "#E0DFDB" : ""}} */}
                                          <select
                                            name="location"
                                            className="form-control set-placehol"
                                            value={
                                              this.state.selectedReturnLocation
                                            }
                                            onChange={(e) =>
                                              this.dropPlaceChange(e)
                                            }
                                          >
                                            <option
                                              value="DEFAULT"
                                              disabled
                                              hidden
                                            >
                                              Airport or Anywhere
                                            </option>
                                            {this.RenderPickLocation()}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {/* Promotion Code */}
                                    <div
                                      className="col-sm-2 reset-col"
                                      style={{
                                        marginTop: !isMobile ? "30px" : 0,
                                      }}
                                    >
                                      <div className="form-group has-icon has-label reset-group">
                                        <label
                                          htmlFor="formSearchUpLocation3"
                                          style={{
                                            color: "#01adef",
                                            fontSize: "16px",
                                          }}
                                        >
                                          &nbsp;
                                          {strings.book_now.promotion_code}
                                        </label>

                                        <input
                                          type="text"
                                          value={this.state.isCode}
                                          className="form-control re-form-con"
                                          placeholder={
                                            strings.book_now.please_input_code
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              isCode:
                                                e.target.value.toUpperCase(),
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row row-inputs reset-row"
                                  style={{ backgroundColor: "#f8f7f9" }}
                                >
                                  <div className="container-fluid">
                                    <div className="col-sm-5 reset-col">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        style={{
                                          color: "#01adef",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {strings.book_now.pick_up_date}
                                      </label>
                                    </div>
                                    <div className="col-sm-5 reset-col return-label-desktop">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        style={{
                                          color: "#01adef",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {strings.book_now.return_date}
                                      </label>
                                    </div>
                                    <div className="col-sm-3 reset-col">
                                      <div className="form-group has-icon has-label reset-group HomeFilter-conreactpicdate HomeFilter-conreactdate-mo">
                                        <Datetime
                                          dateFormat="DD/MM/YYYY"
                                          timeFormat={false}
                                          closeOnClickOutside
                                          locale="en"
                                          renderInput={(props) => {
                                            return (
                                              <input
                                                {...props}
                                                id="pickupdate"
                                                value={
                                                  moment(
                                                    this.state.startDatea
                                                  ).isValid()
                                                    ? props.value
                                                    : ""
                                                }
                                              />
                                            );
                                          }}
                                          value={this.state.startDatea}
                                          onChange={(date) => {
                                            this.setState({
                                              startDatea: moment(date).toDate(),
                                              startDateb: moment(date)
                                                .add(1, "days")
                                                .toDate(),
                                            });
                                          }}
                                        />
                                        <span
                                          className="form-control-icon"
                                          onClick={(e) =>
                                            this.openCalendar("start_date")
                                          }
                                        >
                                          <i className="fa fa-calendar"></i>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="col-xs-6 col-sm-1 reset-col Home-setmarb-split Home-conw-select Home-setfloatl"
                                      style={{ zIndex: 999 }}
                                    >
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isHrTimea}
                                        onChange={(e) =>
                                          this.setState({
                                            isHrTimea: e.target.value,
                                            isHrTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        {/* {this.RenderOptionSelectHr()} */}
                                        <RenderOptionSelectHr
                                          min={
                                            this.state.select_pickup_start_time
                                          }
                                          max={
                                            this.state.select_pickup_end_time
                                          }
                                        />
                                      </select>
                                    </div>
                                    <div
                                      className="col-xs-6 col-sm-1 reset-col Home-conw-select"
                                      style={{ zIndex: 999 }}
                                    >
                                      <select
                                        className="form-control re-form-con control-select"
                                        defaultValue={this.state.isMinTimea}
                                        onChange={(e) =>
                                          this.setState({
                                            isMinTimea: e.target.value,
                                            isMinTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        {/* {this.RenderOptionSelectMin()} */}
                                        <RenderOptionSelectMin />
                                      </select>
                                    </div>

                                    <div className="col-sm-12 reset-col return-label-mobile">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        style={{
                                          color: "#01adef",
                                          fontSize: "16px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {strings.book_now.return_date}
                                      </label>
                                    </div>

                                    <div className="col-sm-3 reset-col">
                                      <div className="form-group has-icon has-label reset-group HomeFilter-conreactpicdate">
                                        <Datetime
                                          dateFormat="DD/MM/YYYY"
                                          timeFormat={false}
                                          closeOnClickOutside
                                          locale="en"
                                          renderInput={(props) => {
                                            return (
                                              <input
                                                {...props}
                                                id="returndate"
                                                value={
                                                  moment(
                                                    this.state.startDateb
                                                  ).isValid()
                                                    ? props.value
                                                    : ""
                                                }
                                              />
                                            );
                                          }}
                                          value={this.state.startDateb}
                                          onChange={(date) => {
                                            this.setState({
                                              startDateb: moment(date).toDate(),
                                            });
                                          }}
                                        />
                                        <span
                                          className="form-control-icon"
                                          onClick={(e) =>
                                            this.openCalendar("return_date")
                                          }
                                        >
                                          <i className="fa fa-calendar"></i>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="col-xs-6 col-sm-1 reset-col Home-setmarb-split Home-conw-select Home-setfloatl"
                                      style={{ zIndex: 999 }}
                                    >
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isHrTimeb}
                                        onChange={(e) =>
                                          this.setState({
                                            isHrTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectHr
                                          min={
                                            this.state.select_return_start_time
                                          }
                                          max={
                                            this.state.select_return_end_time
                                          }
                                        />
                                      </select>
                                    </div>
                                    <div
                                      className="col-xs-6 col-sm-1 reset-col Home-conw-select"
                                      style={{ zIndex: 999 }}
                                    >
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isMinTimeb}
                                        onChange={(e) =>
                                          this.setState({
                                            isMinTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectMin />
                                      </select>
                                    </div>
                                    <div className="col-sm-2">
                                      <button
                                        type="submit"
                                        id="formSearchSubmit3"
                                        className="btn btn-submit ripple-effect btn-theme btn-red"
                                        style={{
                                          width: "100%",
                                          height: "40px",
                                          padding: 0,
                                          marginTop: isMobile ? "20px" : 0,
                                        }}
                                        onClick={this.handleSubmit}
                                      >
                                        {strings.book_now.search_car}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="row" style={{ marginTop: "50px" }}>
                  <div
                    className="col-md-4 wow flipInY"
                    data-wow-offset="70"
                    data-wow-duration="1s"
                  >
                    <Link to={"/promotion"}>
                      {this.state.homeObj.picture_promotion === "" ? (
                        <div style={{ textAlign: "center" }}>ไม่พบข้อมูล</div>
                      ) : (
                        <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">
                          <img
                            src={this.state.homeObj.picture_promotion}
                            alt="promo"
                          />
                        </div>
                      )}
                    </Link>
                  </div>
                  <div
                    className="col-md-4 wow flipInY"
                    data-wow-offset="70"
                    data-wow-duration="1s"
                    data-wow-delay="200ms"
                  >
                    <Link to={"/faq"}>
                      {this.state.homeObj.picture_faq === "" ? (
                        <div style={{ textAlign: "center" }}>ไม่พบข้อมูล</div>
                      ) : (
                        <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">
                          <img src={this.state.homeObj.picture_faq} alt="faq" />
                        </div>
                      )}
                    </Link>
                  </div>
                  <div
                    className="col-md-4 wow flipInY"
                    data-wow-offset="70"
                    data-wow-duration="1s"
                    data-wow-delay="400ms"
                  >
                    <Link to={"/enterprise"}>
                      {this.state.homeObj.picture_enterprise === "" ? (
                        <div style={{ textAlign: "center" }}>ไม่พบข้อมูล</div>
                      ) : (
                        <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">
                          <img
                            src={this.state.homeObj.picture_enterprise}
                            alt="enterprise"
                          />
                        </div>
                      )}
                    </Link>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
        </div>
        {/* Booking Form */}

        {/* Service */}
        <div className="wrapper">
          <div className="content-area" style={{ background: "#fff" }}>
            <section
              className="page-section"
              style={{ paddingTop: isMobile ? 20 : 80, background: "#fff" }}
            >
              <div className="container-fluid Home-fluid">
                <div className="section-header" style={{ marginBottom: 0 }}>
                  {strings.home.service}
                </div>

                <ServiceBox />
              </div>
            </section>
          </div>
        </div>
        {/* Service */}

        {/* Promotion */}
        <div className="wrapper">
          <div className="content-area" style={{ background: "#F5F5F5" }}>
            <section
              className="page-section"
              style={{ background: "#F5F5F5", paddingTop: isMobile ? 20 : 50 }}
            >
              <div className="container-fluid Home-fluid">
                <div className="section-header">{strings.home.promotion}</div>
                <Link to="/promotion">
                  <div className="section-seemore">
                    {`${strings.home.see_more} >`}
                  </div>
                </Link>

                <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                  {this.state.promotions.length > 0 && (
                    <OwlCarousel
                      id="promotion-section"
                      ref="promotion-section"
                      options={{
                        ...options,
                        items: isMobile ? 1 : 3,
                        center: true,
                        margin: 40,
                        dots: isMobile,
                        autoplay: isMobile,
                        mouseDrag: isMobile,
                        touchDrag: isMobile,
                        pullDrag: isMobile,
                        freeDrag: isMobile,
                      }}
                      events={events}
                    >
                      {this.state.promotions.map((p, i) => (
                        <Link
                          key={`promition-home-${i}`}
                          to={`/promotion/${p.post_id}`}
                        >
                          <div
                            className="Home-new-box"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={p.picture_cover}
                              alt={`promo ${i}`}
                              style={{
                                margin: "0 auto",
                                width: "100%",
                              }}
                            />
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Promotion */}

        {/* Benefits from our partners */}
        <div className="wrapper">
          <div
            className="content-area"
            style={{ backgroundColor: "#F5F5F5", paddingBottom: 40 }}
          >
            <section
              className="page-section"
              style={{
                backgroundColor: "#F5F5F5",
                paddingTop: isMobile ? 20 : 50,
              }}
            >
              <div className="container-fluid Home-fluid">
                <div className="section-header">
                  {isMobile
                    ? strings.home.partner.mobile
                    : strings.home.partner.desktop}
                </div>
                <Link to="/partner">
                  <div className="section-seemore">
                    {`${strings.home.see_more} >`}
                  </div>
                </Link>

                <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                  {this.state.partners.length > 0 && (
                    <OwlCarousel
                      id="partner-section"
                      ref="partner-section"
                      options={{
                        ...options,
                        items: isMobile ? 1 : 3,
                        center: true,
                        margin: 40,
                        dots: isMobile,
                        autoplay: isMobile,
                        mouseDrag: isMobile,
                        touchDrag: isMobile,
                        pullDrag: isMobile,
                        freeDrag: isMobile,
                      }}
                      events={events}
                    >
                      {this.state.partners.map((p, i) => (
                        <Link
                          key={`partner-home-${i}`}
                          // to={`/partner/${p.post_id}`}
                          to={`/partner/`}
                        >
                          <div
                            className="Home-new-box"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={p.picture_cover}
                              alt={`promo ${i}`}
                              style={{
                                margin: "0 auto",
                                width: "100%",
                              }}
                            />
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Benefits from our partners */}

        {/* Enjoy your journey with us */}
        <div className="wrapper">
          <div
            className="content-area"
            style={{ backgroundColor: "#fff", paddingBottom: 40 }}
          >
            <section
              className="page-section"
              style={{
                backgroundColor: "#fff",
                paddingTop: isMobile ? 20 : 50,
              }}
            >
              <div className="container-fluid Home-fluid">
                <div className="section-header" style={{ marginBottom: 0 }}>
                  {isMobile
                    ? strings.home.journey.mobile
                    : strings.home.journey.desktop}
                </div>
                <div className="text-center" style={{ marginBottom: "40px" }}>
                  {strings.home.journey_sub_title}
                </div>
                <Link to="/journey">
                  <div
                    className="section-seemore"
                    style={{ display: !isMobile ? "block" : "none" }}
                  >
                    {`${strings.home.see_more} >`}
                  </div>
                </Link>

                <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                  {isMobile && this.state.journey.length > 0 && (
                    <div className="row">
                      {this.state.journey.map((p, i) => (
                        <div className="col">
                          <Link
                            key={`journey-home-${i}`}
                            to={`/journey/${p.post_id}`}
                          >
                            <div
                              className="Home-new-box"
                              style={{
                                cursor: "pointer",
                                width: "90vw",
                                margin: "0 auto",
                                marginBottom: "30px",
                              }}
                            >
                              <img
                                src={p.picture_cover}
                                alt={`promo ${i}`}
                                style={{
                                  margin: "0 auto",
                                  width: "100%",
                                }}
                              />
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}

                  {!isMobile && this.state.journey.length > 0 && (
                    <OwlCarousel
                      id="journey-section"
                      ref="journey-section"
                      options={{
                        ...options,
                        items: isMobile ? 1 : 3,
                        center: true,
                        margin: 40,
                        dots: isMobile,
                        autoplay: isMobile,
                        mouseDrag: isMobile,
                        touchDrag: isMobile,
                        pullDrag: isMobile,
                        freeDrag: isMobile,
                      }}
                      events={events}
                    >
                      {this.state.journey.map((p, i) => (
                        <Link
                          key={`journey-home-${i}`}
                          to={`/journey/${p.post_id}`}
                        >
                          <div
                            className="Home-new-box"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={p.picture_cover}
                              alt={`promo ${i}`}
                              style={{
                                margin: "0 auto",
                                width: "100%",
                              }}
                            />
                          </div>
                        </Link>
                      ))}
                    </OwlCarousel>
                  )}

                  {isMobile && (
                    <div
                      className="section-seemore text-center"
                      style={{ position: "inherit" }}
                    >
                      {`${strings.home.see_more} >`}
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Enjoy your journey with us */}

        {/* Our Service */}
        <div className="wrapper">
          <div
            className="content-area"
            style={{ backgroundColor: "#F5F5F5", paddingBottom: 40 }}
          >
            <section className="page-section">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-6 wow fadeInLeft"
                    data-wow-offset="200"
                    data-wow-delay="100ms"
                    style={{ padding: "0 50px" }}
                    id="conanimatetxt-our"
                  >
                    <h2
                      className="text-center "
                      style={{ color: "#223645", fontWeight: 600 }}
                    >
                      Our Service
                    </h2>
                    {this.state.content.ourservice && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.ourservice.detail,
                        }}
                      />
                    )}

                    <div className="col-sm-12 text-center">
                      <button
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        className="btn btn-submit ripple-effect btn-theme btn-red"
                      >
                        Book now
                      </button>
                    </div>
                  </div>
                  <div
                    className="col-md-6 wow fadeInRight"
                    data-wow-offset="200"
                    data-wow-delay="300ms"
                  >
                    {this.state.homeObj.picture_service === "" ? (
                      <div style={{ textAlign: "center" }}>ไม่พบข้อมูล</div>
                    ) : (
                      <div>
                        {/* {this.state.content.ourservice && (
                          <img
                            src={this.state.content.ourservice.picture_cover}
                            width="100%"
                            alt="service"
                          />
                        )} */}
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={this.onStartVideo}
                        >
                          <div style={{ pointerEvents: "none" }}>
                            <ReactPlayer
                              url={
                                this.state.content?.ourservice?.video_url ??
                                "https://www.youtube.com/watch?v=Uhv6z32qc8s"
                              }
                              light
                              width="100%"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <button onClick={()=>this.props.incrementAsync()}>{this.props.countx}</button> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Our Service */}

        {/* Client Testimonials */}
        <div className="wrapper">
          <div
            className="content-area"
            style={{ backgroundColor: "#FFFFFF", paddingBottom: 40 }}
          >
            <section className="page-section">
              <div className="container-fluid Home-fluid">
                <div className="row">
                  <div
                    className="col-md-12 wow fadeInLeft"
                    data-wow-offset="200"
                    data-wow-delay="100ms"
                    id="conanimatetxt-our"
                  >
                    <div className="Home-testimonials-subtitle">
                      {strings.home.testimonials_sub_title}
                    </div>

                    <div className="Home-testimonials-title">
                      {strings.home.testimonials}
                    </div>

                    <div className="Home-testimonials-text">
                      {strings.home.testimonials_description}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div style={{ marginTop: "5px", marginBottom: "20px" }}>
                      {this.state.testimonials.length > 0 && (
                        <OwlCarousel
                          id="testimonials-section"
                          ref="testimonials-section"
                          options={{
                            ...options,
                            items: isMobile ? 1 : 3,
                            center: true,
                            margin: 40,
                            dots: true,
                            autoplay: true,
                          }}
                          events={events}
                        >
                          {this.state.testimonials.map((p, i) => (
                            <TestimonialBox
                              key={`testimonial-item-${i}`}
                              {...p}
                              reviews={p.abstact}
                            />
                          ))}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Client Testimonials */}
      </div>
    ) : null;
  }
}
const mapStateToProps = (state) => ({
  web_settings: state.setting.booking.settings,
  branch_time: state.branch.branch,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: async () => {
      dispatch(await fetchSettings());
    },
    fetchBranchTime: () => {
      dispatch(fetchBranchTime());
    },
    incrementAsync: () => {
      dispatch(incrementAsync());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeFilter);
