import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/BookingStep1.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {strings} from "../../language/language";
import moment from "moment";

export default class Book1ContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixPath: "",
      preFixImg: "",
      isCode: false,
      isCheck: false,
      startDatea: new Date(),
      currentDate: "",
      startDateb: new Date(),
      isPickUp: ""
    };
    this.handleChangea = this.handleChangea.bind(this);
    this.handleChangeb = this.handleChangeb.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
  }
  toggleCheck = () => {
    this.setState({
      isCheck: !this.state.isCheck
    });
  };
  handleCode = e => {
    const code = e.target.value;
    if (code === "OMG33") {
      this.setState({ isCode: true });
    } else {
      this.setState({ isCode: false });
    }
  };
  handleChangea(date) {
    this.setState({
      startDatea: date
    });
  }
  handleChangeb(date) {
    this.setState({
      startDateb: date
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setPrefixImg();
    // this.getParamUri();
  }

  getParamUri() {
    const query = new URLSearchParams(this.props.location.search);
    // console.log(query.get("pickdate") === "" || query.get("pickdate") === null);

    if (query.get("pickdate") !== null) {
      var pickupdate = query.get("pickdate");
      var pickuplo = query.get("pickuplo");
      var redate = moment(pickupdate, "DD/MM/YYYY");
      var date = new Date(redate);

      this.setState({
        isPickUp: pickuplo,
        startDatea: date
      });
    } else {
      console.log("ไม่มีค่า");
    }
  }
  setPrefixImg() {
    const local = window.location.href.replace("http://", "");
    const prefixLocal = local.split("/");
    const prefiximg = "http://" + prefixLocal[0] + "/";
    this.setState({ preFixImg: prefiximg });
  }

  render() {
    return (
      <div className="wrapper">
        <div className="content-area ">
          <div
            className="container Book1ContentA-setcontainer-default"
            style={{ background: "#fff" }}
          >
            <div className="form-search light reset-form Book1ContentA-setborder-form">
              <form action="">
                <div className="form-title Book1ContentA-setpadl-titleform">
                  <h2
                    className="con-book Book1ContentA-confont-bookhead"
                    style={{ fontFamily: "Prompt" }}
                  >
                    {strings.book_now.book_now}
                  </h2>
                </div>
                <div className="Book1ContentA-setpad-infoform">
                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div
                        className="col-sm-12 reset-col"
                        style={{ marginTop: "20px" }}
                      >
                        <div className="form-group has-icon has-label reset-group">
                          <label
                            htmlFor="formSearchUpLocation3"
                            className="Book1ContentA-confont-labelform"
                          >
                            {strings.book_now.pick_up_location}
                          </label>
                          <input
                            type="text"
                            className="form-control re-form-con Book1ContentA-confont-input"
                            placeholder="Please Select"
                            value={this.state.isPickUp}
                            onChange={e =>
                              this.setState({ isPickUp: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div className="col-sm-6 reset-col">
                        <div className="form-group has-icon has-label reset-group">
                          <DatePicker
                            disabledKeyboardNavigation
                            withPortal
                            selected={this.state.startDatea}
                            onChange={this.handleChangea}
                            className="form-control re-form-con Book1ContentA-confont-input"
                            placeholderText="dd/mm/yyyy"
                            dateFormat="dd/MM/yyyy"
                          />
                          <span className="form-control-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-3 reset-col Book1ContentA-setmarb-mo Book1ContentA-conw-select">
                        <select
                          className="form-control re-form-con control-select"
                          defaultValue={"DEFAULT"}
                        >
                          <option value="DEFAULT">00</option>
                        </select>
                      </div>
                      <div className="col-xs-3 reset-col Book1ContentA-conw-select">
                        <select
                          className="form-control re-form-con control-select"
                          defaultValue={"DEFAULT"}
                        >
                          <option value="DEFAULT">00</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div className="col-sm-12 reset-col">
                        <div
                          className="form-group has-icon has-label reset-group"
                          style={{ margin: "0" }}
                        >
                          <div className="Book1ContentA-confloatlabel-re">
                            <label
                              htmlFor="formSearchUpLocation3"
                              className="Book1ContentA-confont-labelform"
                            >
                              {strings.book_now.return_location}
                            </label>
                          </div>
                          <div className="checkbox Book1ContentA-conmart-same">
                            <label
                              style={{
                                right: "0px"
                              }}
                            >
                              <input type="checkbox" value="" />
                              <span className="cr">
                                <i
                                  className="cr-icon glyphicon glyphicon-ok"
                                  style={{
                                    top: "0"
                                  }}
                                />
                              </span>
                              <span className="Book1ContentA-confont-same">
                                {strings.book_now.same_location_as_pick_up}
                              </span>
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control re-form-con Book1ContentA-confont-input"
                            placeholder="Please Select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div className="col-sm-6 reset-col">
                        <div className="form-group has-icon has-label reset-group">
                          <DatePicker
                            withPortal
                            disabledKeyboardNavigation
                            selected={this.state.startDateb}
                            onChange={this.handleChangeb}
                            className="form-control re-form-con Book1ContentA-confont-input"
                            placeholderText="dd/mm/yyyy"
                            dateFormat="dd/MM/yyyy"
                          />
                          <span className="form-control-icon">
                            <i className="fa fa-calendar" />
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-3 reset-col Book1ContentA-setmarb-mo Book1ContentA-conw-select">
                        <select
                          className="form-control re-form-con control-select"
                          defaultValue={"DEFAULT"}
                        >
                          <option value="DEFAULT">00</option>
                        </select>
                      </div>
                      <div className="col-xs-3 reset-col Book1ContentA-conw-select">
                        <select
                          className="form-control re-form-con control-select"
                          defaultValue={"DEFAULT"}
                        >
                          <option value="DEFAULT">00</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row row-inputs reset-row">
                                        <div className="container-fluid">
                                            <div className="col-sm-12">
                                                <div className="radio">
                                                    <input
                                                        type="radio"
                                                        id="book1-radiochk"
                                                        onClick={
                                                            this.toggleCheck
                                                        }
                                                        checked={
                                                            this.state.isCheck
                                                        }
                                                    />
                                                    <label htmlFor="book1-radiochk">
                                                        Same Location as Pickup
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div className="col-sm-12 reset-col">
                        <div className="form-group has-icon has-label reset-group">
                          <label
                            htmlFor="formSearchUpLocation3"
                            className="Book1ContentA-confont-labelform"
                          >
                            {strings.book_now.promotion_code}
                          </label>
                          <input
                            type="text"
                            className="form-control re-form-con Book1ContentA-con-inputcode"
                            placeholder={strings.book_now.please_input_code}
                            onChange={e => {
                              this.handleCode(e);
                            }}
                          />
                          <span
                            className="form-control-icon form-control-space Book1ContentA-conbg-chk"
                            style={{
                              display: this.state.isCode ? "block" : "none"
                            }}
                          >
                            <i
                              className="fa fa-check-circle Filter-confonticon-chk"
                              style={{
                                color: "#28C66D"
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row-inputs reset-row">
                    <div className="container-fluid">
                      <div className="col-sm-12">
                        <Link to={this.state.preFixPath + "/bookingstep2"}>
                          <button
                            type="submit"
                            id="formSearchSubmit3"
                            className="btn btn-submit ripple-effect btn-theme btn-red Book1ContentA-conbtndanger-book"
                          >
                            {strings.book_now.search_car}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
