import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./css/FAQ.css";
import Filter from "../../Filter";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS } from "../../constants";
import GoogleMapReact from "google-map-react";
let curLang = localStorage.getItem("lang");
const AnyReactComponent = ({ text }) => (
  <div>
    <img className="myMarker" alt="marker" src="images/marker-blue.png" />
  </div>
);
export default class FAQContentA extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      Loading: false,
      preFixImg: "",
      preFixPath: "",
      branchApi: PREFIX_ADDRESS + "api/branch?lang=" + curLang,
      banner: "",
      branches: {
        C: [],
        E: [],
        N: [],
        NE: [],
        S: [],
      },
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // const $ = window.$;
    this._isMounted = true;
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.fetchBranch();
    this.fetchBanner();
  }

  fetchBanner() {
    const apiurl = PREFIX_ADDRESS + "api/content/faq/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        // console.log(data)
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchBranch() {
    const $ = window.$;
    $("#preloader").show();
    const apiurl = this.state.branchApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);

        this.setState({
          branches: data,
        });
      })
      .then(() => {
        $("#preloader").hide();
      })
      .catch((errs) => {
        console.log(errs);
      });
  }
  renderBranchesC() {
    return (
      this.state.branches.C &&
      this.state.branches.C.map((value, index) => {
        return (
          <div key={index} className="col-xs-12">
            {value.title}
          </div>
        );
      })
    );
  }

  renderBranchesN() {
    return (
      this.state.branches.N &&
      this.state.branches.N.map((value, index) => {
        return (
          <div key={index} className="col-xs-6">
            {value.title}
          </div>
        );
      })
    );
  }
  renderBranchesNE() {
    return (
      this.state.branches.NE &&
      this.state.branches.NE.map((value, index) => {
        return (
          <div key={index} className="col-xs-6">
            {value.title}
          </div>
        );
      })
    );
  }
  renderBranchesS() {
    return (
      this.state.branches.S &&
      this.state.branches.S.map((value, index) => {
        return (
          <div key={index} className="col-xs-6">
            {value.title}
          </div>
        );
      })
    );
  }
  renderBranchesE() {
    return (
      this.state.branches.E &&
      this.state.branches.E.map((value, index) => {
        return (
          <div key={index} className="col-xs-12">
            {value.title}
          </div>
        );
      })
    );
  }

  handleScroll() {
    // OnScroll For Sticky Header

    var elementa = document.getElementById("conanimate-cartype");
    var elementb = document.getElementById("conanimate-carprice");
    var elementc = document.getElementById("conanimate-cardoc");
    var elementd = document.getElementById("conanimate-carsldw");

    //  cartype
    if (
      document.documentElement.scrollTop > 1100 &&
      document.documentElement.clientWidth > 992
    ) {
      elementa.classList.add("FAQContentB-conanimate-cartype");
    }
    if (
      document.documentElement.scrollTop > 2200 &&
      document.documentElement.clientWidth < 992
    ) {
      elementa.classList.add("FAQContentB-conanimate-cartype");
    }
    if (
      document.documentElement.scrollTop > 2100 &&
      document.documentElement.clientWidth < 500
    ) {
      elementa.classList.add("FAQContentB-conanimate-cartype");
    }

    //  carprice
    if (
      document.documentElement.scrollTop > 1500 &&
      document.documentElement.clientWidth > 992
    ) {
      elementb.classList.add("FAQContentB-conanimate-carprice");
    }
    if (
      document.documentElement.scrollTop > 2600 &&
      document.documentElement.clientWidth < 992
    ) {
      elementb.classList.add("FAQContentB-conanimate-carprice");
    }
    if (
      document.documentElement.scrollTop > 2500 &&
      document.documentElement.clientWidth < 500
    ) {
      elementb.classList.add("FAQContentB-conanimate-carprice");
    }

    //  cardoc
    if (
      document.documentElement.scrollTop > 2300 &&
      document.documentElement.clientWidth > 992
    ) {
      elementc.classList.add("FAQContentB-conanimate-cardoc");
    }
    if (
      document.documentElement.scrollTop > 3600 &&
      document.documentElement.clientWidth < 992
    ) {
      elementc.classList.add("FAQContentB-conanimate-cardoc");
    }
    if (
      document.documentElement.scrollTop > 3390 &&
      document.documentElement.clientWidth < 992
    ) {
      elementc.classList.add("FAQContentB-conanimate-cardoc");
    }

    //  carsldw
    if (
      document.documentElement.scrollTop > 2700 &&
      document.documentElement.clientWidth > 992
    ) {
      elementd.classList.add("FAQContentB-conanimate-carsldw");
    }
    if (
      document.documentElement.scrollTop > 4300 &&
      document.documentElement.clientWidth < 992
    ) {
      elementd.classList.add("FAQContentB-conanimate-carsldw");
    }
    if (
      document.documentElement.scrollTop > 3950 &&
      document.documentElement.clientWidth < 992
    ) {
      elementd.classList.add("FAQContentB-conanimate-carsldw");
    }
  }
  componentWillUnmount() {
    // Scroll
    window.removeEventListener("scroll", this.handleScroll);
  }
  static defaultProps = {
    center: {
      lat: 13.7351331,
      lng: 100.5295525,
    },
    zoom: 5,
  };
  renderMarker(lat, lng) {
    return <AnyReactComponent lat={lat} lng={lng} />;
  }
  render() {
    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="FAQContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 FAQContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div className="container-title FAQContentA-contain-default">
                        <h1 style={{ fontSize: "50px" }}>
                          <strong>{strings.faq.faq}</strong>
                        </h1>
                        <div className="hr-promo FAQContentA-conhr-head" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area ">
            <div className="container FAQContentB-setmar-default">
              <div className="row FAQContentB-contxtcen-incol">
                <div className="col-md-12 FAQContentB-setmarb-head">
                  <div>
                    <img
                      className="FAQContentB-conwimg-markpoint"
                      src={
                        this.state.preFixImg +
                        "images/FAQ/logo-markpoint-google@2x.png"
                      }
                      alt="logo"
                    />

                    <div className="FAQContentB-setmart-head">
                      <span className="FAQContentB-span-blue FAQContentB-confont-head">
                        {strings.faq.where_are}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row FAQContentB-remarlr-info">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 FAQContentB-repad-ipadpro">
                      <div className="FAQContentB-setpadl-regionh">
                        <span>{strings.faq.central}</span>
                      </div>
                      <div className="col-xs-12 FAQContentB-confont-loca">
                        <div className="row row-branchs">
                          {this.renderBranchesC()}
                        </div>
                      </div>
                    </div>
                    <div className="FAQContentB-hr-mobile FAQContentB-hr-mobileop" />
                    <div className="col-md-5 FAQContentB-testborder-row FAQContentB-addpad-setinfo">
                      <div className="FAQContentB-setpadl-regionh">
                        <span>{strings.faq.north}</span>
                      </div>
                      <div className="col-xs-12 FAQContentB-confont-loca">
                        <div className="row row-branchs">
                          {this.renderBranchesN()}
                        </div>
                      </div>
                    </div>
                    <div
                      className="FAQContentB-hr-mobile"
                      // style={{ height: "2px" }}
                    />
                    <div className="col-md-3 FAQContentB-setpadl-east">
                      <div className="FAQContentB-setpadl-regionh">
                        <span>{strings.faq.east}</span>
                      </div>
                      <div className="col-xs-12 FAQContentB-confont-loca">
                        <div className="row row-branchs">
                          {this.renderBranchesE()}
                        </div>
                      </div>
                    </div>
                    <div
                      className="FAQContentB-hr-mobile FAQContentB-hr-mobileop1"
                      // style={{ height: "1px" }}
                    />
                  </div>
                </div>

                <div className="col-md-2 FAQContentB-control-mobile" />
              </div>
              <div className="row FAQContentB-remarlr-info FAQContentB-setmarb-mobile">
                <div className="col-md-1 FAQContentB-control-mobile" />
                <div className="col-md-5 FAQContentB-border-right FAQContentB-repad-ipadsouth">
                  <div className="FAQContentB-setpadl-regionh">
                    <span>{strings.faq.south}</span>
                  </div>
                  <div className="col-xs-12 FAQContentB-confont-loca">
                    <div className="row row-branchs">
                      {this.renderBranchesS()}
                    </div>
                  </div>
                </div>

                <div
                  className="FAQContentB-hr-mobile"
                  // style={{ height: "2px" }}
                />
                <div className="col-md-5 FAQContentB-setpad FAQContentB-addpad-setinfo">
                  <div className="FAQContentB-setpadl-regionh">
                    <span>{strings.faq.north_east}</span>
                  </div>
                  <div className="col-xs-12 FAQContentB-confont-loca">
                    <div className="row row-branchs">
                      {this.renderBranchesNE()}
                    </div>
                  </div>
                </div>
                <div
                  className="FAQContentB-hr-mobile"
                  // style={{ height: "1.5px" }}
                />
              </div>

              <div style={{ textAlign: "center", margin: "30px 0" }}>
                <Link to={"/branch"}>
                  <button className="btn btn-danger round" id="">
                    คลิ๊กเพื่อดูสาขา
                  </button>
                </Link>
              </div>

              <div className="row FAQContentB-remarlr-info FAQContentB-mart-fpc">
                <div className="col-md-6 FAQContentB-repadlr-mo">
                  <div style={{ height: "50vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDg4kYkvKZO_lMWn-VMwwdBZFDq3pwO3PI",
                      }}
                      defaultCenter={this.props.center}
                      defaultZoom={this.props.zoom}
                    >
                      {this.state.branches.C &&
                        Object.keys(this.state.branches.C).map((key, i) => {
                          let temp = this.state.branches.C[i];
                          return this.renderMarker(
                            temp.latitude,
                            temp.longitude
                          );
                        })}
                      {this.state.branches.E &&
                        Object.keys(this.state.branches.E).map((key, i) => {
                          let temp = this.state.branches.E[i];
                          return this.renderMarker(
                            temp.latitude,
                            temp.longitude
                          );
                        })}
                      {this.state.branches.N &&
                        Object.keys(this.state.branches.N).map((key, i) => {
                          let temp = this.state.branches.N[i];
                          return this.renderMarker(
                            temp.latitude,
                            temp.longitude
                          );
                        })}
                      {this.state.branches.NE &&
                        Object.keys(this.state.branches.NE).map((key, i) => {
                          let temp = this.state.branches.NE[i];
                          return this.renderMarker(
                            temp.latitude,
                            temp.longitude
                          );
                        })}
                      {this.state.branches.S &&
                        Object.keys(this.state.branches.S).map((key, i) => {
                          let temp = this.state.branches.S[i];
                          return this.renderMarker(
                            temp.latitude,
                            temp.longitude
                          );
                        })}
                    </GoogleMapReact>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <img
                      className="FAQContentB-imgw-default"
                      src={
                        this.state.preFixImg + "images/FAQ/image-sider@2x.png"
                      }
                      alt="Rent a Car"
                    />
                  </div>
                </div>
              </div>

              <div className="row FAQContentB-remarlr-info">
                <div className="col-md-6">
                  <div>
                    <img
                      className="FAQContentB-imgwnoh-default"
                      src={this.state.preFixImg + "images/FAQ/FAQ-detail.jpg"}
                      alt="Rent a Car"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="FAQContentB-setpad-infosider"
                    id="conanimate-cartype"
                  >
                    <div className="FAQContentB-set-marb-carhome">
                      <img
                        className="FAQContentB-conlogo-carhome"
                        src={
                          this.state.preFixImg + "images/FAQ/logo-car-home.png"
                        }
                        alt="Rent a Car"
                      />
                    </div>
                    <div className="FAQContentB-confont-infocarhome">
                      <span>ไทยเร้นท์อะคาร์</span>
                      <br />
                      <span>มีรถรุ่นไหนบ้าง ?</span>
                    </div>
                    <div className="FAQContentB-confont-detailcarhome">
                      <span>
                        ไทยเร้นท์อะคาร์มีรถให้ลูกค้าเลือกมากมายทั้ง Standard
                        Fleet
                      </span>
                      <br />
                      <span>
                        จนไปถึง Luxury Fleet
                        และมีไซส์ให้เลือกหลายไซส์ตามความต้องการของลูกค้า
                      </span>
                    </div>
                    <div style={{ display: "none" }}>
                      <button className="btn btn-danger FAQContentB-setup-redbtn">
                        คลิกดูลายละเอียดเพิ่มเติมคลิก
                        <img
                          className="FAQContentB-conpolygon-carhome"
                          src={
                            this.state.preFixImg + "images/FAQ/polygon-l.png"
                          }
                          alt=">"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row FAQContentB-remarlr-info">
                <div className="col-md-5 FAQContentB-setmar-carprice">
                  <div
                    className="FAQContentB-setpad-carprice-sidel"
                    id="conanimate-carprice"
                  >
                    <div>
                      <div className="FAQContentB-setfloat-carprice">
                        <img
                          className="FAQContentB-conw-logocarprice"
                          src={
                            this.state.preFixImg +
                            "images/FAQ/logo-car-price.png"
                          }
                          alt="logo"
                        />
                      </div>
                      <div className="FAQContentB-conspan-headcarprice">
                        <span>เช่ารถที่ไทยเร้นท์อะคาร์</span>
                        <br />
                        <span>ราคาเท่าไร?</span>
                      </div>
                    </div>
                    <div className="FAQContentB-setmarb-infocarprice ">
                      <div className="FAQContentB-setfloat-infocarprice FAQContentB-setmarb-infocarpricea FAQContentB-repadt-infocarprice-a">
                        <img
                          className="FAQContentB-conwlogo-carprice"
                          src={
                            this.state.preFixImg + "images/FAQ/logo-time.png"
                          }
                          alt="logo"
                        />
                      </div>
                      <div className="FAQContentB-confont-infocarprice FAQContentB-repad-infocarprice-a">
                        <ul>
                          <li
                            style={{
                              paddingLeft: "60px",
                            }}
                          >
                            <span>ราคาขึ้นอยู่กับช่วงเวลาการเช่ารถของท่าน</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="FAQContentB-setmarb-infocarprice FAQContentB-setpadt-mo">
                      <div className="FAQContentB-setfloat-infocarprice FAQContentB-repadt-infocarprice-b">
                        <img
                          className="FAQContentB-conwlogo-carprice"
                          src={
                            this.state.preFixImg + "images/FAQ/logo-time.png"
                          }
                          alt="logo"
                        />
                      </div>
                      <div className="FAQContentB-confont-infocarprice FAQContentB-repad-infocarprice-b">
                        <ul>
                          <li
                            style={{
                              paddingLeft: "60px",
                            }}
                          >
                            <span>
                              ศุกร์ เสาร์ อาทิตย์ บวกเพิ่มวันละ 200 บาท
                              วันหยุดนักขัตฤกษ์ บวกเพิ่มวันละ 500 บาท
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="FAQContentB-setmarb-infocarprice">
                      <div className="FAQContentB-setfloat-infocarprice FAQContentB-setmart-infocarpricemo FAQContentB-repadt-infocarprice-c">
                        <img
                          className="FAQContentB-conwlogo-carprice"
                          src={
                            this.state.preFixImg + "images/FAQ/logo-time.png"
                          }
                          alt="logo"
                        />
                      </div>
                      <div className="FAQContentB-confont-infocarprice FAQContentB-repad-infocarprice-c">
                        <ul>
                          <li
                            style={{
                              paddingLeft: "60px",
                            }}
                          >
                            <span>
                              ในบางโปรโมชั่นจะมีการงด เว้นการบวกเพิ่ม
                              ลูกค้าสามารถเช็คโปรโมชั่นรถเช่าราคาพิเศษ
                            </span>
                          </li>
                          <li
                            style={{
                              paddingLeft: "60px",
                            }}
                          >
                            <span>
                              จากไทยเร้นท์อะคาร์ได้ที่&ensp;
                              <Link
                                to="/promotion"
                                className="FAQContentB-span-blu"
                              >
                                Thairentacar.com
                              </Link>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 FAQContentB-remart-keysider">
                  <div className="FAQContentB-setpad-keysider">
                    <img
                      src={
                        this.state.preFixImg + "images/FAQ/image-sider-2.png"
                      }
                      width="90%"
                      alt="Exchange"
                    />
                  </div>
                </div>
              </div>
              <div className="row FAQContentB-remarlr-info">
                <div className="col-xs-12">
                  <div className="FAQContentB-settxtcen-doc">
                    <img
                      className="FAQContentB-conwimg-headdoc"
                      src={
                        this.state.preFixImg + "images/FAQ/image-center@2x.png"
                      }
                      alt="Document"
                    />
                    <br />
                    <br />
                    <div className="FAQContentB-conspanh-doc">
                      <span>เช่ารถต้องใช้เอกสารอะไรบ้าง?</span>
                    </div>
                    <div
                      className="FAQContentB-hideforanimate"
                      id="conanimate-cardoc"
                    >
                      <div className="col-md-3 FAQContentB-repadrl-fortxt">
                        <div>
                          <div>
                            <img
                              className="FAQContentB-conwlogo-doc"
                              src={
                                this.state.preFixImg +
                                "images/FAQ/logo-issue-card.png"
                              }
                              alt="ID card"
                            />
                          </div>
                          <br />
                          <div className="FAQContentB-conspande-doc">
                            <span>บัตรประชาชน หรือหนังสือเดินทาง</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div>
                          <div>
                            <img
                              className="FAQContentB-conwlogo-doc"
                              src={
                                this.state.preFixImg +
                                "images/FAQ/logo-driver.png"
                              }
                              alt="Driver's License"
                            />
                          </div>
                          <br />
                          <div className="FAQContentB-conspande-doc">
                            <span>ใบขับขี่</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 FAQContentB-repadrl-fortxt">
                        <div>
                          <div>
                            <img
                              className="FAQContentB-conwlogo-doc"
                              src={
                                this.state.preFixImg +
                                "images/FAQ/logo-credit-card.png"
                              }
                              alt="Credit Card"
                            />
                          </div>
                          <br />
                          <div className="FAQContentB-conspande-doc">
                            <span>บัตรเครดิต(ขออนุญาตไม่รับบัตรเดบิต)</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 FAQContentB-repadrl-fortxt">
                        <div>
                          <div>
                            <img
                              className="FAQContentB-conwlogo-doc"
                              src={
                                this.state.preFixImg +
                                "images/FAQ/logo-employee.png"
                              }
                              alt="Credit Card"
                            />
                          </div>
                          <br />
                          <div className="FAQContentB-conspande-doc">
                            <span>บัตรพนักงาน/นามบัตร</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div style={{ display: "none" }}>
                          <button className="btn btn-danger FAQContentB-setup-redbtn">
                            คลิกดูลายละเอียดเพิ่มเติมคลิก
                            <img
                              className="FAQContentB-conpolygon-carhome"
                              src={
                                this.state.preFixImg +
                                "images/FAQ/polygon-l.png"
                              }
                              alt=">"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="FAQContentA-setbg-opacity-footer">
                    <div
                      className="FAQContentC-setcentxt-onbg"
                      id="conanimate-carsldw"
                    >
                      <div>
                        <div className="FAQContentB-setfloat-carprice">
                          <img
                            className="FAQContentB-conw-logocarprice"
                            src={
                              this.state.preFixImg +
                              "images/FAQ/logo-sldw-pldw.png"
                            }
                            alt="logo"
                          />
                        </div>
                        <div className="FAQContentC-confont-sldw">
                          <span>
                            <strong>SLDW</strong> และ <strong>PLDW</strong>
                          </span>
                          <br />
                          <span>คืออะไร?</span>
                        </div>
                        <div>
                          <span>
                            <strong>SLDW</strong> คือ
                            <br />
                          </span>
                        </div>
                        <div className="FAQContentB-conspan-de-sldw">
                          <span>
                            คือการซื้อประกันเพื่อคุ้มครองผู้เช่ารถ
                            โดยไม่ต้องชำระค่าเสียหาย
                          </span>

                          <span>
                            ส่วนแรกในกรณีที่เกิดอุบัติเหตุ (รถของไทยเร้นท์อะคาร์
                            ทุกคันมีประกันภัยชั้น 1
                          </span>

                          <span>
                            แบบจำกัดความรับผิดชอบ)
                            ทั้งนี้ยกเว้นความเสียหายบางประเภทที่ไม่คุ้มครอง เช่น
                          </span>

                          <span>
                            ความเสียหายภายในตัวรถยนต์ยางรถยนต์ กระจกหน้ารถ
                            อุปกรณ์เสริม
                          </span>

                          <span>กุญแจหรือ เอกสารสำคัญของรถยนต์หาย เป็นต้น</span>
                          <br />
                          <br />
                        </div>
                        <div>
                          <span>
                            <strong>PLDW</strong> คือ
                            <br />
                          </span>
                        </div>
                        <div className="FAQContentB-conspan-de-sldw">
                          <span>
                            PLDW : Premium Loss Damage Waive
                            เพิ่มความคุ้มครองความเสียหายที่เกิดขี้น
                          </span>

                          <span>
                            กับยางรถยนต์ และ กระจกรถยนต์
                            ยกเว้นความเสียหายบางประเภทไม่คุ้มครอง เช่น
                          </span>

                          <span>
                            ความเสียหายภายในตัวรถยนต์ อุปกรณ์เสริม
                            กุญแจหรือเอกสารสำคัญของรถยนต์หาย
                          </span>

                          <span>เป็นต้น</span>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="item slide3 ver3 FAQContentA-conbg-head"
                    style={{
                      backgroundImage:
                        "url(" +
                        this.state.preFixImg +
                        "images/FAQ/bg-image-footer@2x.png)",
                      height: "580px",
                      backgroundAttachment: "fixed",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area" style={{ background: "#01ADEF" }}>
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="container">
                    <div className="FAQContentD-container-mo">
                      <div className="FAQContentD-confont-footer FAQContentD-setcen-footer">
                        <span>
                          ในกรณีที่ผู้เช่ารถที่ไม่ได้ซื้อประกันค่าเสียหายส่วนแรก
                          SLDW หรือ PLDW และเกิดอุบัติเหตุ โดยผู้เช่าเป็นฝ่ายผิด
                          หรืออุบัติเหตุแบบไม่มีคู่กรณี
                        </span>
                        <br />
                      </div>
                      <div className="FAQContentD-confont-footer">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/FAQ/logo-car-footer.png"
                          }
                          alt="logo"
                        />
                        &emsp;
                        <span
                          style={{
                            lineHeight: "2.5",
                          }}
                        >
                          สำหรับรถ ไซส์ SS, ES, S, M, L, XL
                          ลูกค้าต้องรับผิดชอบค่าความเสียหายส่วนแรก 5,000 บาท
                        </span>
                      </div>
                      <div className="FAQContentD-confont-footer">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/FAQ/logo-car-footer.png"
                          }
                          alt="logo"
                        />
                        &emsp;
                        <span
                          style={{
                            lineHeight: "2.5",
                          }}
                        >
                          สำหรบรถ Mercedes-Benz
                          ลูกค้าต้องรับผิดชอบค่าความเสียหายส่วนแรก 10,700 บาทท
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
