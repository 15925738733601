import React, { Component } from "react";
import axios from "axios";

import Filter from "../../Filter";
import JourneyItem from "./ListItem";
import JourneyRecentPostItem from "./SmallListItem";
import { PREFIX_CONTENT_ADDRESS } from "../../constants";
import { strings } from "../../language/language";
import "./css/styles.css";

const curLang = localStorage.getItem("lang");

export default class JourneyList extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      Loading: false,
      preFixPath: "",
      dataApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/journey?itemPerPage=6&lang=" +
        curLang,
      dataObj: [],
      recentPostObj: [],
      categories: [],
      banner: "",
      totalPage: [],
      totalPageForPagi: 0,
      currentPage: 1,
      disBtnPrev: true,
      disBtnNext: false,
      minPage: false,
      maxPage: false,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const $ = window.$;
    $("#preloader").show();
    window.scrollTo(0, 0);

    const query = new URLSearchParams(this.props.location.search);

    var currentUrlPage = query.get("page");
    if (currentUrlPage === null) {
      currentUrlPage = 1;
    }
    var currentCategory = query.get("category");
    if (currentCategory === null) {
      currentCategory = "all";
    }

    // this.fetchBanner();
    this.fetchCategories();
    this.fetchRecentPosts();
    this.handlePage(parseInt(currentUrlPage), currentCategory);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchRecentPosts() {
    const apiUrl =
      PREFIX_CONTENT_ADDRESS +
      "api/content/journey?itemPerPage=3&page=1&lang=" +
      curLang;

    axios
      .get(apiUrl)
      .then((res) => {
        const { data } = res;
        this.setState({
          recentPostObj: data.content ?? {},
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  // fetchBanner() {
  //   const apiurl =
  //     PREFIX_CONTENT_ADDRESS + "api/content/journey/banner?lang=" + curLang;
  //   axios
  //     .get(apiurl)
  //     .then((res) => {
  //       const { data } = res;
  //       this.setState({
  //         banner: data.banner,
  //       });
  //     })
  //     .catch((errs) => {
  //       console.log(errs);
  //     });
  // }

  fetchCategories() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/journey_category?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        this.setState({
          categories: data,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  handlePage(id, category, str) {
    if (id === 1 && category === "all") {
      this.props.history.push({
        pathname: "/journey",
      });
    } else {
      const hasBoth = id !== 1 && category !== "all";

      if (hasBoth) {
        this.props.history.push({
          pathname: "/journey",
          search:
            "?" +
            (id === 1 ? "" : "page=" + id) +
            (category === "all" && parseInt(category) === 1
              ? ""
              : "&category=" + category),
        });
      } else if (id !== 1) {
        this.props.history.push({
          pathname: "/journey",
          search: "?page=" + id,
        });
      } else {
        this.props.history.push({
          pathname: "/journey",
          search:
            category !== "all" && parseInt(category) !== 1
              ? "?category=" + category
              : "",
        });
      }
    }

    const $ = window.$;

    // $("#preloader").show();
    const intId = parseInt(id);
    const apiurl =
      this.state.dataApi +
      "&page=" +
      intId +
      "&journey_category_id=" +
      (parseInt(category) === 1 || category === "all" ? 1 : category);
    var totalPageActive = 0;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        totalPageActive = Math.ceil(data.total / 6);

        this.setState({
          dataObj: data.content ?? {},
          currentPage: intId,
          totalPageForPagi: totalPageActive,
        });
        var arr = [];
        for (let i = 0; i < totalPageActive; i++) {
          arr[i] = i;
        }
        this.setState({
          totalPage: arr,
        });
        if (intId > 1) {
          this.setState({
            disBtnPrev: false,
            minPage: true,
          });
        }
        if (intId === Math.ceil(data.total / 6)) {
          this.setState({
            disBtnNext: true,
            maxPage: true,
          });
        }
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .then(() => {
        const selectorId = "#page-" + id;
        for (let i = 1; i <= totalPageActive; i++) {
          var str = "page-" + i;
          if (document.getElementById(str))
            document.getElementById(str).classList.remove("active");
        }
        if (selectorId) {
          setTimeout(() => {
            if (document.querySelector(selectorId))
              document.querySelector(selectorId).classList.add("active");
          }, 100);
        }

        const $ = window.$;
        $("#preloader").hide();
      })
      .catch((errs) => {
        console.log(errs);
      });
    const totalpage = parseInt(this.state.totalPageForPagi);
    if (str === "Num") {
      if (intId === 1) {
        this.setState({
          disBtnPrev: true,
          disBtnNext: false,
          minPage: false,
          maxPage: false,
        });
      } else if (intId === totalpage) {
        this.setState({
          disBtnNext: true,
          maxPage: true,
        });
      } else {
        this.setState({
          disBtnNext: false,
          disBtnPrev: false,
          maxPage: false,
        });
      }
    }

    if (str === "Next") {
      if (intId > 1 && intId !== totalpage) {
        this.setState({
          disBtnPrev: false,
          minPage: true,
        });
      } else if (intId === totalpage) {
        this.setState({
          disBtnNext: true,
          maxPage: false,
        });
      }
    } else {
      if (intId !== totalpage && intId > 1) {
        this.setState({
          disBtnNext: false,
          maxPage: false,
        });
      } else if (intId < 2) {
        this.setState({
          disBtnPrev: true,
          minPage: false,
          maxPage: false,
        });
      }
    }
  }

  handleClickCategory(cat) {
    const query = new URLSearchParams(this.props.location.search);
    this.handlePage(parseInt(query.get("page") ?? 1), cat);
  }

  handlePrev(str) {
    const prevId = this.state.currentPage - 1;

    const query = new URLSearchParams(this.props.location.search);
    var currentCategory = query.get("category");
    if (currentCategory === null) {
      currentCategory = "all";
    }

    this.handlePage(prevId, currentCategory, str);
  }
  handleNext(str) {
    const nextId = this.state.currentPage + 1;

    const query = new URLSearchParams(this.props.location.search);
    var currentCategory = query.get("category");
    if (currentCategory === null) {
      currentCategory = "all";
    }

    this.handlePage(nextId, currentCategory, str);
  }

  renderRowA() {
    const journeys = this.state.dataObj;

    return Object.keys(journeys).map(function (key, i) {
      let setClassHide = "col-xs-12 wow flipInY h-100";
      const item = journeys[key];

      if (i > 2) {
        setClassHide = "col-xs-12 wow flipInY Review-hide-mobile h-100";
      }
      return (
        <div key={`${key}`} className={setClassHide}>
          <JourneyItem
            {...item}
            description={item.abstact ?? ''}
          />
        </div>
      );
    });
  }

  renderCategories() {
    const categories = this.state.categories;

    return (
      <div className="Journey-sidebar-category-container">
        {Object.entries(categories).map((c, i) => {
          const title = c[1].title;
          const total_posts = c[1].total_posts;
          let link = this.props.location.pathname;
          const query = new URLSearchParams(this.props.location.search);

          if (c[0] === "1") {
            if (query.get("page") !== null) {
              link += `?page=${query.get("page")}`;
            } else {
              link += "";
            }
          } else if (query.get("page") !== null) {
            link += `?page=${query.get("page")}&category=${c[0]}`;
          } else {
            link += `?category=${c[0]}`;
          }

          return (
            <div
              key={`category-${i}`}
              className="item"
              onClick={() => this.handleClickCategory(c[0])}
            >
              <div>{title}</div>
              <div>{total_posts}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderRecentPosts() {
    const posts = this.state.recentPostObj;

    return (
      <div className="row">
        {Object.keys(posts).map(function (key, i) {
          const item = posts[key];

          return (
            <div key={`${key}`} className="col-xs-12 wow flipInY h-100">
              <JourneyRecentPostItem {...item} />
            </div>
          );
        })}
      </div>
    );
  }

  //  Pagination
  renderPagination() {
    if (this.state.totalPage instanceof Array) {
      return this.state.totalPage.map((item, i) => {
        var index = item + 1;
        var addClassPage = " page-link-" + index;
        const query = new URLSearchParams(this.props.location.search);
        return (
          <li key={i} className="page-item">
            <button
              className={"page-link set-border1" + addClassPage}
              id={"page-" + index}
              onClick={() => {
                this.handlePage(index, query.get("category") ?? "all", "Num");
              }}
            >
              {index}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div style={{ backgroundColor: "#ffffff" }}>
        <Filter />

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  {/* <div className="ReviewContentA-setbg-opacity" /> */}
                  <img
                    style={{ width: "100%" }}
                    src={`/images/Destination/${curLang}.jpg`}
                    alt="Destination Banner"
                  />
                  {/* <div
                    className="item slide3 ver3 ProContentA-setbgheader BranchContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div
                        className="container-title ReviewContentA-contain-default"
                        style={{
                          textAlign: "center",
                          marginTop: "130px",
                        }}
                      >
                        <h1>
                          <strong>{strings.journey.banner_title}</strong>
                        </h1>
                        <div className="hr-promo" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section">
              <div className="container-fluid Promotion-fluid">
                <div className="row">
                  <div className="col-xs-12 col-md-8">
                    <div className="row">{this.renderRowA()}</div>
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <hr className="Journey-sidebar-hr" />
                    <span className="Journey-sidebar-header">
                      {strings.journey.category}
                    </span>
                    {this.renderCategories()}

                    <hr className="Journey-sidebar-hr" />
                    <span className="Journey-sidebar-header">
                      {strings.journey.recent_posts}
                    </span>
                    {this.renderRecentPosts()}

                    <hr
                      className="Journey-sidebar-hr"
                      style={{ marginTop: "40px" }}
                    />
                  </div>
                </div>
                <nav
                  aria-label="Page navigation example"
                  className="control-pagination"
                >
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link set-border-prev"
                        aria-label="Previous"
                        id="prev-page"
                        onClick={() => {
                          this.handlePrev("Prev");
                        }}
                        disabled={this.state.disBtnPrev}
                        style={
                          this.state.minPage
                            ? {}
                            : { color: "#ccc", background: "#E9E5E4" }
                        }
                      >
                        <span className="glyphicon glyphicon-chevron-left" />
                      </button>
                    </li>
                    {/* renderPagination */}
                    {this.renderPagination()}
                    <li className="page-item">
                      <button
                        className="page-link set-border-next"
                        aria-label="Next"
                        id="next-page"
                        onClick={() => {
                          this.handleNext("Next");
                        }}
                        disabled={this.state.disBtnNext}
                        style={
                          this.state.maxPage
                            ? { color: "#ccc", background: "#E9E5E4" }
                            : {}
                        }
                      >
                        <span className="glyphicon glyphicon-chevron-right" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
