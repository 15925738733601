export const en = {
  menu: {
    menu: "MENU",
    home: "Home",
    our_service: "Our service",
    fleet_service: "Fleet & Service",
    short_rent: "Short-term Car Rental",
    long_rent: "Long-term Car Rental",
    enterprise_rent: "Corporate Car Rental",
    chauffeur_service: "Chauffeur Drive Services",
    coporate_solution: "Corporate Solution",
    promotion: "Promotions",
    promotion_company: "Thai Rent A Car",
    promotion_partner: "Partner",
    travel_attraction: "Travel Attraction",
    branches: "Branches",
    our_company: "Our Company",
    about_us: "About Us",
    news_activities: "News & Activities",
    partnership: "Partnership",
    contact_us: "Contact Us",
    faq: "FAQ",
    career: "Career",
    login: "Login",
    consent_cookie: `Please note that we use cookie for enabling our Website or Application to work properly and/or provide any services as per your preferences. We may use cookie based upon non-consent basis or consent basis. Kindly verify our cookie policy for our Website through the following`,
    link: "link",
    consent_accept: "Accept",
    consent_decline: "Decline",
    follow_us: "FOLLOW US ON",
  },
  service_box: {
    daily: "Short-term Car Rental",
    monthly: "Long-term Car Rental",
    short_term: "Short-term Car Rental",
    short_term_desc: "Daily Rental / Weekly Rental / Monthly Rental",
    long_term: "Long-term Car Rental",
    long_term_desc: "Yearly Rental Up to 5 Years",
    corporate: "Corporate Car Rental",
    corporate_desc: "Car Rental for Corporate",
    chauffeur: "Chauffeur Drive Services",
    chauffeur_desc: "Car Rental with Driver",
  },
  service: {
    offer_title: "What We Offer",
    offer_subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    offer_1_title: "Best Price Guarantee",
    offer_1_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_2_title: "Best Quality Cars",
    offer_2_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_3_title: "Customer Support",
    offer_3_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_1_title_corporate:
      "Maintenance fees and 1st class insurance fees Included <br /> Replacement cars ready in service",
    offer_2_title_corporate:
      "Annual car tax and car registration service Included",
    offer_3_title_corporate:
      "Customer Service <br /> standby 24 hours - 7 days to serves all cases.",
    "long-term": "Long-term Car Rental",
    enterprise: "Corporate Car Rental",
    chaffeur: "Chauffeur Drive Services",
    short_term_title: "Short Term Rental",
    short_term_description:
      "Short-term (Self drive) car rental services (Daily - Weekly - Monthly) International standards from Thai Rent a Car. Guaranteed car quality and service by a professional team. Search for rental cars for traveling to use or rental cars during repairs, Thai Rent A Car, complete rental car throughout Thailand.",
    long_term_title: "Long Term Rental",
    long_term_description:
      "Long-term (Self drive) car rental services (Yearly) International standards from Thai Rent a Car. Guaranteed car quality and service by a professional team. Search for rental cars for traveling to use or rental cars during repairs, Thai Rent A Car, complete rental car throughout Thailand.",
    enterprise_title: "Corporate Car Rental",
    enterprise_description:
      "Thai Rent A Car Corporation Co., Ltd., the leader of corporate rented car in Thailand. We are able to supply all types of cars according to your corporate needs and usage with fixed rental rate throughout rental contract. Available for both self-drive car rentals and car with chauffuer which your corporate can choose from the appropriate usage. Monthly rental rate includes annual car tax, car registration service, 1st class insurance, maintenance fees and a replacement car ready in service within 24/7 (for your cost effective management)",
    chaffeur_title: "Chauffeur Drive Services",
    chaffeur_description:
      "Car rental service with driver (Monthly and Annual contracts) from Thai Rent A Car. Providing you with comfort throughtout your journey Thai Rent A Car Corporation Company Limited, 45 years of being the leader in the car rental market in Thailand.",
    why_thai_rent: "Why Thai Rent A Car ?",
    book_now: "book now",
    book_now_chaffeur: "book now",
    book_contact: "please contact call: 1647 / 02-318-8888",
    contact_us: "CONTACT US",
  },
  home: {
    service: "Products & Services",
    promotion: "PROMOTIONS",
    partner: { desktop: "BENEFITS FROM OUR PARTNERS", mobile: "PARTNERS" },
    journey: { desktop: "ENJOY YOUR JOURNEY WITH US", mobile: "OUR JOURNEY" },
    journey_sub_title: "Discover Thailand with Thai Rent A Car Cars Rental",
    testimonials: "CUSTOMER REVIEWS",
    testimonials_sub_title: "CLIENT's REVIEWS",
    testimonials_description:
      "What do our customer say	Join our Thai Rent A Car Community, Tag us @thrairentacarclub on Instagram",
    see_more: "SEE MORE",
  },
  footer: {
    name: "Name",
    email: "E-mail",
    subject: "Subject",
    message: "Your Message…",
    send: "Send",
  },
  about_us: {
    about_us: "ABOUT US",
  },
  contact: {
    contact: "CONTACT",
    thairent: "Thai Rent A Car",
    name: "Name",
    email: "E-mail",
    message: "Type something…",
    number: "Number",
    send: "SEND MESSAGE",
    address: "Address",
    phone: "Telephone",
    facebook: "Facebook",
  },
  general: {
    more: "MORE",
  },
  book_now: {
    book_now: "BOOK NOW",
    pick_up_location: "Pick Up",
    please_select: "Please Select",
    return_location: "Drop Off",
    same_location_as_pick_up: "Same Location as Pick up",
    promotion_code: "Promotion Code",
    please_input_code: "Please input code",
    search_car: "BOOK NOW",
    pick_up_date: "Pick Up Date/Time",
    time: "Time",
    return_date: "Drop Off Date/Time",
    refresh: "Time",
  },
  booking_step: {
    step_1: "Step 1",
    step_2: "Step 2",
    step_3: "Step 3",
    step_4: "Step 4",
    step: "step",
    pay_now: "Pay Now",
    pay_later: "Pay Later",
    per_day: "Per day",
    total: "total",
    for: "For",
    days: "day(s)",
    choose_your_extra_option: "Chosse Your Extra option",
    thb_per_day: "THB / day",
    extra_charge: "Extra Charge",
    back: "Back",
    next: "Next",
    car_rental_summary: "CAR RENTAL SUMMARY",
    rental_information: "RENTAL INFORMATION",
    pick_up: "Pick-up",
    return: "Return",
    price_detail: "Price Detail",
    partial: "Partial",
    car_price: "Car Rental Price",
    extra_option_price: "Other Extra Charge",
    vat: "VAT 7%",
    thb: "THB",
    payment: "Payment",
    extra_charge_detail:
      "This price includes Peak Period and Weekend Charge if the period of rental is on the specified date Click for more information.",
    modal_extra_charge:
      "Peak Period Charge + 500 bath/day 1-2 Jan 2022 /9-17 Apr 2022 / 3-11 Dec 2022 /24-31 Dec 2022 / 1-2 Jan /2023" +
      "Weekend Charge Please see the promotion terms of use",
    modal_extra_option: `SLDW: Super Loss Damage Waiver is the partial coverage which not includes the following damages eg. interior damages, tires, windshield, accessory, car key or important lost. 	 
PLDW: Premium Loss Damage Waive is the partial coverage includes tires and windshield but not includes interior damage accessory, car key or important document lost. 
`,
    alert_voucher: "Please present Cash Voucher on the pick up day",
    drop_off_fee: "Drop Off Fee",
    print_receipt: "Print Receipt",
    not_found_car_list: `<h3>Sorry, your booking during the selected period cannot be completed online. Please contact Thai Rent A Car Contact Center 1647 to continue your booking. Thank you for travelling with us.</h3>
<p>Note: Please check the information below in order to complete your booking online</p>
<ul>
<li>The spelling of promotion code must be correct</li>
<li>The promotion code must be valid (Not expired)</li>
<li>The driver must be over 21 years old and must have a driving license</li></ul>`,
  },
  booking_info: {
    booking_history: "Booking History",
    your_infomation: "YOUR INFORMATION",
    first_name: "First Name",
    middle_name: "",
    last_name: "Last Name",
    email: "Email",
    phone: "Phone",

    tax_invoice: "Tax Invoice",
    travel_infomation: "TRAVEL INFORMATION",
    flight_info: "Flight Info",
    flight_number: "Flight Number",
    driving_license: "Driving License",
    card_detail: "Credit Card Detail",
    card_issuer: "Credit Card / Debit Card Issuer",
    card_type: "Card Type",
    credit_card_remark:
      "Acceptable of payment only Credit Cards for pre-authorization the deposit from the credit card.",
    english_only: "Please fill information with English Language only",
    verify: "Verify",
    paylater_info: `<h3>PAY Later</h3>
<ul><li>Book and pay online the advance rental fee 500 THB for all models by credit card (No fee charged)</li>
<li>Customer can change the rental details 1 time within 30 days after the booking confirmation date</li>
<li>In case of changing rental details, customer will pay the additional cost regarding to the new rental period (the rental fee might be changed upon the period)</li>
<li>Thai Rent A Car reserves not to pay the refund of partial payment 500 THB in every case</li>
<li>Customer cannot make a double rental in the same period ( 1 customer name per 1 car rental in the same period).</li></ul>`,
    paynow_info: `
<h3>PAY Now</h3>
<ul><li>Best deal guarantee</li>
<li>Book and pay online by credit card (No fee charged)</li>
<li>Customers can’t change rental details after booked</li>
<li>Thai Rent A Car reserves not to pay the refund in every case</li>
<li>Customer cannot make a double rental in the same period ( 1 customer name per 1 car rental in the same period).</li></ul>`,
  },
  car_details: {
    size: "Size",
    type: "Type",
    engine: "Engine",
    kms_free: "Km’s (Free)",
    insurances: "Insurances",
    more_details: "More Vehicle Details",
    seat: "Seat",
    door: "Door",
    air_condition: "Air Condition",
    transmission: "Transmission",
    luggage: "Luggage",
    radio_with_cd: "Radio with CD player",
    extra_option: "Extra option",
  },
  voucher: {
    voucher: "Voucher",
    fill: "fill in Voucher No.",
    value: "Value",
    check: "CHECK",
    delete: "Delete",
    remark: "REMARK",
  },
  term: {
    term: "Term & Condition",
    accept_information: "I accept all information",
    more_information: "More Information",
    alert: 'Please read and check "I accept all information before continue',
    popup_title: "Thai Rent A Car Terms & Conditions",
    qualification: "Driver must be at least 21 years old",
    document_title: "Documents required",
    document_desc:
      "1. ID Card\n1.1 Driver of Thai nationality - Thai ID Card\n1.2 Driver of other nationalities – valid passport\n2. Valid Thai or international driver’s license\n3. Credit card (must be valid for at least 6 months)\n• Rental rate excludes fuel surcharge, optional items, and other personal expenses.",
    pre_authorize_title: "Pre-Authorization",
    pre_authorize_desc:
      "- ค่ามัดจำ S,M ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 5,000 บาท ค่ามัดจำ Toyota Cross ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 10,000 บาท ค่ามัดจำ Size L , Mercedes Benz  ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 15,000 บาท",
  },
  journey: {
    banner_title: "Enjoy your journey with us",
    category: "หมวดหมู่",
    recent_posts: "บทความล่าสุด",
  },
  promotion: {
    promotion: "PROMOTION",
    related: "RELATED PROMOTIONS",
    read_more: "MORE",
  },
  review: {
    review: "Reviews & Activities",
    review_gallery: "Reviews & Activities Gallery",
  },
  career: {
    career: "CAREER",
    branch: "Branch",
    saraly: "Salary",
    qualification: "Qualification",
    apply_message: "For more information",
  },
  partner: {
    partner_thairent: "Partners Thai Rent a Car",
    worldwide: "<p>เช่ารถทั่วโลก</p> <p>Worldwide car rental</p>",
    worldwide_button: "READ MORE",
  },
  log_in: {
    log_in: "LOGIN",
    email: "Email",
    password: "Password",
    forgot: "Forgot your password?",
    sign_in: "SING IN",
    or: "OR",
    log_in_facebook: "Login with Facebook?",
    log_in_line: "Login with LINE",
    dont_have_account: "Don’t have an account? ",
    register: "RESGITER",
    first_name: "First Name",
    last_name: "Last Name",
    forgot_message:
      "Insert your account email address\nWe will send you a link to reset your password.",
    reset_password: "RESET PASSWORD",
    required: "Please fill in all the required fields before continuing ",
  },
  faq: {
    faq: "FAQ",
    where_are: "Where are Thai Rent A Car Branches?",
    central: "Central Region",
    north: "Northern Thailand",
    south: "Southern Thailand",
    east: "Eastern Thailand",
    north_east: "Northeastern Thailand",
    view_map: "View Map",
  },
  profile: {
    profile: "PROFILE",
    user_profile: "User Profile",
    first_name: "First Name",
    last_name: "Lastname",
    middle_name: "Middle name",
    email: "Email",
    address: "Address",
    postcode: "Postcode",
    phone: "Phone",
    nationality: "Nationality",
    country: "Country",
    driving_license: "Driving license number",
    dob: "Date of birth",
    upload: "Upload Driving license",
    id_card: "Identification card",
    lang_message: "**Input English Only",
    change_password: "CHANGE PASSWORD",
    current: "Current",
    new: "New",
    retype_new: "Retype New",
    forgot: "Forgot your password?",
    save_change: "SAVE CHANGES",
    back: "BACK TO HOME",
    facebook_placeholder: "Please fill in your facebook user name",
    line_placeholder: "Please fill in your Line ID",
    require_field: "please fill in all the required fields before continuing",
  },
  error: {
    error_invalid_promotion_code:
      "The promotion code is invalid. Please check and try again.",
    validate_before_payment: "Please check your e-mail before making payment.",
    error_start_time_period:
      "Please make your booking at least $1 hour in advance",
    "required field need to be filled":
      "please fill in all the required fields before continuing",
    "PO not found": "invalid PO",
    "limit usage": "Fully booked",
  },
  modal: {
    fill: "edit my profile",
    nofill: "no thanks",
  },
  ourservice_table: {
    type_of_insurance: "Type of Insurance (Option)",
    ldw: "Basic Package",
    sldw: "Zero Deductible",
    pldw: "Fully Cover ",
    short_term: {
      1: "Waives the first cost of 5,000 baht from Car accident / damage (Exclude damage from Windows and tires)",
      2: "Waives the cost of 5,000 baht from Windows damage and cost of damaged tires (the cost is according to the price of the tire model)",
      3: "Price charge per day *Baht (Exclude Vat)",
      4: "Price charge per month *Baht (Exclude Vat)",
    },
    long_term: {
      1: "Waives the first cost of 5,000 baht from Car accident / damage (Exclude damage from Windows and tires)",
      2: "Waives the cost of 5,000 baht from Windows damage and cost of damaged tires (the cost is according to the price of the tire model)",
      3: "Price charge per month *Baht (Exclude Vat)",
    },
  },
};
