import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./css/item.css";

export default class JourneyItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id ?? "",
      post_id: props.post_id ?? "",
      created_at: props.created_at ?? "",
      updated_at: props.updated_at ?? "",
      picture_cover: props.picture_cover ?? "",
      title: props.title ?? "",
      description: props.description ?? "",
    };
  }

  render() {
    return (
      <div className="Journey-item-container">
        <div className="img-container">
          <Link to={`/journey/${this.state.post_id}`}>
            <img src={this.state.picture_cover} alt={this.state.title} />
          </Link>
        </div>
        <div className="content-container">
          <span>{moment(this.state.created_at).format("MMMM DD, YYYY")}</span>
          <Link to={`/journey/${this.state.post_id}`}>
            <h3 className="content-title">{this.state.title}</h3>
          </Link>
          <div
            dangerouslySetInnerHTML={{ __html: this.state.description }}
          ></div>
        </div>
      </div>
    );
  }
}
