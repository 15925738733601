import React, { Component } from "react";
import { Link } from "react-router-dom";
import Filter from "../../Filter";
import { SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import "./css/styles.css";

export default class WorldWidePartner extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      preFixImg: SITE_URL + "/",
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  render() {
    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider" style={{ height: "450px" }}>
                  <div className="PartnerContentC-setbg-opacity" />
                  <div
                    className="item slide3 ver3 PartnerContentC-conbg-head"
                    style={{
                      height: "450px",
                      backgroundSize: "cover",
                      backgroundImage:
                        'url("' +
                        this.state.preFixImg +
                        'images/Partner/bg-image-footer@2x.png")',
                    }}
                  >
                    <div className="container PartnerContentC-remar-confmo">
                      <div className="container-title PartnerContentC-contain-default">
                        <h1 style={{ fontSize: "50px" }}>
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: strings.partner.worldwide,
                            }}
                          ></strong>
                        </h1>

                        <div className="PartnerContentC-conbtn-image">
                          <Link
                            to={{
                              pathname:
                                "https://www.rentalcars.com/?affiliateCode=thairentacar&preflang=th&adplat=banner",
                            }}
                            target="_blank"
                          >
                            <button className="Wordwide-Partner-button">
                              {strings.partner.worldwide_button}
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
