import React, { Component } from "react";
import "./css/Enterprise.css";
import Filter from "../../Filter";
import axios from "axios";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
let curLang = localStorage.getItem("lang");
export default class EnterpriseContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      enterApi: PREFIX_CONTENT_ADDRESS + "",
      banner: "",
      content: "",
      enterObj: {
        picture_cover: "images/Enterprise/bg-enterprise-header@2x.png",
      },
    };
    // รายละเอียดสามารถแก้ไขได้หมด
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.fetchBanner();
    this.fetchContent();
  }

  fetchContent() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/corporate?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data.content);
        this.setState({
          content: data.content["_61"].detail,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  fetchBanner() {
    let curLang = localStorage.getItem("lang");
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/corporate/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        // console.log(data)
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  render() {
    return (
      <div>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="EnterpriseContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="container">
                      <div className="container-title EnterpriseContentA-contain-default">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/Enterprise/logo-in-bg.png"
                          }
                          width="75px"
                          alt="Corporate Car Rental"
                        />
                        <h1 style={{ fontSize: "45px" }}>
                          รถเช่าสำหรับบริษัท
                          <br />
                          <strong>Corporate Solution</strong>
                        </h1>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="row" style={{ background: "#fff" }}>
                  <div className="container EnterpriseContentB-mocontainer">
                    <div
                      className="col-xs-12 EnterpriseContentB-area-content"
                      dangerouslySetInnerHTML={{ __html: this.state.content }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
