import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./components/Home/css/promotion.css";
import { dom } from "@fortawesome/fontawesome-svg-core";

import moment from "moment";
import axios from "axios";
import { addDays } from "date-fns";
import { strings } from "./language/language";
import { connect } from "react-redux";
import { fetchSettings, fetchBranchTime } from "./actions/settingAction";
import {
  calculatePickerTime,
  PREFIX_ADDRESS,
  RenderOptionSelectHr,
  RenderOptionSelectMin,
  SITE_URL,
  handleError,
  handleProfileError,
  rangeMaxReserveDay,
} from "./constants";
import { isTablet, isMobile } from "react-device-detect";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const dayofweek = moment().format("E");
dom.watch();
const token = localStorage.login_token;
class Filter extends Component {
  constructor(props) {
    super(props);
    let today = new Date();

    this.state = {
      openStartCalender: false,
      openReturnCalender: false,

      isCheck: false,
      isShow: false,
      isCode: "",
      testCode: "",
      startDatea: today,
      currentDatea: "",
      isHrTimea: "08",
      isMinTimea: "00",
      isSameLocation: true,
      isReturn: "",
      startDateb: addDays(today, 2),
      isHrTimeb: "08",
      isMinTimeb: "00",
      isCurrency: "",
      status: "1",
      preFixPath: "",
      preFixImg: "",
      isPickUp: "",
      isBranches: "",
      max_period: 30,
      apiFilter: PREFIX_ADDRESS + "",
      selectedPickupLocation: "DEFAULT",
      selectedReturnLocation: "DEFAULT",
      isCurrentHref: "",
      select_pickup_start_time: 0,
      select_pickup_end_time: 23,
      select_return_start_time: 0,
      select_return_end_time: 23,
    };
    this.toggleCheck = this.toggleCheck.bind(this);
  }
  toggleCheck = () => {
    this.setState({
      isCheck: !this.state.isCheck,
    });
  };

  openCalendar(type) {
    if (type === "start_date") {
      document.getElementById("start_date_location_calendar").click();
    } else if (type === "return_date") {
      document.getElementById("return_date_location_calendar").click();
    }
  }
  closeCalender(type) {
    if (type === "start_date") {
      this.state.openStartCalender = false;
    } else if (type === "return_date") {
      this.state.openReturnCalender = false;
    }
  }

  onShow = () => {
    this.setState({ isShow: !this.state.isShow });
  };

  async componentDidMount() {
    const scrwidth = window.innerWidth;
    if (scrwidth >= 1200) {
      this.setState({ isShow: false });
    }
    var local = SITE_URL;
    if (local.includes("#")) {
      const indexHash = local.indexOf("#");
      local = local.substring(0, indexHash);
    }

    // if (!this.props.web_settings) {
    //     this.props.fetchSettings()
    // }
    await this.props.fetchSettings();

    await this.props.fetchBranchTime();
    if (this.props.web_settings) {
      let { restrict_time, pickup_end_time, max_period } =
        this.props.web_settings;
      let { startDate, startHour, startMinute } = calculatePickerTime(
        this.props.web_settings
      );

      this.setState({
        startDatea: startDate,
        isHrTimea: startHour,
        isMinTimea: startMinute,
        startDateb: startDate,
        isHrTimeb: startHour,
        isMinTimeb: startMinute,
        max_period: max_period,
      });
    }
    this.fetchLocation();

    this.getParameter();
  }

  fetchLocation() {
    axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then((res) => {
      if (res.data.Success === "Y") {
        const { data } = res;
        // console.log(data.Branch[10]);
        this.setState({ isBranches: data.Branch });
      }
    });
  }
  getParameter = () => {
    this.setState({
      isCurrentHref: window.location.href,
    });

    let booking_temp = localStorage.getItem("booking_temp");
    booking_temp = JSON.parse(booking_temp);

    let outBranch = "DEFAULT";
    let outDate = "";
    let outtime = "";
    let inBranch = "DEFAULT";
    let inDate = "";
    let intime = "";
    let code = "";
    if (this.props.urlData) {
      let query = new URLSearchParams(this.props.urlData);
      outBranch = query.get("OutBranch");
      outDate = query.get("OutDate");
      outtime = query.get("OutTime");
      inBranch = query.get("InBranch");
      inDate = query.get("InDate");
      intime = query.get("InTime");
      code = query.get("Code");
    }

    if (!outBranch && booking_temp) {
      if (booking_temp.OutBranch !== null) {
        outBranch = booking_temp.OutBranch;
      }
      if (booking_temp.OutDate !== null) {
        outDate = booking_temp.OutDate;
      }
      if (booking_temp.OutTime !== null) {
        outtime = booking_temp.OutTime;
      }
      if (booking_temp.InBranch !== null) {
        inBranch = booking_temp.InBranch;
      }
      if (booking_temp.InDate !== null) {
        inDate = booking_temp.InDate;
      }
      if (booking_temp.InTime !== null) {
        intime = booking_temp.InTime;
      }
    }

    if (!code && booking_temp && booking_temp.Code !== null) {
      code = booking_temp.Code;
    }

    let addState = {};
    if (outBranch && outBranch !== "DEFAULT") {
      let branchInfo = this.props.branch_time[outBranch].sch[dayofweek];
      let starthour = moment(branchInfo.StartTime, "hh:mm").hour();
      let endhour = moment(branchInfo.EndTime, "hh:mm").hour();
      addState = {
        ...addState,
        selectedPickupLocationInfo: branchInfo,
        select_pickup_start_time: starthour,
        select_pickup_end_time: endhour,
      };
    }

    if (inBranch && inBranch !== "DEFAULT") {
      let branchInfo2 = this.props.branch_time[inBranch].sch[dayofweek];
      let starthour2 = moment(branchInfo2.StartTime, "hh:mm").hour();
      let endhour2 = moment(branchInfo2.EndTime, "hh:mm").hour();
      addState = {
        ...addState,
        selectedReturnLocationInfo: branchInfo2,
        select_return_start_time: starthour2,
        select_return_end_time: endhour2,
      };
    }

    // console.log(addState)

    this.setState({
      ...addState,
      selectedPickupLocation: outBranch,
      selectedReturnLocation: inBranch,
      isCode: code,
    });

    if (outDate) {
      let reoutdate = moment(outDate, "DD/MM/YYYY");
      outDate = new Date(reoutdate);
      this.setState({ startDatea: outDate });
    }
    if (outtime) {
      outtime = outtime.split(":");
      this.setState({
        isHrTimea: outtime[0],
        isMinTimea: outtime[1],
      });
    }
    if (inDate) {
      let reindate = moment(inDate, "DD/MM/YYYY");
      inDate = new Date(reindate);
      this.setState({ startDateb: inDate });
    }
    if (intime) {
      intime = intime.split(":");
      this.setState({
        isHrTimeb: intime[0],
        isMinTimeb: intime[1],
      });
    }
  };

  handleSameLocation(e) {
    // console.log("in handleSameLocation");
    this.setState({
      isSameLocation: !this.state.isSameLocation,
    });
    const outBranch = this.state.selectedPickupLocation;
    var sameactive = e.target.checked;
    // console.log(sameactive);

    if (sameactive === true) {
      this.setState({
        selectedReturnLocation: outBranch,
        selectedReturnLocationInfo: this.state.selectedPickupLocationInfo,
        select_return_start_time: this.state.select_pickup_start_time,
        select_return_end_time: this.state.select_pickup_end_time,
      });
    } else {
      this.setState({ selectedReturnLocation: "DEFAULT" });
    }
  }

  pickupPlaceChange(e) {
    let branchInfo = this.props.branch_time[e.target.value].sch[dayofweek];
    let starthour = moment(branchInfo.StartTime, "hh:mm").hour();
    let endhour = moment(branchInfo.EndTime, "hh:mm").hour();
    this.setState({
      selectedPickupLocation: e.target.value,
      selectedPickupLocationInfo: branchInfo,
      select_pickup_start_time: starthour,
      select_pickup_end_time: endhour,
    });
    if (this.state.isSameLocation === true) {
      this.setState({
        selectedReturnLocation: e.target.value,
        selectedReturnLocationInfo: branchInfo,
        select_return_start_time: starthour,
        select_return_end_time: endhour,
      });
    }
  }
  dropPlaceChange(e) {
    let branchInfo = this.props.branch_time[e.target.value].sch[dayofweek];
    let starthour = moment(branchInfo.StartTime, "hh:mm").hour();
    let endhour = moment(branchInfo.EndTime, "hh:mm").hour();
    this.setState({
      selectedReturnLocation: e.target.value,
      selectedReturnLocationInfo: branchInfo,
      select_return_start_time: starthour,
      select_return_end_time: endhour,
    });
    if (this.state.isSameLocation === true) {
      this.setState({
        selectedPickupLocation: e.target.value,
        selectedPickupLocationInfo: branchInfo,
        select_pickup_start_time: starthour,
        select_pickup_end_time: endhour,
      });
    }
  }

  handleSubmit(e) {
    const $ = window.$;
    $("#preloader").show();

    e.preventDefault();

    var outBranch = this.state.selectedPickupLocation;
    var pickupdate = this.state.startDatea;
    var outTime = `${this.state.isHrTimea}:${this.state.isMinTimea}`;
    var samelo = this.state.isSameLocation;
    var inBranch = this.state.selectedReturnLocation;
    var returndate = this.state.startDateb;
    var inTime = `${this.state.isHrTimeb}:${this.state.isMinTimeb}`;
    var code = this.state.isCode;
    var outDate = moment(pickupdate).format(`DD/MM/YYYY`);
    var inDate = moment(returndate).format(`DD/MM/YYYY`);

    var d1 = new Date(moment(pickupdate).format(`YYYY-MM-DD`));
    var d2 = new Date(moment(returndate).format(`YYYY-MM-DD`));

    if ("DEFAULT" === outBranch) {
      // alert("Please Select Pickup Location");
      //
      handleError("Please Select Pickup Location");
      $("#preloader").hide();
      return;
    }
    if (+d1 > +d2) {
      handleError("Please Check Return Date Again");
      $("#preloader").hide();
      return;
    }
    // if (+d1 === +d2 && (this.state.isHrTimeb - this.state.isHrTimea) < this.props.web_settings.restrict_time) {

    //     handleError(`ระยะเวลาการเช่ารถต้องมากกว่า ${this.props.web_settings.restrict_time} ชั่วโมง`);

    //     $("#preloader").hide();
    //     return
    // }

    if ("DEFAULT" === inBranch) {
      handleError("Please Select Return Location");
      $("#preloader").hide();
      return;
    }
    if (true === samelo) {
      inBranch = outBranch;
      if ("" !== outBranch) {
        inBranch = this.state.selectedPickupLocation;
        this.setState({ selectedReturnLocation: inBranch });
      }
    }

    var picktimeMoment = moment(outTime, "HH:mm");
    var returnMoment = moment(inTime, "HH:mm");
    var branchPickStartTime = moment(
      this.state.selectedPickupLocationInfo.StartTime,
      "HH:mm"
    );
    var branchPickEndTime = moment(
      this.state.selectedPickupLocationInfo.EndTime,
      "HH:mm"
    );
    var branchReturnStartTime = moment(
      this.state.selectedReturnLocationInfo.StartTime,
      "HH:mm"
    );
    var branchReturnEndTime = moment(
      this.state.selectedReturnLocationInfo.EndTime,
      "HH:mm"
    );

    if (
      branchPickEndTime.isBefore(picktimeMoment) ||
      picktimeMoment.isBefore(branchPickStartTime)
    ) {
      handleError(
        `สถานที่รับรถเปิดทำการเวลา ${this.state.selectedPickupLocationInfo.StartTime} - ${this.state.selectedPickupLocationInfo.EndTime}`
      );
      $("#preloader").hide();
      return;
    }

    if (
      branchReturnEndTime.isBefore(returnMoment) ||
      returnMoment.isBefore(branchReturnStartTime)
    ) {
      handleError(
        `สถานที่คืนรถเปิดทำการเวลา ${this.state.selectedReturnLocationInfo.StartTime} - ${this.state.selectedReturnLocationInfo.EndTime}`
      );
      $("#preloader").hide();
      return;
    }

    var difreversecar = addDays(pickupdate, this.props.web_settings.max_period);
    if (returndate > difreversecar) {
      handleError(
        "ระยะเวลาจองรถและคืนรถต้องไม่ห่างกันเกิน " +
          this.props.web_settings.max_period +
          " วัน"
      );
      $("#preloader").hide();
      return;
    }

    const param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&Code=${code}`;

    let callback;
    axios
      .get(`${PREFIX_ADDRESS}api/booking/carlist${param}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // console.log(res.data.Success);
        callback = res;
        if (res.data.Success !== undefined) {
          this.setState({
            isStatusBookNow: res.data.Success,
            isMessage: res.data.message,
          });
        }
      })
      .then(() => {
        // console.log(callback.data);
        // return
        if (callback.data.Success === "Y") {
          window.location.href = `/bookingstep2${param}`;
          // this.props.history.push({pathname:"/bookingstep2", search:param});
        } else {
          let str_error = strings.error[callback.data.error.message[0]];
          if (str_error === undefined) {
            str_error = callback.data.error.message[0];
          }
          if (callback.data.error.message[0] === "error_start_time_period") {
            str_error = str_error.replace("$1", "12");
          }

          if (
            callback.data.error.message[0] ===
            "required field need to be filled"
          ) {
            handleProfileError(str_error);
          } else {
            handleError(str_error);
          }
          //
          this.setState({ isCode: "" });
          $("#preloader").hide();
          return;
        }
      })
      .then(() => {
        setTimeout(() => {
          // $("#preloader").hide();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  RenderLocation() {
    var branches = this.state.isBranches;
    const branchOptions = Object.keys(branches).map((key) => (
      <option key={key} value={key.replace(/[^0-9.]/g, "")}>
        {branches[key]}
      </option>
    ));
    return branchOptions;
  }

  RenderPickLocation() {
    var branches = this.state.isBranches;
    const branchOptions = Object.keys(branches).map(
      (key) => (
        <option key={key} value={key.replace(/[^0-9.]/g, "")}>
          {branches[key]}
        </option>
      )
      // <option value={branches[this.state.selectedPickupLocation]} key={key}>{branches[key]}</option>
    );
    return branchOptions;
  }

  render() {
    return (
      <>
        <div
          className={`wrapper filter-wrapper ${
            this.props.className ? this.props.className : ""
          }`}
        >
          <div className="content-area">
            <section
              className="page-section no-padding slider"
              style={{ overflow: "visible" }}
            >
              <div className="container full-width">
                <div className="wrap-form-filter">
                  <div className="caption">
                    <div className="form-search light">
                      <div
                        className={`form-title ${
                          this.props.className ? this.props.className : ""
                        }`}
                        style={{ paddingTop: "30px", paddingBottom: "40px" }}
                      >
                        <div className="container-fluid Top-Filter-fluid Filter-repad-containbook">
                          <div className="Filter-showhidefilter">
                            <button
                              onClick={this.onShow}
                              className="Filter-relink-btn"
                            >
                              <div>
                                <i
                                  className="fa fa-chevron-circle-down Filter-confont-updown"
                                  style={{
                                    transform: this.state.isShow
                                      ? "rotate(540deg)"
                                      : "rotate(0deg)",
                                    transition: "all 0.4s ease 0s",
                                  }}
                                ></i>
                              </div>
                            </button>
                            {/* <a onClick={this.onShow} style={{display: this.state.isShow ? 'block' : 'none'}}>
                                                            <div>
                                                                <i className="fa fa-chevron-circle-up Filter-confont-updown"></i>
                                                            </div>
                                                        </a> */}
                          </div>
                          <div>
                            <h2
                              style={{
                                padding: "10px 0",
                                fontFamily: "Prompt",
                                fontWeight: "bold",
                                fontSize: "24px",
                              }}
                            >
                              {strings.book_now.book_now}
                            </h2>
                          </div>
                        </div>
                        <div className="container-fluid Top-Filter-fluid Filter-hidemobile-active">
                          <form action="" className="row">
                            <div
                              className="col-sm-2 form-group has-icon has-label"
                              style={{ width: "13%" }}
                            >
                              <label htmlFor="formSearchUpLocation3">
                                {strings.book_now.pick_up_location}
                              </label>
                              <select
                                name="location"
                                className="form-control set-placehol"
                                id="formSearchUpLocation3"
                                value={this.state.selectedPickupLocation}
                                onChange={(e) => this.pickupPlaceChange(e)}
                              >
                                <option value="DEFAULT" hidden>
                                  {strings.book_now.please_select}
                                </option>
                                {this.RenderPickLocation()}
                              </select>
                            </div>

                            <div
                              className="col-sm-2 form-group has-icon has-label"
                              style={{ width: "15%" }}
                            >
                              <label htmlFor="formSearchUpLocation3">
                                {strings.book_now.pick_up_date}
                              </label>
                              <div className="control-form-pickup">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  timeFormat={false}
                                  closeOnClickOutside
                                  locale="en"
                                  renderInput={(props) => {
                                    return (
                                      <input
                                        {...props}
                                        id="start_date_location_calendar"
                                        value={
                                          moment(
                                            this.state.startDatea
                                          ).isValid()
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                  value={this.state.startDatea}
                                  onChange={(date) => {
                                    this.setState({
                                      startDatea: moment(date).toDate(),
                                      startDateb: moment(date)
                                        .add(1, "days")
                                        .toDate(),
                                    });
                                  }}
                                />
                                <span
                                  className="form-control-icon"
                                  onClick={(e) =>
                                    this.openCalendar("start_date")
                                  }
                                >
                                  <i
                                    className="fa fa-calendar"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>

                            <div
                              className="col-sm-2 form-group has-icon has-label control-form-timea"
                              style={{ padding: 0, width: "max-content" }}
                            >
                              <div>
                                <label htmlFor="formSearchUpLocation3">
                                  {strings.book_now.time}
                                </label>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  className="control-timea-hr"
                                  style={{ marginRight: 0 }}
                                >
                                  <select
                                    className="form-control"
                                    value={this.state.isHrTimea}
                                    onChange={(e) =>
                                      this.setState({
                                        isHrTimea: e.target.value,
                                        isHrTimeb: e.target.value,
                                      })
                                    }
                                  >
                                    <RenderOptionSelectHr
                                      min={this.state.select_pickup_start_time}
                                      max={this.state.select_pickup_end_time}
                                    />
                                  </select>
                                </div>

                                <div
                                  className="control-timea-min"
                                  style={{ marginRight: 0 }}
                                >
                                  <select
                                    className="form-control"
                                    value={this.state.isMinTimea}
                                    onChange={(e) =>
                                      this.setState({
                                        isMinTimea: e.target.value,
                                        isMinTimeb: e.target.value,
                                      })
                                    }
                                  >
                                    <RenderOptionSelectMin />
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="hr-for-mobile"></div>

                            <div
                              className="col-sm-2 form-group has-icon no-label"
                              style={{ top: "25px", width: "13%" }}
                            >
                              <select
                                name="location"
                                className="form-control set-placehol"
                                value={this.state.selectedReturnLocation}
                                onChange={(e) => this.dropPlaceChange(e)}
                              >
                                <option value="DEFAULT" disabled hidden>
                                  {strings.book_now.please_select}
                                </option>
                                {this.RenderLocation()}
                              </select>
                            </div>

                            <div
                              className="col-sm-2 form-group has-icon has-label"
                              style={{ width: "15%" }}
                            >
                              <div>
                                <label htmlFor="formSearchUpLocation3">
                                  {strings.book_now.return_date}
                                </label>
                              </div>
                              <div className="control-form-pickup">
                                <Datetime
                                  dateFormat="DD/MM/YYYY"
                                  timeFormat={false}
                                  closeOnClickOutside
                                  locale="en"
                                  renderInput={(props) => {
                                    return (
                                      <input
                                        {...props}
                                        id="return_date_location_calendar"
                                        value={
                                          moment(
                                            this.state.startDateb
                                          ).isValid()
                                            ? props.value
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                  value={this.state.startDateb}
                                  onChange={(date) => {
                                    this.setState({
                                      startDateb: moment(date).toDate(),
                                    });
                                  }}
                                />
                                <span
                                  className="form-control-icon"
                                  onClick={(e) =>
                                    this.openCalendar("return_date")
                                  }
                                >
                                  <i
                                    className="fa fa-calendar"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>

                            <div
                              className="col-sm-2 form-group has-icon has-label control-form-timeb"
                              style={{ padding: 0, width: "max-content" }}
                            >
                              <div>
                                <label htmlFor="formSearchUpLocation3">
                                  {strings.book_now.time}
                                </label>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  className="control-timeb-hr"
                                  style={{ marginRight: 0 }}
                                >
                                  <select
                                    className="form-control"
                                    value={this.state.isHrTimeb}
                                    onChange={(e) =>
                                      this.setState({
                                        isHrTimeb: e.target.value,
                                      })
                                    }
                                  >
                                    <RenderOptionSelectHr
                                      min={this.state.select_return_start_time}
                                      max={this.state.select_return_end_time}
                                    />
                                  </select>
                                </div>
                                <div
                                  className="control-timeb-min"
                                  style={{ marginRight: 0 }}
                                >
                                  <select
                                    className="form-control"
                                    value={this.state.isMinTimeb}
                                    onChange={(e) =>
                                      this.setState({
                                        isMinTimeb: e.target.value,
                                      })
                                    }
                                  >
                                    <RenderOptionSelectMin />
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-sm-2 form-group has-icon no-label control-form-check"
                              style={{ width: "13%" }}
                            >
                              <input
                                type="text"
                                className="form-control set-placehol"
                                value={this.state.isCode}
                                placeholder={strings.book_now.please_input_code}
                                onChange={(e) =>
                                  this.setState({
                                    isCode: e.target.value.toUpperCase(),
                                  })
                                }
                              />
                              {/* <span
                                className="form-control-icon form-control-space"
                                style={{
                                  display: this.state.isCode ? "block" : "none",
                                }}
                              >
                                <i
                                  className="fa fa-check-circle"
                                  style={{ color: "#28C66D" }}
                                ></i>
                              </span> */}
                            </div>

                            {/* onClick={() => {window.location.href = this.state.isCurrentHref}} */}

                            <div
                              className="col-sm-2 form-group has-icon no-label1 control-form-btn"
                              style={{ width: "max-content" }}
                            >
                              <button
                                type="submit"
                                onClick={(e) => this.handleSubmit(e)}
                                className="btn btn-danger control-btn-refresh"
                              >
                                <div className="d-flex flex-row align-items-center">
                                  <span>{strings.book_now.search_car}</span>{" "}
                                  <span>
                                    <i
                                      className="fa fa-refresh"
                                      aria-hidden="true"
                                      style={{ fontSize: "18px" }}
                                    ></i>
                                  </span>
                                </div>
                              </button>
                            </div>
                          </form>
                        </div>

                        <div className="container-fluid Top-Filter-fluid Filter-hidemobile-active">
                          {/* <div className="radio has-label control-form-check-return">
                                                        <input type="radio" id="radio-chkreturna" value="isReturna" onClick={this.toggleCheck} checked={this.state.isCheck} /><label htmlFor="radio-chkreturna">Return car to the same Location</label>
                                                    </div> */}
                          <div className="checkbox Filter-conmart-same">
                            <label style={{ right: "25px" }}>
                              <input
                                type="checkbox"
                                checked={this.state.isSameLocation}
                                value={this.state.isSameLocation ? "1" : "0"}
                                onChange={(e) => this.handleSameLocation(e)}
                              />
                              <span className="cr">
                                <i
                                  className="cr-icon glyphicon glyphicon-ok"
                                  style={{
                                    top: "0",
                                  }}
                                />
                              </span>
                              <span className="Filter-confont-same">
                                {strings.book_now.same_location_as_pick_up}
                              </span>
                            </label>
                          </div>
                        </div>
                        {/* <div className="container Filter-repad-contain" style={{display: this.state.isShow ? 'block' : 'none',transition: 'opacity 0.5s linear, display 0.5s linear, visibility 0.5s 1s linear', opacity: this.state.isShow ? '1' : '0', visibility: this.state.isShow ? 'visible' : 'hidden' }} ></div> */}
                        <div
                          className="container-fluid Top-Filter-fluid Filter-repad-contain"
                          style={{
                            transition:
                              "opacity 0.4s linear 0s, height 0.2s linear 0s",
                            opacity: this.state.isShow ? "1" : "0",
                            height: this.state.isShow ? "auto" : "0",
                          }}
                        >
                          <div
                            className="form-search light reset-form"
                            style={{
                              display: this.state.isShow ? "block" : "none",
                            }}
                          >
                            <form action="">
                              <div className="Filter-setpad-infoform">
                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div
                                      className="col-xs-8 Filter-repadlr-input Filter-conw-mobile"
                                      style={{
                                        marginTop: "20px",
                                        width: isTablet ? "100%" : "auto",
                                      }}
                                    >
                                      <div className="form-group has-icon has-label reset-group">
                                        <label
                                          htmlFor="formSearchUpLocation3"
                                          className="Filter-confont-labelform"
                                        >
                                          {strings.book_now.pick_up_location}
                                        </label>
                                        <select
                                          name="location"
                                          className="form-control re-form-con"
                                          value={
                                            this.state.selectedPickupLocation
                                          }
                                          onChange={(e) =>
                                            this.pickupPlaceChange(e)
                                          }
                                        >
                                          <option
                                            value="DEFAULT"
                                            disabled
                                            hidden
                                          >
                                            {strings.book_now.please_select}
                                          </option>
                                          {this.RenderLocation()}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="col-xs-4 Filter-repadr-input Filter-conw-inputdate"
                                      style={{
                                        width:
                                          isTablet || isMobile
                                            ? "100%"
                                            : "auto",
                                        marginTop: isTablet ? 0 : "20px",
                                      }}
                                    >
                                      <div className="form-group has-icon has-label reset-group">
                                        <label
                                          htmlFor="formSearchUpLocation3"
                                          className="Filter-confont-labelform"
                                        >
                                          {strings.book_now.pick_up_date}
                                        </label>
                                        <div id="book-now-mobile">
                                          <Datetime
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            closeOnClickOutside
                                            locale="en"
                                            renderInput={(props) => {
                                              return (
                                                <input
                                                  {...props}
                                                  id="start_date_location_calendar"
                                                  value={
                                                    moment(
                                                      this.state.startDatea
                                                    ).isValid()
                                                      ? props.value
                                                      : ""
                                                  }
                                                />
                                              );
                                            }}
                                            value={this.state.startDatea}
                                            onChange={(date) => {
                                              this.setState({
                                                startDatea:
                                                  moment(date).toDate(),
                                                startDateb: moment(date)
                                                  .add(1, "days")
                                                  .toDate(),
                                              });
                                            }}
                                          />
                                          <span
                                            className="form-control-icon Filter-conicon-date Filter-conicon-datemo"
                                            onClick={() => {
                                              this.openCalendar("start_date");
                                            }}
                                          >
                                            <i
                                              className="fa fa-calendar"
                                              style={{ fontSize: "14px" }}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div className="col-xs-4 Filter-repadlr-input Book1ContentA-setmarb-mo Filter-conw-select">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        className="Filter-confont-labelform"
                                      >
                                        {strings.book_now.time}
                                      </label>
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isHrTimea}
                                        onChange={(e) =>
                                          this.setState({
                                            isHrTimea: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectHr
                                          min={
                                            this.state.select_pickup_start_time
                                          }
                                          max={
                                            this.state.select_pickup_end_time
                                          }
                                        />
                                      </select>
                                    </div>
                                    <div className="col-xs-4 Filter-repadr-input Filter-conw-selecta">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        className="Filter-confont-labelform"
                                        style={{ color: "#055CAB" }}
                                      >
                                        Time
                                      </label>
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isMinTimea}
                                        onChange={(e) =>
                                          this.setState({
                                            isMinTimea: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectMin />
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div
                                      className="col-sm-12 Filter-repadlr-input"
                                      style={{ marginTop: "20px" }}
                                    >
                                      <div className="checkbox Filter-conmart-same">
                                        <label style={{ right: "25px" }}>
                                          <input
                                            type="checkbox"
                                            checked={this.state.isSameLocation}
                                            value={
                                              this.state.isSameLocation
                                                ? "1"
                                                : "0"
                                            }
                                            onChange={(e) =>
                                              this.handleSameLocation(e)
                                            }
                                          />
                                          <span className="cr">
                                            <i
                                              className="cr-icon glyphicon glyphicon-ok"
                                              style={{
                                                top: "0",
                                              }}
                                            />
                                          </span>
                                          <span className="Filter-confont-same">
                                            Same Location as Pickup
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div
                                      className="col-xs-8 Filter-repadlr-input Filter-conw-mobile"
                                      style={{
                                        width: isTablet ? "100%" : "auto",
                                      }}
                                    >
                                      <div
                                        className="form-group has-icon has-label reset-group"
                                        style={{ margin: "0" }}
                                      >
                                        <label className="Filter-confont-labelform">
                                          {strings.book_now.return_location}
                                        </label>
                                        <select
                                          name="return-location"
                                          className="form-control re-form-con"
                                          value={
                                            this.state.selectedReturnLocation
                                          }
                                          onChange={(e) =>
                                            this.dropPlaceChange(e)
                                          }
                                        >
                                          <option
                                            value="DEFAULT"
                                            disabled
                                            hidden
                                          >
                                            {strings.book_now.please_select}
                                          </option>
                                          {this.RenderLocation()}
                                        </select>
                                      </div>
                                    </div>
                                    <div
                                      className="col-xs-4 Filter-repadr-input Filter-conw-inputdate"
                                      style={{
                                        width:
                                          isTablet || isMobile
                                            ? "100%"
                                            : "auto",
                                        marginTop: isTablet ? "10px" : "20px",
                                      }}
                                    >
                                      <div
                                        className="form-group has-icon has-label reset-group"
                                        id="book-now-mobile"
                                      >
                                        <label
                                          htmlFor="formSearchUpLocation3"
                                          className="Filter-confont-labelform"
                                        >
                                          {strings.book_now.return_date}
                                        </label>
                                        <Datetime
                                          dateFormat="DD/MM/YYYY"
                                          timeFormat={false}
                                          closeOnClickOutside
                                          locale="en"
                                          renderInput={(props) => {
                                            return (
                                              <input
                                                {...props}
                                                id="return_date_location_calendar"
                                                value={
                                                  moment(
                                                    this.state.startDateb
                                                  ).isValid()
                                                    ? props.value
                                                    : ""
                                                }
                                              />
                                            );
                                          }}
                                          value={this.state.startDateb}
                                          onChange={(date) => {
                                            this.setState({
                                              startDateb: moment(date).toDate(),
                                            });
                                          }}
                                        />
                                        <span
                                          className="form-control-icon Filter-conicon-date"
                                          onClick={() => {
                                            this.openCalendar("return_date");
                                          }}
                                        >
                                          <i
                                            className="fa fa-calendar"
                                            style={{ fontSize: "14px" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div className="col-xs-2 Filter-repadlr-input Book1ContentA-setmarb-mo Filter-conw-select">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        className="Filter-confont-labelform"
                                        style={{ marginBottom: "3px" }}
                                      >
                                        {strings.book_now.time}
                                      </label>
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isHrTimeb}
                                        onChange={(e) =>
                                          this.setState({
                                            isHrTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectHr
                                          min={
                                            this.state.select_return_start_time
                                          }
                                          max={
                                            this.state.select_return_end_time
                                          }
                                        />
                                      </select>
                                    </div>
                                    <div className="col-xs-2 Filter-repadr-input Filter-conw-selecta">
                                      <label
                                        htmlFor="formSearchUpLocation3"
                                        className="Filter-confont-labelform"
                                        style={{
                                          color: "#055CAB",
                                          marginBottom: "3px",
                                        }}
                                      >
                                        Time
                                      </label>
                                      <select
                                        className="form-control re-form-con control-select"
                                        value={this.state.isMinTimeb}
                                        onChange={(e) =>
                                          this.setState({
                                            isMinTimeb: e.target.value,
                                          })
                                        }
                                      >
                                        <RenderOptionSelectMin />
                                      </select>
                                    </div>
                                    <div className="col-xs-8 Filter-repadr-input Filter-conw-inputcode">
                                      <div
                                        className="form-group has-icon control-form-check-a"
                                        style={{ marginTop: "28px" }}
                                      >
                                        <input
                                          type="text"
                                          className="form-control re-form-con"
                                          value={this.state.isCode}
                                          placeholder="กรอก CODE"
                                          onChange={(e) =>
                                            this.setState({
                                              isCode:
                                                e.target.value.toUpperCase(),
                                            })
                                          }
                                        />
                                        <span
                                          className="form-control-icon form-control-space"
                                          style={{
                                            display: this.state.isCode
                                              ? "block"
                                              : "none",
                                          }}
                                        >
                                          <i
                                            className="fa fa-check-circle Filter-confonticon-chk"
                                            style={{ color: "#28C66D" }}
                                          ></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div className="col-sm-12 reset-col"></div>
                                  </div>
                                </div>

                                <div className="row row-inputs reset-row">
                                  <div className="container-fluid Filter-repadcon-fluid">
                                    <div className="col-md-12 Filter-conw-colrefresh">
                                      <div>
                                        <Link to="/bookingstep2">
                                          <div className="form-group control-form-btn">
                                            <button
                                              type="submit"
                                              className="btn btn-danger control-btn-refresh"
                                              onClick={(e) =>
                                                this.handleSubmit(e)
                                              }
                                            >
                                              REFRESH{" "}
                                              <i
                                                className="fa fa-refresh"
                                                aria-hidden="true"
                                                style={{ fontSize: "18px" }}
                                              ></i>
                                            </button>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- /Slide 3 --> */}
                </div>
              </div>
            </section>
            {/* <!-- /PAGE --> */}
          </div>
        </div>

        <style>{`
          .rdtPicker .rdtDay, .rdtPicker .dow, .rdtPicker .rdtSwitch, .rdtPicker .rdtPrev, .rdtPicker .rdtNext {
            color: #5C5C5C !important;
          }

          .rdtDay.rdtActive  {
            color: #fff !important;
          }

        
        `}</style>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  web_settings: state.setting.booking.settings,
  branch_time: state.branch.branch,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSettings: () => {
      dispatch(fetchSettings());
    },
    fetchBranchTime: () => {
      dispatch(fetchBranchTime());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
