import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import parse from "html-react-parser";
import "./css/Career.css";
import Filter from "../../Filter";
import axios from "axios";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import { handleError } from "../../constants";
import { isMobile } from "react-device-detect";
let curLang = localStorage.getItem("lang");
let yup = require("yup");
export default class CareerContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      careerApi: PREFIX_ADDRESS + "api/career?lang=" + curLang,
      careerObj: [],
      banner: "",
      selectedFile: null,
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        position: "",
        pdf_file: "",
      },
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
    const $ = window.$;
    window.scrollTo(0, 0);

    $("#preloader").show();
    this.fetchCareer();
    this.fetchBanner();
  }
  fetchCareer() {
    const $ = window.$;
    const urlapi = this.state.careerApi;
    axios
      .get(urlapi)
      .then((res) => {
        const { data } = res;
        console.log(data);
        this.setState({
          careerObj: data,
        });
      })
      .then(() => {
        $("#preloader").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  onChangeHandler = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };
  fetchBanner() {
    const apiurl = PREFIX_ADDRESS + "api/content/career/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }
  handleFormSubmit = async (e) => {
    e.preventDefault();
    let schema = yup.object().shape({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      email: yup.string().email(),
      phone: yup.string().required(),
      position: yup.string().required(),
    });

    let firstname = this.state.form.firstname;
    let lastname = this.state.form.lastname;
    let email = this.state.form.email;
    let phone = this.state.form.phone;
    let position = this.state.form.position;

    let addressFormData = {
      email: email,
      firstname: firstname,
      lastname: lastname,
      phone: phone,
      position: position,
    };
    const valid = await schema.isValid(addressFormData);

    const data = new FormData();
    data.append("firstname", firstname);
    data.append("lastname", lastname);
    data.append("email", email);
    data.append("phone", phone);
    data.append("position", position);
    data.append("pdf_file", this.state.selectedFile);

    if (!valid || this.state.selectedFile === null) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    axios({
      url: `${PREFIX_ADDRESS}api/job_apply`,
      method: "POST",
      data: data,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      // then print response status
      if (res.data.Success === "Y") {
        alert("ข้อมูลของท่านได้ทำการส่งแล้ว");
        document.getElementById("myForm").reset();
        this.state = {
          form: {
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            position: "",
            pdf_file: "",
          },
        };
      }
    });
  };

  handleOpenCareerQualification = (jobTitle, detail) => {
    const $ = window.$;
    $("#alertmodal").modal("show");
    $("#alertmodal .modal-body").html(
      ReactDOMServer.renderToString(
        <>
          <div className="row CareerContentB-remar-rowdefault Career-Modal">
            <div className="col-xs-12 text-center" style={{ marginTop: 0 }}>
              <div
                style={{
                  color: "#01ADEF",
                  fontWeight: "bold",
                  fontSize: "24px",
                  marginTop: 0,
                }}
              >
                {jobTitle}
              </div>
            </div>

            <div
              className="col-xs-12 CareerContentB-remart-btnpropa CareerContentB-repad-prop"
              style={{ marginTop: 0 }}
            >
              <span
                className="CareerContentB-confont-titleprop"
                style={{ color: "#01ADEF", fontSize: "18px" }}
              >
                {strings.career.qualification}
              </span>
              <ul className="CareerContentB-addmarl-prop CareerContentB-addmart-prop CareerContentB-confont-prop">
                <li>
                  <span>{parse(detail)}</span>
                </li>
              </ul>
            </div>
          </div>

          <style>{`
            .Career-Modal table {
              width: 100% !important;
            }
          `}</style>
        </>
      )
    );
  };

  RenderCareer() {
    if (this.state.careerObj instanceof Array) {
      return this.state.careerObj.map((item, i) => {
        return (
          <div key={i} className="col-md-4">
            <div className="CareerContentB-setpad-default">
              <div
                className="CareerContentB-confont-head"
                style={{
                  color: "#01ADEF",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                <span>{item.title}</span>
              </div>
              <div className="CareerContentB-hr-info" />

              <div
                style={{
                  height: "180px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="row CareerContentB-remar-rowdefault">
                  <div className="col-xs-6 CareerContentB-repad-info">
                    <div className="CareerContentB-setfloat-infocarprice CareerContentB-setmarb-infocarpricea">
                      <img
                        className="CareerContentB-conwlogo-gps"
                        src="/images/Career/logo-gps-mark.png"
                        alt="logo"
                      />
                    </div>
                    <div className="CareerContentB-confont-infocarprice">
                      <ul
                        className="CareerContentB-conpaddl-gps"
                        style={{
                          color: "#01ADEF",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        <li>{strings.career.branch}</li>

                        <li>{item.branch}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-6 CareerContentB-repad-info CareerContentB-setminh">
                    <div className="CareerContentB-setfloat-infocarprice CareerContentB-setmarb-infocarpricea">
                      <img
                        className="CareerContentB-conwlogo-money"
                        src={
                          this.state.preFixImg + "images/Career/logo-money.png"
                        }
                        alt="logo"
                      />
                    </div>
                    <div className="CareerContentB-confont-infocarprice">
                      <ul
                        className="CareerContentB-conpaddl-money"
                        style={{
                          color: "#01ADEF",
                          fontSize: "13px",
                          fontWeight: "bold",
                        }}
                      >
                        <li>{strings.career.saraly}</li>

                        <li>{item.saraly}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <button
                  className="Career-qualification-button"
                  onClick={() =>
                    this.handleOpenCareerQualification(item.title, item.detail)
                  }
                >
                  คลิกดูคุณสมบัติ
                </button>
              </div>

              {/* <div className="row CareerContentB-remar-rowdefault">
                <div className="col-xs-2 CareerContentB-remart-btnprop CareerContentB-repad-prop">
                  <img
                    className="CareerContentB-conw-logoprop"
                    src={this.state.preFixImg + "images/Career/property@2x.png"}
                    alt="Property"
                  />
                </div>
                <div className="col-xs-10 CareerContentB-remart-btnpropa CareerContentB-repad-prop">
                  <span className="CareerContentB-confont-titleprop">
                    {strings.career.qualification}
                  </span>
                  <ul className="CareerContentB-addmarl-prop CareerContentB-addmart-prop CareerContentB-confont-prop">
                    <li>
                      <span>{parse(item.detail)}</span>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        );
      });
    }
  }
  render() {
    return (
      <div>
        <Filter />

        {/* {this.state.careerObj[0].title} */}
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="CareerContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 CareerContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div className="container-title CareerContentA-contain-default">
                        <h1 style={{ fontSize: "50px" }}>
                          <strong>{strings.career.career}</strong>
                        </h1>
                        <div className="CareerContentA-hr-promo CareerContentA-conhr-head" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section CareerContentA-repadb-con">
              <div className="container-fluid CareerContentA-setmarb-afboxcareer Career-container-fluid">
                {/* <!-- Content For Pagination --> */}
                <div className="row">{this.RenderCareer()}</div>
              </div>
              <div className="container full-width">
                <div className="main-slider">
                  <div
                    className="item slide3 ver3 CareerContentB-conbg-head"
                    style={{
                      background: "#01ADEF",
                    }}
                  >
                    <div className="container-fluid">
                      <div className="container-title CareerContentB-contain-default">
                        <div className="CareerContentB-setpad-infocontact Hr-banner-container">
                          <div className="CareerContentB-shon-mobile">
                            <img
                              className="CareerContentB-conw-logophone-con"
                              src={
                                this.state.preFixImg +
                                "images/Career/logo-phone.png"
                              }
                              alt="logo"
                            />
                            <span
                              className="CareerContentB-fontnum-phone"
                              style={{ fontWeight: "bold" }}
                            >
                              1647
                            </span>
                          </div>
                          <div
                            className="CareerContentB-conspan-fr"
                            style={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "18px" : "24px",
                            }}
                          >
                            <span>{strings.career.apply_message}</span>
                          </div>

                          <div className="Hr-banner-tel-email-container">
                            <div className="CareerContentB-setfloatl-a">
                              <img
                                style={{ width: "80px" }}
                                className="CareerContentB-conw-logophone-con"
                                src={
                                  this.state.preFixImg +
                                  "images/Career/logo-phone.png"
                                }
                                alt="logo"
                              />
                              <span
                                className="CareerContentB-fontnum-phone"
                                style={{ fontSize: "90px", fontWeight: "bold" }}
                              >
                                1647
                              </span>
                            </div>
                            <div className="CareerContentB-setfloatl-b">
                              <img
                                className="CareerContentB-conw-logomail-con"
                                src={
                                  this.state.preFixImg +
                                  "images/Career/logo-mail.png"
                                }
                                alt="logo"
                              />
                              <span
                                className="CareerContentB-fontemail-th"
                                style={{
                                  fontSize: isMobile ? "18px" : "24px",
                                  fontWeight: "bold",
                                }}
                              >
                                hr@thairentacar.com
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="CareerContentC-setbg-opacity" />
                  <div
                    className="item slide3 ver3 CareerContentC-conbg-head"
                    style={{
                      backgroundImage:
                        'url("' +
                        this.state.preFixImg +
                        'images/Career/bg-image-footer@2x.png")',
                    }}
                  >
                    <div className="container CareerContentC-contain-default">
                      <div className="CareerContentC-setmar-infofoot">
                        <form id="myForm" onSubmit={this.handleFormSubmit}>
                          <div className="input-group CareerContentC-conmarb-input">
                            <span
                              className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-first"
                              id="basic-addon1"
                            >
                              First Name
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    firstname: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="input-group CareerContentC-conmarb-input">
                            <span
                              className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-last"
                              id="basic-addon1"
                            >
                              Last Name
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    lastname: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="input-group CareerContentC-conmarb-input">
                            <span
                              className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-phone"
                              id="basic-addon1"
                            >
                              Phone
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    phone: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="input-group CareerContentC-conmarb-input">
                            <span
                              className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-po"
                              id="basic-addon1"
                            >
                              ตำแหน่ง
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    position: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div className="input-group CareerContentC-conmarb-input">
                            <span
                              className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-mail"
                              id="basic-addon1"
                            >
                              EMAIL
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              aria-describedby="basic-addon1"
                              onChange={(e) =>
                                this.setState({
                                  form: {
                                    ...this.state.form,
                                    email: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>

                          <div className="Resume-input-container">
                            <div
                              className="input-group CareerContentC-conmarb-input"
                              style={{ width: "65%", marginBottom: 0 }}
                            >
                              <span
                                className="input-group-addon CareerContentC-conspan-front CareerContentC-conspan-re"
                                id="basic-addon1"
                              >
                                Resume PDF
                              </span>
                              <input
                                id="resume-pdf-input"
                                type="file"
                                className="form-control"
                                placeholder="Resume PDF"
                                aria-describedby="basic-addon1"
                                onChange={this.onChangeHandler}
                              />
                            </div>

                            <div style={{ width: "35%" }}>
                              <button
                                className="btn btn-default CareerContentC-buildbtn-upload"
                                type="button"
                                onClick={() => {
                                  document
                                    .getElementById("resume-pdf-input")
                                    .click();
                                }}
                              >
                                UPLOAD
                              </button>
                            </div>
                          </div>

                          <div className="CareerContentC-conbtn-submitfoot">
                            <button
                              className="btn btn-danger CareerContentC-buildbtn-submit"
                              type="submit"
                            >
                              SUBMIT
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
