import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import HomeFilter from "./components/Home/HomeFilter";
import ProContentA from "./components/Promotion/ProContentA";
import ProDeContentA from "./components/PromotionDetail/ProDeContentA";
import ReviewContentA from "./components/Review/ReviewContentA";
import ReviewDeContentA from "./components/ReviewDetail/ReviewDeContentA";
import Book1ContentA from "./components/BookingStep1/Book1ContentA";
import BookHeaderStatus from "./components/BookingStep2/BookHeaderStatus";
import Book3HeaderStatus from "./components/BookingStep3/Book3HeaderStatus";
import Book4HeaderStatus from "./components/BookingStep4/Book4HeaderStatus";
import Book4EX from "./components/BookingStep4/Book4HeaderStatus_EX";

import paymentForm from "./components/Payment/Form";
import paymentFail from "./components/Payment/Fail";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import OurServiceHeaderContent from "./components/OurService/OurService";
import BranchContentA from "./components/BranchLocation/BranchContentA";
import EnterpriseContentA from "./components/Enterprise/EnterpriseContentA";
import FAQContentA from "./components/FAQ/FAQContentA";
import PolicyTerm from "./components/PolicyTerm";
import CookiePolicy from "./components/CookiePolicy/index";
import PolicyNotice from "./components/PolicyNotice/index";

import ContactContentA from "./components/Contact/ContactContentA";
import CareerContentA from "./components/Career/CareerContentA";
import PartnerContentA from "./components/Partner/PartnerContentA";
import PartnerDetail from "./components/PartnerDetail/PartnerDetail";
import ConfirmContentA from "./components/Confirm/ConfirmContentA";
import AboutContentA from "./components/AboutUs/AboutContentA";
import ProfileContentA from "./components/Profile/ProfileContentA";
import BookHisContentA from "./components/Profile/BookHisContentA";
import RegisterPage from "./components/Register/RegisterPage";
import ResetPasswordPage from "./components/Register/ResetPasswordPage";
import LoginPage from "./components/Login/LoginPage";
import LoginBookingPage from "./components/Login/LoginBookingPage";
import WorldWidePartner from "./components/WorldWidePartner/WorldWidePartner";
import JourneyList from "./components/Journey/Journey";
import JourneyDetail from "./components/JourneyDetail/JourneyDetail";

import FbPage from "./components/Login/Fb";

import Demo1 from "./components/Home/HomeFilterDemo";

import { strings } from "./language/language";
// import { createBrowserHistory } from "history";
// import { Router } from "react-router-dom";
// const hist = createBrowserHistory();

class RoutePath extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preFixPath: "",
    };
  }
  componentDidMount() {}
  render() {
    return (
      <main>
        <Switch>
          <Route
            exact
            path={this.state.preFixPath + "/"}
            component={HomeFilter}
          />

          <Route
            exact
            path={this.state.preFixPath + "/demo/vdoBanner"}
            component={Demo1}
          />

          <Route
            exact
            path={this.state.preFixPath + "/journey"}
            component={JourneyList}
          />

          <Route
            exact
            path={this.state.preFixPath + "/journey/:id"}
            component={JourneyDetail}
          />

          <Route
            exact
            path={this.state.preFixPath + "/promotion"}
            render={(props) => (
              <ProContentA {...props} localString={strings.promotion} />
            )}
          />
          <Route
            exact
            path={this.state.preFixPath + "/promotion/:id"}
            component={ProDeContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/review"}
            component={ReviewContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/privacy-policy-and-term"}
            component={PolicyTerm}
          />
          <Route
            exact
            path={this.state.preFixPath + "/cookie-policy"}
            component={CookiePolicy}
          />
          <Route
            exact
            path={this.state.preFixPath + "/policy-notice"}
            component={PolicyNotice}
          />

          <Route
            exact
            path={this.state.preFixPath + "/review/:id"}
            component={ReviewDeContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookingstep1"}
            component={Book1ContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookingstep2"}
            component={BookHeaderStatus}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookingstep3"}
            component={Book3HeaderStatus}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookingstep4"}
            component={Book4HeaderStatus}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookingstep4EX"}
            component={Book4EX}
          />

          <Route
            exact
            path={this.state.preFixPath + "/payment"}
            component={paymentForm}
          />
          <Route
            exact
            path={this.state.preFixPath + "/payment-fail"}
            component={paymentFail}
          />

          <Route
            exact
            path={this.state.preFixPath + "/payment-success"}
            component={PaymentSuccess}
          />
          <Route path="/ourservice">
            <Route
              exact
              path={this.state.preFixPath + "/ourservice/short-term"}
              component={OurServiceHeaderContent}
            />
            <Route
              exact
              path={this.state.preFixPath + "/ourservice/long-term"}
              component={OurServiceHeaderContent}
            />
            <Route
              exact
              path={this.state.preFixPath + "/ourservice/enterprise"}
              component={OurServiceHeaderContent}
            />
            <Route
              exact
              path={this.state.preFixPath + "/ourservice/chaffeur"}
              component={OurServiceHeaderContent}
            />

            {/* <Redirect to={this.state.preFixPath + "/ourservice/short-term"} /> */}
          </Route>

          <Route
            exact
            path={this.state.preFixPath + "/branch"}
            component={BranchContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/enterprise"}
            component={EnterpriseContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/faq"}
            component={FAQContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/contact"}
            component={ContactContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/career"}
            component={CareerContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/partner"}
            component={PartnerContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/partner/worldwide"}
            component={WorldWidePartner}
          />
          <Route
            exact
            path={this.state.preFixPath + "/partner/:id"}
            component={PartnerDetail}
          />

          <Route
            exact
            path={this.state.preFixPath + "/confirm"}
            component={ConfirmContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/profile"}
            component={ProfileContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/bookinghistory"}
            component={BookHisContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/about"}
            component={AboutContentA}
          />
          <Route
            exact
            path={this.state.preFixPath + "/register"}
            component={RegisterPage}
          />

          <Route
            exact
            path={this.state.preFixPath + "/reset-password"}
            component={ResetPasswordPage}
          />
          <Route
            exact
            path={this.state.preFixPath + "/login"}
            component={LoginPage}
          />
          <Route
            exact
            path={this.state.preFixPath + "/login-booking"}
            component={LoginBookingPage}
          />
          <Route
            exact
            path={this.state.preFixPath + "/fb"}
            component={FbPage}
          />
        </Switch>
      </main>
    );
  }
}

export default RoutePath;
