import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import "./css/BookingStep4.css";
import Filter from "../../Filter";
import axios from "axios";
import { PREFIX_ADDRESS, handleError, handleProfileError } from '../../constants';
import { strings } from "../../language/language";
import { Button } from 'react-bootstrap';
import { fetchSettings } from '../../actions/settingAction'
let booking_temp;
let booking_option;
let booking_voucher;
let yup = require('yup');
let total_amount;
let token = localStorage.login_token;
let is_input_update = false
// let token = "";


const apiUrl = PREFIX_ADDRESS + "api/member/details";
const bank = [
    { code: "BBL", title: "Bangkok Bank Public Company Limited" },
    { code: "BAY", title: "Bank of Ayudhya Public Company Limited" },
    { code: "KBANK", title: "Kasikornbank Public Company Limited" },
    { code: "KTC", title: "Krung Thai Bank Public Company Limited" },
    { code: "GSB", title: "The Government Savings Bank" },
    { code: "TMB", title: "Tmb Bank Public Company Limited" },
    { code: "UOB", title: "United Overseas Bank(Thai)Public Company Limited" },
    { code: "TBANK", title: "Thanachart Bank Public Company Limited" },
    { code: "Citibank", title: "CITI / citigroup global markets limited" },
    { code: "SCB", title: "The Siam Commercial Bank Public Company Limited" },
    { code: "Other", title: "Other" }
];

class Book4HeaderStatus extends Component {
    constructor(props) {
        super(props);
        // console.log('xxx',props.web_settings.alipay_enable)
        this.state = {
            isActiveSmartpay: false,
            isActiveStandard: false,
            isActiveUnionPay: false,
            isActiveAlipay: false,
            isActiveWechat: false,
            isActiveQr: false,

            preFixPath: "",
            preFixImg: "",
            isCheck: false,
            isShow: false,
            isFirstName: "",
            chkFistName: false,
            isLastName: "",
            isEmail: "",
            isPhone: "",
            isDriLicense: "",
            chkForOther: false,
            otherFirstName: "",
            otherLastName: "",
            otherEmail: "",
            otherPhone: "",
            otherDriLicense: "",
            isPersonType: "person",
            isNameCompany: "",
            isNumberTax: "",
            isAddress: "",
            isFlightInfo: "",
            isFlightNumber: "",
            isCreditOrDebit: 1,
            isCardType: 1,
            outBranch: "",
            outDate: "",
            outTime: "00:00",
            inBranch: "",
            inDate: "",
            inTime: "00:00",
            isParam: "",
            isNewMember: false,
            isAlert: false,
            needTaxInvoice: false,
            branchcode: "0000",
            fetchDriver: "",
            confirmComplete: false,
            validateStatusTxt: "",
            paymentChannel: "standard",
            isBranches: [],
            bank: {

            },
            book4Obj: {
                detail: {
                    title: "",
                    size: "",
                    type: "",
                    engine: "",
                    seat: "",
                    door: "",
                    air_condition: "",
                    tranmission: "",
                    luggage: "",
                    radio_type: ""
                },
                rate: {
                    total: 0,
                    vat: 0,
                    extra: 0,
                    insurance: 0,
                    amount: 0,
                    car_price: 0,
                    drop_off: 0,
                    partial: ""
                }
            },
            isCarPrice: 0,
            isExtra: 0,
            isVat: 0,
            isVoucher: 0,
            totalPrice: 0
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.checkProfile = this.checkProfile.bind(this);

    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        const $ = window.$;
        // await this.props.fetchSettings()

        // console.log('this.props.web_settings',this.props.web_settings.smartpay_enable)
        const query = new URLSearchParams(this.props.location.search);
        let lineToken = query.get("lineToken");

        $(".modal-backdrop").remove();

        booking_temp = localStorage.getItem("booking_temp");
        booking_voucher = localStorage.getItem("booking_voucher");


        if (!booking_temp) {
            window.location.href = "/";
            return;
        }
        else if (token) {
            // this.getData();
        }

        if (lineToken && token != lineToken) {
            localStorage.setItem("login_token", lineToken)
            token = lineToken;
            window.location.reload(false);
        }

        // console.log(this.props.location.state.option_price);

        this.fetchBook4();
        this.fetchLocation();
        this.getData();
    }

    componentDidUpdate() {
        let web_setting = this.props.web_settings
        if (!is_input_update && web_setting) {

            this.setState({
                isActiveSmartpay: web_setting.smartpay_enable ? true : false,
                isActiveStandard: web_setting.standard_enable ? true : false,
                isActiveUnionPay: web_setting.unionpay_enable ? true : false,
                isActiveAlipay: web_setting.alipay_enable ? true : false,
                isActiveWechat: web_setting.wechat_enable ? true : false,
                isActiveQr: web_setting.qr_enable ? true : false,
            })
            is_input_update = true
        }

    }


    getData = (e) => {
        if (token) {
            axios
                .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    localStorage.setItem("member_name", data.success.firstname);
                    // console.log("getData", data)
                    this.setState({
                        profile: data.success,
                        isFirstName: data.success.firstname,
                        isLastName: data.success.lastname,
                        isPhone: data.success.phone,
                        isEmail: data.success.email,
                        isDriLicense: data.success.driving_license_no !== "null" ? data.success.driving_license_no : "",
                        confirmComplete: true,

                    })
                })
                .catch(errs => {
                    console.error(errs);
                    // this.props.history.push({pathname:"/login",state: {}});
                });
        }

    }


    checkProfile = async (e) => {
        // console.log(this)
        let email = this.state.isEmail;
        let schema = yup.object().shape({
            email: yup.string().email()
        });
        let addressFormData = {
            email: email
        }

        const valid = await schema.isValid(addressFormData);

        if (!valid) {
            this.setState({
                validateStatusTxt: "กรอกอีเมลไม่ถูกต้อง",
            });
            return false;
        }


        if (this.state.isEmail) {
            this.setState({
                validateStatusTxt: "กำลังตรวจสอบข้อมูล",
            });
            axios.get(`${PREFIX_ADDRESS}api/booking/checkemail?email=${this.state.isEmail}`).then(res => {
                // console.log(res);
                if (res.data.Success === "Y") {

                    this.setState({
                        fetchDriver: res.data.detail,
                        isFirstName: res.data.detail.FirstName,
                        isLastName: res.data.detail.LastName,
                        isPhone: res.data.detail.Mobile,
                        isNewMember: false,
                        validateStatusTxt: "",
                        confirmComplete: true,
                    });
                } else {
                    this.setState({
                        validateStatusTxt: "ไม่พบข้อมูลสมาชิกในระบบ กรุณากรอกข้อมูลเพิ่มให้ครบถ้วน",
                        isNewMember: true,
                        isFirstName: "",
                        isLastName: "",
                        isPhone: "",
                        confirmComplete: true,
                    });

                }
                // this.setState({
                //     confirmComplete: true
                // });

            })
        }
    }
    fetchBook4 = async (e) => {
        const $ = window.$;
        $("#preloader").show();

        // console.log("booking_temp" + booking_temp);
        booking_temp = JSON.parse(booking_temp);
        let outBranch = booking_temp.OutBranch;
        let outDate = booking_temp.OutDate;
        let outTime = booking_temp.OutTime;
        let inBranch = booking_temp.InBranch;
        let inDate = booking_temp.InDate;
        let inTime = booking_temp.InTime;
        let cdp = booking_temp.CDP;
        let carGroup = booking_temp.CarGroup;
        let rateNo = booking_temp.RateNo;
        let ratePackage = booking_temp.RatePackage;
        let isActiveSmartpay;


        if (booking_voucher && cdp !== "PAYLATER") {
            booking_voucher = JSON.parse(booking_voucher);
            Object.keys(booking_voucher).forEach(key => {
                let voucher_amount = parseFloat(booking_voucher[key].Value);

                this.setState(prevState => ({
                    isVoucher: prevState.isVoucher + voucher_amount
                }));
            });
        }
        await axios.get(`${PREFIX_ADDRESS}api/isActiveSmartPay`).then(res => {
            isActiveSmartpay = res.data
        })




        this.setState({ outBranch: outBranch, outDate: outDate, outTime: outTime, inBranch: inBranch, inDate: inDate, inTime: inTime, isCarGrop: carGroup, isActiveSmartpay: isActiveSmartpay });

        if (localStorage.getItem("booking_option")) {
            booking_option = JSON.parse(localStorage.getItem("booking_option"));
        }
        else {
            booking_option = { "extra": {}, "insurance": {} };
        }
        let param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&CarGroup=${carGroup}&CDP=${cdp}&RateNo=${rateNo}&RatePackage=${ratePackage}`;
        Object.keys(booking_option.extra).map((item) => {
            param = param + "&Options[extra][" + item + "]=" + booking_option.extra[item];
            return null;

        })

        Object.keys(booking_option.insurance).map((item) => {
            param = param + "&Options[insurance][" + item + "]=" + booking_option.insurance[item];
            return null;
        })

        this.setState({ isParam: param });

        axios.get(`${PREFIX_ADDRESS}api/booking/cardetails${param}`).then(res => {
            const { data } = res;
            // console.log(data);


            if (data.Success === "Y") {
                this.setState({
                    book4Obj: data,
                    rentalPrice: data.rate.car_price
                });
            } else {
                handleError(data.message);
                window.location.href = "/";
            }
        }).then(() => {
            setTimeout(() => {
                $("#preloader").hide();
            }, 100);
        }).catch(err => {
            console.log(err);

        });


    }

    fetchLocation() {
        axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then(res => {
            if (res.data.Success === "Y") {
                const { data } = res;
                // console.log(data.Branch);
                this.setState({ isBranches: data.Branch })
            }
        })
    }

    onKeyPressOnlyLetter(event, pattern = /[a-zA-Z0-9,\s]/) {
        // console.log(event.key);

        if (!event.key.match(pattern)) {
            event.preventDefault();
        }
    }


    changeProfile = async (e) => {
        let formdata = new FormData();


        formdata.append("phone", this.state.isPhone);
        formdata.append("firstname", this.state.isFirstName);
        formdata.append("lastname", this.state.isLastName);
        formdata.append("driving_license_no", this.state.isDriLicense);


        if (token) {
            axios
                .post(apiUrl, formdata, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;

                })
                .catch(errs => {
                    console.error(errs);
                });
        }

        return
    }

    handleFormSubmit = async (e) => {

        e.preventDefault();
        // return false;
        if (this.state.profile && this.state.profile.email && (!this.state.profile.country || !this.state.profile.nationality || !this.state.profile.birthday)) {
            handleProfileError(strings.error['required field need to be filled']);
            return;
        }
        this.changeProfile();

        let schema = yup.object().shape({
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            paymentChannel: yup.string().required(),
            email: yup.string().email(),
            phone: yup.string().required(),
        });
        if (this.state.confirmComplete === false && !this.state.profile) {
            this.setState({ isAlert: true })
            handleError(strings.error.validate_before_payment);
            // console.log(this.inputFocus);
            for (var i = 0; i < this.inputFocus.length; i++) {
                if (this.inputFocus[i].value === "") {
                    this.inputFocus[i].focus();
                    console.log(this.inputFocus[i])
                    break;
                }
            }

            return false;
        } else {
            this.setState({ isAlert: false })
        }



        const $ = window.$;


        let firstname = this.state.isFirstName;
        let lastname = this.state.isLastName;
        let email = this.state.isEmail;
        let phone = this.state.isPhone;
        let drivinglicense = this.state.isDriLicense;
        let isForOther = this.state.chkForOther ? 1 : 0;
        let other_firstname = this.state.otherFirstName;
        let other_lastname = this.state.otherLastName;
        let other_email = this.state.otherEmail;
        let other_phone = this.state.otherPhone;
        let other_drivinglicense = this.state.other_drivinglicense;


        let namecompany = this.state.isNameCompany;
        let numbertax = this.state.isNumberTax;
        let address = this.state.isAddress;
        let flightinfo = this.state.isFlightInfo;
        let flightnumber = this.state.isFlightNumber;
        let creditordebit = this.state.isCreditOrDebit;
        let cardtype = this.state.isCardType;
        let personType = this.state.isPersonType;
        let needTaxInvoice = this.state.needTaxInvoice;
        let branchcode = this.state.branchcode;
        let paymentChannel = this.state.paymentChannel;

        let addressFormData = {
            email: email,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            paymentChannel: paymentChannel
        }
        const valid = await schema.isValid(addressFormData);

        if (!valid) {
            handleError("กรุณากรอกข้อมูลให้ครบถ้วน");
            return false;
        }



        if (this.state.isShow === false) {
            namecompany = ""
            numbertax = ""
            address = ""
        }


        let booking_temp = JSON.parse(localStorage.getItem("booking_temp"));
        let booking_extra = JSON.parse(localStorage.getItem("booking_option"));
        let booking_voucher = JSON.parse(localStorage.getItem("booking_voucher"));
        let x = this.props.history;

        if (booking_temp.CDP === "PAYLATER") {
            booking_voucher = [];
        }


        let booking_info = {
            YourInformation: { firstname: firstname, lastname: lastname, email: email, phone: phone, drivinglicense: drivinglicense, namecompany: namecompany, numbertax: numbertax, address: address, person_type: personType, need_tax_invoice: needTaxInvoice, branchcode: branchcode },
            OtherInformation: { firstname: other_firstname, lastname: other_lastname, email: other_email, phone: other_phone, drivinglicense: other_drivinglicense },
            isForOther: isForOther,
            TravelInformation: { flightinfo: flightinfo, flightnumber: flightnumber, },
            CreditCardDetail: { issuer: creditordebit, type: cardtype },
            BookingDetail: booking_temp,
            paymentChannel: paymentChannel,
            BookingOption: booking_extra,
            BookingVoucher: booking_voucher,
            Rate: { total: total_amount, vat: this.state.book4Obj.rate.vat, extra: this.state.book4Obj.rate.extra, insurance: this.state.book4Obj.rate.insurance, carprice: this.state.book4Obj.rate.car_price, voucher: this.state.isVoucher, drop_off: this.state.book4Obj.rate.drop_off },

        }


        $("#preloader").show();
        // console.log(booking_info);

        axios.post(`${PREFIX_ADDRESS}api/booking/preparation`, booking_info, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
            .then(function (res) {
                //handle success
                console.log(res);
                if (res.data.status === "success") {

                    if (res.data.url) {
                        window.location.href = res.data.url;
                    }
                    else {
                        console.log(res.data.channel);
                        x.push({
                            pathname: "/payment", state: {
                                token: res.data.token,
                                channel: res.data.channel,
                            }
                        });
                    }

                }
                else {
                }
                // x.push({pathname:"/bookingstep6",state: {
                //         callback_data:JSON.stringify(res),
                //         isExtra: this_state.isExtra,
                //         isVat: this_state.isVat,
                //         isVoucher: this_state.isVoucher,
                //         totalPrice: this_state.totalPrice,
                //         rentalPrice:this_state.rentalPrice
                //     }});

            })
            .catch(function (res) {
                //handle error
                console.log(res);
            });

    }


    render() {
        this.inputFocus = [];
        total_amount = this.state.book4Obj.rate.total - this.state.isVoucher;
        total_amount = total_amount < 0 ? 0 : total_amount;
        return (
            <div>
                <Filter />
                <div className="wrapper">
                    <div
                        className="content-area"
                        style={{ paddingBottom: "30px" }}
                    >
                        <div className="container BookHeaderStatus-repad-statusbar set-container-mobile">
                            <div className="row BookHeaderStatus-row" style={{ height: "110px" }}>
                                <div className="col-xs-1 BookHeaderStatus-reon-mobile" />
                                <div className="col-xs-10 BookHeaderStatus-reset-col" style={{ marginTop: "8px" }}>
                                    <div className="col-xs-3 BookHeaderStatus-set-cen">
                                        <div className="BookHeaderStatus-circle">
                                            {/* <i class="fa fa-check-circle" /> */}
                                            <img
                                                src={this.state.preFixImg + "images/BookingStep2/AlreayStatus@2x.png"}
                                                width="100%"
                                                className="BookHeaderStatus-img-mobile"
                                                alt="Status"
                                            />
                                        </div>
                                        <span className="BookHeaderStatus-con-span"><span className="wrap_step_txt">{strings.booking_step.step}</span> 1</span>
                                    </div>
                                    <div className="col-xs-3 BookHeaderStatus-set-cen">
                                        <Link to={{
                                            pathname: "/bookingstep2",
                                            search: this.state.isParam
                                        }}>
                                            <i className="fa fa-chevron-right" />
                                            <div className="BookHeaderStatus-circle">
                                                <img
                                                    src={this.state.preFixImg + "images/BookingStep2/AlreayStatus@2x.png"}
                                                    width="100%"
                                                    className="BookHeaderStatus-img-mobile"
                                                    alt="Status"
                                                />
                                            </div>
                                            <span className="BookHeaderStatus-con-span"><span className="wrap_step_txt">{strings.booking_step.step}</span> 2</span>
                                        </Link>
                                    </div>
                                    <div className="col-xs-3 BookHeaderStatus-set-cen">
                                        <Link
                                            to={
                                                this.state.preFixPath +
                                                "/bookingstep3"
                                            }
                                        >
                                            <i className="fa fa-chevron-right" />
                                            <div className="BookHeaderStatus-circle">
                                                <img
                                                    src={this.state.preFixImg + "images/BookingStep2/AlreayStatus@2x.png"}
                                                    width="100%"
                                                    className="BookHeaderStatus-img-mobile"
                                                    alt="Status"
                                                />
                                            </div>
                                            <span className="BookHeaderStatus-con-span"><span className="wrap_step_txt">{strings.booking_step.step}</span> 3</span>
                                        </Link>
                                    </div>
                                    <div className="col-xs-3 BookHeaderStatus-set-cen">
                                        <i className="fa fa-chevron-right" style={{ color: "#fff", opacity: "1" }} />
                                        <div className="BookHeaderStatus-circle">
                                            <img
                                                src={this.state.preFixImg + "images/BookingStep2/CurrentStatus-4@2x.png"}
                                                width="100%"
                                                className="BookHeaderStatus-img-mobile"
                                                alt="Status"
                                            />
                                        </div>
                                        <span className="BookHeaderStatus-con-span"><span className="wrap_step_txt">{strings.booking_step.step}</span> 4</span>
                                    </div>
                                </div>
                                <div className="col-xs-1" />
                            </div>
                        </div>
                        <div
                            className="container BookingStep3-re-contain"
                            style={{
                                marginTop: "0"
                            }}
                        >
                            <div
                                className="row"
                                style={{
                                    margin: "0 0",
                                    paddingBottom: "30px"
                                }}
                            >
                                <div className="col-md-9 BookingStep3-set-mar BookingStep3-re-padl BookingStep3-mobile-padl BookingStep3-repadl-contain">


                                    <form onSubmit={this.handleFormSubmit}>
                                        <div className="Book3ContentA-head-option">
                                            {strings.booking_info.your_infomation}
                                        </div>

                                        <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                                            <div className="row BookingStep4-rowmar-default">
                                                <div className="col-sm-12 BookingStep4-font-default">

                                                    <div className="BookingStep4-maringroup">
                                                        <div style={{ marginBottom: "20px" }}>{strings.booking_info.english_only}</div>

                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.email}
                                                            </span>
                                                            <span className="BookingStep4-span-red">
                                                                {" "}
                                                                *
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                required
                                                                type="email"
                                                                ref={(input) => { if (input) { this.inputFocus.push(input) } }}
                                                                // onKeyPress={event => this.onKeyPressOnlyLetter(event,/[a-zA-Z0-9,@._-]/)}
                                                                className={"BookingStep4-conwidth-input " + (this.state.isAlert && this.state.isEmail === "" ? "danger" : "")}
                                                                value={this.state.isEmail}
                                                                readOnly={this.state.profile}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    value = value.replace(/[^a-zA-Z0-9,@._-]/ig, '')
                                                                    this.setState({
                                                                        isEmail: value,
                                                                        confirmComplete: false,
                                                                        validateStatusTxt: "",
                                                                    })
                                                                }
                                                                }
                                                            />
                                                            {!this.state.profile && (
                                                                <Button className="btnCheckEmail" variant={this.state.confirmComplete === false ? "primary" : "success"} onClick={this.checkProfile} style={{}}>{strings.booking_info.verify}</Button>
                                                            )}

                                                        </div>
                                                        <div style={{ marginTop: "10px", color: "red" }}>{this.state.validateStatusTxt}</div>
                                                    </div>


                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.first_name}
                                                            </span>
                                                            <span className="BookingStep4-span-red">
                                                                {" "}
                                                                *
                                                            </span>
                                                            {/* {"  "}{this.state.chkFistName ? <span style={{color: "red"}}>"Pleast Fill "</span> : "" } */}
                                                        </div>

                                                        <div>
                                                            <input
                                                                required
                                                                type="text"
                                                                ref={(input) => { if (input) { this.inputFocus.push(input) } }}
                                                                name="firstname"
                                                                readOnly={!this.state.confirmComplete || this.state.profile}
                                                                className={"BookingStep4-conwidth-input " + (this.state.isAlert && this.state.isFirstName === "" ? "danger" : "")}
                                                                value={this.state.isFirstName}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    value = value.replace(/[^A-Za-z]/ig, '')
                                                                    this.setState({
                                                                        isFirstName: value
                                                                    })
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.last_name}
                                                            </span>
                                                            <span className="BookingStep4-span-red">
                                                                {" "}
                                                                *
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                required
                                                                type="text"
                                                                readOnly={!this.state.confirmComplete || this.state.profile}
                                                                className={"BookingStep4-conwidth-input " + (this.state.isAlert && this.state.isLastName === "" ? "danger" : "")}
                                                                value={this.state.isLastName}
                                                                ref={(input) => { if (input) { this.inputFocus.push(input) } }}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    value = value.replace(/[^A-Za-z]/ig, '')
                                                                    this.setState({
                                                                        isLastName: value
                                                                    })
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.phone}
                                                            </span>
                                                            <span className="BookingStep4-span-red">
                                                                {" "}
                                                                *{"  "}
                                                            </span>
                                                            <span style={{ color: "red", fontSize: "10px" }}>Format: 0801112222 </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                required
                                                                maxLength="20"
                                                                type="text"
                                                                className={"BookingStep4-conwidth-inputP " + (this.state.isAlert && this.state.isPhone === "" ? "danger" : "")}
                                                                readOnly={!this.state.confirmComplete || (this.state.profile && this.state.profile.phone)}
                                                                value={this.state.isPhone || ""}
                                                                ref={(input) => { if (input) { this.inputFocus.push(input) } }}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    value = value.replace(/[^0-9]/ig, '')
                                                                    this.setState({
                                                                        isPhone: value
                                                                    })
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.driving_license}
                                                            </span>
                                                            <span className="BookingStep4-span-red">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="BookingStep4-conwidth-inputP"
                                                                value={this.state.isDriLicense || ""}
                                                                readOnly={!this.state.confirmComplete || this.state.profile}
                                                                onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    value = value.replace(/[^A-Za-z0-9]/ig, '')
                                                                    this.setState({
                                                                        isDriLicense: value
                                                                    })
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                        <div className="checkbox">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id="chk-tax-invoice"
                                                                    value="1"
                                                                    onClick={(e) => this.setState({ isShow: !this.state.isShow, needTaxInvoice: e.target.checked })}
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    {strings.booking_info.tax_invoice}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="BookingStep4-maringroup" style={{
                                                        display: this.state
                                                            .isShow
                                                            ? "block"
                                                            : "none",
                                                        animation:
                                                            "fadeIn 1.5s"
                                                    }}>
                                                        <div className="radio" style={{ display: "inline-block" }}>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="radio-tax-personal"
                                                                    name="personal_type"
                                                                    value="person"
                                                                    checked={this.state.isPersonType === "person"}
                                                                    onChange={(e) => this.setState({
                                                                        isPersonType: e.target.value
                                                                    })}

                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    บุคคลธรรมดา
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="radio" style={{ display: "inline-block" }}>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="radio-tax-company"
                                                                    name="personal_type"
                                                                    value="company"
                                                                    checked={this.state.isPersonType === "company"}
                                                                    onChange={(e) => this.setState({
                                                                        isPersonType: e.target.value
                                                                    })}

                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    นิติบุคคล
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                    <div
                                                        className="BookingStep4-conforchkbox"
                                                        style={{
                                                            display: this.state
                                                                .isShow
                                                                ? "block"
                                                                : "none",
                                                            animation:
                                                                "fadeIn 1.5s"
                                                        }}
                                                    >
                                                        <div className="BookingStep4-maringroup">
                                                            <div className="Book4ContentA-marb-span">
                                                                <span className="BookingStep4-span-blue">
                                                                    ชื่อ และ
                                                                    นามสกุล /
                                                                    ชื่อบริษัท
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="BookingStep4-conwidth-input"
                                                                    value={this.state.isNameCompany}
                                                                    onChange={(e) => this.setState({
                                                                        isNameCompany: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="BookingStep4-maringroup">
                                                            <div className="Book4ContentA-marb-span">
                                                                <span className="BookingStep4-span-blue">
                                                                    รหัสสาขา
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="BookingStep4-conwidth-input"
                                                                    value={this.state.branchcode}
                                                                    onChange={(e) => this.setState({
                                                                        branchcode: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>


                                                        <div className="BookingStep4-maringroup">
                                                            <div className="Book4ContentA-marb-span">
                                                                <span className="BookingStep4-span-blue">
                                                                    หมายเลขประจำตัวผู้เสียภาษีอากร
                                                                </span>
                                                                {"  "}{this.state.isShow && this.state.isNumberTax.length < 13 ? <span style={{ color: "red", fontSize: "10px" }}>"ต้องการตัวเลข 13 หลัก "</span> : ""}
                                                            </div>
                                                            <div>
                                                                <input
                                                                    maxLength="13"
                                                                    minLength="13"
                                                                    type="text"
                                                                    className="BookingStep4-conwidth-input"
                                                                    value={this.state.isNumberTax}
                                                                    onChange={(e) => this.setState({
                                                                        isNumberTax: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="BookingStep4-maringroup">
                                                            <div className="Book4ContentA-marb-span">
                                                                <span className="BookingStep4-span-blue">
                                                                    ที่อยู่
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    type="text"
                                                                    className="BookingStep4-conwidth-input"
                                                                    value={this.state.isAddress}
                                                                    onChange={(e) => this.setState({
                                                                        isAddress: e.target.value
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 
                                                    <div className="BookingStep4-maringroup" style={{ marginBottom: "35px" }}>
                                                        <div className="checkbox">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="checkbox"
                                                                    id="chk-tax-invoice"
                                                                    value="1"
                                                                    onClick={(e) => this.setState({ chkForOther: e.target.checked })}
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    จองให้บุคคลอื่น
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div> */}







                                                </div>
                                            </div>
                                        </div>

                                        <div style={{
                                            display: this.state
                                                .chkForOther
                                                ? "block"
                                                : "none",
                                            animation:
                                                "fadeIn 1.5s"
                                        }}>
                                            <div className="Book3ContentA-head-option">
                                                Contact Information
                                        </div>
                                            <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                                                <div className="row BookingStep4-rowmar-default">
                                                    <div className="col-sm-12 BookingStep4-font-default">


                                                        <div
                                                            className="BookingStep4-conforchkbox"

                                                        >

                                                            <div className="BookingStep4-maringroup">
                                                                <div className="Book4ContentA-marb-span">
                                                                    <span className="BookingStep4-span-blue">
                                                                        อีเมล
                                                                </span>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="BookingStep4-conwidth-input"
                                                                        value={this.state.otherEmail}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            value = value.replace(/[^a-zA-Z0-9,@._-]/ig, '')
                                                                            this.setState({
                                                                                otherEmail: value
                                                                            })
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="BookingStep4-maringroup">
                                                                <div className="Book4ContentA-marb-span">
                                                                    <span className="BookingStep4-span-blue">
                                                                        ชื่อ
                                                                </span>
                                                                </div>


                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="BookingStep4-conwidth-input"
                                                                        value={this.state.otherFirstName}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            value = value.replace(/[^A-Za-z]/ig, '')
                                                                            this.setState({
                                                                                otherFirstName: value
                                                                            })
                                                                        }
                                                                        }

                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="BookingStep4-maringroup">
                                                                <div className="Book4ContentA-marb-span">
                                                                    <span className="BookingStep4-span-blue">
                                                                        นามสกุล
                                                                </span>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="BookingStep4-conwidth-input"
                                                                        value={this.state.otherLastName}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            value = value.replace(/[^A-Za-z]/ig, '')
                                                                            this.setState({
                                                                                otherLastName: value
                                                                            })
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className="BookingStep4-maringroup">
                                                                <div className="Book4ContentA-marb-span">
                                                                    <span className="BookingStep4-span-blue">
                                                                        เบอร์โทรศัพท์
                                                                </span>
                                                                    {"  "}{this.state.isShow && this.state.isNumberTax.length < 13 ? <span style={{ color: "red", fontSize: "10px" }}>"ต้องการตัวเลข 13 หลัก "</span> : ""}
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        maxLength="13"
                                                                        minLength="13"
                                                                        type="text"
                                                                        className="BookingStep4-conwidth-input"
                                                                        value={this.state.otherPhone}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            value = value.replace(/[^0-9]/ig, '')
                                                                            this.setState({
                                                                                otherPhone: value
                                                                            })
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="BookingStep4-maringroup">
                                                                <div className="Book4ContentA-marb-span">
                                                                    <span className="BookingStep4-span-blue">
                                                                        ใบขับขี่
                                                                </span>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="BookingStep4-conwidth-input"
                                                                        value={this.state.other_drivinglicense}
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            value = value.replace(/[^A-Za-z0-9]/ig, '')
                                                                            this.setState({
                                                                                other_drivinglicense: value
                                                                            })
                                                                        }
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <div className="Book3ContentA-head-option">
                                            {strings.booking_info.travel_infomation}
                                        </div>
                                        <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                                            <div className="row BookingStep4-rowmar-default">
                                                <div className="col-sm-12 BookingStep4-font-default">
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.flight_info}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="BookingStep4-conwidth-input"
                                                                onKeyPress={event => this.onKeyPressOnlyLetter(event)}
                                                                value={this.state.isFlightInfo}
                                                                onChange={(e) => this.setState({
                                                                    isFlightInfo: e.target.value
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                {strings.booking_info.flight_number}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="number"
                                                                className="BookingStep4-conwidth-input"
                                                                value={this.state.isFlightNumber}
                                                                onChange={(e) => this.setState({
                                                                    isFlightNumber: e.target.value
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Book3ContentA-head-option">
                                            {strings.booking_info.card_detail}
                                        </div>
                                        <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                                            <div className="row BookingStep4-rowmar-default">
                                                <div className="col-sm-12 BookingStep4-font-default">
                                                    {/* 
                                                    <div className="BookingStep4-maringroup">
                                                        <div className="Book4ContentA-marb-span">
                                                            <span className="BookingStep4-span-blue">
                                                                Bank issue
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <select
                                                                name="cre-de"
                                                                className="Book4ContentA-set-select"
                                                                defaultValue={""}
                                                                onChange={(e) => this.setState({
                                                                    isCreditOrDebit: e.target.value
                                                                })}
                                                            >
                                                                <option value="">Please Select</option>
                                                                 {bank.map(({ code, title }, index) => <option  key={index} value={code} >{title} {(code!=="Other"?"("+code+")":null)}</option>)}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    {this.state.isActiveSmartpay && (
                                                        <div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                            <div className="radio">
                                                                <label
                                                                    style={{
                                                                        right:
                                                                            "25px"
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="radio"
                                                                        id="chk_payment_channel_1_1"
                                                                        value="smartpay"
                                                                        name="chk_payment_channel"

                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            this.setState({
                                                                                paymentChannel: value
                                                                            })
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className="cr">
                                                                        <i
                                                                            className="cr-icon glyphicon glyphicon-ok"
                                                                            style={{
                                                                                top:
                                                                                    "0"
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    <span className="Book3ContentA-span-chkb">
                                                                        VISA / MASTERCARD / JCB <span className="line2_smartpay">(ผ่อนชำระ 6 เดือน ดอกเบี้ย 0%)</span>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>)}

                                                    {this.state.isActiveStandard && (<div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                        <div className="radio">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id="chk_payment_channel_1"
                                                                    value="standard"
                                                                    name="chk_payment_channel"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        this.setState({
                                                                            paymentChannel: value
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    VISA / MASTERCARD / JCB
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>)}

                                                    {this.state.isActiveUnionPay && (<div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                        <div className="radio">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id="chk_payment_channel_2"
                                                                    value="unionpay"
                                                                    name="chk_payment_channel"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        this.setState({
                                                                            paymentChannel: value
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    UNION PAY
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>)}

                                                    {this.state.isActiveAlipay && (<div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                        <div className="radio">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id="chk_payment_channel_3"
                                                                    value="alipay"
                                                                    name="chk_payment_channel"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        this.setState({
                                                                            paymentChannel: value
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    ALIPAY
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>)}
                                                    <div className="BookingStep4-maringroup" style={{ marginBottom: "0" }}>
                                                        <div className="radio">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id="chk_payment_channel_4"
                                                                    value="wechat"
                                                                    name="chk_payment_channel"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        this.setState({
                                                                            paymentChannel: value
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    WECHAT
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>


                                                    <div className="BookingStep4-maringroup" style={{ marginBottom: "35px" }}>
                                                        <div className="radio">
                                                            <label
                                                                style={{
                                                                    right:
                                                                        "25px"
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id="chk_payment_channel_5"
                                                                    value="qr"
                                                                    name="chk_payment_channel"
                                                                    onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        this.setState({
                                                                            paymentChannel: value
                                                                        })
                                                                    }
                                                                    }
                                                                />
                                                                <span className="cr">
                                                                    <i
                                                                        className="cr-icon glyphicon glyphicon-ok"
                                                                        style={{
                                                                            top:
                                                                                "0"
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="Book3ContentA-span-chkb">
                                                                    QR Code (Thai Only)
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>










                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-12 Book4ContentA-remar-btnfoot">
                                                <div className="Book4ContentA-btnfoot-content">
                                                    <Link
                                                        to={
                                                            this.state
                                                                .preFixPath +
                                                            "/bookingstep3"
                                                        }
                                                    >
                                                        <input
                                                            type="button"
                                                            value={strings.booking_step.back}
                                                            className="Book4ContentA-con-btnb"

                                                        />
                                                    </Link>
                                                    {/* <Link
                                                        to={
                                                            this.state
                                                                .preFixPath +
                                                            "/bookingstep6"
                                                        }
                                                    > */}
                                                    <input
                                                        type="submit"
                                                        value={strings.booking_step.payment}
                                                        className="Book4ContentA-con-btna"
                                                        onClick={this.handleFormSubmit}
                                                    />
                                                    {/* </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-3 BookingStep3-set-mar Book3ContentA-repad-conside">
                                    <div className="Book3ContentA-head-side-car">
                                        <span>{strings.booking_step.car_rental_summary}</span>
                                    </div>
                                    <div className="BookingStep4-setbg-info">
                                        <div className="Book3ContentA-mar-info">
                                            <span className="Book3ContentA-span-blue">
                                                {strings.car_details.size}:
                                                {" "}{this.state.book4Obj.detail.size}
                                            </span>
                                            <br />
                                            <span>
                                                {this.state.book4Obj.detail.title}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="Book3ContentA-head-side-ren">
                                        <span>{strings.booking_step.rental_information}</span>
                                    </div>
                                    <div className="BookingStep4-setbg-info">
                                        <div>
                                            <div className="Book3ContentA-mar-info">
                                                <span className="Book3ContentA-span-blue">
                                                    {strings.booking_step.pick_up}
                                                </span>
                                                <br />
                                                <span>
                                                    {this.state.isBranches["'" + this.state.outBranch + "'"]}
                                                </span>
                                                <br />
                                                <span>{this.state.outDate}{"  "}{this.state.outTime}</span>
                                            </div>
                                        </div>
                                        <div className="Book3ContentA-mar-info">
                                            <span className="Book3ContentA-span-blue">
                                                {strings.booking_step.return}
                                            </span>
                                            <br />
                                            <span>
                                                {this.state.isBranches["'" + this.state.inBranch + "'"]}
                                            </span>
                                            <br />
                                            <span>{this.state.inDate}{"  "}{this.state.inTime}</span>
                                        </div>
                                    </div>
                                    <div className="Book3ContentA-head-side-rate">
                                        <span>{strings.booking_step.price_detail}</span>
                                    </div>
                                    <div className="BookingStep4-setbg-info">
                                        <div className="Book3ContentA-mar-info Book3ContentA-span-blue">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>{strings.booking_step.car_price}</td>
                                                        <td className="Book3ContentA-td-price">
                                                            {parseFloat(this.state.book4Obj.rate.car_price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr>
                                                    {this.state.book4Obj.rate.partial ? (<tr>
                                                        <td>{strings.booking_step.partial}</td>
                                                        <td className="Book3ContentA-td-price">
                                                            {this.state.book4Obj.rate.partial}

                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr>) : null
                                                    }
                                                    <tr>
                                                        <td>
                                                            {strings.booking_step.extra_option_price}
                                                        </td>
                                                        <td className="Book3ContentA-td-price">
                                                            {(parseFloat(this.state.book4Obj.rate.extra) + parseFloat(this.state.book4Obj.rate.insurance)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}

                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td>
                                                            ค่าคืนรถต่างสาขา
                                                        </td>
                                                        <td className="Book3ContentA-td-price">
                                                            0.00
                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr> */}
                                                    {this.state.book4Obj.rate.drop_off ? (
                                                        <tr>

                                                            <td>{strings.booking_step.drop_off_fee}</td>
                                                            <td className="Book3ContentA-td-price">
                                                                {parseFloat(this.state.book4Obj.rate.drop_off).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td className="Book3ContentA-td-unit">{strings.booking_step.thb}</td>
                                                        </tr>) : null}
                                                    <tr>
                                                        <td>{strings.booking_step.vat}</td>
                                                        <td className="Book3ContentA-td-price">
                                                            {parseFloat(this.state.book4Obj.rate.vat).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Voucher</td>
                                                        <td className="Book3ContentA-td-price">
                                                            {parseFloat(this.state.isVoucher).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </td>
                                                        <td className="Book3ContentA-td-unit">
                                                            {strings.booking_step.thb}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="Book3ContentA-head-side-total">
                                            <div style={{ float: "right" }}>
                                                <span>{parseFloat(total_amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {strings.booking_step.thb}</span>
                                            </div>
                                            <div>
                                                <span>{strings.booking_step.total}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    web_settings: state.setting.booking.settings,
});
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSettings: () => { dispatch(fetchSettings()) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Book4HeaderStatus);