import React, { Component } from "react";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { Link } from "react-router-dom";
import "./components/Home/css/promotion.css";
import { isBrowser } from "react-device-detect";
// import axios from "axios";
import {
  PREFIX_ADDRESS,
  SetDefaultLang,
  SITE_URL,
  is_disable_membership,
} from "./constants";
dom.watch();

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMarb: "",
      isFixed: false,
      preFixImg: "",
      preFixPath: "",
      apiLang: `${PREFIX_ADDRESS}api/content/promotion`,
      isLang: "th",
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.handleCurPosition = this.handleCurPosition.bind(this);
    this.handleLang = this.handleLang.bind(this);
  }

  componentDidMount() {
    // const $ = window.$;
    this.getCurrentLang();
    // Check Current Address
    const local = window.location.href.replace("http://", "");
    const prefixLocal = local.split("/");
    const prefiximg = SITE_URL + "/";
    this.setState({ preFixImg: prefiximg });

    // Scroll
    window.addEventListener("scroll", this.handleScroll);

    // Add Active Class Onclick & Refresh Page
    var rehref = window.location.href.replace("http://", "");
    var current_uri = "";
    var n = rehref.split("/");
    var indexStr = "";
    var replaceStr = "";
    if (n[1].includes("?")) {
      indexStr = n[1].indexOf("?");
      replaceStr = n[1].substring(0, indexStr);
    } else if (n[1].includes("#")) {
      indexStr = n[1].indexOf("#");
      replaceStr = n[1].substring(0, indexStr);
    }
    if (replaceStr !== "") {
      n[1] = replaceStr;
    }

    if (
      prefixLocal[0] !== "127.0.0.1:3000" &&
      prefixLocal[0] !== "localhost:3000"
    ) {
      current_uri = "/" + n[1] + "/" + n[2] + "/" + n[3];
    } else {
      if (n.length > 2) {
        current_uri = "/" + n[1] + "/" + n[2];
      } else {
        current_uri = "/" + n[1];
      }
    }

    const $ = window.$;
    $(".sf-menu a[href='" + current_uri + "']")
      .parents("li")
      .addClass("active");
    $(".sf-menu > li > a,.sf-menu > li > ul > a").click(function () {
      var parent = $(this).parents("li");
      parent.siblings("li").removeClass("active");
      parent.addClass("active");
      // document.getElementById("btnlogin-menuid").classList.remove("active");
    });

    // document.getElementById("btnlogin-menuid").classList.remove("active");
    document.getElementById("lang-menuid").classList.remove("active");
  }

  getCurrentLang() {
    SetDefaultLang();
    let curLang = localStorage.getItem("lang");
    const query = new URLSearchParams(window.location.search);
    var lang = query.get("lang");
    if (lang !== null) {
      localStorage.setItem("lang", lang);
    }
    this.setState({
      isLang: curLang,
    });
  }

  handleCurPosition() {
    document.getElementById("our-service-menuid").classList.remove("active");
    document.getElementById("promotion-menuid").classList.remove("active");
    document.getElementById("branch-menuid").classList.remove("active");
    document.getElementById("ourcompany-menuid").classList.remove("active");
    document.getElementById("contact-menuid").classList.remove("active");
    // document
    //     .getElementById("btnlogin-menuid")
    //     .classList.remove("active");
  }
  componentWillUnmount() {
    // Scroll
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll() {
    // OnScroll For Sticky Header
    var element = document.getElementById("undefined-sticky-wrapper");
    if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
      this.setState({ isFixed: true });
      element.classList.add("is-sticky");
    } else {
      this.setState({ isFixed: false });
      element.classList.remove("is-sticky");
    }
  }
  showMenu() {
    const $ = window.$;
    // Show Header Menu Bar On Mobile
    $(".navigation.closed").css("right", "0px");
    $(".navigation.closed").css("display", "block");
  }
  hideMenu() {
    const $ = window.$;
    // Hide Header Menu Bar On Click Icon Close
    $(".Header-conw-mosubour").removeClass("Header-conw-mosubour-show");
    $(".Header-conw-mosubcom").removeClass("Header-conw-mosubcom-show");
    $(".Header-conw-mosubcon").removeClass("Header-conw-mosubcon-show");
    $(".navigation").css("display", "none");
  }

  showSubMenu(clasa, clasb, clasc) {
    const $ = window.$;
    // Add & Remove Header Class Onclick ที่ตัว Parent
    const scrwidth = window.innerWidth;
    if (scrwidth >= 1200) {
      // $(".Header-conw-subour.Header-conw-mosubour").css(
      //     "display",
      //     "block"
      // );
    } else {
      const targeta = ".".concat(clasa);
      const targetb = ".".concat(clasb);
      const targetc = ".".concat(clasc);
      const classtargeta = clasa.concat("-show");
      const classtargetb = clasb.concat("-show");
      const classtargetc = clasc.concat("-show");
      $("" + targeta + "").addClass("" + classtargeta + "");
      $("" + targetb + "").removeClass("" + classtargetb + "");
      $("" + targetc + "").removeClass("" + classtargetc + "");
    }
  }
  logout() {
    localStorage.removeItem("login_token");
    localStorage.removeItem("member_name");

    window.location.href = SITE_URL;
  }
  handleLang(lang) {
    localStorage.setItem("lang", lang);
    var re = new RegExp("lang=(\\w{2})");
    var urlRedirect = window.location.href.replace(re, "");
    window.location.href = urlRedirect;

    this.setState({
      isLang: lang,
    });

    // axios.get(`${this.state.apiLang}${isLang}`).then(res => {
    //     console.log(res);
    //     this.setState({
    //         isLang: lang
    //     })

    // }).catch(err => {
    //     console.log(err);
    // })
  }
  removeParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
      param,
      params_arr = [],
      queryString =
        sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
      params_arr = queryString.split("&");
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
        param = params_arr[i].split("=")[0];
        if (param === key) {
          params_arr.splice(i, 1);
        }
      }
      rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
  }

  render() {
    return (
      <>
        <div
          className="wrapper"
          style={{
            background: "fff",
          }}
        >
          <div id="undefined-sticky-wrapper" className="sticky-wrapper">
            <header
              className="header fixed"
              style={{
                width: "1519px",
                position: this.state.isFixed ? "fixed" : "unset",
                top: "0px",
              }}
            >
              <div className="header-wrapper" style={{ background: "fff" }}>
                <div className="container Header-custom">
                  <div className="logo">
                    <Link
                      to={this.state.preFixPath + "/"}
                      replace
                      onClick={this.handleCurPosition}
                    >
                      <img
                        src={
                          this.state.preFixImg + "images/logo-thairentacar.jpg"
                        }
                        alt="Rent It"
                      />
                    </Link>
                  </div>

                  <div className="logo control-phone-header">
                    <span>
                      &nbsp;&nbsp;
                      <i className="fa fa-phone" /> 1647
                    </span>
                  </div>

                  <Link
                    to="#"
                    className="menu-toggle btn ripple-effect btn-theme-transparent Header-btnrehover"
                    onClick={this.showMenu}
                  >
                    <i className="fa fa-bars" />
                  </Link>

                  <nav className="navigation closed clearfix">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <Link to="#" className="menu-toggle-close btn">
                          <i className="fa fa-times" onClick={this.hideMenu} />
                        </Link>
                        <ul className="nav sf-menu">
                          <li id="our-service-menuid" className="">
                            <Link
                              to={
                                isBrowser
                                  ? this.state.preFixPath +
                                    "/ourservice/short-term"
                                  : false
                              }
                              replace
                              className="Header-con-menumobile"
                              onClick={() => {
                                this.showSubMenu(
                                  "Header-conw-mosubour",
                                  "Header-conw-mosubcom",
                                  "Header-conw-mosubcon"
                                );
                              }}
                            >
                              {this.props.localize.our_service}
                            </Link>
                            <ul className="Header-conw-subour Header-conw-mosubour">
                              <Link
                                to={
                                  this.state.preFixPath +
                                  "/ourservice/short-term"
                                }
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {this.props.localize.short_rent}
                                    </div>
                                  </span>
                                </li>
                              </Link>

                              <Link
                                to={
                                  this.state.preFixPath +
                                  "/ourservice/long-term"
                                }
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      <div>{this.props.localize.long_rent}</div>
                                    </div>
                                  </span>
                                </li>
                              </Link>

                              <Link
                                to={
                                  this.state.preFixPath +
                                  "/ourservice/enterprise"
                                }
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {this.props.localize.enterprise_rent}
                                    </div>
                                  </span>
                                </li>
                              </Link>

                              <Link
                                to={
                                  this.state.preFixPath + "/ourservice/chaffeur"
                                }
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {this.props.localize.chauffeur_service}
                                    </div>
                                  </span>
                                </li>
                              </Link>
                            </ul>
                          </li>

                          <li id="promotion-menuid" className="">
                            <Link
                              to={this.state.preFixPath + "/promotion"}
                              replace
                              className="Header-con-menumobile"
                              onClick={() => {
                                this.showSubMenu(
                                  "Header-conw-mosubcom",
                                  "Header-conw-mosubour",
                                  "Header-conw-mosubcon"
                                );
                              }}
                            >
                              {this.props.localize.promotion}
                            </Link>

                            <ul className="Header-conw-subour Header-conw-mosubour">
                              <Link
                                to={this.state.preFixPath + "/promotion"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {this.props.localize.promotion_company}
                                    </div>
                                  </span>
                                </li>
                              </Link>

                              <Link
                                to={this.state.preFixPath + "/partner"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      <div>
                                        {this.props.localize.promotion_partner}
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                            </ul>
                          </li>

                          <li id="branch-menuid" className="">
                            <Link
                              to={this.state.preFixPath + "/journey"}
                              onClick={this.hideMenu}
                            >
                              {this.props.localize.travel_attraction}
                            </Link>
                          </li>

                          <li id="branch-menuid" className="">
                            <Link
                              to={this.state.preFixPath + "/branch"}
                              onClick={this.hideMenu}
                            >
                              {this.props.localize.branches}
                            </Link>
                          </li>

                          <li id="ourcompany-menuid">
                            <Link
                              to={
                                isBrowser
                                  ? this.state.preFixPath + "/about"
                                  : false
                              }
                              className="Header-con-menumobile"
                              onClick={() => {
                                this.showSubMenu(
                                  "Header-conw-mosubcom",
                                  "Header-conw-mosubour",
                                  "Header-conw-mosubcon"
                                );
                              }}
                            >
                              {this.props.localize.our_company}
                            </Link>
                            <ul className="Header-conw-subour Header-conw-mosubcom">
                              <Link
                                to={this.state.preFixPath + "/about"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {/* <div>
                                                                            เกี่ยวกับเรา
                                                                        </div> */}
                                      <div>{this.props.localize.about_us}</div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                              <Link
                                to={this.state.preFixPath + "/review"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {/* <div>
                                                                            ข่าวสาร
                                                                        </div> */}
                                      <div>
                                        {this.props.localize.news_activities}
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                            </ul>
                          </li>

                          <li id="contact-menuid">
                            <Link
                              to={
                                isBrowser
                                  ? this.state.preFixPath + "/faq"
                                  : false
                              }
                              className="Header-con-menumobile"
                              onClick={() => {
                                this.showSubMenu(
                                  "Header-conw-mosubcon",
                                  "Header-conw-mosubour",
                                  "Header-conw-mosubcom"
                                );
                              }}
                            >
                              {this.props.localize.contact_us}
                            </Link>
                            <ul className="Header-conw-subour Header-conw-mosubcon">
                              <Link
                                to={this.state.preFixPath + "/faq"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {/* <div>
                                                                            คำถามที่พบบ่อย
                                                                        </div> */}
                                      <div>{this.props.localize.faq}</div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                              <Link
                                to={this.state.preFixPath + "/career"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {/* <div>
                                                                            ร่มงานกับเรา
                                                                        </div> */}
                                      <div>{this.props.localize.career}</div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                              <Link
                                to={this.state.preFixPath + "/contact"}
                                onClick={this.hideMenu}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      {/* <div>
                                                                            ติดต่อเรา
                                                                        </div> */}
                                      <div>
                                        {this.props.localize.contact_us}
                                      </div>
                                    </div>
                                  </span>
                                </li>
                              </Link>
                            </ul>
                          </li>
                          {/* {!is_disable_membership && (
                            <li
                              className="Header-setpadb-btnlog profile-nav"
                              id="btnlogin-menuid"
                            >
                              {" "}
                              {
                                <Link
                                  style={{ padding: 0 }}
                                  to={
                                    localStorage.member_name
                                      ? this.state.preFixPath + "/profile"
                                      : this.state.preFixPath + "/login"
                                  }
                                  replace
                                  onClick={this.hideMenu}
                                >
                                  <button
                                    className="btn btn-primary control-btn-login"
                                    id="myInput1"
                                    // data-toggle="modal"
                                    // data-target="#loginmodal"
                                  >
                                    {!localStorage.member_name ? (
                                      <i className="fa fa-lock fa-lg" />
                                    ) : (
                                      <i className="fa fa-user fa-lg" />
                                    )}
                                    &nbsp;&nbsp;
                                    {!localStorage.member_name
                                      ? this.props.localize.login
                                      : localStorage.member_name}
                                  </button>
                                </Link>
                              }
                              {localStorage.member_name && (
                                <ul className="Header-conw-subour Header-conw-mosubloged">
                                  <Link
                                    to={this.state.preFixPath + "/profile"}
                                    onClick={this.hideMenu}
                                    replace
                                  >
                                    <li>
                                      <span>
                                        <div className="Header-borderbothover">
                                          <div>Edit Profile</div>
                                        </div>
                                      </span>
                                    </li>
                                  </Link>
                                  <Link
                                    to={
                                      this.state.preFixPath + "bookinghistory"
                                    }
                                    onClick={this.hideMenu}
                                  >
                                    <li>
                                      <span>
                                        <div className="Header-borderbothover">
                                          <div>Booking History</div>
                                        </div>
                                      </span>
                                    </li>
                                  </Link>

                                  <a onClick={this.logout}>
                                    <li>
                                      <span>
                                        <div className="Header-borderbothover">
                                          <div>Logout</div>
                                        </div>
                                      </span>
                                    </li>
                                  </a>
                                </ul>
                              )}
                            </li>
                          )} */}

                          <li id="lang-menuid">
                            {/* <ul className="social-icons"> */}
                            <button
                              className={`Header-conwpad-social Head-relink-btn ${
                                this.state.isLang === "th"
                                  ? ""
                                  : "lang-inactive"
                              }`}
                              onClick={() => this.handleLang("th")}
                            >
                              <img
                                alt="logo"
                                src={
                                  this.state.preFixImg + "images/logo-th@2x.png"
                                }
                                width="25px"
                              />
                            </button>

                            <button
                              className={`Header-conwpad-social Head-relink-btn ${
                                this.state.isLang === "en"
                                  ? ""
                                  : "lang-inactive"
                              }`}
                              onClick={() => this.handleLang("en")}
                            >
                              <img
                                alt="logo"
                                src={
                                  this.state.preFixImg + "images/logo-en@2x.png"
                                }
                                width="25px"
                              />
                            </button>

                            <button
                              className={`Header-conwpad-social Head-relink-btn ${
                                this.state.isLang === "cn"
                                  ? ""
                                  : "lang-inactive"
                              }`}
                              onClick={() => this.handleLang("cn")}
                            >
                              <img
                                alt="logo"
                                src={
                                  this.state.preFixImg +
                                  "images/logo-chi@2x.png"
                                }
                                width="25px"
                              />
                            </button>

                            {/* <ul className="Header-conw-subour Header-conw-mosublagu">
                              <button
                                className="Head-relink-btn"
                                onClick={() => this.handleLang("th")}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      <img
                                        alt="logo-th"
                                        src={
                                          this.state.preFixImg +
                                          "images/logo-th@2x.png"
                                        }
                                        width="25px"
                                      />
                                    </div>
                                  </span>
                                </li>
                              </button>
                              <button
                                className="Head-relink-btn"
                                onClick={() => this.handleLang("en")}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      <img
                                        alt="logo-en"
                                        src={
                                          this.state.preFixImg +
                                          "images/logo-en@2x.png"
                                        }
                                        width="25px"
                                      />
                                    </div>
                                  </span>
                                </li>
                              </button>
                              <button
                                className="Head-relink-btn"
                                onClick={() => this.handleLang("cn")}
                              >
                                <li>
                                  <span>
                                    <div className="Header-borderbothover">
                                      <img
                                        alt="logo-chinese"
                                        src={
                                          this.state.preFixImg +
                                          "images/logo-chi@2x.png"
                                        }
                                        width="25px"
                                      />
                                    </div>
                                  </span>
                                </li>
                              </button>
                            </ul> */}
                            {/* </ul> */}
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="swiper-scrollbar" />
                  </nav>
                </div>
              </div>
            </header>
          </div>
        </div>

        <style>{`
            .Header-custom {
                width: 80vw;
            }

            .lang-inactive {
                filter: grayscale(100%);
            }

            @media (max-width: 767px) {
                .Header-custom {
                    width: 100% !important;
                }
            }
        `}</style>
      </>
    );
  }
}
