// Library Components
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";

//  View Components
import Preloader from "./Preloader";
import Header from "./Header";
import Footer from "./Footer";
import RoutePath from "./RoutePath";
import {strings} from "./language/language";
import * as ReactGA from "react-ga";
// import Test from "./Test";
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    
    
  }
  render() {
    return (
      <Router>
        <Preloader />
        {/* <Test /> */}
        <Header localize={strings.menu}/>
        <RoutePath />
        <Footer localize={strings.menu}/>
      </Router>
    );
  }
}
