import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import FacebookLogin from 'react-facebook-login';

import "./css/Login.css";
import Filter from "../../Filter";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";

const responseFacebook = (response) => {
    console.log(response);
  }

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            preFixImg: SITE_URL + "/",
            email:"",
            password:"",
        };
        // this.loginHandle = this.loginHandle.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }



    componentClicked(e) {

    }

 
    render() {


        return (
            <div style={{ background: "#fff" }}>
                <Filter />
                <div className="wrapper">
                    <div className="content-area">
                        <div className="container">



                        <FacebookLogin
    appId="141259823231378"
    autoLoad={true}
    fields="name,email,picture"
    onClick={this.componentClicked}
    callback={responseFacebook} />,



                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
