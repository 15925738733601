export const cn = {
  menu: {
    home: "主页",
    our_service: "我们服务",
    fleet_service: "车型组",
    short_rent: "短租車服務",
    long_rent: "長租車服務",
    enterprise_rent: "企業租車服務",
    chauffeur_service: "專職司機服務",
    coporate_solution: "企业租车方案",
    promotion: "促销",
    promotion_company: "Thai Rent A Car",
    promotion_partner: "夥伴",
    travel_attraction: "旅遊景點",
    branches: "部门",
    our_company: "我们公司",
    about_us: "关于我们",
    news_activities: "新闻与活动",
    partnership: "合作伙伴",
    contact_us: "联系我们",
    faq: "常见问题",
    career: "加入我们",
    login: "登录",
    consent_cookie: `Please note that we use cookie for enabling our Website or Application to work properly and/or provide any services as per your preferences. We may use cookie based upon non-consent basis or consent basis. Kindly verify our cookie policy for our Website through the following`,
    link: "link",
    consent_accept: "Accept",
    consent_decline: "Decline",
    follow_us: "FOLLOW US ON",
  },
  service_box: {
    daily: "Short-term Car Rental",
    monthly: "Long-term Car Rental",
    short_term: "Short-term Car Rental",
    short_term_desc: "Daily Rental / Weekly Rental / Monthly Rental",
    long_term: "Long-term Car Rental",
    long_term_desc: "Yearly Rental Up to 5 Years",
    corporate: "Corporate Car Rental",
    corporate_desc: "Car Rental for Corporate",
    chauffeur: "Chauffeur Drive Services",
    chauffeur_desc: "Car Rental with Driver",
  },
  service: {
    offer_title: "What We Offer",
    offer_subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    offer_1_title: "Best Price Guarantee",
    offer_1_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_2_title: "Best Quality Cars",
    offer_2_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_3_title: "Customer Support",
    offer_3_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_1_title_corporate:
      "Maintenance fees and 1st class insurance fees Included <br /> Replacement cars ready in service",
    offer_2_title_corporate:
      "Annual car tax and car registration service Included",
    offer_3_title_corporate:
      "Customer Service <br /> standby 24 hours - 7 days to serves all cases.",
    "long-term": "Long-term Car Rental",
    enterprise: "Corporate Car Rental",
    chaffeur: "Chauffeur Drive Services",
    short_term_title: "Short Term Rental",
    short_term_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    long_term_title: "Long Term Rental",
    long_term_description:
      "Long-term (Self drive) car rental services (Yearly) International standards from Thai Rent a Car. Guaranteed car quality and service by a professional team. Search for rental cars for traveling to use or rental cars during repairs, Thai Rent A Car, complete rental car throughout Thailand.",
    enterprise_title: "Corporate Car Rental",
    enterprise_description:
      "Thai Rent A Car Corporation Co., Ltd., the leader of corporate rented car in Thailand. We are able to supply all types of cars according to your corporate needs and usage with fixed rental rate throughout rental contract. Available for both self-drive car rentals and car with chauffuer which your corporate can choose from the appropriate usage. Monthly rental rate includes annual car tax, car registration service, 1st class insurance, maintenance fees and a replacement car ready in service within 24/7 (for your cost effective management)",
    chaffeur_title: "Chauffeur Drive Services",
    chaffeur_description:
      "Car rental service with driver (Monthly and Annual contracts) from Thai Rent A Car. Providing you with comfort throughtout your journey Thai Rent A Car Corporation Company Limited, 45 years of being the leader in the car rental market in Thailand.",
    why_thai_rent: "Why Thai Rent A Car ?",
    book_now: "book now",
    book_now_chaffeur: "book now",
    book_contact: "please contact call: 1647 / 02-318-8888",
    contact_us: "Contact Us",
  },
  home: {
    service: "Products & Services",
    promotion: "PROMOTION",
    partner: { desktop: "BENEFITS FROM OUR PARTNERS", mobile: "PARTNERS" },
    journey: { desktop: "ENJOY YOUR JOURNEY WITH US", mobile: "OUR JOURNEY" },
    journey_sub_title: "Discover Thailand with Thai Rent A Car Cars Rental",
    testimonials: "CUSTOMER REVIEWS",
    testimonials_sub_title: "CLIENT's REVIEWS",
    testimonials_description:
      "What do our customer say Join our Thai Rent A Car Community  , Tag us @thrairentacarclub on Instagram",
    see_more: "SEE MORE",
  },
  footer: {
    name: "姓名（拼音或英文）",
    email: "邮箱地址",
    subject: "题目",
    message: "输入文字",
    send: "发",
  },
  about_us: {
    about_us: "关于我们",
  },
  contact: {
    contact: "联系我们",
    thairent: "泰国租车公司",
    name: "姓名（拼音或英文）",
    email: "邮箱地址",
    message: "",
    number: "电话",
    send: "发",
    address: "地方",
    phone: "电话",
    facebook: "Facebook",
  },
  general: {
    more: "别的",
  },
  book_now: {
    book_now: "搜索",
    pick_up_location: "取车地方",
    please_select: "请选择",
    return_location: "还车地方",
    same_location_as_pick_up: "取还车",
    promotion_code: "促销代码",
    please_input_code: "请输入代码",
    search_car: "搜索",
    pick_up_date: "取车号",
    time: "时间",
    return_date: "还车号",
    refresh: "时间",
  },
  booking_step: {
    step_1: "第一步",
    step_2: "第二步",
    step_3: "第三步",
    step_4: "第四步",
    step: "步",
    pay_now: "Pay Now",
    pay_later: "Pay Later",
    per_day: "租车对一天",
    total: "总计",
    for: "对",
    days: "天。",
    choose_your_extra_option: "展开",
    thb_per_day: "THB / 天",
    extra_charge: "天",
    back: "上页",
    next: "下",
    car_rental_summary: "总结",
    rental_information: "租赁信息",
    pick_up: "取车",
    return: "还车",
    price_detail: "租赁信息",
    partial: "部分付款",
    car_price: "租车费用",
    extra_option_price: "设备租赁费",
    vat: "百分之七税",
    thb: "泰铢",
    payment: "在线支付",
    extra_charge_detail: "额外收费明细",
    modal_extra_charge:
      "Peak Period Charge + 500 bath/day 1-2 Jan 2022 /9-17 Apr 2022 / 3-11 Dec 2022 /24-31 Dec 2022 / 1-2 Jan /2023" +
      "Weekend Charge Please see the promotion terms of use",
    modal_extra_option: `SLDW: Super Loss Damage Waiver is the partial coverage which not includes the following damages eg. interior damages, tires, windshield, accessory, car key or important lost. 	 
PLDW: Premium Loss Damage Waive is the partial coverage includes tires and windshield but not includes interior damage accessory, car key or important document lost. 
`,
    alert_voucher: "Please present Cash Voucher on the pick up day",
    drop_off_fee: "Drop Off Fee",
    print_receipt: "Print Receipt",
    not_found_car_list: `<h3>Sorry, your booking during the selected period cannot be completed online. Please contact Thai Rent A Car Contact Center 1647 to continue your booking. Thank you for travelling with us.</h3>
<p>Note: Please check the information below in order to complete your booking online</p>
<ul>
<li>The spelling of promotion code must be correct</li>
<li>The promotion code must be valid (Not expired)</li>
<li>The driver must be over 21 years old and must have a driving license</li></ul>`,
  },
  booking_info: {
    booking_history: "预订历史",
    your_infomation: "你的资料",
    first_name: "名（拼音或英文）",
    middle_name: "",
    last_name: "姓（拼音或英文）",
    email: "邮箱地址",
    phone: "电话号码",
    verify: "Verify",
    tax_invoice: "税务发票",
    travel_infomation: "您订单号",
    credit_card_remark:
      "Acceptable of payment only Credit Cards for pre-authorization the deposit from the credit card.",
    flight_info: "航飞",
    flight_number: "航飞号",
    driving_license: "驾照",
    card_detail: "信用卡",
    card_issuer: "信用卡/借记卡发卡机构",
    card_type: "卡种",
    english_only: "Please fill information with English Language only",
    paylater_info: `<h3>PAY Later</h3>
<ul><li>Book and pay online the advance rental fee 500 THB for all models by credit card (No fee charged)</li>
<li>Customer can change the rental details 1 time within 30 days after the booking confirmation date</li>
<li>In case of changing rental details, customer will pay the additional cost regarding to the new rental period (the rental fee might be changed upon the period)</li>
<li>Thai Rent A Car reserves not to pay the refund of partial payment 500 THB in every case</li>
<li>Customer cannot make a double rental in the same period ( 1 customer name per 1 car rental in the same period).</li></ul>`,
    paynow_info: `<h3>PAY Now</h3>
<ul><li>Best deal guarantee</li>
<li>Book and pay online by credit card (No fee charged)</li>
<li>Customers can’t change rental details after booked</li>
<li>Thai Rent A Car reserves not to pay the refund in every case</li>
<li>Customer cannot make a double rental in the same period ( 1 customer name per 1 car rental in the same period).</li></ul>`,
  },
  car_details: {
    size: "车大小",
    type: "车型",
    engine: "发动机",
    kms_free: "路的公斤",
    insurances: "基本险(SLD)安心全险(PLD)",
    more_details: "展开",
    seat: "座椅",
    door: "门",
    air_condition: "空调",
    transmission: "自动挡",
    luggage: "行李箱",
    radio_with_cd: "电台和播放器",
    extra_option: "额外的选择",
  },
  voucher: {
    voucher: "券",
    fill: "券号",
    value: "值",
    check: "检查",
    delete: "删除",
    remark: "备注",
  },
  term: {
    term: "条款和条件",
    accept_information: "同意",
    more_information: "展开",
    alert: '请阅读并检查“我在继续之前接受所有信息"',
    popup_title: "我们条件",
    qualification: "年龄要求：21-80岁",
    document_title: "文件",
    document_desc:
      "1. 主泰国身分证原件或有效护照原件.( 租车人须年满21岁 )\n2.  有效泰国驾照或国际驾照驾照年限：六月以上\n3.  信用卡 信用卡年限：六月以上  需要信用卡姓名同样主泰国身分证原件或有效护照原件姓名  租车费用以上没保油，设备租赁费与别的费用    押金（预授权）对于SS，ES，S，M车型，汽车租赁必须通过信用卡支付押金。 10,000泰铢（一万泰铢）   对于L, XL, Mercedes-Benz 车型，汽车租赁必须通过信用卡支付押金。 20,000泰铢（两万泰铢）归还车后归还押金时。大约7-14天工作日(取决于每个金融机构的财务流程) 公司保留不接受借记卡押金的权利, 美国运通信用卡。以及未经金融机构批准的信用卡     如果汽车承租人没有购买损害保险的第一部分，则SLDW或PLDW发生事故并且由于租客是错误的一方或没有一方的事故\n\n对于SS, ES, S, M, L, XL尺寸的汽车，客户必须承担5,000泰铢的首次损坏",
    pre_authorize_title: "文件",
    pre_authorize_desc:
      "1. 主泰国身分证原件或有效护照原件.( 租车人须年满21岁 ) 2. 有效泰国驾照或国际驾照驾照年限：六月以上 3. 信用卡 信用卡年限：六月以上 需要信用卡姓名同样主泰国身分证原件或有效护照原件姓名 租车费用以上没保油，设备租赁费与别的费用 押金（预授权）对于S，M车型，汽车租赁必须通过信用卡支付押金 5,000泰铢。  对于L车型 Mercedes-Benz 车型，汽车租赁必须通过信用卡支付押金 25,000泰铢 归还车后归还押金时。大约7-14天工作日(取决于每个金融机构的财务流程) 公司保留不接受借记卡押金的权利, 美国运通信用卡。以及未经金融机构批准的信用卡 如果汽车承租人没有购买损害保险的第一部分，则SLDW或PLDW发生事故并且由于租客是错误的一方或没有一方的事故 对于S, M, L 尺寸的汽车，客户必须承担5,000泰铢的首次损坏",
  },
  journey: {
    banner_title: "Enjoy your journey with us",
    category: "หมวดหมู่",
    recent_posts: "บทความล่าสุด",
  },
  promotion: {
    promotion: "促销",
    related: "相關促銷",
    read_more: "阅读更多",
  },
  review: {
    review: "评论和活动",
    review_gallery: "评论和活动照片",
  },
  career: {
    career: "事业",
    branch: "部门",
    saraly: "工资",
    qualification: "資質",
    apply_message: "欲獲得更多信息",
  },
  partner: {
    partner_thairent: "Partners Thai Rent a Car",
    worldwide: "<p>合作伙伴</p> <p>Worldwide car rental</p>",
    worldwide_button: "阅读更多",
  },
  log_in: {
    log_in: "登录",
    email: "邮箱地址",
    password: "邮箱密码",
    forgot: "忘记密码了吗？",
    sign_in: "登入",
    or: "与",
    log_in_facebook: "用Facebook登录",
    log_in_line: "用 LINE 登录",
    dont_have_account: "没有帐户？寄存器",
    register: "寄存器",
    first_name: "名（拼音或英文）",
    last_name: "姓（拼音或英文）",
    forgot_message: "邮箱地址",
    reset_password: "邮箱密码",
    required: "请先填写所有必填字段，再继续。",
  },
  faq: {
    faq: "FAQ",
    where_are: "我们部门",
    central: "中部",
    north: "北部",
    south: "南部",
    east: "东部",
    north_east: "北东部",
    view_map: "地图",
  },
  profile: {
    profile: "简历",
    user_profile: "User Profile",
    first_name: "名（拼音或英文）",
    last_name: "姓（拼音或英文）",
    middle_name: "Middle name",
    email: "邮箱地址",
    address: "地址",
    postcode: "邮编",
    phone: "电话号码",
    nationality: "国籍",
    country: "country",
    driving_license: "驾照",
    dob: "生日",
    upload: "Upload Driving license",
    id_card: "身分证原件",
    lang_message: "**Input English Only",
    change_password: "CHANGE PASSWORD",
    current: "Current",
    new: "New",
    retype_new: "Retype New",
    forgot: "Forgot your password?",
    save_change: "SAVE CHANGES",
    back: "首页",
    facebook_placeholder: "Please fill in your facebook user name",
    line_placeholder: "Please fill in your Line ID",
    require_field: "请先填写所有必填字段，再继续。",
  },
  error: {
    error_invalid_promotion_code: "โปรโมชั่นโค้ดไม่ถูกต้องกรุณาตรวจสอบอีกครั้ง",
    validate_before_payment: "付款前请检查您的电子邮件。",
    "required field need to be filled":
      "您需要填寫完整的信息。在可以使用此促銷之前",
    error_start_time_period: "请先填写所有必填字段，再继续。",
    "PO not found": "无效代码的PO号。",
    "limit usage": "抱歉，现在订空间已满。",
  },
  modal: {
    fill: "編輯我的個人資料",
    nofill: "沒有",
  },
  ourservice_table: {
    type_of_insurance: "Type of Insurance (Option)",
    ldw: "Basic Package",
    sldw: "Zero Deductible",
    pldw: "Fully Cover ",
    short_term: {
      1: "Waives the first cost of 5,000 baht from Car accident / damage (Exclude damage from Windows and tires)",
      2: "Waives the cost of 5,000 baht from Windows damage and cost of damaged tires (the cost is according to the price of the tire model)",
      3: "Price charge per day *Baht (Exclude Vat)",
      4: "Price charge per month *Baht (Exclude Vat)",
    },
    long_term: {
      1: "Waives the first cost of 5,000 baht from Car accident / damage (Exclude damage from Windows and tires)",
      2: "Waives the cost of 5,000 baht from Windows damage and cost of damaged tires (the cost is according to the price of the tire model)",
      3: "Price charge per month *Baht (Exclude Vat)",
    },
  },
};
