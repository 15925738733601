import React, { Component } from "react";
import { strings } from "../../language/language";
import moment from "moment";
import { PREFIX_ADDRESS, SITERL } from "../../constants";
export default class BookHisContentAsubA extends Component {
    constructor(props) {
        super(props);
        var local = window.location.href.replace("http://", "");
        if (local.includes("#")) {
            const indexHash = local.indexOf("#");
            local = local.substring(0, indexHash);
        }
        const prefixLocal = local.split("/");
        const prefiximg = "http://" + prefixLocal[0] + "/";
        // this.setState({ preFixImg: prefiximg,detail:this.props.detail,insurance:this.props.detail.insurance[0]?this.props.detail.insurance[0].code:""});
        this.state = {
            preFixPath: "",
            preFixImg: prefiximg,
            detail: this.props.detail,
            insurance: this.props.detail.insurance[0] ? this.props.detail.insurance[0].code : "",
        }
    };
    renderExtra(extra) {
        let title = "- " + extra.code;
        if (extra.code.toLowerCase() === "bs") {
            title = "- Baby Seat x " + extra.qty;
        }

        return title;
    }
    componentDidMount() {





        // let reoutdate = moment(this.props.detail.rental_out_datetime).format("DD/MM/YYYY HH:mm");


        // Object.keys(this.props.detail.extra).map((key, i) => {
        //     console.log(this.props.detail.extra[key])
        // })


    }
    render() {
        return (
            <div className="row BookHisContentA-buildbg-info">
                <div className="col-md-3">
                    <div>
                        <img
                            className="BookHisContentA-conw-imgsidel"
                            src={PREFIX_ADDRESS + 'images/content_img/' + this.props.detail.car_group.picture_cover}
                            alt="Car"
                        />

                    </div>
                </div>
                <div className="col-md-7">
                    <div className="row BookHisContentA-addpadinfo-fmo">



                        <div className="col-md-12 BookHisContentA-repad-booking">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="BookHisContentA-confont-ra BookHisContentA-confont-typec">
                                        <span>{this.props.detail.car_group.title} </span>
                                    </div>
                                </div>

                                {this.props.detail.is_book_for_other === "1" && (<div className="row" style={{ marginBottom: 10 }}>
                                    <div className="col-xs-6">
                                        <span className="btn btn-primary btn-blue">จองให้บุคคลอื่น</span>
                                    </div>
                                </div>)}

                            </div>



                        </div>


                        <div className="col-md-6 BookHisContentA-repad-booking mt0">
                            <div className="BookHisContentA-confont-ra BookHisContentA-confont-pick">
                                <span>{strings.book_now.pick_up_location} : {this.props.branch["'" + this.state.detail.rental_out_branch + "'"]}</span>
                            </div>
                            <div className="BookHisContentA-confont-ra BookHisContentA-confont-pick">
                                <span>วันเวลารับรถ : {moment(this.state.detail.rental_out_datetime).format("DD/MM/YYYY HH:mm")}</span>
                            </div>

                            <div className="BookHisContentA-confont-ra BookHisContentA-confont-re">
                                <span>{strings.book_now.return_location} : {this.props.branch["'" + this.state.detail.rental_in_branch + "'"]}</span>
                            </div>
                            <div className="BookHisContentA-confont-ra BookHisContentA-confont-pick">
                                <span>วันเวลาคืนรถ : {moment(this.state.detail.rental_in_datetime).format("DD/MM/YYYY HH:mm")}</span>
                            </div>


                        </div>
                        <div className="col-md-6 BookHisContentA-repad-booking">




                            <div className="row">
                                <div className="col-xs-6 mt0">
                                    ชื่อ-นามสกุล
                                </div>
                                <div className="col-xs-6 mt0">
                                    {this.props.detail.is_book_for_other === "1" ?
                                        this.state.detail.other_firstname + " " + this.state.detail.other_lastname :
                                        this.state.detail.personal_firstname + " " + this.state.detail.personal_lastname

                                    }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 mt0">
                                    Reservation No.
                                </div>
                                <div className="col-xs-6 mt0">
                                    {this.state.detail.carpro_booking_number}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 mt0">
                                    ใบเสร็จเลขที่ No.
                                </div>
                                <div className="col-xs-6 mt0">
                                    {this.state.detail.carpro_receipt_number}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-6 mt0">
                                    Web Ref.
                                </div>
                                <div className="col-xs-6 mt0">
                                    {this.state.detail.booking_number}
                                </div>
                            </div>



                        </div>
                    </div>
                    {((this.state.insurance !== "" || this.props.detail.extra[0]) &&
                        <div className="row BookHisContentA-addpadinfo-fmo BookHisContentA-addmart-extra">
                            <div className="col-md-12 BookHisContentA-remart-ex BookHisContentA-repad-booking">
                                <div>
                                    <span className="BookHisContentA-confont-extitle">
                                        {strings.car_details.extra_option}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {
                                    Object.keys(this.props.detail.extra).map((key, i) => this.renderExtra(this.props.detail.extra[key]))
                                }
                                {this.state.insurance !== "" && (<div>- Insurance : {this.state.insurance}</div>)}


                            </div>
                        </div>)}



                </div>
                <div className="col-md-2 BookHisContentA-setmoprice-footer">
                    <div className="BookHisContentA-confont-sider">

                        <div>
                            <span className="BookHisContentA-confont-rowc">
                                {parseFloat(this.props.detail.rate_total).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {strings.booking_step.thb}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
