import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/Register.css";
import Filter from "../../Filter";
import axios from "axios";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";
let token;

export default class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(this.props.location.search);
    this.state = {
      preFixPath: "",
      preFixImg: SITE_URL + "/",
      formReset: {
        email: query.get("email"),
        password: "",
        confirmPassword: "",
      }
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);
    token = query.get("token");
    if(!token)
    {
        alert("error no token");
    }

  }
  registerHandle = () => {
    var bodyFormData = new FormData();
    if(this.state.formReset.password !== this.state.formReset.confirmPassword)
    {
        alert("รหัสผ่านไม่ถูกต้อง");
        return;
    }
    bodyFormData.set('email', this.state.formReset.email);
    bodyFormData.set('password', this.state.formReset.password);
    bodyFormData.set('password_confirmation', this.state.formReset.confirmPassword);
    bodyFormData.set('token', token);
    
      
    
    axios.post(`${PREFIX_ADDRESS}api/member/resetPassword`,bodyFormData,{
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type':'multipart/form-data',
      }}).then(res => {
      const { data } = res;
      console.log(data);
      if(data.status==="success"){
        this.props.history.push({pathname:"/login",state: {resetpassword:1}});
      }
    }).then(() => {
      setTimeout(() => {
      }, 100);
    }).catch(function (err) {
        console.log(err.response.data);
        alert(err.response.data.message);
        
    });

  }

  render() {


    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <div className="container">



              <div className="row">
                <div className="col-md-6 col-md-push-3" style={{ margin: "80px 0" }}>
                  <div className="registerHeadline"><h1>Change Password</h1></div>

                  <div className="LoginModal-set-container Regis-reset-contianer">
                    <form>
                      <div>
                      
                        <div className="input-group LoginModal-con-inpute">
                          <span
                            className="input-group-addon LoginModal-logo-email"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logomail-w"
                              src="images/LoginModal/logo-input-mail@2x.png"
                              alt="Login"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control LoginModal-con-border"
                            placeholder={strings.log_in.email}
                            value={this.state.formReset.email}
                            aria-describedby="basic-addon1"
                            onChange={(e) => {this.setState({ formReset: { ...this.state.formReset, email: e.target.value} });}}
                          />
                        </div>
                        <div className="input-group LoginModal-con-inputp">
                          <span
                            className="input-group-addon LoginModal-logo-pass"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logopass-w"
                              src="images/LoginModal/logo-input-pass@2x.png"
                              alt="Pass"
                            />
                          </span>
                          <input
                            type="password"
                            className="form-control LoginModal-con-border"
                            placeholder={strings.log_in.password}
                            aria-describedby="basic-addon1"
                            onChange={(e) => {this.setState({ formReset: { ...this.state.formReset, password: e.target.value} });}}
                          />
                        </div>

                        <div className="input-group LoginModal-con-inputp">
                          <span
                            className="input-group-addon LoginModal-logo-pass"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logopass-w"
                              src="images/LoginModal/logo-input-pass@2x.png"
                              alt="Pass"
                            />
                          </span>
                          <input
                            type="password"
                            className="form-control LoginModal-con-border"
                            placeholder={'Confirm '+strings.log_in.password}
                            aria-describedby="basic-addon1"
                            onChange={(e) => {this.setState({ formReset: { ...this.state.formReset, confirmPassword: e.target.value} });}}
                          />
                        </div>
                        
                        <div className="RegisModal-setmar-signin">
                          <button
                            // aria-label="Close"
                            type="button"
                            className="btn btn-danger LoginModal-con-signin"
                            data-dismiss="modal"
                            onClick={this.registerHandle}
                          >
                            <span
                              style={{
                                color: "#fff"
                              }}
                            >
                              Reset
                            </span>
                          </button>
                        </div>
                 
                     
                      </div>
                    </form>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div >
    );
  }
}
