import React, { Component } from "react";
import Filter from "../../Filter";
import axios from "axios";
import parse from "html-react-parser";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const images = [];

export default class ReviewDeContentA extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      Loading: false,
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      photoIndex: 0,
      isOpen: false,
      path: "",
      reviewDeApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/review/" +
        this.props.match.params.id,
      reviewDeObj: {
        picture_cover: "",
        title: "",
        abstact: "",
      },
      reviewDeRelatedApi:
        PREFIX_CONTENT_ADDRESS +
        "api/gallery/review/" +
        this.props.match.params.id,
      reviewDeRelatedObj: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const $ = window.$;
    this._isMounted = true;
    $("#preloader").show();

    this.fetchReviewDetails();
    this.fetchReviewDeRelated();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  fetchReviewDetails() {
    const $ = window.$;
    const query = new URLSearchParams(this.props.location.search);
    var lang = query.get("lang");

    // ถ้ามี  get url
    if (lang !== null) {
      localStorage.setItem("lang", lang);
    }
    // ถ้าใน local มีค่า
    if (localStorage.getItem("lang") !== null) {
      lang = localStorage.getItem("lang");
    }

    // this.props.history.push({
    //   pathname: "/review/" + this.props.match.params.id,
    //   search: "?lang=" + lang
    // });
    var reviewDeApi = this.state.reviewDeApi + "?lang=" + lang;

    // this.setState({
    //   Loading: true
    // });
    const apiurl = reviewDeApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);
        if (data.detail !== null) {
          data.detail = data.detail.replace("/\r/\n", "");
        }
        // console.log(data.detail);

        this.setState({
          reviewDeObj: data,
          // Loading: false
        });
      })
      .then(() => {
        $("#preloader").hide();
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchReviewDeRelated() {
    const $ = window.$;
    this.setState({
      Loading: true,
    });
    const apiurl = this.state.reviewDeRelatedApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);

        this.setState({
          reviewDeRelatedObj: data,
          Loading: false,
        });
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  RenderRelated() {
    if (this.state.reviewDeRelatedObj instanceof Array) {
      return this.state.reviewDeRelatedObj.map((item, i) => {
        images.push(item.pic);
        return (
          <div key={i} className="col-sm-3 set-pad-rerelated b1 img-mobile">
            <img
              style={{ cursor: "pointer" }}
              src={item.pic}
              width="100%"
              alt="Review"
              onClick={() => this.setState({ isOpen: true, photoIndex: i })}
            />
          </div>
        );
      });
    }
  }
  render() {
    const { photoIndex, isOpen } = this.state;
    return (
      <div>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-a">
              <div className="container-fluid Review-container-fluid">
                <div
                  className="row"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <div
                    className="col-sm-8 shadow"
                    style={{ overflow: "hidden", height: "auto" }}
                  >
                    {this.state.reviewDeObj.picture_content === "" ||
                    this.state.reviewDeObj.picture_content === null ? (
                      "ไม่พอข้อมูลรูปภาพ"
                    ) : (
                      <img
                        src={this.state.reviewDeObj.picture_content}
                        width="100%"
                        alt="Review"
                      />
                    )}
                  </div>

                  <div className="col-sm-4 set-pad">
                    <div className="info">
                      <div className="info-header">
                        <h3 style={{ color: "#fff" }} className="set-pad-info">
                          <strong>{strings.review.review}</strong>
                        </h3>
                      </div>
                      <div className="content set-pad-info">
                        <div className="info-content-a">
                          <h4>
                            <strong>
                              {this.state.reviewDeObj.title === "" ||
                              this.state.reviewDeObj.title === null
                                ? ""
                                : this.state.reviewDeObj.title}
                            </strong>
                          </h4>
                          <div className="control-p">
                            <h5 style={{ fontFamily: "Prompt" }}>
                              {this.state.reviewDeObj.abstact === "" ||
                              this.state.reviewDeObj.abstact === null
                                ? ""
                                : this.state.reviewDeObj.abstact}
                            </h5>
                          </div>
                          <div className="control-p">
                            {/* <p> */}
                            {this.state.reviewDeObj.detail === "" ||
                            this.state.reviewDeObj.detail === null ||
                            this.state.reviewDeObj.detail === undefined
                              ? ""
                              : parse(this.state.reviewDeObj.detail)}
                            {/* </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-b">
              <div className="container-fluid Review-container-fluid">
                <div className="row related-hr">
                  <div className="col-sm-4 hr-left hr-left-rede" />
                  <div
                    className="col-sm-4 related-title related-title-rede"
                    style={{ margin: "0" }}
                  >
                    <strong>{strings.review.review_gallery}</strong>
                  </div>
                  <div className="col-sm-4 hr-right hr-right-rede" />
                </div>
                <div className="row related-img">{this.RenderRelated()}</div>
              </div>
            </section>
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}
