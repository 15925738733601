import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import parse from "html-react-parser";
import Filter from "../../Filter";
import { PREFIX_CONTENT_ADDRESS } from "../../constants";
import { strings } from "../../language/language";
import "./css/JourneyDetail.css";

const curLang = localStorage.getItem("lang");

export default class JourneyDetail extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      postId: this.props.match.params.id,
      banner: "",
      path: "",
      galleries: [],
      journeyDeApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/promotion/" +
        this.props.match.params.id,
      journeyDeObj: {
        picture_cover: "",
        title: "",
        abstact: "",
      },
      related_picture: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const $ = window.$;
    this._isMounted = true;
    $("#preloader").show();

    this.fetchBanner();
    this.fetchData();
    // this.fetchGallery();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        promoDeApi:
          PREFIX_CONTENT_ADDRESS +
          "api/content/promotion/" +
          nextProps.match.params.id,
      },
      function () {
        this.fetchData();
        window.scrollTo(0, 0);
      }
    );
  }

  fetchBanner() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/promotion/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }


  fetchData() {
    const $ = window.$;
    const query = new URLSearchParams(this.props.location.search);
    var lang = query.get("lang");

    if (lang !== null) {
      localStorage.setItem("lang", lang);
    }
    // ถ้าใน local มีค่า
    if (localStorage.getItem("lang") !== null) {
      lang = localStorage.getItem("lang");
    }

    var journeyDeApi = this.state.journeyDeApi + "?lang=" + lang;
    const apiurl = journeyDeApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;

        this.setState({
          journeyDeObj: data,
        });

        // FIXME: Temporary for not having API
        axios
          .get(
            PREFIX_CONTENT_ADDRESS +
              "api/content/journey?itemPerPage=5&lang=th&page=1"
          )
          .then((res) => {
            const { data } = res;

            this.setState({
              related_picture: data.content,
            });
          });
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchGallery() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS +
      "api/gallery/promotion/" +
      this.props.match.params.id;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        this.setState({
          galleries: data,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  renderRelatedPictures() {
    let related_picuture = this.state.related_picture;
    const postId = this.state.postId;
    let j = 0;
    return Object.keys(related_picuture).map(function (key, i) {
      let item = related_picuture[key];
      let res = null;

      if (parseInt(item.post_id) === parseInt(postId)) {
        return res;
      }

      if (j < 4) {
        j++;
        res = (
          <div
            key={i}
            className="col-xs-12 col-lg-3 set-pad-rerelated b1 img-mobile"
            style={{ padding: 0, cursor: "pointer" }}
          >
            <a href={`/journey/${item.post_id}`}>
              <img
                style={{ width: "100%" }}
                src={item.picture_cover}
                alt="Promotion"
              />
            </a>
          </div>
        );
      }
      return res;
    });
  }

  render() {
    return (
      <div>
        <Filter />

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <img
                    style={{ width: "100%" }}
                    src={`/images/Destination/${curLang}.jpg`}
                    alt="Destination Banner"
                  />

                  {/* <div className="ReviewContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 ProContentA-setbgheader BranchContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div
                        className="container-title ReviewContentA-contain-default"
                        style={{
                          textAlign: "center",
                          marginTop: "130px",
                        }}
                      >
                        <h1>
                          <strong>{strings.journey.banner_title}</strong>
                        </h1>
                        <div className="hr-promo" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-a">
              <div className="container-fluid Promotion-Detail-fluid">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    {this.state.journeyDeObj.picture_content === "" ||
                    this.state.journeyDeObj.picture_content === null ? (
                      "ไม่พบข้อมูลรูปภาพ"
                    ) : (
                      <img
                        src={this.state.journeyDeObj.picture_content}
                        width="50%"
                        alt="Promotion"
                      />
                    )}
                  </div>
                  <div className="col-sm-12 set-pad">
                    <div className="info">
                      <div className="info-header">
                        <h3 className="set-pad-info" style={{ marginRight: 0 }}>
                          <strong>
                            {this.state.journeyDeObj.title === "" ||
                            this.state.journeyDeObj.title === null
                              ? ""
                              : this.state.journeyDeObj.title}
                          </strong>
                        </h3>
                      </div>
                      <div
                        className="content set-pad-info"
                        style={{ marginRight: 0 }}
                      >
                        <div className="info-content-a">
                          <h5>
                            <strong>
                              {this.state.journeyDeObj.abstact === "" ||
                              this.state.journeyDeObj.abstact === null
                                ? ""
                                : this.state.journeyDeObj.abstact}
                            </strong>
                          </h5>
                          <div className="control-p journey-detail-control-p">
                            {this.state.journeyDeObj.detail === "" ||
                            this.state.journeyDeObj.detail === null
                              ? ""
                              : parse(
                                  "" + this.state.journeyDeObj.detail === "" ||
                                    this.state.journeyDeObj.detail === null ||
                                    this.state.journeyDeObj.detail === undefined
                                    ? ""
                                    : this.state.journeyDeObj.detail + ""
                                )}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-b">
              <div
                className="container-fluid Promotion-Detail-fluid"
                style={{ padding: 0 }}
              >
                {/* <div className="row related-hr">
                  <div className="col-sm-4 hr-left" />
                  <div
                    className="col-sm-4 related-title"
                    style={{ margin: "0" }}
                  >
                    <strong>{strings.promotion.related}</strong>
                  </div>
                  <div className="col-sm-4 hr-right" />
                </div> */}

                <div className="Journey-Detail-related-img-container">
                  {this.renderRelatedPictures()}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
