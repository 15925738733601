// Imports: Dependencies
import { combineReducers } from 'redux';
// Imports: Reducers
import settingReducer from './settingReducer';
import branchReducer from './branchReducer';

// Redux: Root Reducer
const rootReducer = combineReducers({
    setting: settingReducer,
    branch: branchReducer
});
// Exports
export { rootReducer, settingReducer, branchReducer };