import settingApi from '../api/setting'
import branchApi from '../api/branch'
const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const login = (trueFalse) => ({
    type: 'LOGIN',
    trueFalse: trueFalse,
});

export const fetchSettings = () => async dispatch => {
    const response = await settingApi.get('/bookingConfig')
    dispatch({ type: 'FETCH_SETTING', payload: response.data })

    
}

export const fetchBranchTime = () => async dispatch => {
    const response = await branchApi.get('/branch_time')
    dispatch({ type: 'FETCH_BRANCH', payload: response.data })
}

function increment() {
    return {
      type: INCREMENT_COUNTER
    };
  }
  
  export function incrementAsync() {
     
    return dispatch => {
      setTimeout(() => {
        // You can invoke sync or async actions with `dispatch`
        dispatch(increment());
      }, 1000);
    };
  }