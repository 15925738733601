import React, { Component } from "react";
import "./css/BranchLocation.css";
import axios from "axios";
import Filter from "../../Filter";
import { ListGroup } from "react-bootstrap";
import parse from "html-react-parser";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import GoogleMapReact from "google-map-react";
const AnyReactComponent = ({ text }) => (
  <div>
    <img className="myMarker" alt="marker" src="images/marker-blue.png" />
  </div>
);
let curLang = localStorage.getItem("lang");
export default class BranchContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      currentRegion: 1,
      isShowChevronl: true,
      isShowChevronr: true,
      branchApi: PREFIX_CONTENT_ADDRESS + "api/branch?lang=" + curLang,
      banner: "",
      isRegion: {
        title: strings.faq.central,
        branch: {
          S: "",
          NA: "",
        },
      },
      isCopyRegion: {
        copyTitle: strings.faq.central,
        copyBranch: {
          S: "",
          NA: "",
        },
      },
      test: [],
      currentBranch: {
        location: "",
        locationDetail: "",
        googlePlace: "",
      },
      center: {
        lat: 13.7351331,
        lng: 100.5295525,
      },
    };
    this.handleRegion = this.handleRegion.bind(this);
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
    this.handleBranch = this.handleBranch.bind(this);
  }
  componentDidMount() {
    const $ = window.$;
    window.scrollTo(0, 0);

    $("#preloader").show();
    // this.props.history.push({
    //   pathname: "/branch",
    //   search: ""
    // });
    this.fetchBanner();
    this.fetchRegion();
  }
  fetchBanner() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/branch/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchRegion() {
    const $ = window.$;

    const apiurl = this.state.branchApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);

        this.setState({
          isRegion: {
            title: strings.faq.central,
            branch: data,
          },
          isCopyRegion: {
            copyTitle: strings.faq.central,
            copyBranch: data.C,
          },
          currentBranch: {
            location: data.C[0].title,
            locationDetail: data.C[0].detail,
            googlePlace: data.C[0].google_place,
            locationLat: data.C[0].latitude,
            locationLng: data.C[0].longitude,
          },
          center: {
            lat: Number(data.C[0].latitude),
            lng: Number(data.C[0].longitude),
          },
        });
      })
      .then(() => {
        $("#preloader").hide();
        $("#lo-0").prop("checked", true);
      })
      .catch((errs) => {
        console.log(errs);
      });
  }
  renderMarker(lat, lng) {
    return (
      <AnyReactComponent
        lat={this.state.currentBranch.locationLat}
        lng={this.state.currentBranch.locationLng}
      />
    );
  }
  handleRegion(id) {
    console.log("handleRegion", id);
    const $ = window.$;
    $("#preloader").hide();
    $("#lo-0").prop("checked", true);
    if ("region-a" === id) {
      this.setState({
        currentRegion: 1,
        isCopyRegion: {
          copyTitle: strings.faq.central,
          copyBranch: this.state.isRegion.branch.C,
        },
        currentBranch: {
          location: this.state.isRegion.branch.C[0].title,
          locationDetail: this.state.isRegion.branch.C[0].detail,
          googlePlace: this.state.isRegion.branch.C[0].google_place,
          locationLat: this.state.isRegion.branch.C[0].latitude,
          locationLng: this.state.isRegion.branch.C[0].longitude,
        },
        center: {
          lat: Number(this.state.isRegion.branch.C[0].latitude),
          lng: Number(this.state.isRegion.branch.C[0].longitude),
        },
      });
    } else if ("region-b" === id && this.state.isRegion.branch.E) {
      this.setState({
        currentRegion: 2,
        isCopyRegion: {
          copyTitle: strings.faq.east,
          copyBranch: this.state.isRegion.branch.E,
        },
        currentBranch: {
          location: this.state.isRegion.branch.E[0].title,
          locationDetail: this.state.isRegion.branch.E[0].detail,
          googlePlace: this.state.isRegion.branch.E[0].google_place,
          locationLat: this.state.isRegion.branch.E[0].latitude,
          locationLng: this.state.isRegion.branch.E[0].longitude,
        },
        center: {
          lat: Number(this.state.isRegion.branch.E[0].latitude),
          lng: Number(this.state.isRegion.branch.E[0].longitude),
        },
      });
    } else if ("region-c" === id && this.state.isRegion.branch.N) {
      this.setState({
        currentRegion: 3,
        isCopyRegion: {
          copyTitle: strings.faq.north,
          copyBranch: this.state.isRegion.branch.N,
        },
        currentBranch: {
          location: this.state.isRegion.branch.N[0].title,
          locationDetail: this.state.isRegion.branch.N[0].detail,
          googlePlace: this.state.isRegion.branch.N[0].google_place,
          locationLat: this.state.isRegion.branch.N[0].latitude,
          locationLng: this.state.isRegion.branch.N[0].longitude,
        },
        center: {
          lat: Number(this.state.isRegion.branch.N[0].latitude),
          lng: Number(this.state.isRegion.branch.N[0].longitude),
        },
      });
    } else if ("region-d" === id && this.state.isRegion.branch.NE) {
      this.setState({
        currentRegion: 4,
        isCopyRegion: {
          copyTitle: strings.faq.north_east,
          copyBranch: this.state.isRegion.branch.NE,
        },
        currentBranch: {
          location: this.state.isRegion.branch.NE[0].title,
          locationDetail: this.state.isRegion.branch.NE[0].detail,
          googlePlace: this.state.isRegion.branch.NE[0].google_place,
          locationLat: this.state.isRegion.branch.NE[0].latitude,
          locationLng: this.state.isRegion.branch.NE[0].longitude,
        },
        center: {
          lat: Number(this.state.isRegion.branch.NE[0].latitude),
          lng: Number(this.state.isRegion.branch.NE[0].longitude),
        },
      });
    } else if ("region-e" === id && this.state.isRegion.branch.S) {
      this.setState({
        currentRegion: 5,
        isCopyRegion: {
          copyTitle: strings.faq.south,
          copyBranch: this.state.isRegion.branch.S,
        },
        currentBranch: {
          location: this.state.isRegion.branch.S[0].title,
          locationDetail: this.state.isRegion.branch.S[0].detail,
          googlePlace: this.state.isRegion.branch.S[0].google_place,
          locationLat: this.state.isRegion.branch.S[0].latitude,
          locationLng: this.state.isRegion.branch.S[0].longitude,
        },
        center: {
          lat: Number(this.state.isRegion.branch.S[0].latitude),
          lng: Number(this.state.isRegion.branch.S[0].longitude),
        },
      });
    }
  }

  handleChangeRegion(state) {
    const $ = window.$;
    var chkregion = 0;

    if ("minus" === state) {
      this.setState({ currentRegion: this.state.currentRegion - 1 });
      chkregion = this.state.currentRegion - 1;
    } else {
      this.setState({ currentRegion: this.state.currentRegion + 1 });
      chkregion = this.state.currentRegion + 1;
    }

    if (5 === chkregion) {
      this.handleRegion("region-e");
    } else if (4 === chkregion) {
      this.handleRegion("region-d");
    } else if (3 === chkregion) {
      this.handleRegion("region-c");
    } else if (2 === chkregion) {
      this.handleRegion("region-b");
    } else if (1 === chkregion) {
      this.handleRegion("region-a");
    }
  }

  handleBranch(id) {
    this.setState({
      currentBranch: {
        location: this.state.isCopyRegion.copyBranch[id].title,
        locationDetail: this.state.isCopyRegion.copyBranch[id].detail,
        googlePlace: this.state.isCopyRegion.copyBranch[id].google_place,
        locationLat: this.state.isCopyRegion.copyBranch[id].latitude,
        locationLng: this.state.isCopyRegion.copyBranch[id].longitude,
      },
      center: {
        lat: Number(this.state.isCopyRegion.copyBranch[id].latitude),
        lng: Number(this.state.isCopyRegion.copyBranch[id].longitude),
      },
    });
  }

  RenderBranchColA() {
    if (this.state.isCopyRegion.copyBranch instanceof Array) {
      return this.state.isCopyRegion.copyBranch.map((item, i) => {
        var setClass = "";
        var setClassRadio = "";
        var setClassMobile = "";
        var setClassRadioMobile = "";
        if (i === 0) {
        } else if (i === 1) {
          setClassMobile = " BranchContentB-remart-onmobile";
          setClassRadioMobile = " BranchContentB-remart-locaonmo";
        } else if (i > 1) {
          setClass = " BranchContentB-remart-location";
          setClassRadio = " BranchContentB-remart-radio";
        }
        return (
          <div className={"col-md-6" + setClass + setClassMobile} key={i}>
            <div className={"radio" + setClassRadio + setClassRadioMobile}>
              <input
                type="radio"
                name="location"
                id={"lo-" + i}
                onClick={() => {
                  this.handleBranch(i);
                }}
              />
              <label
                htmlFor={"lo-" + i}
                className="BranchContentB-confont-location"
              >
                {item.title}
              </label>
            </div>
          </div>
        );
      });
    }
  }
  // RenderCurrentBranch() {
  //   this.state.isRegion.branch.map((item, i) => {
  //     return <div key={i}>{item.title}</div>;
  //   });
  // }
  static defaultProps = {
    center: {
      lat: 13.7351331,
      lng: 100.5295525,
    },
    zoom: 11,
  };
  render() {
    return (
      <div>
        <Filter />
        {/* {this.state.isRegion.branch} */}
        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  {/* <div className="BranchContentA-setbg-opacity" /> */}
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={`/images/Locations/${curLang}.png`}
                    alt="Branch Banner"
                  />
                  {/* <div
                    className="item slide3 ver3 BranchContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div className="container-title Branch-contain-default">
                        <h1 style={{ fontSize: "50px" }}>
                          <strong>Branch Location</strong>
                        </h1>
                        <div className="hr-promo BranchContentA-conhr-head" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="wrapper">
          <div className="content-area ">
            <div className="container-fluid BranchContentB-setmar-default Branch-container-fluid">
              <div className="row BranchContentB-contxtcen-incol">
                <ListGroup>
                  <ListGroup.Item
                    action
                    className={
                      "mo-list-group-item mo-list-group-item-p1 " +
                      (this.state.currentRegion === 1 ? "active" : "")
                    }
                    onClick={() => {
                      this.handleRegion("region-a");
                    }}
                  >
                    <div className="BranchContentB-buildbox BranchContentB-mock-disnone768">
                      <img
                        className="BranchContentB-hideimg-mo"
                        src={
                          this.state.preFixImg +
                          "images/BranchLocation/gps-point.png"
                        }
                        width="20px"
                        style={{ marginRight: "15px" }}
                        alt="logo"
                      />
                      <span
                        className="BranchContentB-confont-region"
                        id="BranchContentB-centralmenu"
                      >
                        {strings.faq.central}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className={
                      "mo-list-group-item mo-list-group-item-p2 " +
                      (this.state.currentRegion === 2 ? "active" : "")
                    }
                    onClick={() => {
                      this.handleRegion("region-b");
                    }}
                  >
                    <div className="BranchContentB-buildbox BranchContentB-mock-disnone768">
                      <img
                        className="BranchContentB-hideimg-mo"
                        src={
                          this.state.preFixImg +
                          "images/BranchLocation/gps-point.png"
                        }
                        width="20px"
                        style={{ marginRight: "15px" }}
                        alt="logo"
                      />
                      <span
                        className="BranchContentB-confont-region"
                        id="BranchContentB-eastmenu"
                      >
                        {strings.faq.east}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className={
                      "mo-list-group-item mo-list-group-item-p3 " +
                      (this.state.currentRegion === 3 ? "active" : "")
                    }
                    onClick={() => {
                      this.handleRegion("region-c");
                    }}
                  >
                    <div className="BranchContentB-buildbox BranchContentB-mock-disnone768">
                      <img
                        className="BranchContentB-hideimg-mo"
                        src={
                          this.state.preFixImg +
                          "images/BranchLocation/gps-point.png"
                        }
                        width="20px"
                        style={{ marginRight: "15px" }}
                        alt="logo"
                      />
                      <span
                        className="BranchContentB-confont-region"
                        id="BranchContentB-northmenu"
                      >
                        {strings.faq.north}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className={
                      "mo-list-group-item mo-list-group-item-p4 " +
                      (this.state.currentRegion === 4 ? "active" : "")
                    }
                    onClick={() => {
                      this.handleRegion("region-d");
                    }}
                  >
                    <div className="BranchContentB-buildbox BranchContentB-mock-disnone768">
                      <img
                        className="BranchContentB-hideimg-mo"
                        src={
                          this.state.preFixImg +
                          "images/BranchLocation/gps-point.png"
                        }
                        width="20px"
                        style={{ marginRight: "15px" }}
                        alt="logo"
                      />
                      <span
                        className="BranchContentB-confont-region"
                        id="BranchContentB-northeastmenu"
                      >
                        {strings.faq.north_east}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className={
                      "mo-list-group-item mo-list-group-item-p5 " +
                      (this.state.currentRegion === 5 ? "active" : "")
                    }
                    onClick={() => {
                      this.handleRegion("region-e");
                    }}
                  >
                    <div className="BranchContentB-buildbox BranchContentB-mock-onclick">
                      <img
                        className="BranchContentB-hideimg-mo"
                        src={
                          this.state.preFixImg +
                          "images/BranchLocation/gps-point.png"
                        }
                        width="20px"
                        style={{ marginRight: "15px" }}
                        alt="logo"
                      />
                      <span
                        className="BranchContentB-confont-region BranchContentB-confont-souregion BranchContentB-setfont-activedefault"
                        id="BranchContentB-southmenu"
                      >
                        {strings.faq.south}
                      </span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <div
                  className="BranchContentA-conchevron BranchContentA-hidechevron BranchContentA-conchevronl"
                  onClick={() => {
                    this.handleChangeRegion("minus");
                  }}
                  style={{
                    visibility:
                      this.state.currentRegion !== 1 ? "visible" : "hidden",
                  }}
                >
                  <i className="fa fa-chevron-left fa-2x"></i>
                </div>
                <div
                  className="BranchContentA-conchevron BranchContentA-hidechevron BranchContentA-conchevronr"
                  onClick={() => {
                    this.handleChangeRegion("plus");
                  }}
                  style={{
                    visibility:
                      this.state.currentRegion === 5 ? "hidden" : "visible",
                  }}
                >
                  <i className="fa fa-chevron-right fa-2x"></i>
                </div>
              </div>
              <div
                className="row BranchContentB-con-row-sidel"
                style={{ border: "1px solid #fff", background: "#fff" }}
              >
                <div className="col-md-6 BranchContentB-content-sidel BranchContentB-padfor-contentl">
                  <div>
                    <div className="col-md-12 BranchContentB-remart-contentl BranchContentB-addmarl-onmo">
                      <div className="BranchContentB-splitrow-marb">
                        <span className="BranchContentB-confont-headlocation">
                          {this.state.isCopyRegion.copyTitle === ""
                            ? "ไม่พบข้อมูล"
                            : this.state.isCopyRegion.copyTitle}
                        </span>
                      </div>
                      <div className="row">{this.RenderBranchColA()}</div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 BranchContentB-content-sider BranchContentB-padfor-contentr"
                  style={{ background: "#F4F7F8" }}
                >
                  <div>
                    <div className="col-md-12 BranchContentB-remart-contentr">
                      <div className="BranchContentB-splitrow-marb">
                        <span className="BranchContentB-confont-headlocation">
                          {this.state.currentBranch.location === ""
                            ? "ไม่พบข้อมูล"
                            : this.state.currentBranch.location}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-md-12 BranchContentB-confontinfo-sider">
                          {this.state.currentBranch.locationDetail === ""
                            ? "ไม่พบข้อมูล"
                            : parse(
                                "" +
                                  this.state.currentBranch.locationDetail +
                                  ""
                              )}
                        </div>
                      </div>
                      {this.state.currentBranch.googlePlace !== "" &&
                      this.state.currentBranch.googlePlace !== undefined &&
                      this.state.currentBranch.googlePlace !== null ? (
                        <div className="row">
                          <div className="col-md-12">
                            <a
                              href={this.state.currentBranch.googlePlace}
                              target="_blank"
                              className="btn  btn-theme btn-red"
                            >
                              {strings.faq.view_map}
                            </a>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <img
                    style={{ width: "100%" }}
                    src={`/images/map/${curLang}.png`}
                    alt="Map"
                  />

                  {/* <div style={{ height: "50vh", width: "100%" }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDg4kYkvKZO_lMWn-VMwwdBZFDq3pwO3PI",
                      }}
                      center={this.state.center}
                      options={{ clickableIcons: false }}
                      defaultZoom={this.props.zoom}
                    >
                      {this.renderMarker()}
                    </GoogleMapReact>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
