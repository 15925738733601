import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/form.css";
import Filter from "../../Filter";
import axios from "axios";
import { PREFIX_ADDRESS } from "../../constants";
let booking_temp;
let auth_token = localStorage.login_token || "";
const curLang = localStorage.getItem("lang");
export default class Form extends Component {
  constructor(props) {
    super(props);
    booking_temp = localStorage.getItem("booking_temp");
    booking_temp = JSON.parse(booking_temp);
    let outBranch = booking_temp.OutBranch;
    let outDate = booking_temp.OutDate;
    let outTime = booking_temp.OutTime;
    let inBranch = booking_temp.InBranch;
    let inDate = booking_temp.InDate;
    let inTime = booking_temp.InTime;
    let cdp = booking_temp.CDP;
    let carGroup = booking_temp.CarGroup;
    let rateNo = booking_temp.RateNo;
    let ratePackage = booking_temp.RatePackage;

    let param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&CarGroup=${carGroup}&CDP=${cdp}&RateNo=${rateNo}&RatePackage=${ratePackage}`;

    this.state = {
      preFixPath: "",
      preFixImg: "",
      isParam: param,
      iframe_url:
        `${PREFIX_ADDRESS}api/booking/payment?auth_token=${auth_token}&token=` +
        this.props.location.state.token +
        `&lang=` +
        curLang +
        `&channel=` +
        this.props.location.state.channel,
    };
  }
  componentDidMount() {
    const $ = window.$;
    window.scrollTo(0, 0);
    $("#preloader").hide();

    window.location.href = this.state.iframe_url;
  }

  handleFormSubmit(e) {
    e.preventDefault();
    // return false;
    console.log("handleFormSubmit");
    const $ = window.$;

    const firstname = this.state.isFirstName;
    const lastname = this.state.isLastName;
    const email = this.state.isEmail;
    const phone = this.state.isPhone;
    const drivinglicense = this.state.isDriLicense;
    var namecompany = this.state.isNameCompany;
    var numbertax = this.state.isNumberTax;
    var address = this.state.isAddress;
    const flightinfo = this.state.isFlightInfo;
    const flightnumber = this.state.isFlightNumber;
    const creditordebit = this.state.isCreditOrDebit;
    const cardtype = this.state.isCardType;

    if (this.state.isShow === false) {
      namecompany = "";
      numbertax = "";
      address = "";
    }
    let booking_temp = JSON.parse(localStorage.getItem("booking_temp"));
    let booking_extra = JSON.parse(localStorage.getItem("booking_option"));
    let booking_voucher = JSON.parse(localStorage.getItem("booking_voucher"));

    let booking_info = {
      YourInformation: {
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        drivinglicense: drivinglicense,
        namecompany: namecompany,
        numbertax: numbertax,
        address: address,
      },
      TravelInformation: { flightinfo: flightinfo, flightnumber: flightnumber },
      CreditCardDetail: { issuer: creditordebit, type: cardtype },
      BookingDetail: booking_temp,
      BookingOption: booking_extra,
      BookingVoucher: booking_voucher,
      Rate: {
        total: this.state.totalPrice,
        vat: this.state.isVat,
        extra: this.state.isExtra,
        carprice: this.state.book4Obj.rate.car_price,
        voucher: this.state.isVoucher,
      },
    };

    $("#preloader").show();

    axios
      .post(`${PREFIX_ADDRESS}api/booking/preparation`, booking_info, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then(function (res) {
        //handle success
        console.log(res);
        if (res.data.status === "success") {
          console.log(1);
          window.location.href =
            `${PREFIX_ADDRESS}api/booking/payment?token=` + res.data.token;
          return null;
        } else {
        }
      })
      .catch(function (res) {
        //handle error
        console.log(res);
      });
  }

  render() {
    return (
      <div>
        <Filter />
        <div className="wrapper">
          <div className="content-area" style={{ paddingBottom: "30px" }}>
            <div className="container BookHeaderStatus-repad-statusbar set-container-mobile">
              <div
                className="row BookHeaderStatus-row"
                style={{ height: "110px" }}
              >
                <div className="col-xs-1 BookHeaderStatus-reon-mobile" />
                <div
                  className="col-xs-10 BookHeaderStatus-reset-col"
                  style={{ marginTop: "8px" }}
                >
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <div className="BookHeaderStatus-circle">
                      {/* <i class="fa fa-check-circle" /> */}
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/AlreayStatus@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">Step 1</span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <Link
                      to={{
                        pathname: "/bookingstep2",
                        search: this.state.isParam,
                      }}
                    >
                      <i className="fa fa-chevron-right" />
                      <div className="BookHeaderStatus-circle">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep2/AlreayStatus@2x.png"
                          }
                          width="100%"
                          className="BookHeaderStatus-img-mobile"
                          alt="Status"
                        />
                      </div>
                      <span className="BookHeaderStatus-con-span">Step 2</span>
                    </Link>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <Link to={this.state.preFixPath + "/bookingstep3"}>
                      <i className="fa fa-chevron-right" />
                      <div className="BookHeaderStatus-circle">
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep2/AlreayStatus@2x.png"
                          }
                          width="100%"
                          className="BookHeaderStatus-img-mobile"
                          alt="Status"
                        />
                      </div>
                      <span className="BookHeaderStatus-con-span">Step 3</span>
                    </Link>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ color: "#fff", opacity: "1" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/CurrentStatus-4@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">Step 4</span>
                  </div>
                </div>
                <div className="col-xs-1" />
              </div>
            </div>
            <div
              className="container BookingStep3-re-contain"
              style={{
                marginTop: "0",
              }}
            >
              <div
                className="row"
                style={{
                  margin: "0 0",
                  paddingBottom: "30px",
                }}
              >
                <div className="col-md-12 BookingStep3-set-mar BookingStep3-re-padl BookingStep3-mobile-padl BookingStep3-repadl-contain">
                  <div className="Book3ContentA-head-option">
                    Credit Card Information
                  </div>
                  <div className="BookingStep4-setbg-info BookingStep4-marinfo-mo">
                    <div className="row BookingStep4-rowmar-default">
                      <div className="col-sm-12 BookingStep4-font-default">
                        <div className="BookingStep4-maringroup">
                          <div className="Book4ContentA-marb-span">
                            <iframe
                              title="payment_iframe"
                              style={{
                                width: "100%",
                                height: "600px",
                                border: "none",
                              }}
                              src={this.state.iframe_url}
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
