import React, { Component } from "react";
import { strings } from "./language/language";
import { PREFIX_ADDRESS } from "./constants";
import axios from "axios";

export default class FooterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
      }
    handleSubmitContact(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        this.setState({ isLoading: true })

        axios({
            method: 'post',
            url: `${PREFIX_ADDRESS}api/footer_email`,
            data: data
        }).then(function (response) {
            if (response.data.Success === 'Y') {
                alert("Thank you for your message. It has been sent.");
                document.getElementById("contact-form").reset();
                this.setState({ isLoading: false })
            }
        });
    }

    render() {
        return (
            <div
                className="col-md-4 wow fadeInLeft form-footer"
                data-wow-offset="200"
                data-wow-delay="200ms"
            >

                <form
                    name="contact-form"
                    method="post"
                    // action=""
                    onSubmit={(e) => this.handleSubmitContact(e)}
                    className="contact-form"
                    id="contact-form"
                >
                    <div className="outer required">
                        <div className="form-group af-inner has-icon form-name">
                            <label className="sr-only" htmlFor="subject">
                                Subject
                            </label>
                            <input
                                type="text"
                                name="name"
                                required
                                placeholder={strings.footer.name}
                                size="30"
                                data-toggle="tooltip"
                                title="Subject is required"
                                className="form-control placeholder"
                            />
                        </div>
                    </div>

                    <div className="outer required">
                        <div className="form-group af-inner has-icon form-email">
                            <label className="sr-only" htmlFor="subject">
                                Email
                            </label>
                            <input
                                type="text"
                                name="email"
                                required
                                placeholder={strings.footer.email}
                                size="30"
                                data-toggle="tooltip"
                                title="Subject is required"
                                className="form-control placeholder"
                            />
                        </div>
                    </div>

                    <div className="outer required">
                        <div className="form-group af-inner has-icon ">
                            <label className="sr-only" htmlFor="subject">
                                Subject
                            </label>
                            <input
                                type="text"
                                required
                                name="subject"
                                placeholder={strings.footer.subject}
                                size="30"
                                data-toggle="tooltip"
                                title="Subject is required"
                                className="form-control placeholder"
                            />
                        </div>
                    </div>

                    <div className="form-group af-inner has-icon form-something">
                        <label className="sr-only" htmlFor="input-message">
                            Message
                        </label>
                        <textarea
                            name="message"
                            required
                            id="input-message"
                            placeholder={strings.footer.message}
                            rows="2"
                            cols="50"
                            data-toggle="tooltip"
                            title="Message is required"
                            className="form-control placeholder"
                            style={{ height: "100px" }}
                        />
                    </div>

                    <div className="outer required">
                        <div className="form-group af-inner btn-submit">
                            <input
                                type="submit"
                                disabled={this.state.isLoading}
                                className="form-button btn btn-blue btn-theme ripple-effect btn-theme-dark"
                                value={strings.footer.send}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
