import React from "react";
const nationality = 
 [
       {
           "CODE": "1",
           "DESCRIPTION": "Afghan"
       },
       {
           "CODE": "2",
           "DESCRIPTION": "Albanian"
       },
       {
           "CODE": "3",
           "DESCRIPTION": "Algerian"
       },
       {
           "CODE": "4",
           "DESCRIPTION": "Andorran"
       },
       {
           "CODE": "5",
           "DESCRIPTION": "Angolan "
       },
       {
           "CODE": "6",
           "DESCRIPTION": "Argentinian"
       },
       {
           "CODE": "7",
           "DESCRIPTION": "Armenian"
       },
       {
           "CODE": "8",
           "DESCRIPTION": "Australian "
       },
       {
           "CODE": "9",
           "DESCRIPTION": "Austrian"
       },
       {
           "CODE": "10",
           "DESCRIPTION": "Azerbaijani"
       },
       {
           "CODE": "11",
           "DESCRIPTION": "Bahamian"
       },
       {
           "CODE": "12",
           "DESCRIPTION": "Bahraini"
       },
       {
           "CODE": "13",
           "DESCRIPTION": "Bangladeshi"
       },
       {
           "CODE": "14",
           "DESCRIPTION": "Barbadian"
       },
       {
           "CODE": "15",
           "DESCRIPTION": "Belarusan"
       },
       {
           "CODE": "16",
           "DESCRIPTION": "Belgian "
       },
       {
           "CODE": "17",
           "DESCRIPTION": "Belizean"
       },
       {
           "CODE": "18",
           "DESCRIPTION": "Beninese"
       },
       {
           "CODE": "19",
           "DESCRIPTION": "Bhutanese  "
       },
       {
           "CODE": "20",
           "DESCRIPTION": "Bolivian"
       },
       {
           "CODE": "21",
           "DESCRIPTION": "Bosnian "
       },
       {
           "CODE": "22",
           "DESCRIPTION": "Botswanan  "
       },
       {
           "CODE": "23",
           "DESCRIPTION": "Brazilian  "
       },
       {
           "CODE": "24",
           "DESCRIPTION": "British "
       },
       {
           "CODE": "25",
           "DESCRIPTION": "Bruneian"
       },
       {
           "CODE": "26",
           "DESCRIPTION": "Bulgarian  "
       },
       {
           "CODE": "27",
           "DESCRIPTION": "Burkinese  "
       },
       {
           "CODE": "28",
           "DESCRIPTION": "Burmese "
       },
       {
           "CODE": "29",
           "DESCRIPTION": "Burundian  "
       },
       {
           "CODE": "30",
           "DESCRIPTION": "Cambodian  "
       },
       {
           "CODE": "31",
           "DESCRIPTION": "Cameroonian"
       },
       {
           "CODE": "32",
           "DESCRIPTION": "Canadian"
       },
       {
           "CODE": "33",
           "DESCRIPTION": "Cape Verdean                                      "
       },
       {
           "CODE": "34",
           "DESCRIPTION": "Chadian "
       },
       {
           "CODE": "35",
           "DESCRIPTION": "Chilean "
       },
       {
           "CODE": "36",
           "DESCRIPTION": "Chinese "
       },
       {
           "CODE": "37",
           "DESCRIPTION": "Colombian  "
       },
       {
           "CODE": "38",
           "DESCRIPTION": "Congolese  "
       },
       {
           "CODE": "39",
           "DESCRIPTION": "Costa Rican"
       },
       {
           "CODE": "40",
           "DESCRIPTION": "Croatian"
       },
       {
           "CODE": "41",
           "DESCRIPTION": "Cuban "
       },
       {
           "CODE": "42",
           "DESCRIPTION": "Cypriot "
       },
       {
           "CODE": "43",
           "DESCRIPTION": "Czech "
       },
       {
           "CODE": "44",
           "DESCRIPTION": "Danish"
       },
       {
           "CODE": "45",
           "DESCRIPTION": "Djiboutian "
       },
       {
           "CODE": "46",
           "DESCRIPTION": "Dominican  "
       },
       {
           "CODE": "47",
           "DESCRIPTION": "Dominican  "
       },
       {
           "CODE": "48",
           "DESCRIPTION": "Ecuadorean "
       },
       {
           "CODE": "49",
           "DESCRIPTION": "Egyptian"
       },
       {
           "CODE": "50",
           "DESCRIPTION": "Salvadorean"
       },
       {
           "CODE": "51",
           "DESCRIPTION": "English "
       },
       {
           "CODE": "52",
           "DESCRIPTION": "Eritrean"
       },
       {
           "CODE": "53",
           "DESCRIPTION": "Estonian"
       },
       {
           "CODE": "54",
           "DESCRIPTION": "Ethiopian  "
       },
       {
           "CODE": "55",
           "DESCRIPTION": "Fijian"
       },
       {
           "CODE": "56",
           "DESCRIPTION": "Finnish "
       },
       {
           "CODE": "57",
           "DESCRIPTION": "French"
       },
       {
           "CODE": "58",
           "DESCRIPTION": "Gabonese"
       },
       {
           "CODE": "59",
           "DESCRIPTION": "Gambian "
       },
       {
           "CODE": "60",
           "DESCRIPTION": "Georgian"
       },
       {
           "CODE": "61",
           "DESCRIPTION": "German"
       },
       {
           "CODE": "62",
           "DESCRIPTION": "Ghanaian"
       },
       {
           "CODE": "63",
           "DESCRIPTION": "Greek "
       },
       {
           "CODE": "64",
           "DESCRIPTION": "Grenadian  "
       },
       {
           "CODE": "65",
           "DESCRIPTION": "Guatemalan "
       },
       {
           "CODE": "66",
           "DESCRIPTION": "Guinean "
       },
       {
           "CODE": "67",
           "DESCRIPTION": "Guyanese"
       },
       {
           "CODE": "68",
           "DESCRIPTION": "Haitian "
       },
       {
           "CODE": "69",
           "DESCRIPTION": "Dutch "
       },
       {
           "CODE": "70",
           "DESCRIPTION": "Honduran"
       },
       {
           "CODE": "71",
           "DESCRIPTION": "Hungarian  "
       },
       {
           "CODE": "72",
           "DESCRIPTION": "Icelandic  "
       },
       {
           "CODE": "73",
           "DESCRIPTION": "Indian"
       },
       {
           "CODE": "74",
           "DESCRIPTION": "Indonesian "
       },
       {
           "CODE": "75",
           "DESCRIPTION": "Iranian "
       },
       {
           "CODE": "76",
           "DESCRIPTION": "Iraqi "
       },
       {
           "CODE": "77",
           "DESCRIPTION": "Irish "
       },
       {
           "CODE": "78",
           "DESCRIPTION": "Italian "
       },
       {
           "CODE": "79",
           "DESCRIPTION": "Jamaican"
       },
       {
           "CODE": "80",
           "DESCRIPTION": "Japanese"
       },
       {
           "CODE": "81",
           "DESCRIPTION": "Jordanian  "
       },
       {
           "CODE": "82",
           "DESCRIPTION": "Kazakh"
       },
       {
           "CODE": "83",
           "DESCRIPTION": "Kenyan"
       },
       {
           "CODE": "84",
           "DESCRIPTION": "Kuwaiti "
       },
       {
           "CODE": "85",
           "DESCRIPTION": "Laotian "
       },
       {
           "CODE": "86",
           "DESCRIPTION": "Latvian "
       },
       {
           "CODE": "87",
           "DESCRIPTION": "Lebanese"
       },
       {
           "CODE": "88",
           "DESCRIPTION": "Liberian"
       },
       {
           "CODE": "89",
           "DESCRIPTION": "Libyan"
       },
       {
           "CODE": "90",
           "DESCRIPTION": "Lithuanian "
       },
       {
           "CODE": "91",
           "DESCRIPTION": "Macedonian "
       },
       {
           "CODE": "92",
           "DESCRIPTION": "Madagascan "
       },
       {
           "CODE": "93",
           "DESCRIPTION": "Malawian"
       },
       {
           "CODE": "94",
           "DESCRIPTION": "Malaysian  "
       },
       {
           "CODE": "95",
           "DESCRIPTION": "Maldivian  "
       },
       {
           "CODE": "96",
           "DESCRIPTION": "Malian"
       },
       {
           "CODE": "97",
           "DESCRIPTION": "Maltese "
       },
       {
           "CODE": "98",
           "DESCRIPTION": "Mauritanian"
       },
       {
           "CODE": "99",
           "DESCRIPTION": "Mauritian  "
       },
       {
           "CODE": "100",
           "DESCRIPTION": "Mexican "
       },
       {
           "CODE": "101",
           "DESCRIPTION": "Moldovan"
       },
       {
           "CODE": "102",
           "DESCRIPTION": "Monacan "
       },
       {
           "CODE": "103",
           "DESCRIPTION": "Mongolian  "
       },
       {
           "CODE": "104",
           "DESCRIPTION": "Montenegrin"
       },
       {
           "CODE": "105",
           "DESCRIPTION": "Moroccan"
       },
       {
           "CODE": "106",
           "DESCRIPTION": "Mozambican "
       },
       {
           "CODE": "108",
           "DESCRIPTION": "Namibian"
       },
       {
           "CODE": "109",
           "DESCRIPTION": "Nepalese"
       },
       {
           "CODE": "110",
           "DESCRIPTION": "Dutch "
       },
       {
           "CODE": "111",
           "DESCRIPTION": "New Zealanders                                    "
       },
       {
           "CODE": "112",
           "DESCRIPTION": "Nicaraguan "
       },
       {
           "CODE": "113",
           "DESCRIPTION": "Nigerien"
       },
       {
           "CODE": "114",
           "DESCRIPTION": "Nigerian"
       },
       {
           "CODE": "115",
           "DESCRIPTION": "North Korean                                      "
       },
       {
           "CODE": "116",
           "DESCRIPTION": "Norwegian  "
       },
       {
           "CODE": "117",
           "DESCRIPTION": "Omani "
       },
       {
           "CODE": "118",
           "DESCRIPTION": "Pakistani  "
       },
       {
           "CODE": "119",
           "DESCRIPTION": "Panamanian "
       },
       {
           "CODE": "120",
           "DESCRIPTION": "Guinean "
       },
       {
           "CODE": "121",
           "DESCRIPTION": "Paraguayan "
       },
       {
           "CODE": "122",
           "DESCRIPTION": "Peruvian"
       },
       {
           "CODE": "123",
           "DESCRIPTION": "Philippine "
       },
       {
           "CODE": "124",
           "DESCRIPTION": "Polish"
       },
       {
           "CODE": "125",
           "DESCRIPTION": "Portuguese "
       },
       {
           "CODE": "126",
           "DESCRIPTION": "Qatari"
       },
       {
           "CODE": "127",
           "DESCRIPTION": "Romanian"
       },
       {
           "CODE": "128",
           "DESCRIPTION": "Russian "
       },
       {
           "CODE": "129",
           "DESCRIPTION": "Rwandan "
       },
       {
           "CODE": "130",
           "DESCRIPTION": "Saudi Arabian                                     "
       },
       {
           "CODE": "131",
           "DESCRIPTION": "Scottish"
       },
       {
           "CODE": "132",
           "DESCRIPTION": "Senegalese "
       },
       {
           "CODE": "133",
           "DESCRIPTION": "Serbian "
       },
       {
           "CODE": "134",
           "DESCRIPTION": "Seychellois"
       },
       {
           "CODE": "135",
           "DESCRIPTION": "Sierra Leonian                                    "
       },
       {
           "CODE": "136",
           "DESCRIPTION": "Singaporean"
       },
       {
           "CODE": "137",
           "DESCRIPTION": "Slovak"
       },
       {
           "CODE": "138",
           "DESCRIPTION": "Slovenian  "
       },
       {
           "CODE": "139",
           "DESCRIPTION": "Solomon Islander                                  "
       },
       {
           "CODE": "140",
           "DESCRIPTION": "Somali"
       },
       {
           "CODE": "141",
           "DESCRIPTION": "South African                                     "
       },
       {
           "CODE": "142",
           "DESCRIPTION": "South Korean                                      "
       },
       {
           "CODE": "143",
           "DESCRIPTION": "Spanish "
       },
       {
           "CODE": "144",
           "DESCRIPTION": "Sri Lankan "
       },
       {
           "CODE": "145",
           "DESCRIPTION": "Sudanese"
       },
       {
           "CODE": "146",
           "DESCRIPTION": "Surinamese "
       },
       {
           "CODE": "147",
           "DESCRIPTION": "Swazi "
       },
       {
           "CODE": "148",
           "DESCRIPTION": "Swedish "
       },
       {
           "CODE": "149",
           "DESCRIPTION": "Swiss "
       },
       {
           "CODE": "150",
           "DESCRIPTION": "Syrian"
       },
       {
           "CODE": "151",
           "DESCRIPTION": "Taiwanese  "
       },
       {
           "CODE": "152",
           "DESCRIPTION": "Tadjik"
       },
       {
           "CODE": "153",
           "DESCRIPTION": "Tanzanian  "
       },
       {
           "CODE": "154",
           "DESCRIPTION": "Thai  "
       },
       {
           "CODE": "155",
           "DESCRIPTION": "Togolese"
       },
       {
           "CODE": "156",
           "DESCRIPTION": "Trinidadian"
       },
       {
           "CODE": "157",
           "DESCRIPTION": "Tobagan "
       },
       {
           "CODE": "158",
           "DESCRIPTION": "Tobagonian "
       },
       {
           "CODE": "159",
           "DESCRIPTION": "Tunisian"
       },
       {
           "CODE": "160",
           "DESCRIPTION": "Turkish "
       },
       {
           "CODE": "161",
           "DESCRIPTION": "Turkoman"
       },
       {
           "CODE": "162",
           "DESCRIPTION": "Tuvaluan"
       },
       {
           "CODE": "163",
           "DESCRIPTION": "Ugandan "
       },
       {
           "CODE": "164",
           "DESCRIPTION": "Ukrainian  "
       },
       {
           "CODE": "165",
           "DESCRIPTION": "Emirati "
       },
       {
           "CODE": "166",
           "DESCRIPTION": "British "
       },
       {
           "CODE": "167",
           "DESCRIPTION": "American"
       },
       {
           "CODE": "168",
           "DESCRIPTION": "Uruguayan  "
       },
       {
           "CODE": "169",
           "DESCRIPTION": "Uzbek "
       },
       {
           "CODE": "170",
           "DESCRIPTION": "Vanuatuan  "
       },
       {
           "CODE": "172",
           "DESCRIPTION": "Venezuelan "
       },
       {
           "CODE": "173",
           "DESCRIPTION": "Vietnamese "
       },
       {
           "CODE": "174",
           "DESCRIPTION": "Welsh "
       },
       {
           "CODE": "175",
           "DESCRIPTION": "Western Samoan                                    "
       },
       {
           "CODE": "176",
           "DESCRIPTION": "Yemeni"
       },
       {
           "CODE": "177",
           "DESCRIPTION": "Yugoslav"
       },
       {
           "CODE": "178",
           "DESCRIPTION": "Za?rean "
       },
       {
           "CODE": "179",
           "DESCRIPTION": "Zambian "
       },
       {
           "CODE": "180",
           "DESCRIPTION": "Zimbabwean "
       },
       {
           "CODE": "181",
           "DESCRIPTION": "ISRAELI "
       }
   ]
const  NationalitySelect = (props) => {
	return (
		<select onChange={(e)=>props.onChange(e)} value={props.value} className={props.className}>
			<option value="">Please select</option>
		{nationality.map((item,key)=>{
			return <option key={key} value={item.CODE}>{item.DESCRIPTION.trim()}</option>
		})}
		</select>
	)
}
 export default NationalitySelect;

