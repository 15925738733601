import React, { Component } from 'react';

export default class Preloader extends Component {
    render() {
        return (
            <div>
                <div id="preloader" style={{ display: "none" }}>
                    <div id="preloader-status">
                        <div className="spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div>
                        <div id="preloader-title">Loading</div>
                    </div>
                </div>
            </div>
        );
    }
}
