import React, { Component } from 'react';
import './css/promotion.css';
import './css/home.css';
import { Link } from "react-router-dom";
import { dom } from '@fortawesome/fontawesome-svg-core';
import { addDays, addHours } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import moment from 'moment';
import { PREFIX_ADDRESS, RenderOptionSelectMin, RenderOptionSelectHr, SITE_URL } from '../../constants';
import { strings } from "../../language/language";
import YoutubeBackground from 'react-youtube-background'

dom.watch();


let countz = 0;
export default class HomeFilter extends Component {
    constructor(props) {
        super(props);
        let curLang = localStorage.getItem("lang");
        let today = new Date();
        this.state = {
            preFixAddress: "",
            preFixImg: SITE_URL + "/",
            isCode: "",
            isBranches: "",
            startDatea: addDays(today, 2),
            currentDatea: "",
            isHrTimea: addHours(today, 1).getHours(),
            isMinTimea: "00",
            isSameLocation: false,
            isReturn: "",
            startDateb: addDays(today, 3),
            isHrTimeb: addHours(today, 1).getHours(),
            isMinTimeb: "00",
            isPromoCode: "",
            selectedPickupLocation: "DEFAULT",
            selectedReturnLocation: "DEFAULT",
            bannerApi: PREFIX_ADDRESS + "api/gallery/home/67?lang=" + curLang,
            promiseIsResolved: null,
            homeObj: {
                picture_cover: SITE_URL + "/images/bannerHome/1.jpg",
                picture_promotion: SITE_URL + "/images/ICON-536x371-01.jpg",
                picture_faq: SITE_URL + "/images/ICON-536x371-02.jpg",
                picture_enterprise: SITE_URL + "/images/ICON-536x371-03.jpg",
                picture_service: SITE_URL + "/images/a5719ee681d3979b766f79d640ff8ecd.jpg"
            },
            bannerObj: [],
            isStatusBookNow: "N"
        }
        /// หน้านี้ เหลือ ยิง Api , setState ในส่วนของ Text Our Service ด้านล่างฝั่งซ้าย

        this.handleScroll = this.handleScroll.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const $ = window.$;
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
        // this.fetchHome()
        await this.fetchMainBanner()
        this.fetchLocation()


        $(".main-slider").owlCarousel({
            items: 1,
            autoplay: true,
            autoplayTimeout: 5000
        });



    }
    openCalendar(ele) {
        ele.setOpen(true)
    }
    fetchLocation() {
        axios.get(`${PREFIX_ADDRESS}api/booking/branchlist`).then(res => {
            if (res.data.Success === "Y") {
                const { data } = res;
                // console.log(data.Branch[10]);
                this.setState({ isBranches: data.Branch })
            }
        })
    }
    fetchMainBanner() {

        const uriapi = this.state.bannerApi;
        axios.get(uriapi).then(res => {
            console.log("fetchMainBanner")
            let banner = res.data;
            this.setState({ bannerObj: banner, promiseIsResolved: true });


        }).catch(err => {
            console.log(err);
        })

        // return (<div className="main-slider">{bannerItem}</div>);
    }


    handleScroll() {
        // OnScroll For Sticky Header
        var element = document.getElementById("conanimatetxt-our");
        if (
            document.documentElement.scrollTop > 80 && document.documentElement.clientWidth > 992
        ) {
            element.classList.add("HomeFilter-conanimatetxt-our");
        }
        if (
            document.documentElement.scrollTop > 1000 && document.documentElement.clientWidth < 992
        ) {
            element.classList.add("HomeFilter-conanimatetxt-our");
        }
    }
    componentWillUnmount() {
        // Scroll
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleSameLocation(e) {
        // console.log("in handleSameLocation");
        this.setState({
            isSameLocation: !this.state.isSameLocation
        })
        const outBranch = this.state.selectedPickupLocation;
        var sameactive = e.target.checked;
        // console.log(sameactive);

        if (sameactive === true) {
            this.setState({ selectedReturnLocation: outBranch })
        } else {
            this.setState({ selectedReturnLocation: "DEFAULT" })
        }
    }

    handleSubmit(e) {
        const $ = window.$;
        $("#preloader").show();
        e.preventDefault();

        const outBranch = this.state.selectedPickupLocation;
        const pickupdate = this.state.startDatea;
        const outTime = `${this.state.isHrTimea}:${this.state.isMinTimea}`
        const samelo = this.state.isSameLocation;
        var inBranch = this.state.selectedReturnLocation;
        const returndate = this.state.startDateb;
        const inTime = `${this.state.isHrTimeb}:${this.state.isMinTimeb}`
        const code = this.state.isCode;
        const outDate = moment(pickupdate).format(`DD/MM/YYYY`);
        const inDate = moment(returndate).format(`DD/MM/YYYY`);



        if ("DEFAULT" === outBranch) {
            alert("Please Select Pickup Location");
            $("#preloader").hide();
            return
        }
        if (pickupdate > returndate) {
            alert("Please Check Return Date Again");
            $("#preloader").hide();
            return
        }
        if (outDate === inDate && (this.state.isHrTimeb - this.state.isHrTimea) < 4) {
            alert("ระยะเวลาการเช่ารถต้องมากกว่า 4 ชั่วโมง");

            this.setState({ isHrTimeb: this.state.isHrTimea + 4 })
            $("#preloader").hide();
            return
        }

        if ("DEFAULT" === inBranch) {
            alert("Please Select Return Location");
            $("#preloader").hide();
            return
        }
        if (true === samelo) {
            inBranch = outBranch
            if ("" !== outBranch) {
                inBranch = this.state.selectedPickupLocation
                this.setState({ selectedReturnLocation: inBranch })
            }
        }

        var difreversecar = addDays(pickupdate, 30);
        if (returndate > difreversecar) {
            alert("ระยะเวลาจองรถและคืนรถต้องไม่ห่างกันเกิน 30 วัน");
            $("#preloader").hide();
            return
        }


        const param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&Code=${code}`;

        // console.log(this.props);
        let callback;
        axios.get(`${PREFIX_ADDRESS}api/booking/carlist${param}`).then(res => {
            // console.log(res.data.Success);
            callback = res;
            if (res.data.Success !== undefined) {
                this.setState({
                    isStatusBookNow: res.data.Success,
                    isMessage: res.data.message
                })
            }

        }).then(() => {
            // console.log("dd");
            // console.log(callback);

            if (callback.data.Success === "Y") {
                // window.location.href = `/bookingstep2${param}`;
                this.props.history.push({ pathname: "/bookingstep2", search: param });
            } else {
                let str_error = strings.error[callback.data.error.message[0]];
                if (str_error === undefined) {
                    str_error = callback.data.error.message[0];
                }

                alert(str_error);
                // 
                this.setState({ isCode: "" })
                $("#preloader").hide();
                return
            }
        }).then(() => {
            setTimeout(() => {
                $("#preloader").hide();
            }, 100);
        })
            .catch(err => {
                console.log(err);
            })
    }
    pickupPlaceChange(e) {

        this.setState({ selectedPickupLocation: e.target.value })
        if (this.state.isSameLocation === true) {
            this.setState({ selectedReturnLocation: e.target.value })
        }
    }
    dropPlaceChange(e) {
        this.setState({ selectedReturnLocation: e.target.value })
        if (this.state.isSameLocation === true) {
            this.setState({ selectedPickupLocation: e.target.value })
        }
    }
    RenderPickLocation() {
        var branches = this.state.isBranches;
        const branchOptions = Object.keys(branches).map(key =>

            <option key={key} value={key}>{branches[key]}</option>
            // <option value={branches[this.state.selectedPickupLocation]} key={key}>{branches[key]}</option>
        )
        return branchOptions;
    }

    mainBanner() {
        console.log("mainBanner" + countz);
        countz++;
        if (countz === 0) {
            return null;
        }
        // console.log("mainBanner" + countz);
        let curLang = localStorage.getItem("lang");
        let folder_banner = curLang === "th" ? "th" : "en";
        // if(this.state.bannerObj.length>0)
        // {
        //     return null;
        // }

        let banner = this.state.bannerObj;


        let bannerItem2 = [];
        let bz;
        let stylez;
        if (banner.length > 0) {
            console.log("Fire")
            console.log(banner.length)

            for (let i = 0; i <= banner.length; i++) {
                bz = banner[i] ? banner[i]['pic'] : "";

                bannerItem2.push(<div className="item slide3 ver3 item-bg" style={stylez} />)
            }


        }
        return (<div className="main-slider">{bannerItem2}</div>);
    }



    render() {

        let curLang = localStorage.getItem("lang");
        // if(this.state.bannerObj.length>0)
        // {
        //     return null;
        // }

        let banner = this.state.bannerObj;

        let bannerItem2 = [];
        let bz;
        let stylez;
        if (banner.length > 0) {


            for (let i = 0; i <= banner.length - 1; i++) {
                bz = banner[i] ? banner[i]['pic'].replace(" ", "%20") : "";


                stylez = {
                    backgroundImage: 'url(' + bz + ')',
                };

                bannerItem2.push(<a key={i} href={banner[i] && banner[i]['link'] ? banner[i]['link'] : ""}><div className="item slide3 ver3 item-bg" style={stylez} /></a>)
                console.log(i)
            }


        }




        // if(!this.state.promiseIsResolved){return null}




        console.log("render")
        return (
            <div>
                <div className="wrapper">

                    <div className="content-area">
                        <section className="page-section no-padding slider">
                            <div className="container full-width">

                                <YoutubeBackground
                                    videoId={"Uhv6z32qc8s"}

                                >
                                    <div style={{ height: "40vw", minHeight: "550px" }}></div>
                                </YoutubeBackground>



                                <div className="caption main-booking-form">
                                    <div className="container" style={{ marginTop: '-9px' }}>
                                        <div className="div-table reset-table">
                                            <div className="div-cell HomeFilter-conanimate" style={{ paddingBottom: '25px' }}>
                                                <div className="caption-content HomeFilter-conanimate-opa">

                                                    <div className="form-search light reset-form">
                                                        <form action="">
                                                            <div className="form-title">
                                                                <h2 className="con-book" style={{ fontFamily: 'Prompt' }}>BOOK NOW</h2>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-12 reset-col" style={{ marginTop: '20px' }}>
                                                                        <div className="form-group has-icon has-label reset-group">
                                                                            <label htmlFor="formSearchUpLocation3" style={{ color: '#01adef', fontSize: '16px' }}>{strings.book_now.pick_up_location}</label>
                                                                            {/* selected={this.state.isPickUp}  */}
                                                                            <select name="location" className="form-control set-placehol" id="formSearchUpLocation3" value={this.state.selectedPickupLocation} onChange={(e) => this.pickupPlaceChange(e)} >
                                                                                <option value="DEFAULT" disabled hidden>Airport or Anywhere</option>
                                                                                {this.RenderPickLocation()}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-6 reset-col">
                                                                        <div className="form-group has-icon has-label reset-group HomeFilter-conreactpicdate HomeFilter-conreactdate-mo">
                                                                            <DatePicker
                                                                                ref={(c) => this._calendarPick = c}
                                                                                withPortal
                                                                                disabledKeyboardNavigation
                                                                                showYearDropdown
                                                                                // fixedHeight
                                                                                // showPopperArrow={false}
                                                                                // dropdownMode="select"
                                                                                minDate={addDays(new Date(), 2)}
                                                                                selected={this.state.startDatea}
                                                                                onChange={date => this.setState({
                                                                                    startDatea: date,
                                                                                    startDateb: addDays(date, 1)
                                                                                })}
                                                                                className="form-control re-form-con"
                                                                                placeholderText="Click to select a date"
                                                                                dateFormat="dd/MM/yyyy"
                                                                            />
                                                                            <span className="form-control-icon" onClick={(e) => this.openCalendar(this._calendarPick)}><i className="fa fa-calendar"></i></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-3 reset-col Home-setmarb-split Home-conw-select Home-setfloatl">
                                                                        <select className="form-control re-form-con control-select" value={this.state.isHrTimea} onChange={(e) => this.setState({ isHrTimea: e.target.value, isHrTimeb: e.target.value })}>
                                                                            {/* {this.RenderOptionSelectHr()} */}
                                                                            <RenderOptionSelectHr />
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-3 reset-col Home-conw-select">
                                                                        <select className="form-control re-form-con control-select" defaultValue={this.state.isMinTimea} onChange={(e) => this.setState({ isMinTimea: e.target.value, isMinTimeb: e.target.value })}>
                                                                            {/* {this.RenderOptionSelectMin()} */}
                                                                            <RenderOptionSelectMin />
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-12 reset-col">
                                                                        <div className="form-group has-icon has-label reset-group" style={{ margin: '0' }}>
                                                                            <div className="Home-setfloatl-same">
                                                                                <label htmlFor="formSearchUpLocation3" style={{ color: '#01adef', fontSize: '16px' }}>{strings.book_now.return_location}</label>
                                                                            </div>
                                                                            <div className="checkbox Home-conmart-same">
                                                                                <label className="label-same-location">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        value={this.state.isSameLocation ? "1" : "0"}
                                                                                        onChange={e => this.handleSameLocation(e)}

                                                                                    />
                                                                                    <span className="cr">
                                                                                        <i
                                                                                            className="cr-icon glyphicon glyphicon-ok"
                                                                                            style={{
                                                                                                top: "0"
                                                                                            }}
                                                                                        />
                                                                                    </span>
                                                                                    <span className="Home-confont-same">
                                                                                        {strings.book_now.same_location_as_pick_up}
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div>
                                                                                {/* disabled={this.state.isSameLocation} style={{background: this.state.isSameLocation ? "#E0DFDB" : "", color: this.state.isSameLocation ? "#E0DFDB" : ""}} */}
                                                                                <select name="location" className="form-control set-placehol" value={this.state.selectedReturnLocation} onChange={(e) => this.dropPlaceChange(e)} >
                                                                                    <option value="DEFAULT" disabled hidden>Airport or Anywhere</option>
                                                                                    {this.RenderPickLocation()}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-6 reset-col">
                                                                        <div className="form-group has-icon has-label reset-group HomeFilter-conreactpicdate">
                                                                            <DatePicker
                                                                                disabledKeyboardNavigation
                                                                                withPortal
                                                                                showYearDropdown
                                                                                // minDate={addDays(this.state.startDatea,1)}
                                                                                ref={(c) => this._calendarDrop = c}
                                                                                minDate={addDays(this.state.startDatea, 0)}
                                                                                maxDate={addDays(this.state.startDatea, 30)}
                                                                                selected={this.state.startDateb}
                                                                                onChange={(date, e) => this.setState({ startDateb: date })}

                                                                                className="form-control re-form-con"
                                                                                placeholderText="dd/mm/yyyy"
                                                                                dateFormat="dd/MM/yyyy"
                                                                            />
                                                                            <span className="form-control-icon" onClick={(e) => this.openCalendar(this._calendarDrop)}><i className="fa fa-calendar"></i></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-3 reset-col Home-setmarb-split Home-conw-select Home-setfloatl">
                                                                        <select className="form-control re-form-con control-select" value={this.state.isHrTimeb} onChange={(e) => this.setState({ isHrTimeb: e.target.value })}>
                                                                            <RenderOptionSelectHr />
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-xs-6 col-sm-3 reset-col Home-conw-select">
                                                                        <select className="form-control re-form-con control-select" value={this.state.isMinTimeb} onChange={(e) => this.setState({ isMinTimeb: e.target.value })}>
                                                                            <RenderOptionSelectMin />
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-12 reset-col">
                                                                        <div className="form-group has-icon has-label reset-group">
                                                                            <label htmlFor="formSearchUpLocation3" style={{ color: '#01adef', fontSize: '16px' }}>{strings.book_now.promotion_code}</label>
                                                                            <input type="text" value={this.state.isCode} className="form-control re-form-con" placeholder={strings.book_now.please_input_code} onChange={(e) => { this.setState({ isCode: e.target.value.toUpperCase() }) }} />
                                                                            {/* <span className="form-control-icon form-control-space HomeFilter-conpo-iconchk" style={{ display: this.state.chkCode ? 'block' : 'none' }}>
                                                                                        <i className="fa fa-check-circle HomeFilter-chk-procode" style={{ color: '#28C66D' }}></i>
                                                                                    </span>
                                                                                    <span className="form-control-icon form-control-space HomeFilter-conpo-iconchk" style={{ display: this.state.chkCode ? 'none' : 'block' }}>
                                                                                        <i className="fa fa-times-circle HomeFilter-chk-procode" style={{ color: 'red' }}></i>
                                                                                    </span> */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row row-inputs reset-row">
                                                                <div className="container-fluid">
                                                                    <div className="col-sm-12">
                                                                        <button type="submit" id="formSearchSubmit3" className="btn btn-submit ripple-effect btn-theme btn-red" onClick={this.handleSubmit}>{strings.book_now.search_car}</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                    </div>
                </div>

                <div className="wrapper">
                    <div className="content-area" style={{ background: "#fff" }}>
                        <section className="page-section sec-a">
                            <div className="container">
                                <div className="row">
                                    <div
                                        className="col-md-4 wow flipInY"
                                        data-wow-offset="70"
                                        data-wow-duration="1s"
                                    >
                                        <Link
                                            to={"/promotion"}
                                        >
                                            {this.state.homeObj.picture_promotion === "" ? <div style={{ textAlign: 'center' }}>ไม่พบข้อมูล</div> :
                                                <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">

                                                    <img src={this.state.homeObj.picture_promotion} alt="promo" />
                                                </div>
                                            }
                                        </Link>
                                    </div>
                                    <div
                                        className="col-md-4 wow flipInY"
                                        data-wow-offset="70"
                                        data-wow-duration="1s"
                                        data-wow-delay="200ms"
                                    >
                                        <Link
                                            to={"/faq"}

                                        >
                                            {this.state.homeObj.picture_faq === "" ? <div style={{ textAlign: 'center' }}>ไม่พบข้อมูล</div> :
                                                <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">

                                                    <img src={this.state.homeObj.picture_faq} alt="faq" />
                                                </div>
                                            }
                                        </Link>
                                    </div>
                                    <div
                                        className="col-md-4 wow flipInY"
                                        data-wow-offset="70"
                                        data-wow-duration="1s"
                                        data-wow-delay="400ms"
                                    >
                                        <Link
                                            to={"/enterprise"}
                                        >
                                            {this.state.homeObj.picture_enterprise === "" ? <div style={{ textAlign: 'center' }}>ไม่พบข้อมูล</div> :
                                                <div className="thumbnail thumbnail-featured no-border no-padding HomeFilter-transscale">

                                                    <img src={this.state.homeObj.picture_enterprise} alt="enterprise" />
                                                </div>
                                            }
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="content-area" style={{ background: "#fff" }}>
                        <section className="page-section">
                            <div className="container">
                                <div className="row">
                                    <div
                                        className="col-md-6 wow fadeInLeft"
                                        data-wow-offset="200"
                                        data-wow-delay="100ms"
                                        style={{ padding: "0 50px" }}
                                        id="conanimatetxt-our"
                                    >
                                        <h2 className="text-center font-blue">
                                            Our Service
                                        </h2>
                                        <p>
                                            Thai Rent A Car, We are the car rental
                                            service provider for over 40 years
                                        </p>
                                        <h5 className="text-center font-blue">
                                            Why choosing THAI RENT A CAR?
                                        </h5>
                                        <ul>
                                            <li>
                                                • We provided you more than 8,000
                                                brand new cars.
                                            </li>
                                            <li>
                                                • THAI RENT A CAR has 27 branches
                                                ready to support you throughout
                                                Thailand.
                                            </li>
                                            <li>
                                                • Replacement cars ready in service.
                                            </li>
                                            <li>
                                                • We standby 24 hours - 7 days to
                                                serves all cases.
                                            </li>
                                        </ul>
                                        <div className="col-sm-12 text-center">
                                            <button onClick={() => { window.scrollTo(0, 0); }} className="btn btn-submit ripple-effect btn-theme btn-red">
                                                Book now
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="col-md-6 wow fadeInRight"
                                        data-wow-offset="200"
                                        data-wow-delay="300ms"
                                    >
                                        {this.state.homeObj.picture_service === "" ? <div style={{ textAlign: 'center' }}>ไม่พบข้อมูล</div> :
                                            <div>
                                                <img src={this.state.homeObj.picture_service} width="100%" alt="service" />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        )
    }
}

