import React, { useState,useEffect } from 'react';
import {Modal, Button } from 'react-bootstrap';
import {strings} from "../../language/language";
import { Link } from 'react-router-dom';
var AlertProfileModal = (props) => {
  const [show, setShow] = useState(false);
const style = {
  button_alert : {
    margin: "0 20px",
    width: "160px"
  }
}

  const handleClose = () => {
    const $ = window.$;
    $("#alertProfilemodal").modal("hide");
  }
  const handleShow = () => setShow(true);
  
  // useEffect(() => {alert(1)},[])

  return (
    <div
        className="modal fade"
        id="alertProfilemodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        style={{ zIndex: "99999" }}
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header modal-txtheadcen">
              <button
                type="button"
                className="close LoginModal-conbtn-closemodal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title LoginModal-title" id="myModalLabel">
                        
              </h4>
            </div>
            <div className="modal-body" style={{textAlign:"center",padding:"40px 20px"}}>
            </div>

            <div className="modal-footer" style={{"textAlign":"center"}}>
            <Link onClick={()=>handleClose()} to="/profile"><button type="button" style={style.button_alert} className="btn btn-primary">{strings.modal.fill}</button></Link>
            <Link onClick={()=>handleClose()} to="/"><button type="button"  style={style.button_alert} className="btn btn-danger"  data-dismiss="modal">{strings.modal.nofill}</button></Link>
            
          </div>
          </div>
        </div>
      </div>
  );
}

export default AlertProfileModal;