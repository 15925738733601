import React, { useState, useEffect } from 'react';
import styles from './css/confirmModal.css';
let booking_temp = localStorage.getItem("booking_temp")


const ConfirmModal = (props) => {
    const handleClose = () => {
        const $ = window.$;
        $("#confirmmodal").modal("hide");
    }
    return (
        <>
            {(<div
                className="modal fade"
                id="confirmmodal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                style={{ zIndex: "99999" }}
            >
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header modal-txtheadcen">
                            <button
                                type="button"
                                className="close LoginModal-conbtn-closemodal"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title LoginModal-title" id="myModalLabel">

                            </h4>
                        </div>
                        <div className="modal-body" style={{ textAlign: "center", padding: "20px 20px" }}>
                            <table className="confirmTbl">
                                <tbody>
                                    <tr>
                                        <td>Pick up location :</td>
                                        <td>{props.branch["'" + props.outBranch + "'"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Pick-up date :</td>
                                        <td>{props.outDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Pick-up time :</td>
                                        <td>{props.outTime}</td>
                                    </tr>
                                    <tr>
                                        <td>Return location :</td>
                                        <td>{props.branch["'" + props.inBranch + "'"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Return date :</td>
                                        <td>{props.inDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Return time :</td>
                                        <td>{props.inTime}</td>
                                    </tr>

                                    <tr>
                                        <td>Total :</td>
                                        <td>{props.total} THB</td>
                                    </tr>
                                </tbody>




                            </table>
                            <p style={{ marginTop: "30px" }}>กรุณาตรวจสอบข้อมูลการจองให้ถูกต้องก่อนดำเนินการต่อ</p>

                        </div>
                        <div className="modal-footer" style={{ "textAlign": "center" }}>
                            <div className="row">
                                <div className="col-xs-6"><a onClick={() => { props.customerConfirm(); handleClose(); }}><button type="button" className="btn btn-primary btn-block">ดำเนินการ</button></a></div>
                                <div className="col-xs-6"><a onClick={() => handleClose()}><button type="button" className="btn btn-danger btn-block">ย้อนกลับ</button></a></div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>)}
        </>
    );
}

export default ConfirmModal