// Initial State
const initialState = {
    branch: [],
};
// Reducers (Modifies The State And Returns A New State)
const branchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_BRANCH':
            return {
                ...state,
                branch: action.payload
            }
        // Default
        default: {
            return state;
        }
    }
};
// Exports
export default branchReducer;