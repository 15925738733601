import React, { Component } from "react";
import "./css/OurService.css";
import axios from "axios";
import Filter from "../../Filter";
import ServiceComparisonTable from "./Table";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import ServiceBox from "../ServiceBox/ServiceBox";
import { strings } from "../../language/language";
import {
  BANNER_IMGAES,
  TITLE_TEXT,
  DESCRIPTION_TEXT,
  WHAT_WE_OFFER,
  CORPORATE_WHAT_WE_OFFER,
  SHORT_TERM_TABLE,
  LONG_TERM_TABLE,
  SHOW_BANNER_RENT_TYPE,
  SHOW_LUXURY_FLEET,
  SHOW_WHAT_WE_OFFER_RENT_TYPE,
  SHOW_TABLE_RENT_TYPE,
  SHOW_BOOK_NOW_RENT_TYPE,
} from "./config";

let curLang = localStorage.getItem("lang");

export default class OurService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rent_type: this.props.location.pathname.split("/")?.[2],
      banner: "",

      preFixPath: "",
      preFixImg: SITE_URL + "/",
      isButtonl: true, // true => bg Blue
      isButtonr: true, // true => bg Gray
      isCar: "images/OurService/Jaguar-car-img@2x.png",
      active_category: "Standard Fleet",
      active_model: "",
      active_type: "S",
      fleet_content: "",
      activeBookingForm: false,
      test: {
        a: "1",
        b: "2",
      },
    };
    // this.modelCarSelected = this.modelCarSelected.bind(this);
  }
  selectTypeFleet($category) {
    let fleet_content = this.state.fleet_content;
    if (!fleet_content) {
      return false;
    }
    let first_type = fleet_content[$category]
      ? Object.keys(fleet_content[$category])[0]
      : {};
    let first_model = fleet_content[$category]
      ? Object.keys(fleet_content[$category][first_type])[0]
      : {};

    this.setState({
      active_category: $category,
      active_model: fleet_content[$category]
        ? fleet_content[$category][first_type][first_model]
        : {},
    });
  }
  shortPeriodClick = () => {
    window.scrollTo(0, 0);
    this.setState({ activeBookingForm: true }, () => {
      setTimeout(() => {
        this.setState({ activeBookingForm: false });
      }, 1500);
    });
  };
  getButtonr() {
    if (this.state.isButtonr !== false) {
      this.setState({ isButtonr: !this.state.isButtonr });
      this.setState({ isButtonl: false });
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    this.fetchContents();
    this.fetchBanner();
  }
  modelCarSelected(title) {
    // alert(1);
    console.log(title);
  }

  fetchBanner() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/partner/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchContents() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/fleet?category=" + this.state.rent_type;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;

        const minFleet = Object.keys(data.content["Standard Fleet"]["S"])[0];
        this.setState({
          fleet_content: data.content,
          active_model: data.content["Standard Fleet"]["S"][minFleet] ?? "",
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  renderListLeft() {
    let fleet_content = this.state.fleet_content[this.state.active_category];

    if (!fleet_content) {
      return false;
    }

    return (
      <div className="list-group">
        {Object.keys(fleet_content).map((key, i) => {
          return (
            <a
              className={
                "list-group-item list-group-item-action " +
                (this.state.active_type === key ? "active" : "")
              }
              key={key}
              href={"#compact-" + key}
            >
              <div
                onClick={() =>
                  this.setState({
                    active_type: key,
                    active_model:
                      fleet_content[key][Object.keys(fleet_content[key])[0]],
                  })
                }
                className="ServiceContentA-btn-typecar ServiceContentA-btn-typecar-test"
              >
                <div className="ServiceContentA-confontsidel">
                  <div className="ServiceContentA-floatrbtn-typecar">
                    COMPACT Cars
                  </div>
                  <div>{key}</div>
                </div>
              </div>
              {Object.keys(fleet_content[key]).map((key2, x) => (
                <div
                  onClick={() =>
                    this.setState({ active_model: fleet_content[key][key2] })
                  }
                  key={fleet_content[key][key2].id}
                  className="sub-item"
                >
                  {fleet_content[key][key2].title}
                </div>
              ))}
            </a>
          );
        })}
      </div>
    );
  }

  renderListLeft2() {
    //  let fleet_content = this.state.test;
    //  console.log(fleet_content);
    // return (
    //  <ListGroup defaultActiveKey="#compact-a">
    //      { Object.keys(fleet_content).map(function(key,i) {
    //      <ListGroup.Item action href="#compact-a">
    //          <div className="ServiceContentA-btn-typecar ServiceContentA-btn-typecar-test">
    //              <div className="ServiceContentA-confontsidel">
    //                  <div className="ServiceContentA-floatrbtn-typecar">
    //                      COMPACT Cars
    //                  </div>
    //                  <div>SS</div>
    //              </div>
    //          </div>
    //      </ListGroup.Item>})}
    //  </ListGroup>);
  }

  render() {
    return (
      <div style={{ background: "#fff" }}>
        <Filter
          className={this.state.activeBookingForm ? "shadow_blue" : null}
        />

        <img
          style={{ width: "100%" }}
          src={BANNER_IMGAES[curLang][this.state.rent_type]}
          alt="Our Service Rent Type Banner"
        />

        {/* {SHOW_BANNER_RENT_TYPE.includes(this.state.rent_type) && (
          <div className="wrapper">
            <div className="content-area">
              <section className="page-section no-padding slider">
                <div className="container full-width">
                  <div className="main-slider">
                    <div
                      className="PartnerContentA-setbg-opacity"
                      style={{ background: "#000000" }}
                    />
                    <div
                      className="item slide3 ver3 PartnerContentA-conbg-head setbgheader-parallax"
                      style={{
                        background: `url("/images/OurService/promo_bg.jpg") no-repeat fixed 0 0/cover`,
                      }}
                    >
                      <div className="container">
                        <div className="row" style={{ marginTop: 0 }}>
                          <div className="col-md-6" style={{ marginTop: 0 }}>
                            <div className="OurService-car-left">
                              <img
                                src="/images/OurService/white-camry.png"
                                alt="White Car"
                              />
                            </div>
                          </div>
                          <div className="col-md-6" style={{ marginTop: 0 }}>
                            <div className="OurService-car-right">
                              <h3>
                                {strings.service[this.state.rent_type] ??
                                  "Rental Car"}
                              </h3>
                              <a
                                href="tel:1647"
                                className="button"
                                style={{ fontWeight: "bold", fontSize: "20px" }}
                              >
                                <i
                                  class="fa fa-phone"
                                  style={{ fontSize: "24px" }}
                                ></i>{" "}
                                1647
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        )} */}

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section">
              <div className="container-fluid Home-fluid">
                <div className="row" style={{ marginBottom: "50px" }}>
                  <div
                    className="col-md-12 wow fadeInLeft"
                    data-wow-offset="200"
                    data-wow-delay="100ms"
                    id="conanimatetxt-our"
                  >
                    <div className="section-header">
                      {TITLE_TEXT[this.state.rent_type]}
                    </div>

                    <div
                      className="what-we-offer-sub-title"
                      dangerouslySetInnerHTML={{
                        __html: DESCRIPTION_TEXT[this.state.rent_type],
                      }}
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="why-title">
                    {this.state.rent_type === "chaffeur"
                      ? strings.service.offer_title
                      : strings.service.why_thai_rent}
                  </div>
                </div>

                {SHOW_WHAT_WE_OFFER_RENT_TYPE.includes(
                  this.state.rent_type
                ) && (
                  <div className="row">
                    {(this.state.rent_type === "enterprise"
                      ? CORPORATE_WHAT_WE_OFFER
                      : WHAT_WE_OFFER
                    ).map((item, idx) => (
                      <div
                        className="col-sm-12 col-md-4"
                        key={`what-we-offer-${idx}`}
                      >
                        <div className="what-we-offer-button">
                          <img
                            src={item.img}
                            alt={strings.service[item.title]}
                          />

                          <div
                            className="title"
                            dangerouslySetInnerHTML={{
                              __html: strings.service[item.title],
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area ServiceHeader-bg-default">
            <div className="container-fluid Home-fluid">
              <div className="ServiceHeader-headarea-content">
                <button
                  onClick={() => (
                    this.selectTypeFleet("Standard Fleet"),
                    this.setState({ active_type: "SS" })
                  )}
                  className="ServiceHeader-relink-btn"
                >
                  <span
                    className="ServiceHeader-buildbtn-headl"
                    style={{
                      background:
                        this.state.active_category === "Standard Fleet"
                          ? "#055cab"
                          : "#58595b",
                      borderTopRightRadius: !SHOW_LUXURY_FLEET.includes(
                        this.state.rent_type
                      )
                        ? "25px"
                        : 0,
                      borderBottomRightRadius: !SHOW_LUXURY_FLEET.includes(
                        this.state.rent_type
                      )
                        ? "25px"
                        : 0,
                    }}
                  >
                    STANDARD FLEET
                  </span>
                </button>
                {SHOW_LUXURY_FLEET.includes(this.state.rent_type) && (
                  <button
                    onClick={() => (
                      this.selectTypeFleet("Luxury Fleet"),
                      this.setState({ active_type: "MBM" })
                    )}
                    className="ServiceHeader-relink-btn"
                  >
                    <span
                      className="ServiceHeader-buildbtn-headr"
                      style={{
                        background:
                          this.state.active_category === "Luxury Fleet"
                            ? "#055cab"
                            : "#58595b",
                      }}
                    >
                      LUXURY FLEET
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper" style={{ marginBottom: "30px" }}>
          <div className="content-area ServiceContentA-bg-default">
            <div className="container-fluid Home-fluid">
              <div className="row ServiceContentA-border-default">
                <div className="col-md-3 ServiceContentA-remart ServiceContentA-setmart-mobile">
                  {this.renderListLeft()}
                </div>
                <div className="col-md-6 ServiceContentA-remart ServiceContentA-remart-imgcontent">
                  <div className="ServiceContentA-consubimg-cenmo">
                    {this.state.active_model &&
                    this.state.active_model?.gallery?.[0] ? (
                      <img
                        src={this.state.active_model.gallery[0].pic}
                        width="100%"
                        alt="Car"
                      />
                    ) : (
                      ""
                    )}

                    {/*  <img
                                            className="ServiceContentA-conpo-subcar ServiceContentA-conpo-subcar1"
                                            src={this.state.preFixImg+"images/OurService/Jaguar-sub1-car-2@2x.png"}
                                            alt="Car-Detail"
                                        />
                                   
                                        <img
                                            className="ServiceContentA-conpo-subcar ServiceContentA-conpo-subcar2"
                                            src={this.state.preFixImg+"images/OurService/Jaguar-sub2-car-2@2x.png"}
                                            alt="Car-Detail"
                                        />
                                        <img
                                            className="ServiceContentA-conpo-subcar ServiceContentA-conpo-subcar3"
                                            src={this.state.preFixImg+"images/OurService/Jaguar-sub3-car-2@2x.png"}
                                            alt="Car-Detail"
                                        />
                                        <img
                                            className="ServiceContentA-conpo-subcar ServiceContentA-conpo-subcar4"
                                            src={this.state.preFixImg+"images/OurService/Jaguar-sub4-car-2@2x.png"}
                                            alt="Car-Detail"
                                        />*/}
                  </div>
                </div>
                <div className="col-md-3 ServiceContentA-remart">
                  <div className="ServiceContentA-setmar-sider ServiceContentA-txtcen-mo">
                    <div className="ServiceContentA-marb-headsider">
                      <span className="ServiceContentA-span-blue ServiceContentA-conhead-font">
                        {this.state.active_model?.title ?? ''}
                      </span>
                    </div>
                    <div>
                      <table className="ServiceContentA-confont-tbl">
                        <tbody>
                          <tr>
                            <td className="ServiceContentA-con-tblth">Size</td>
                            <td className="ServiceContentA-con-tbltdc">:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.size  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Type</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.type  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Engine</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.engine  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Transmission</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.tranmission  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Door</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.door  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Seats</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.seat  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Air Condition</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.air_condition  ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Music System</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.radio_type ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Luggage</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.luggage ?? ''}
                            </td>
                          </tr>
                          <tr>
                            <td>Similar Car</td>
                            <td>:</td>
                            <td className="ServiceContentA-span-gray">
                              {this.state.active_model?.similar_car ?? ''}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row ServiceContentA-setmarb-cententfoot">
                <div className="col-md-6 ServiceContentA-remart-mo">
                  <div
                    onClick={() => this.shortPeriodClick()}
                    className="ServiceContentA-conbg-travel"
                    style={{
                      backgroundImage:
                        "url(" +
                        this.state.preFixImg +
                        "images/OurService/travel-link-img.jpg)",
                    }}
                  >
                    <div>
                      <div className="ServiceContentA-setbg-opacity" />
                      <div className="ServiceContentA-contxt-cententfoot">
                        <div className="ServiceContentA-setpadt-mo">
                          <img
                            className="ServiceContentA-conlogol-travel"
                            src={
                              this.state.preFixImg +
                              "images/OurService/travel-logo.png"
                            }
                            alt="Travel"
                          />
                          <span className="ServiceContentA-conspan-disinblock">
                            รถเช่ารถระยะสั้น
                            <br />
                            Travel & Leisure
                          </span>
                        </div>
                        <div className="ServiceContentA-contxt-travel" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <Link to={this.state.preFixPath + "/enterprise"}>
                    <div
                      className="ServiceContentA-conbg-travel"
                      style={{
                        backgroundImage: `url('${this.state.preFixImg}images/OurService/travel-link-img2.jpg')`,
                      }}
                    >
                      <div>
                        <div className="ServiceContentA-setbg-opacity" />
                        <div className="ServiceContentA-contxt-cententfoot">
                          <div>
                            <img
                              className="ServiceContentA-conlogor-corporate"
                              src={
                                this.state.preFixImg +
                                "images/OurService/corporate-logo.png"
                              }
                              alt="Corporate"
                            />
                            <span className="ServiceContentA-conspan-disinblock">
                              รถเช่าระยะยาว
                              <br />
                              Corporate Solution
                            </span>
                          </div>
                          <div className="ServiceContentA-contxt-travel" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {SHOW_TABLE_RENT_TYPE.includes(this.state.rent_type) && (
          <div className="wrapper" style={{ marginBottom: "50px" }}>
            <div className="content-area ServiceHeader-bg-default">
              <div className="container-fluid Home-fluid">
                <ServiceComparisonTable
                  items={
                    this.state.rent_type === "short-term"
                      ? SHORT_TERM_TABLE
                      : LONG_TERM_TABLE
                  }
                />
              </div>
            </div>
          </div>
        )}

        {SHOW_BOOK_NOW_RENT_TYPE.includes(this.state.rent_type) && (
          <div className="wrapper" style={{ marginBottom: "30px" }}>
            <div className="content-area ServiceHeader-bg-default">
              <div className="container-fluid Home-fluid text-center">
                <h3 className="OurService-book-now-text">
                  {this.state.rent_type === "enterprise"
                    ? strings.service.book_now
                    : strings.service.book_now_chaffeur}
                </h3>
                <h3
                  className="OurService-book-now-text"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {strings.service.book_contact}
                </h3>
                {/* {this.state.rent_type !== "enterprise" && (
                  <button className="OurService-book-now-button">
                    {strings.service.contact_us}
                  </button>
                )} */}
              </div>
            </div>
          </div>
        )}

        <div className="wrapper" style={{ paddingBottom: "40px" }}>
          <div className="content-area ServiceHeader-bg-default">
            <div className="container-fluid Home-fluid">
              <ServiceBox />
            </div>
          </div>
        </div>

        {/* <div
          className="wrapper"
          style={{ paddingBottom: "50px", backgroundColor: "#ffffff" }}
        >
          <div className="content-area ServiceHeader-bg-default">
            <div className="container-fluid Home-fluid">
              <div className="row">
                <div
                  className="col-xs-12 col-md-3"
                  style={{ fontWeight: "bold" }}
                >
                  Short-term Rental
                </div>
                <div className="col-xs-12 col-md-9">
                  Short-term (Self drive) car rental services (Daily - Weekly -
                  Monthly)
                </div>

                <div
                  className="col-xs-12 col-md-3"
                  style={{ fontWeight: "bold" }}
                >
                  Long-term Rental
                </div>
                <div className="col-xs-12 col-md-9">
                  Long-term (Self drive) car rental services (Yearly)
                </div>

                <div
                  className="col-xs-12 col-md-3"
                  style={{ fontWeight: "bold" }}
                >
                  Corporate Car Rental
                </div>
                <div className="col-xs-12 col-md-9">Corporate Solution</div>

                <div
                  className="col-xs-12 col-md-3"
                  style={{ fontWeight: "bold" }}
                >
                  Chauffeur Drive Services
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}
