import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import "../LoginModal/css/LoginModal.css";
import "./css/Login.css";

import Filter from "../../Filter";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";
import FacebookLogin from 'react-facebook-login';

import ForgotModal from "../ForgotPassword/ForgotModal";


const loginApi = PREFIX_ADDRESS + "api/member/login";
const loginurl = PREFIX_ADDRESS + "api/line/login";
const apiFbUrl = PREFIX_ADDRESS + "api/facebook/login";
const apiUrl = PREFIX_ADDRESS + "api/member/details";

let token = localStorage.login_token;
// const loginApi = "http://localhost:8000/api/member/login";

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            preFixImg: SITE_URL + "/",
            email: "",
            password: "",
        };
        // this.loginHandle = this.loginHandle.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    responseFacebook = (response) => {

        console.log(response);
        if (response.accessToken) {
            axios
                .post(apiFbUrl, { token: response.accessToken })
                .then(res => {
                    const { data } = res;
                    // console.log(data);
                    localStorage.setItem("login_token", data.success.token)
                    token = data.success.token;
                    this.getData();

                })
                .catch(errs => {
                    console.error(errs);
                });
        }
    }

    // loginHandle(e) {
    //     console.log(branchApi);

    // }

    loginHandle = (e) => {

        axios
            .post(loginApi, {
                email: this.state.email,
                password: this.state.password
            }, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                }
            })
            .then(res => {
                const { data } = res;
                // console.log(data)
                localStorage.setItem("login_token", data.success.token)
                token = data.success.token;
                this.getData();


            })
            .catch(errs => {
                handleError("เข้าสู่ระบบไม่ถูกต้อง")
                console.error(errs);
            });

    }


    getData = (e) => {

        if (token) {
            axios
                .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    console.log(data)
                    localStorage.setItem("member_name", data.success.firstname);
                    // this.props.history.push({pathname:"/profile",state: {}});
                    window.location.replace(SITE_URL + "/profile");
                })
                .catch(errs => {
                    console.error(errs);
                    this.props.history.push({ pathname: "/login", state: {} });
                });
        }

    }
    render() {


        return (
            <div style={{ background: "#fff" }}>
                <Filter />
                <div className="wrapper">
                    <div className="content-area">
                        <div className="container">



                            <div className="row">
                                <div className="col-md-6 col-md-push-3" style={{ margin: "80px 0" }}>
                                    <div className="registerHeadline"><h1>Login</h1></div>

                                    <div className="LoginModal-set-container Regis-reset-contianer">
                                        <form>
                                            <div>


                                                <div className="input-group LoginModal-con-inpute">
                                                    <span
                                                        className="input-group-addon LoginModal-logo-email"
                                                        id="basic-addon1"
                                                    >
                                                        <img
                                                            className="LoginModal-logomail-w"
                                                            src="images/LoginModal/logo-input-mail@2x.png"
                                                            alt="Login"
                                                        />
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control LoginModal-con-border"
                                                        placeholder={strings.log_in.email}
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            this.setState({
                                                                email: value
                                                            })
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <div className="input-group LoginModal-con-inputp">
                                                    <span
                                                        className="input-group-addon LoginModal-logo-pass"
                                                        id="basic-addon1"
                                                    >
                                                        <img
                                                            className="LoginModal-logopass-w"
                                                            src="images/LoginModal/logo-input-pass@2x.png"
                                                            alt="Pass"
                                                        />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        className="form-control LoginModal-con-border"
                                                        placeholder={strings.log_in.password}
                                                        aria-describedby="basic-addon1"
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            this.setState({
                                                                password: value
                                                            })
                                                        }
                                                        }
                                                    />
                                                </div>
                                                <div className="LoginModal-con-forgotpass">
                                                    <Link style={{ color: "#2699fb" }} to={this.state.preFixPath + "/register"}>Register</Link>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button
                                                        className="Login-relink-btn"
                                                        id="myInput2"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#forgotmodal"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                        onClick={this.setClass}
                                                    >
                                                        <span>{strings.log_in.forgot}</span>
                                                    </button>
                                                </div>
                                                <div className="RegisModal-setmar-signin">
                                                    <button
                                                        // aria-label="Close"
                                                        type="button"
                                                        className="btn btn-danger LoginModal-con-signin"
                                                        data-dismiss="modal"
                                                        onClick={this.loginHandle}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#fff"
                                                            }}
                                                        >
                                                            SIGN IN
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-5">
                                                        <div className="LoginModal-hr-l" />
                                                    </div>
                                                    <div className="col-xs-2">
                                                        <div className="LoginModal-txt-hr text-center">{strings.log_in.or}</div>
                                                    </div>
                                                    <div className="col-xs-5">
                                                        <div className="LoginModal-hr-r" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12 RegisModal-remart-logf">
                                                        <FacebookLogin
                                                            appId="181006856480214"
                                                            autoLoad={false}
                                                            fields="name,email,picture"
                                                            cssClass="LoginModal-build-btnF"
                                                            callback={this.responseFacebook} />
                                                    </div>
                                                </div>


                                                <div className="row">
                                                    <div className="col-xs-12 RegisModal-remart-logl">
                                                        <a href={loginurl}>
                                                            <div className="LoginModal-build-btnL">
                                                                <div className="LoginModal-setfloatab-logo">
                                                                    <img
                                                                        className="LoginModal-conw-logoL"
                                                                        src="images/LoginModal/logo-line.png"
                                                                        alt="Login-Line"
                                                                    />
                                                                </div>
                                                                <div className="LoginModal-contxt-logL">
                                                                    <span>{strings.log_in.log_in_line}</span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
                <ForgotModal />
            </div >
        );
    }
}
