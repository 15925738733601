import React, { Component } from "react";
import { Link } from "react-router-dom";
import Filter from "../../Filter";
import axios from "axios";

import parse from "html-react-parser";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import "./css/styles.css";

let curLang = localStorage.getItem("lang");

export default class PartnerDetail extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;

    this.state = {
      path: "",
      // promoDeApi: target_url,
      promoDeApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/promotion/" +
        this.props.match.params.id,
      promoDeObj: {
        picture_cover: "",
        title: "",
        abstact: "",
      },
      promoDeRelatedApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/promotion?itemPerPage=5&lang=th&page=1",
      promoDeRelatedObj: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const $ = window.$;
    this._isMounted = true;
    $("#preloader").show();
    this.fetchPromoDetails();
    this.fetchPromoRelate();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        promoDeApi:
          PREFIX_CONTENT_ADDRESS +
          "api/content/promotion/" +
          nextProps.match.params.id,
      },
      function () {
        this.fetchPromoDetails();
        window.scrollTo(0, 0);
      }
    );
  }

  fetchPromoDetails() {
    const $ = window.$;
    const query = new URLSearchParams(this.props.location.search);
    var lang = query.get("lang");

    if (lang !== null) {
      localStorage.setItem("lang", lang);
    }
    // ถ้าใน local มีค่า
    if (localStorage.getItem("lang") !== null) {
      lang = localStorage.getItem("lang");
    }

    var promoDeApi = this.state.promoDeApi + "?lang=" + lang;

    const apiurl = promoDeApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;

        this.setState({
          promoDeObj: data,
        });
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  fetchPromoRelate() {
    let lang = localStorage.getItem("lang");

    let promoDeApi = this.state.promoDeRelatedApi + "?lang=" + lang;
    console.log("Start fetchPromoRelate");
    axios
      .get(promoDeApi)
      .then((res) => {
        const { data } = res;
        console.log(data);

        this.setState({
          promoDeRelatedObj: data.content,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  RenderRelated() {
    let promotion_id = this.props.match.params.id;
    let relate_promotions = this.state.promoDeRelatedObj;
    let j = 0;
    return Object.keys(relate_promotions).map(function (key, i) {
      let item = relate_promotions[key];
      let res = null;
      if (j < 4 && parseInt(item.post_id) !== parseInt(promotion_id)) {
        j++;
        res = (
          <div
            key={i}
            className="col-xs-12 col-lg-3 set-pad-rerelated b1 img-mobile"
          >
            <div className="promotion-card-container">
              <img src={item.picture_cover} alt="Promotion" />

              <div className="content">
                <div className="promotion-card-header">{item.title}</div>
                <Link
                  to={{
                    pathname: "/promotion/" + item.post_id,
                  }}
                >
                  <button className="promotion-card-button">
                    อ่านเพิ่มเติม
                  </button>
                </Link>
              </div>
            </div>
          </div>
        );
      }
      return res;
    });
  }

  render() {
    return (
      <div>
        <Filter />

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-a">
              <div className="container-fluid Promotion-Detail-fluid">
                <div className="row">
                  <div className="col-sm-8">
                    {this.state.promoDeObj.picture_content === "" ||
                    this.state.promoDeObj.picture_content === null ? (
                      "ไม่พอข้อมูลรูปภาพ"
                    ) : (
                      <img
                        src={this.state.promoDeObj.picture_content}
                        width="100%"
                        alt="Promotion"
                      />
                    )}
                  </div>
                  <div className="col-sm-4 set-pad">
                    <div className="info">
                      <div className="info-header">
                        <h3 className="set-pad-info">
                          <strong>
                            {this.state.promoDeObj.title === "" ||
                            this.state.promoDeObj.title === null
                              ? ""
                              : this.state.promoDeObj.title}
                          </strong>
                        </h3>
                      </div>
                      <div className="content set-pad-info">
                        <div className="info-content-a">
                          <h5>
                            <strong>
                              {this.state.promoDeObj.abstact === "" ||
                              this.state.promoDeObj.abstact === null
                                ? ""
                                : this.state.promoDeObj.abstact}
                            </strong>
                          </h5>
                          <div className="control-p">
                            {this.state.promoDeObj.detail === "" ||
                            this.state.promoDeObj.detail === null
                              ? ""
                              : parse(
                                  "" + this.state.promoDeObj.detail === "" ||
                                    this.state.promoDeObj.detail === null ||
                                    this.state.promoDeObj.detail === undefined
                                    ? ""
                                    : this.state.promoDeObj.detail + ""
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section page-a page-pad-b">
              <div className="container-fluid Promotion-Detail-fluid">
                <div className="row related-hr">
                  <div className="col-sm-4 hr-left" />
                  <div
                    className="col-sm-4 related-title"
                    style={{ margin: "0" }}
                  >
                    <strong>{strings.promotion.related}</strong>
                  </div>
                  <div className="col-sm-4 hr-right" />
                </div>
                <div className="row related-img">{this.RenderRelated()}</div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
