import React, { Component } from "react";
import axios from "axios";
import "./css/About.css";
import Filter from "../../Filter";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import { strings } from "../../language/language";
import { isMobile } from "react-device-detect";

let curLang = localStorage.getItem("lang");
export default class AboutContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: "",
      preFixPath: "",
      aboutApi:
        PREFIX_CONTENT_ADDRESS + "api/content/about_us/banner?lang=" + curLang,
      aboutObj: {},
      content: "",
      contact: "",
      aboutUsTopRightContentHeight: 0,
    };
  }
  // componentWillMount() {
  //     console.log("componentWillMount");

  //     window.scrollTo(0, 0);

  //     const local = window.location.href.replace("http://", "");
  //     const prefixLocal = local.split("/");
  //     const prefiximg = "http://" + prefixLocal[0] + "/";

  //     if (prefixLocal[0] !== "127.0.0.1:8000") {
  //         this.setState({ preFixPath: "/~thairent/public" });
  //         this.setState({ preFixImg: prefiximg });
  //     } else {
  //         console.log("---");

  //         this.setState({ preFixImg: prefiximg });
  //     }

  //     this.fetchAbout();
  // }
  componentDidMount() {
    window.scrollTo(0, 0);

    const prefiximg = SITE_URL + "/";
    this.setState({
      preFixImg: prefiximg,
    });

    this.fetchBanner();
    this.fetchContent();
  }

  fetchContent() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/about_us?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;

        this.setState({
          content: data.content["_65"] ? data.content["_65"] : "",
          contact: data.content["_81"] ? data.content["_81"].detail : "",
          bannerTxt: data.content["_121"] ? data.content["_121"].detail : "",
          aboutUsTopRightContentHeight:
            document.getElementById("left-about-us-img").offsetHeight,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }
  fetchBanner() {
    const apiurl = this.state.aboutApi;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);
        this.setState({
          aboutObj: data,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  render() {
    return (
      <div>
        <Filter />

        <div className="wrapper">
          <div className="content-area">
            {/* <!-- PAGE --> */}
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="AboutContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.aboutObj.banner}")`,
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="container">
                      <div className="container-title AboutContentA-contain-default">
                        <h1>
                          <strong>{strings.about_us.about_us}</strong>
                        </h1>
                        <div
                          className="AboutContentA-confont-spanhead"
                          dangerouslySetInnerHTML={{
                            __html: this.state.bannerTxt,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="wrapper">
          <div className="content-area">
            <div className="container-fluid">
              <div
                className="row AboutContentB-marb-infoa"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginBottom: 0,
                  backgroundColor: "#ffffff",
                }}
              >
                <div
                  className="col-md-6 AboutContentB-repadrl-mo"
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    marginTop: 0,
                  }}
                >
                  <div
                    className="AboutContentB-setpadtb-imgdefault"
                    style={{ padding: 0 }}
                  >
                    <img
                      id="left-about-us-img"
                      alt="Car US"
                      className="AboutContentB-conimg-sidel"
                      // src={this.state.content.picture_cover}
                      src={"/images/AboutUs/about-1.jpg"}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "50px",
                    paddingLeft: isMobile ? "30px" : "60px",
                    paddingRight: isMobile ? "30px" : "60px",
                    marginTop: 0,
                    height: isMobile
                      ? undefined
                      : this.state.aboutUsTopRightContentHeight,
                    overflowY: "scroll",
                  }}
                  className="col-md-6 AboutContentB-repad-sider"
                  dangerouslySetInnerHTML={{
                    __html: this.state.content.detail,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="content-area" style={{ background: "#fff" }}>
            <div className="container-fluid">
              <div
                className="row AboutContentB-marb-infob"
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div
                  style={{
                    paddingLeft: isMobile ? "30px" : "60px",
                    paddingRight: isMobile ? "30px" : "60px",
                  }}
                  className="AboutContentB-confont-contact col-md-6"
                  dangerouslySetInnerHTML={{ __html: this.state.contact }}
                />

                <div className="col-md-6" style={{ marginTop: 0, padding: 0 }}>
                  <img
                    alt="logo"
                    // src={
                    //   this.state.preFixImg + "images/AboutUs/logo-phone@2x.png"
                    // }
                    src="/images/AboutUs/about-2.jpg"
                    style={{ width: "100%" }}
                  />
                </div>

                <div
                  className="col-md-6 AboutContentB-addmart-mo"
                  style={{ display: "none" }}
                >
                  <div className="AboutContentB-setpad-sider">
                    <div className="AboutContentB-setfloat-infocarprice AboutContentB-setmarb-infocarpricea">
                      <img
                        alt="logo"
                        className="AboutContentB-conwlogo-phonenum"
                        src={
                          this.state.preFixImg +
                          "images/AboutUs/logo-phone-number@2x.png"
                        }
                      />
                    </div>
                    <div className="AboutContentB-confont-contact AboutContentB-addmart">
                      <ul className="AboutContentB-conpaddl-contactb">
                        <li>
                          <span className="AboutContentB-splittxt-mo">
                            สามารถดาวน์โหลด
                          </span>
                          Application icall 1647
                        </li>
                        <li>
                          <span className="AboutContentB-splittxt-mo">
                            เพื่อความสะดวกในการติดต่อ
                          </span>
                          ไทยเร้นท์อะคาร์ In One Touch
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
