import React, { Component } from "react";
import "./css/Table.css";
import { strings } from "../../language/language";

export default class ServiceComparisonTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.items ?? [],
    };
  }

  renderInfoItem(item) {
    if (typeof item === "boolean") {
      return (
        <div className="info-item">
          <img
            src={
              item === true
                ? "/images/WhatWeOffer/check.png"
                : "/images/WhatWeOffer/clear.png"
            }
            alt="Service Comparison Table Info Item Check"
          />
        </div>
      );
    } else if (typeof item === "string") {
      return (
        <div
          className="info-item"
          dangerouslySetInnerHTML={{ __html: item }}
        ></div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="service-comparison-table">
        <div>
          <div
            className="label-header"
            dangerouslySetInnerHTML={{
              __html: strings.ourservice_table.type_of_insurance,
            }}
          ></div>
          <div className="info-header">
            <span className="title">LDW</span>
            <span className="description">{strings.ourservice_table.ldw}</span>
          </div>
          <div className="info-header">
            <span className="title">SLDW</span>
            <span className="description">{strings.ourservice_table.sldw}</span>
          </div>
          <div className="info-header">
            <span className="title">PLDW</span>
            <span className="description">{strings.ourservice_table.pldw}</span>
          </div>
        </div>

        {this.state.items.map((item, index) => (
          <div key={`service-comparison-table-${index}`}>
            <div className="label-item">{item.label}</div>
            {this.renderInfoItem(item.ldw)}
            {this.renderInfoItem(item.sldw)}
            {this.renderInfoItem(item.pldw)}
          </div>
        ))}
      </div>
    );
  }
}
