// Initial State
const initialState = {
    booking: [],
    count: 0
};
// Reducers (Modifies The State And Returns A New State)
const settingReducer = (state = initialState, action) => {
    switch (action.type) {
        // Login
        case 'LOGIN': {
            return {
                // State
                ...state,
                // Redux Store
                loggedIn: action.trueFalse,
            }
        }
        case 'FETCH_SETTING':
            return {
                ...state,
                booking: action.payload
            }
        case 'INCREMENT_COUNTER':
            // console.log(state.count)
            return {
                ...state,
                count: state.count + 1
            }
        // Default
        default: {
            return state;
        }
    }
};
// Exports
export default settingReducer;