import React, { Component } from "react";
import "./css/Register.css";
import Filter from "../../Filter";
import axios from "axios";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";
import FacebookLogin from 'react-facebook-login';
let yup = require('yup');
const loginurl = PREFIX_ADDRESS + "api/line/login";

export default class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertRequired: false,
      preFixPath: "",
      preFixImg: SITE_URL + "/",
      formRegister: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        // country:"",
        // nationality:"",
      }
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const query = new URLSearchParams(this.props.location.search);
    let error_msg = query.get("error");
    if (error_msg === "email_permission") {
      handleError("กรุณาอนุญาตให้ใช้ข้อมูลอีเมล์ในการ Register");
    }

  }
  registerHandle = async () => {

    let profileFormData = {
      email: this.state.formRegister.email,
      firstname: this.state.formRegister.firstname,
      lastname: this.state.formRegister.lastname,
    }
    let schema = yup.object().shape({
      email: yup.string().email().required(),
      firstname: yup.string().required(),
      lastname: yup.string().required(),
    });

    const valid = await schema.isValid(profileFormData);
    if (!valid) {
      this.setState({ alertRequired: true })
      handleError(strings.log_in.required);
      return false;
    }


    var bodyFormData = new FormData();
    for (let key in profileFormData) {
      bodyFormData.append(key, profileFormData[key]);
    }
    bodyFormData.set('password', this.state.formRegister.password);

    axios.post(`${PREFIX_ADDRESS}api/member/register`, bodyFormData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
      }
    }).then(res => {
      const { data } = res;
      if (data.success) {
        let token = data.success.token
        localStorage.setItem("login_token", token);
        // this.props.history.push({pathname:"/profile",state: {}});
        window.location.replace(SITE_URL + "/profile");
      }
    }).then(() => {
      setTimeout(() => {
      }, 100);
    }).catch(function (err) {
      console.log(err.response.data);
      if (err.response.data.error.email) {
        handleError(err.response.data.error.email);
      }
      else {
        handleError("Handling Error");
      }

    });

  }

  render() {


    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <div className="container">



              <div className="row">
                <div className="col-md-6 col-md-push-3" style={{ margin: "80px 0" }}>
                  <div className="registerHeadline"><h1>Register</h1></div>

                  <div className="LoginModal-set-container Regis-reset-contianer">
                    <form>
                      <div>
                        <div className="input-group LoginModal-con-inpute">
                          <span
                            className="input-group-addon LoginModal-logo-email"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logomail-w"
                              src="images/Register/logo-profile@2x.png"
                              alt="Register"
                            />
                          </span>
                          <input
                            type="text"
                            className={"form-control LoginModal-con-border" + (this.state.alertRequired && !this.state.formRegister.firstname ? " bd-red" : "")}
                            placeholder={strings.log_in.first_name}
                            aria-describedby="basic-addon1"
                            onChange={(e) => { this.setState({ formRegister: { ...this.state.formRegister, firstname: e.target.value } }); }}
                          />
                        </div>
                        <div className="input-group LoginModal-con-inpute">
                          <span
                            className="input-group-addon LoginModal-logo-email"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logomail-w"
                              src="images/Register/logo-profile@2x.png"
                              alt="Login"
                            />
                          </span>
                          <input
                            type="text"
                            className={"form-control LoginModal-con-border" + (this.state.alertRequired && !this.state.formRegister.lastname ? " bd-red" : "")}
                            placeholder={strings.log_in.last_name}
                            aria-describedby="basic-addon1"
                            onChange={(e) => { this.setState({ formRegister: { ...this.state.formRegister, lastname: e.target.value } }); }}
                          />
                        </div>
                        <div className="input-group LoginModal-con-inpute">
                          <span
                            className="input-group-addon LoginModal-logo-email"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logomail-w"
                              src="images/LoginModal/logo-input-mail@2x.png"
                              alt="Login"
                            />
                          </span>
                          <input
                            type="text"
                            className={"form-control LoginModal-con-border" + (this.state.alertRequired && !this.state.formRegister.email ? " bd-red" : "")}
                            placeholder={strings.log_in.email}
                            aria-describedby="basic-addon1"
                            onChange={(e) => { this.setState({ formRegister: { ...this.state.formRegister, email: e.target.value } }); }}
                          />
                        </div>
                        <div className="input-group LoginModal-con-inputp">
                          <span
                            className="input-group-addon LoginModal-logo-pass"
                            id="basic-addon1"
                          >
                            <img
                              className="LoginModal-logopass-w"
                              src="images/LoginModal/logo-input-pass@2x.png"
                              alt="Pass"
                            />
                          </span>
                          <input
                            type="password"
                            className={"form-control LoginModal-con-border" + (this.state.alertRequired && !this.state.formRegister.password ? " bd-red" : "")}
                            placeholder={strings.log_in.password}
                            aria-describedby="basic-addon1"
                            onChange={(e) => { this.setState({ formRegister: { ...this.state.formRegister, password: e.target.value } }); }}
                          />
                        </div>




                        <div className="RegisModal-setmar-signin">
                          <button
                            // aria-label="Close"
                            type="button"
                            className="btn btn-danger LoginModal-con-signin"
                            data-dismiss="modal"
                            onClick={this.registerHandle}
                          >
                            <span
                              style={{
                                color: "#fff"
                              }}
                            >
                              REGISTER
                            </span>
                          </button>
                        </div>
                        <div className="row">
                          <div className="col-xs-5">
                            <div className="LoginModal-hr-l" />
                          </div>
                          <div className="col-xs-2">
                            <div className="LoginModal-txt-hr">{strings.log_in.or}</div>
                          </div>
                          <div className="col-xs-5">
                            <div className="LoginModal-hr-r" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 RegisModal-remart-logf">
                            <FacebookLogin
                              appId="181006856480214"
                              autoLoad={false}
                              fields="name,email,picture"
                              cssClass="LoginModal-build-btnF"
                              callback={this.responseFacebook} />
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-xs-12 RegisModal-remart-logl">
                            <a href={loginurl}>
                              <div className="LoginModal-build-btnL">
                                <div className="LoginModal-setfloatab-logo">
                                  <img
                                    className="LoginModal-conw-logoL"
                                    src="images/LoginModal/logo-line.png"
                                    alt="Login-Line"
                                  />
                                </div>
                                <div className="LoginModal-contxt-logL">
                                  <span>{strings.log_in.log_in_line}</span>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div >
    );
  }
}
