import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Helmet from "react-helmet";
import Filter from "../../Filter";
import axios from "axios";
import { PREFIX_CONTENT_ADDRESS } from "../../constants";
import { strings } from "../../language/language";
// import socialBanner from "../../images/Page1-02@2x.png";
let curLang = localStorage.getItem("lang");

export default class ProContentA extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      Loading: false,
      preFixPath: "",
      promoApi:
        PREFIX_CONTENT_ADDRESS +
        "api/content/promotion?itemPerPage=6&lang=" +
        curLang,
      promoObj: [],
      banner: "",
      totalPage: [],
      totalPageForPagi: 0,
      currentPage: 1,
      disBtnPrev: true,
      disBtnNext: false,
      minPage: false,
      maxPage: false,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }
  componentDidMount() {
    // const $ = window.$;
    this._isMounted = true;
    window.scrollTo(0, 0);
    // $("#preloader").show();
    // this.fetchPromotion();
    // console.log(this.props.location.search);

    const query = new URLSearchParams(this.props.location.search);

    var currentUrlPage = query.get("page");
    if (currentUrlPage === null) {
      // console.log("null");

      currentUrlPage = 1;
    }

    this.handlePage(parseInt(currentUrlPage));
    this.fetchBanner();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  fetchBanner() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/promotion/banner?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        console.log(data);
        this.setState({
          banner: data.banner,
        });
      })
      .catch((errs) => {
        console.log(errs);
      });
  }

  handlePage(id, str) {
    if (id === 1) {
      this.props.history.push({
        pathname: "/promotion",
      });
    } else {
      this.props.history.push({
        pathname: "/promotion",
        search: "?page=" + id,
      });
    }
    // if (id !== 1) {
    //   this.props.history.push({
    //     pathname: "/promotion",
    //     search: "?page=" + id
    //   });
    // } else {
    //   if (str === "Num" || str === "Next" || str === "Prev") {
    //     this.props.history.push({
    //       pathname: "/promotion",
    //       search: "?page=" + id
    //     });
    //   }
    // }

    const $ = window.$;

    // $("#preloader").show();
    const intId = parseInt(id);
    const apiurl = this.state.promoApi + "&page=" + intId;
    var totalPageActive = 0;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        totalPageActive = Math.ceil(data.total / 6);

        this.setState({
          promoObj: data.content,
          currentPage: intId,
          totalPageForPagi: totalPageActive,
        });
        var arr = [];
        for (let i = 0; i < totalPageActive; i++) {
          arr[i] = i;
        }
        this.setState({
          totalPage: arr,
        });
        if (intId > 1) {
          this.setState({
            disBtnPrev: false,
            minPage: true,
          });
        }
        if (intId === Math.ceil(data.total / 6)) {
          this.setState({
            disBtnNext: true,
            maxPage: true,
          });
        }
      })
      .then(() => {
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .then(() => {
        const selectorId = "#page-" + id;
        for (let i = 1; i <= totalPageActive; i++) {
          var str = "page-" + i;
          document.getElementById(str).classList.remove("active");
        }
        if (selectorId) {
          setTimeout(() => {
            document.querySelector(selectorId).classList.add("active");
          }, 100);
        }
      })
      .catch((errs) => {
        console.log(errs);
      });
    const totalpage = parseInt(this.state.totalPageForPagi);
    if (str === "Num") {
      if (intId === 1) {
        this.setState({
          disBtnPrev: true,
          disBtnNext: false,
          minPage: false,
          maxPage: false,
        });
      } else if (intId === totalpage) {
        this.setState({
          disBtnNext: true,
          maxPage: true,
        });
      } else {
        this.setState({
          disBtnNext: false,
          disBtnPrev: false,
          maxPage: false,
        });
      }
    }

    if (str === "Next") {
      if (intId > 1 && intId !== totalpage) {
        this.setState({
          disBtnPrev: false,
          minPage: true,
        });
      } else if (intId === totalpage) {
        this.setState({
          disBtnNext: true,
          maxPage: false,
        });
      }
    } else {
      if (intId !== totalpage && intId > 1) {
        this.setState({
          disBtnNext: false,
          maxPage: false,
        });
      } else if (intId < 2) {
        this.setState({
          disBtnPrev: true,
          minPage: false,
          maxPage: false,
        });
      }
    }
  }
  handleClick(i) {}
  handlePrev(str) {
    const prevId = this.state.currentPage - 1;
    this.handlePage(prevId, str);
  }
  handleNext(str) {
    const nextId = this.state.currentPage + 1;
    this.handlePage(nextId, str);
  }

  RenderPromotionRowA() {
    let promotions = this.state.promoObj;
    return Object.keys(promotions).map(function (key, i) {
      let setClassHide = "col-md-4 wow flipInY h-100";
      let item = promotions[key];

      if (i > 2) {
        setClassHide = "col-md-4 wow flipInY Review-hide-mobile h-100";
      }
      return (
        <div key={i} className={setClassHide}>
          <div className="promotion-card-container">
            <img src={item.picture_cover} alt="Promotion" />

            <div className="content">
              <div className="promotion-card-header">{item.title}</div>
              <Link
                to={{
                  pathname: "/promotion/" + item.post_id,
                }}
              >
                <button className="promotion-card-button">
                  {strings.promotion.read_more}
                </button>
              </Link>
            </div>
          </div>
        </div>
      );
    });
  }

  RenderPromotionRowB() {
    if (this.state.promoObj instanceof Object) {
      return this.state.promoObj.map((item, i) => {
        var setClassHide = "col-md-4 wow flipInY";
        if (i > 2) {
          setClassHide = "col-md-4 wow flipInY Review-hide-mobile";
        }
        return (
          <div key={i} className={setClassHide}>
            <div className="thumbnail thumbnail-featured no-border no-padding wrap-promotions">
              <div className="set-min-content">
                <div className="ProContentA-wrapper-img">
                  <Link
                    to={{
                      pathname: "/promotion/" + item.post_id,
                    }}
                  >
                    <img src={item.picture_cover} alt="Promotion" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  //  Pagination
  RenderPagination() {
    if (this.state.totalPage instanceof Array) {
      return this.state.totalPage.map((item, i) => {
        var index = item + 1;
        var addClassPage = " page-link-" + index;
        return (
          <li key={i} className="page-item">
            <button
              className={"page-link set-border1" + addClassPage}
              id={"page-" + index}
              onClick={() => {
                this.handlePage(index, "Num");
              }}
            >
              {index}
            </button>
          </li>
        );
      });
    }
  }

  render() {
    return (
      <div>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <section className="page-section no-padding slider">
              <div className="container full-width">
                <div className="main-slider">
                  <div className="ReviewContentA-setbg-opacity" />
                  <div
                    className="item slide3 ver3 ProContentA-setbgheader BranchContentA-conbg-head setbgheader-parallax"
                    style={{
                      backgroundImage: `url("${this.state.banner}")`,
                    }}
                  >
                    <div className="container">
                      <div
                        className="container-title ReviewContentA-contain-default"
                        style={{
                          textAlign: "center",
                          marginTop: "130px",
                        }}
                      >
                        <h1>
                          <strong>{strings.promotion.promotion}</strong>
                        </h1>
                        <div className="hr-promo" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="wrapper">
          <div className="content-area">
            <section className="page-section">
              <div className="container-fluid Promotion-fluid">
                <div className="row">{this.RenderPromotionRowA()}</div>
                <nav
                  aria-label="Page navigation example"
                  className="control-pagination"
                >
                  <ul className="pagination">
                    <li className="page-item">
                      <button
                        className="page-link set-border-prev"
                        aria-label="Previous"
                        id="prev-page"
                        onClick={() => {
                          this.handlePrev("Prev");
                        }}
                        disabled={this.state.disBtnPrev}
                        style={
                          this.state.minPage
                            ? {}
                            : { color: "#ccc", background: "#E9E5E4" }
                        }
                      >
                        <span className="glyphicon glyphicon-chevron-left" />
                      </button>
                    </li>
                    {/* RenderPagination */}
                    {this.RenderPagination()}
                    <li className="page-item">
                      <button
                        className="page-link set-border-next"
                        aria-label="Next"
                        id="next-page"
                        onClick={() => {
                          this.handleNext("Next");
                        }}
                        disabled={this.state.disBtnNext}
                        style={
                          this.state.maxPage
                            ? { color: "#ccc", background: "#E9E5E4" }
                            : {}
                        }
                      >
                        <span className="glyphicon glyphicon-chevron-right" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
