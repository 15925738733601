import React, { Component } from "react";
import { Link } from "react-router-dom";
import Filter from "../../Filter";
import "../Home/css/promotion.css";
import "./css/BookingStep2.css";
import { dom } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";
import { strings } from "../../language/language";
const token = localStorage.login_token;
dom.watch();

const $ = window.$;
$("body").on("click", ".menumoreBtn", function () {
  $(this)
    .parents(".container-car")
    .find(".BookContentA-hide-options")
    .slideToggle();
});

export default class BookHeaderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixPath: "",
      preFixImg: "",
      isShow: false,
      bookObj: [],
      loadComplete: false,
      chkCode: true,
      inBranch: "",
      outBranch: "",
      isCode: "",
      isBranches: [],
      isParam: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setPrefixImg();
    this.fetchBook2();
    this.fetchLocation();
  }

  fetchLocation() {
    axios
      .get(`${PREFIX_ADDRESS}api/booking/branchlist`)
      .then((res) => {
        if (res.data.Success === "Y") {
          const { data } = res;
          // console.log(data.Branch[10]);
          this.setState({ isBranches: data.Branch });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchBook2() {
    const $ = window.$;
    $("#preloader").show();

    const query = new URLSearchParams(this.props.location.search);
    let outBranch = query.get("OutBranch");
    let outDate = query.get("OutDate");
    let outTime = query.get("OutTime");
    let inBranch = query.get("InBranch");
    let inDate = query.get("InDate");
    let inTime = query.get("InTime");
    let code = query.get("Code");

    if (code) {
      this.setState({ chkCode: false });
    } else {
      code = "";
    }
    this.setState({ isCode: code, inBranch: inBranch, outBranch: outBranch });

    const param = `?OutBranch=${outBranch}&OutDate=${outDate}&OutTime=${outTime}&InBranch=${inBranch}&InDate=${inDate}&InTime=${inTime}&Code=${code}`;

    this.setState({ isParam: param });

    // const paramTest =
    //   "?OutBranch=10&OutDate=13/09/2019&OutTime=10:00&InBranch=10&InDate=13/09/2019&InTime=10:00&Currency=THB";

    axios
      .get(`${PREFIX_ADDRESS}api/booking/carlist${param}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const { data } = res;
        if (res.data.Success === "N") {
          // console.log(res.data.error.message[0])
          handleError(strings.error[res.data.error.message[0]]);

          this.props.history.push({ pathname: "/" });
        } else {
          this.setState({
            bookObj: data.result,
            loadComplete: true,
          });
        }
      })
      .then(() => {
        $("#preloader2").hide();
        $("#preloader").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setPrefixImg() {
    const prefiximg = SITE_URL + "/";
    this.setState({ preFixImg: prefiximg });
  }
  RenderCarNotFound = () => {
    if (
      this.state.loadComplete === true &&
      Object.keys(this.state.bookObj).length === 0
    ) {
      return (
        <div className="container container-car">
          <div className="row" style={{ margin: "0" }}>
            <div
              className="col-xs-12"
              style={{ backgroundColor: "#FFF" }}
              dangerouslySetInnerHTML={{
                __html: strings.booking_step.not_found_car_list,
              }}
            />
          </div>
        </div>
      );
    }
  };

  RenderMockMg() {
    return (
      <div className="container ">
              <div className="row" style={{paddingBottom:20}}>
                <div className="col-xs-12">
                <img src="/images/BookingStep2/mockup-mg.jpg" style={{width:'100%'}}/>
                </div>
                </div>
            </div>
    )
  }

  RenderMockTesla() {
    return (
      <div className="container ">
              <div className="row" style={{paddingBottom:20}}>
                <div className="col-xs-12">
                <img src="/images/BookingStep2/mockup-tesla.jpg" style={{width:'100%'}}/>
                </div>
                </div>
            </div>
    )
  }

  RenderPaymentInfo() {
    return (
      <div className="container container-car wrap-payment-info">
        <div className="row" style={{ margin: "0", backgroundColor: "#FFF" }}>
          <div
            className="col-lg-6"
            dangerouslySetInnerHTML={{
              __html: strings.booking_info.paynow_info,
            }}
          />
          <div
            className="col-lg-6"
            dangerouslySetInnerHTML={{
              __html: strings.booking_info.paylater_info,
            }}
          />
        </div>
      </div>
    );
  }
  RenderCarDetails() {
    const bookObj = this.state.bookObj;
    if (!bookObj) {
      return false;
    }
    const isCar = Object.keys(bookObj).map((key, i) => {
      var element = "";
      if (bookObj[key].Success !== "Y") {
        return false;
      }
      // console.log(bookObj[key]);
      element = (
        <div
          key={i}
          className="container container-car"
          style={{
            marginTop: "0",
            marginBottom: "20px",
          }}
        >
          <div
            className="row"
            style={{
              margin: "0 0",
              background: "#fff",
              paddingBottom: this.state.isShow ? "30px" : "20px",
              paddingLeft: "30px",
            }}
          >
            <div className="col-md-3">
              {bookObj[key].detail.picture_cover !== null && (
                <img
                  src={bookObj[key].detail.picture_cover}
                  width="100%"
                  alt="Car"
                />
              )}
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-xs-12">
                  <h1 className="BookContentA-h1">
                    {bookObj[key].detail.title}
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12" style={{ marginTop: "10px" }}>
                  <p className="BookContentA-p-pick-re">
                    {strings.book_now.pick_up_location} :{" "}
                    {this.state.isBranches["'" + this.state.outBranch + "'"]}
                  </p>
                  <p className="BookContentA-p-pick-re">
                    {strings.book_now.return_location} :{" "}
                    {this.state.isBranches["'" + this.state.inBranch + "'"]}
                  </p>
                </div>
              </div>
              <div className="row" style={{ lineHeight: "2" }}>
                <div className="col-md-6" style={{ marginTop: "10px" }}>
                  <table style={{ fontSize: "13px" }}>
                    <tbody>
                      <tr>
                        <th width="50px" className="BookContentA-width-th">
                          <span className="BookContentA-con-span">
                            {strings.car_details.size}
                          </span>
                        </th>
                        <td width="20px" className="BookContentA-width-td">
                          <span className="BookContentA-con-span">:</span>
                        </td>
                        <td>
                          <span className="BookContentA-con-span">
                            {bookObj[key].detail.size}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="BookContentA-con-span">
                            {strings.car_details.type}
                          </span>
                        </th>
                        <td>
                          <span className="BookContentA-con-span">:</span>
                        </td>
                        <td>
                          <span className="BookContentA-con-span">
                            {bookObj[key].detail.type}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="BookContentA-con-span">
                            {strings.car_details.engine}
                          </span>
                        </th>
                        <td>
                          <span className="BookContentA-con-span">:</span>
                        </td>
                        <td>
                          <span className="BookContentA-con-span">
                            {bookObj[key].detail.engine}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="col-md-6 BookContentA-del-l-r-pad BookContentA-pad-mobile"
                  style={{ marginTop: "10px" }}
                >
                  <table style={{ fontSize: "13px" }}>
                    <tbody>
                      <tr>
                        <th width="77px" className="BookContentA-width-th">
                          <span className="BookContentA-con-span">
                            {strings.car_details.kms_free}
                          </span>
                        </th>
                        <td width="25px" className="BookContentA-width-td">
                          <span className="BookContentA-con-span">:</span>
                        </td>
                        <td>
                          <span className="BookContentA-con-span">
                            {bookObj[key].detail.kms_free === null
                              ? ""
                              : bookObj[key].detail.kms_free}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="BookContentA-con-span">
                            {strings.car_details.insurances}
                          </span>
                        </th>
                        <td>
                          <span className="BookContentA-con-span">:</span>
                        </td>
                        <td>
                          <span className="BookContentA-con-span">
                            {bookObj[key].detail.insurance === null
                              ? ""
                              : bookObj[key].detail.insurance}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <div className="menumoreBtn" style={{ display: "flex" }}>
                    <div>
                      <button
                        className="BookContentA-conbtn-more"
                        style={{
                          cursor: "pointer",
                          display: this.state.isShow ? "none" : "block",
                          background: "#fff",
                          border: "none",
                          padding: "0",
                        }}
                      >
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep2/PlusCircle@2x.png"
                          }
                          width="33px"
                          style={{ float: "left" }}
                          alt="Button"
                        />
                      </button>
                      <button
                        style={{
                          cursor: "pointer",
                          display: this.state.isShow ? "block" : "none",
                          background: "#fff",
                          border: "none",
                          padding: "0",
                        }}
                      >
                        <img
                          src={
                            this.state.preFixImg +
                            "images/BookingStep2/MinusCircle@2x.png"
                          }
                          width="33px"
                          style={{ float: "left" }}
                          alt="Button"
                        />
                      </button>
                    </div>
                    <div style={{ paddingTop: "4px" }}>
                      <span
                        style={{
                          fontSize: "19px",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        id="menumore"
                      >
                        {strings.car_details.more_details}
                      </span>
                    </div>
                  </div>
                  <div />
                </div>
              </div>
              <div
                className="BookContentA-hide-options"
                style={{
                  overflow: "hidden",
                  display: "none",
                }}
              >
                <div className="row">
                  <div className="col-xs-4 BookContentA-space-mobile">
                    <img
                      className="BookContentA-logo-mar-r BookContentA-width-logo"
                      src={
                        this.state.preFixImg + "images/BookingStep2/Seat@2x.png"
                      }
                      width="15%"
                      alt="Seat"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {strings.car_details.seat} : {bookObj[key].detail.seat}
                    </span>
                  </div>
                  <div className="col-xs-4 BookContentA-del-l-r-pad BookContentA-width-air">
                    <img
                      className="BookContentA-logo-mar-r BookContentA-logo-air"
                      src={
                        this.state.preFixImg + "images/BookingStep2/Air@2x.png"
                      }
                      width="15%"
                      alt="Air"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {strings.car_details.air_condition} :{" "}
                      {bookObj[key].detail.air_condition === "Y" ? (
                        <i className="fa fa-check"></i>
                      ) : (
                        <i className="fa fa-times"></i>
                      )}
                    </span>
                  </div>
                  <div className="col-xs-4 BookContentA-del-l-r-pad">
                    <img
                      className="BookContentA-logo-mar-r"
                      src={
                        this.state.preFixImg +
                        "images/BookingStep2/Luggage@2x.png"
                      }
                      width="15%"
                      alt="Luggage"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {strings.car_details.luggage} :{" "}
                      {bookObj[key].detail.luggage}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 BookContentA-space-mobile">
                    <img
                      className="BookContentA-logo-mar-r BookContentA-width-logo"
                      src={
                        this.state.preFixImg + "images/BookingStep2/Door@2x.png"
                      }
                      width="15%"
                      alt="Door"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {strings.car_details.door} : {bookObj[key].detail.door}
                    </span>
                  </div>
                  <div className="col-xs-4 BookContentA-del-l-r-pad BookContentA-pad-trans">
                    <img
                      className="BookContentA-logo-mar-r BookContentA-logo-trans"
                      src={
                        this.state.preFixImg +
                        "images/BookingStep2/Transmission@2x.png"
                      }
                      width="7%"
                      alt="Transmission"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {strings.car_details.transmission} :{" "}
                      {bookObj[key].detail.tranmission}
                    </span>
                  </div>
                  <div className="col-xs-4 BookContentA-del-l-r-pad BookContentA-width-radio">
                    <img
                      className="BookContentA-logo-mar-r"
                      src={
                        this.state.preFixImg +
                        "images/BookingStep2/Radio@2x.png"
                      }
                      width="15%"
                      alt="Radio"
                    />
                    <span className="BookContentA-con-span BookContentA-span-option">
                      {bookObj[key].detail.radio_type}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row" style={{ marginRight: "0" }}>
                <div
                  className={
                    this.state.chkCode
                      ? "col-xs-6 BookContentA-mart-pay left"
                      : "col-xs-12 BookContentA-mart-pay left text-center"
                  }
                >
                  <div>
                    {/*
                      <div>
                        <span>
                          <strong>{bookObj[key].paynow.amount}</strong>
                        </span>
                       
                      </div>


                      <div>
                        <span className="BookContentA-set-perday">{strings.booking_step.per_day}</span>
                      </div> */}
                    <div>
                      <span>
                        <strong>
                          {parseFloat(bookObj[key].paynow.total)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          {strings.booking_step.thb}
                        </strong>
                      </span>
                    </div>
                    {/* <div>
                        <strong>Total</strong>
                      </div> */}
                  </div>

                  {bookObj[key].paynow ? (
                    <div className="BookContentA-mart-btn">
                      <Link
                        to={{
                          pathname: "/bookingstep3",
                          search:
                            this.state.isParam +
                            "&CDP=" +
                            bookObj[key].paynow.CDP +
                            "&CarGroup=" +
                            key +
                            "&RateNo=" +
                            bookObj[key].paynow.carrate +
                            "&RatePackage=" +
                            bookObj[key].detail.ratepackage,
                        }}
                      >
                        <button className="BookContentA-con-btna">
                          Pay Now
                        </button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {this.state.chkCode && bookObj[key].paylater ? (
                  <div
                    className="col-xs-6 BookContentA-mart-pay right"
                    style={{
                      display: this.state.chkCode ? "block" : "none",
                    }}
                  >
                    <div>
                      <span>
                        <strong>
                          {parseFloat(bookObj[key].paylater.total)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          {strings.booking_step.thb}
                        </strong>
                      </span>
                      <br />
                    </div>
                    <div className="BookContentA-mart-btn">
                      <Link
                        to={{
                          pathname: "/bookingstep3",
                          search:
                            this.state.isParam +
                            "&CDP=" +
                            bookObj[key].paylater.CDP +
                            "&CarGroup=" +
                            key +
                            "&RateNo=" +
                            bookObj[key].paylater.carrate +
                            "&RatePackage=" +
                            bookObj[key].detail.ratepackage,
                        }}
                      >
                        <button className="BookContentA-con-btnb">
                          Pay Later
                        </button>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="col-xs-12">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#alert-fail-accept"
                    style={{ color: "#ee4231" }}
                  >
                    {strings.booking_step.extra_charge_detail}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

      return element;
    });

    // const isCar = Object.keys(bookObj).forEach(item => {
    //   if (item === "ECAR") {
    //     console.log(bookObj[item].detail.id);
    //     return <div key={item}>{bookObj[item].detail.id}</div>;
    //   }
    // });
    // }

    return isCar;
  }

  render() {
    return (
      <div>
        <Filter urlData={this.props.location.search} />
        {/* {this.state.bookObj.detail.id} */}
        <div className="wrapper">
          <div
            className="content-area BookingStep2"
            style={{ marginBottom: "30px" }}
          >
            <div className="container set-container-mobile">
              <div
                className="row BookHeaderStatus-row"
                style={{
                  background: "#01adef",
                  height: "110px",
                }}
              >
                <div className="col-xs-1 BookHeaderStatus-reon-mobile" />
                <div
                  className="col-xs-10 BookHeaderStatus-reset-col"
                  style={{ marginTop: "8px" }}
                >
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <div className="BookHeaderStatus-circle">
                      {/* <i class="fa fa-check-circle" /> */}
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/AlreayStatus@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      1
                    </span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ color: "#fff" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/CurrentStatus-2@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      2
                    </span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ opacity: "0.4" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/UnReadyStatus-3@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      3
                    </span>
                  </div>
                  <div className="col-xs-3 BookHeaderStatus-set-cen">
                    <i
                      className="fa fa-chevron-right"
                      style={{ opacity: "0.4" }}
                    />
                    <div className="BookHeaderStatus-circle">
                      <img
                        src={
                          this.state.preFixImg +
                          "images/BookingStep2/UnReadyStatus-4@2x.png"
                        }
                        width="100%"
                        className="BookHeaderStatus-img-mobile"
                        alt="Status"
                      />
                    </div>
                    <span className="BookHeaderStatus-con-span">
                      <span className="wrap_step_txt">
                        {strings.booking_step.step}
                      </span>{" "}
                      4
                    </span>
                  </div>
                </div>
                <div className="col-xs-1" />
              </div>
            </div>
            <div>
              <div id="preloader2">
                <div id="preloader-status">
                  <div className="spinner">
                    <div className="rect1"></div>
                    <div className="rect2"></div>
                    <div className="rect3"></div>
                    <div className="rect4"></div>
                    <div className="rect5"></div>
                  </div>
                  <div id="preloader-title">Loading</div>
                </div>
              </div>
            </div>
            {this.RenderCarNotFound()}
            {/* {this.RenderMockTesla()}
            {this.RenderMockMg()} */}
            
            {this.RenderCarDetails()}
            
            {this.RenderPaymentInfo()}

            <div
              className="modal fade"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="mySmallModalLabel"
              aria-hidden="true"
              style={{ zIndex: "99999" }}
              id="alert-fail-accept"
            >
              <div className="modal-dialog modal-sm">
                <div className="modal-content">
                  <div className="modal-header Book3ContentA-rebg-modalh-fail">
                    <button
                      type="button"
                      className="close Book3ContentA-conbtn-closemodal-fail"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body Book3ContentA-setpad-modal-b-fail">
                    <span className="Book3ContentA-conspan-modal-b-fail">
                      <strong>
                        <p>Peak Period Charge + 500 บาท/วัน</p>
                        <p>
                          1-2 มกราคม 2565 /9-17 เมษายน 2565 / 3-11 ธันวาคม 2565
                          /24-31 ธันวาคม 2565 / 1-2 มกราคม /2566
                        </p>
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
