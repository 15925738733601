import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/Profile.css";
import axios from 'axios';
import Filter from "../../Filter";
import MenuProfile from "./MenuProfile";
import { strings } from "../../language/language";
import NationalitySelect from "../Elements/NationalitySelect";
import CountrySelect from "../Elements/CountrySelect"
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";

let yup = require('yup');
let bd, bm, by = "";
// const apiUrl = PREFIX_ADDRESS + "api/member/details";
// const apiUrl = "http://localhost:8000/api/member/details";
// const apiUrlPwd = "http://localhost:8000/api/member/changePassword";

const apiUrl = PREFIX_ADDRESS + "api/member/details";
const apiUrlPwd = PREFIX_ADDRESS + "api/member/changePassword";

let token = localStorage.login_token;
export default class ProfileContentA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            alertRequired: false,
            preFixImg: SITE_URL + "/",
            profile: [],
            currentPwd: "",
            newPwd: "",
            reNewPwd: "",
            profileFile: "",
            idCardFile: "",
            birthday: {
                day: "",
                month: "",
                year: ""
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const query = new URLSearchParams(this.props.location.search);
        let lineToken = query.get("lineToken");
        if (lineToken && token != lineToken) {
            localStorage.setItem("login_token", lineToken)
            token = lineToken;

            window.location.reload(false);
        }
        if (!token) {
            this.props.history.push({ pathname: "/login", state: {} });
        }
        this.getData();
    }


    getData = (e) => {

        if (token) {
            axios
                .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    // console.log(data)
                    localStorage.setItem("member_name", data.success.firstname);
                    this.setState({
                        profile: data.success,
                        birthday: {
                            day: data.success.birthday ? data.success.birthday.substr(8, 2) : "",
                            month: data.success.birthday ? data.success.birthday.substr(5, 2) : "",
                            year: data.success.birthday ? data.success.birthday.substr(0, 4) : ""
                        }
                    })
                    bd = "yyy";
                })
                .catch(errs => {
                    console.error(errs);
                    this.props.history.push({ pathname: "/login", state: {} });
                });
        }

    }

    handleSubmit = async (e) => {
        let file = this.state.profileFile
        let idcardfile = this.state.idCardFile
        let formdata = new FormData();
        let schema = yup.object().shape({
            email: yup.string().required(),
            firstname: yup.string().required(),
            lastname: yup.string().required(),
            // address: yup.string().required(),
            phone: yup.string().required(),
            country: yup.string().required(),
            nationality: yup.string().required(),
            bd: yup.string().required(),
            bm: yup.string().required(),
            by: yup.string().required(),
        });

        let profileFormData = {
            email: this.state.profile.email,
            firstname: this.state.profile.firstname,
            lastname: this.state.profile.lastname,
            phone: this.state.profile.phone,
            country: this.state.profile.country,
            nationality: this.state.profile.nationality,
            bd: this.state.birthday.day,
            bm: this.state.birthday.month,
            by: this.state.birthday.year,
        }

        const valid = await schema.isValid(profileFormData);

        if (!valid) {
            this.setState({ alertRequired: true })
            handleError(strings.profile.require_field);
            return false;
        }
        formdata.append("birthday", this.state.birthday.year + '-' + this.state.birthday.month + '-' + this.state.birthday.day || '');
        formdata.append("middlename", this.state.profile.middlename || '');
        formdata.append("address", this.state.profile.address || '');
        formdata.append("facebook_client", this.state.profile.facebook_client || '');
        formdata.append("line_client", this.state.profile.line_client || '');
        formdata.append('driving_license_no', this.state.profile.driving_license_no || '');
        formdata.append('driving_license', file);
        formdata.append('identification_card', idcardfile);

        for (let key in profileFormData) {
            if (key !== "bd" && key !== "bm" && key !== "by") {

                formdata.append(key, profileFormData[key]);
            }

        }



        if (token) {
            axios
                .post(apiUrl, formdata, { headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    console.log(data);
                    handleError("แก้ไขข้อมูลแล้ว")
                    this.getData();
                })
                .catch(errs => {
                    console.error(errs);
                });
        }

        return false;
    }
    backToHome = () => {
        this.props.history.push({ pathname: "/" });
    }
    handleSubmitPassword = (e) => {

        let formdata = new FormData();


        if (this.state.currentPwd === "" || this.state.newPwd === "") {
            handleError("กรุณาระบุรหัสผ่าน")
            return;
        }

        if (this.state.newPwd !== this.state.reNewPwd) {
            handleError("รหัสผ่านไม่ตรงกัน")
            return;
        }
        formdata.append("oldPassword", this.state.currentPwd || '');
        formdata.append("newPassword", this.state.newPwd || '');


        if (token) {
            axios
                .post(apiUrlPwd, formdata, { credentials: true, headers: { Authorization: `Bearer ${token}` } })
                .then(res => {
                    const { data } = res;
                    console.log(data);
                    handleError("แก้ไขข้อมูลแล้ว")
                    this.setState({
                        currentPwd: "",
                        newPwd: "",
                        reNewPwd: "",
                    })
                })
                .catch(function (error) {
                    handleError(error.response.data.error.message);
                });
        }

        return false;
    }


    handleProfileFile = (e) => {
        let file = e.target.files[0];
        this.setState({ profileFile: file });
    }

    handleIdCardFile = (e) => {
        let file = e.target.files[0];
        this.setState({ idCardFile: file });
    }



    render() {

        let dayItem = [];
        let monthItem = [];
        let yearItem = [];
        let monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        for (let i = 1; i <= 31; i++) {
            dayItem.push(<option key={i}>{i.toString().padStart(2, '0')}</option>)
        }

        for (let i = 0; i <= 11; i++) {
            monthItem.push(<option key={i} value={(i + 1).toString().padStart(2, '0')}>{monthNames[i]}</option>)
        }

        for (let i = 2020; i >= 1950; i--) {
            yearItem.push(<option key={i}>{i}</option>)
        }

        return (
            <div style={{ background: "#fff" }}>
                <Filter />
                {/* <Beforeunload onBeforeunload={event => event.preventDefault()} /> */}
                <div className="wrapper">
                    <div className="content-area">
                        <div className="container">
                            <div className="container-title ProfileContentA-contain-default">
                                <h1
                                    className="ProfileContentA-confont-header"
                                    style={{ letterSpacing: "5px" }}
                                >
                                    <strong>{strings.profile.profile}</strong>

                                </h1>
                                <div className="ProfileContentA-hr-promo ProfileContentA-conhr-head" />
                            </div>
                            <div className="ProfileContentA-setmarlr-de">
                                <div className="row ProfileContentA-setmarb-fform">



                                    <MenuProfile active="profile" drivercode={this.state.profile.driver_code || null} />

                                </div>
                                {(!this.state.profile.phone || !this.state.profile.country || !this.state.profile.nationality) && (
                                    <div className="row" style={{ marginTop: "-25px" }}>
                                        <div className="col-sm-12">
                                            <div className="alert alert-warning">{strings.profile.require_field}</div>
                                        </div>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.firstname ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-first"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.first_name}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.firstname || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            firstname: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.lastname ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-last"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.last_name}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.lastname || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            lastname: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-mid"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.middle_name}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.middlename || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            middlename: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.email ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-mail"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.email}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.email || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9,@._-]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            email: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.address ? " " : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-add"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.address}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.address || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            address: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.phone ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-phone"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.phone}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.phone || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            phone: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.nationality ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-nation"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.nationality}
                                            </span>
                                            <NationalitySelect onChange={(e) => {
                                                let value = e.target.value;
                                                this.setState(prevState => ({
                                                    profile: {
                                                        ...prevState.profile,
                                                        nationality: value
                                                    }
                                                }))
                                            }}
                                                value={this.state.profile.nationality || ""}
                                                className="form-control ProfileContentA-conh-input" />

                                        </div>
                                    </div>

                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className={"input-group ProfileContentA-conmarb-input" + (this.state.alertRequired && !this.state.profile.country ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-country"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.country}
                                            </span>
                                            <CountrySelect
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            country: value
                                                        }
                                                    }))
                                                }}
                                                value={this.state.profile.country || ""}
                                                className="form-control ProfileContentA-conh-input" />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-xs-12 ProfileContentA-remart-colform ">
                                        <div className={"input-group ProfileContentA-conmarb-input" +
                                            (this.state.alertRequired && (!this.state.birthday.day || !this.state.birthday.month || !this.state.birthday.year) ? " bd-red" : "")}>
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-date"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.dob}
                                            </span>
                                            <select
                                                className="form-control ProfileContentA-conh-input ProfileContentA-refont-dobmo"
                                                value={this.state.birthday.day || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        birthday: {
                                                            ...prevState.birthday,
                                                            day: value
                                                        }
                                                    }))
                                                }}
                                            >
                                                <option value="">--</option>
                                                {dayItem}
                                            </select>

                                            <select
                                                className="form-control ProfileContentA-conh-input ProfileContentA-refont-dobmo"
                                                value={this.state.birthday.month || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        birthday: {
                                                            ...prevState.birthday,
                                                            month: value
                                                        }
                                                    }))
                                                }}>
                                                <option value="">--</option>
                                                {monthItem}
                                            </select>

                                            <select
                                                className="form-control ProfileContentA-conh-input ProfileContentA-refont-dobmo"
                                                value={this.state.birthday.year || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        birthday: {
                                                            ...prevState.birthday,
                                                            year: value
                                                        }
                                                    }))
                                                }}>
                                                <option value="">--</option>
                                                {yearItem}
                                            </select>
                                        </div>
                                    </div>


                                </div>


                                <div className="row">
                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-dri"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.driving_license}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.profile.driving_license_no || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    value = value.replace(/[^a-zA-Z0-9\s]/ig, '')
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            driving_license_no: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-3 ProfileContentA-remart-colform ">
                                        <div className="input-group ProfileContentA-conmarb-input ProfileContentA-redisup">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conw-spanup"
                                                id="basic-addon1"
                                            >

                                                <div style={{ width: "150px" }}>Upload National ID Card</div>
                                            </span>
                                            <div className="ProfileContentA-conh-input">
                                                <div className="ProfileContentA-hovercursor">
                                                    <img
                                                        className="ProfileContentA-conh-input ProfileContentA-setborder-logoup"
                                                        src={this.state.preFixImg + "images/Profile/logo-upload.png"}
                                                        alt="logo"
                                                    />
                                                    <input
                                                        type="file"
                                                        className="ProfileContentA-getiden-upload"
                                                        onChange={(e) => this.handleIdCardFile(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3" style={{ marginTop: "15px" }}> {this.state.idCardFile.name ? this.state.idCardFile.name : this.state.profile.identification_card ? (<a target="_blank" href={PREFIX_ADDRESS + "file/identification_card/" + this.state.profile.identification_card}><i class="fa fa-picture-o" aria-hidden="true" style={{ fontSize: "28px" }}></i></a>) : ""}</div>
                                </div>
                                <div className="row">

                                    <div className="col-sm-3 ProfileContentA-remart-colform ">
                                        <div className="input-group ProfileContentA-conmarb-input ProfileContentA-redisup">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conw-spanup"
                                                id="basic-addon1"
                                            >

                                                <div style={{ width: "150px" }}>{strings.profile.upload}</div>
                                            </span>
                                            <div className="ProfileContentA-conh-input">
                                                <div className="ProfileContentA-hovercursor">
                                                    <img
                                                        className="ProfileContentA-conh-input ProfileContentA-setborder-logoup"
                                                        src={this.state.preFixImg + "images/Profile/logo-upload.png"}
                                                        alt="logo"
                                                    />
                                                    <input
                                                        type="file"
                                                        className="ProfileContentA-getiden-upload"
                                                        onChange={(e) => this.handleProfileFile(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-3" style={{ marginTop: "15px" }}> {this.state.profileFile.name ? this.state.profileFile.name : this.state.profile.identification_card ? (<a target="_blank" href={PREFIX_ADDRESS + "file/driving_license/" + this.state.profile.driving_license}><i class="fa fa-picture-o" aria-hidden="true" style={{ fontSize: "28px" }}></i></a>) : ""}</div>


                                </div>
                                <div className="row">



                                    <div className="col-sm-6 ProfileContentA-remart-colform ProfileContentA-setinblock-face">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-face"
                                                id="basic-addon1"
                                            >
                                                <img src={this.state.preFixImg + "images/Profile/logo-face.png"} alt="logo" />
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                placeholder={strings.profile.facebook_placeholder}
                                                value={this.state.profile.facebook_client || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            facebook_client: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-line"
                                                id="basic-addon1"
                                            >
                                                <img src={this.state.preFixImg + "images/Profile/logo-line.png"} alt="logo" />
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                placeholder={strings.profile.line_placeholder}
                                                value={this.state.profile.line_client || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState(prevState => ({
                                                        profile: {
                                                            ...prevState.profile,
                                                            line_client: value
                                                        }
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className="ProfileContentA-conspan-remark">
                                            <span>
                                                {strings.profile.lang_message}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 ProfileContentA-remart-colform ProfileContentA-setmart-fremark">

                                        <button className="btn btn-danger ProfileContent-button" onClick={this.handleSubmit}>
                                            SUBMIT
                                            </button>

                                        <button className="btn btn-primary ProfileContent-button" onClick={this.backToHome} style={{ marginLeft: 20 }}>
                                            BACK TO HOME
                                            </button>

                                    </div>
                                </div>
                            </div>
                            <div className="container-title ProfileContentA-contain-default">
                                <h1
                                    className="ProfileContentA-confont-header"
                                    style={{ letterSpacing: "5px" }}
                                >
                                    <strong>{strings.profile.change_password}</strong>
                                </h1>
                                <div className="ProfileContentA-hr-promo ProfileContentA-conhr-head" />
                            </div>
                            <div className="ProfileContentA-setmarlr-de">
                                <div className="row">
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-current"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.current}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.currentPwd || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState({ currentPwd: value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-new"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.new}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.newPwd || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState({ newPwd: value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div className="input-group ProfileContentA-conmarb-input">
                                            <span
                                                className="input-group-addon ProfileContentA-conspan-front ProfileContentA-conspan-renew"
                                                id="basic-addon1"
                                            >
                                                {strings.profile.retype_new}
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control ProfileContentA-conh-input"
                                                aria-describedby="basic-addon1"
                                                value={this.state.reNewPwd || ""}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    this.setState({ reNewPwd: value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform">
                                        <div>
                                            {/* <button
                                                className="ProfileContentA-hovercursor ProfileContentA-relink-btn"
                                                data-toggle="modal"
                                                data-target="#forgotmodal"
                                            >
                                                <span className="ProfileContentA-conspan-forgot">
                                                    {strings.profile.forgot}
                                                </span>
                                            </button> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-12 ProfileContentA-remart-colform ProfileContentA-setmart-fremark">
                                        <button className="btn btn-danger ProfileContentA-buildbtn-danger" onClick={this.handleSubmitPassword}>
                                            {strings.profile.save_change}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
