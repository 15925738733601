import React, { useState, useEffect } from 'react';
import { PREFIX_ADDRESS, SITE_URL, handleError } from "../../constants";

const PoItem = (props) => {
  let preFixImg = SITE_URL + "/";
  const [po, setPo] = useState("");
  const [checked, setChecked] = useState("");
  return (
    <div className="row Book3ContentA-mo-padr-vouc" style={{
      marginTop: "0",
      marginLeft: "0"
    }}>
      <div className="col-md-3 Book3ContentA-repad-movouc">
        <input type="text" className="Book3ContentA-input-code" placeholder="กรอก PO No." name="Code" value={po} onChange={(e) => setPo(e.target.value)} />
      </div>

      <div className="col-md-4 Book3ContentA-mobile-chk Book3ContentA-repadl-voucV2">
        <div>
          <input
            type="button"
            value={'ตรวจสอบ'}
            className="Book3ContentA-con-btna Book3ContentA-mobtna-vouc"
            onClick={(e) => { props.checkClicked(po); setChecked(true); }}
            disabled={props.active ? true : false}
            style={{ background: props.active ? "#e9e9e9" : "#01adef", borderColor: props.active ? "#e9e9e9" : "#01adef" }}
          />

          {checked && props.active && (
            <input
              type="button"
              value="ลบ"
              onClick={(e) => { props.clearClicked(); setChecked(""); setPo(""); }}
              className="Book3ContentA-con-btnb Book3ContentA-mobtna-vouc"
            />)}


          {checked && props.active ? (<img
            src={
              preFixImg + "images/BookingStep3/Correct@2x.png"
            }
            width="25px"
            className="Book3ContentA-chk-voucher"
            alt="Logo"
          />) : checked && !props.active ? (<img
            src={
              preFixImg + "images/BookingStep3/UnCorrect@2x.png"
            }
            width="25px"
            className="Book3ContentA-chk-voucher"
            alt="Logo"
          />) : null}





        </div>
      </div>
    </div>);
};

export default PoItem;
PoItem.defaultProps = {
  active: false,
  // checked: false,
}
