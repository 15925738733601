export const th = {
  menu: {
    menu: "MENU",
    home: "หน้าหลัก",
    our_service: "บริการของเรา",
    fleet_service: "ประเภทรถเช่าที่ให้บริการ",
    coporate_solution: "บริการรถเช่าเพื่อองค์กร",
    short_rent: "เช่ารถระยะสั้น",
    long_rent: "เช่ารถระยะยาว",
    enterprise_rent: "เช่ารถสำหรับองค์กร",
    chauffeur_service: "เช่ารถพร้อมคนขับ",
    promotion: "โปรโมชั่น",
    promotion_company: "Thai Rent A Car",
    promotion_partner: "พาร์ทเนอร์",
    travel_attraction: "แนะนำสถานที่ท่องเที่ยว",
    branches: "สาขา",
    our_company: "เกี่ยวกับเรา",
    about_us: "เกี่ยวกับเรา",
    news_activities: "ข่าวสารและกิจกรรม",
    partnership: "พันธมิตร",
    contact_us: "ติดต่อเรา",
    faq: "คำถามที่พบบ่อย",
    career: "ร่วมงานกับเรา",
    login: "ลงชื่อเข้าใช้งาน",
    consent_cookie: `โปรดทราบว่าเราใช้งานคุกกี้เพื่อให้เว็บไซต์ฯ หรือแอพริเคชันฯ
    ของเราสามารถทำงานได้อย่างถูกต้องสมบูรณ์
    และ/หรือให้บริการได้ตรงตามความต้องการของคุณ
    โดยเราอาจใช้คุกกี้ประเภทที่ต้องขอความยินยอมและไม่ต้องขอความยินยอมจากคุณ
    ดังนั้น กรุณาศึกษานโยบายการใช้คุกกี้ในเว็บไซต์ของเราผ่าน`,
    link: "ลิงค์ต่อไปนี้",
    consent_accept: "ยินยอมให้ใช้คุกกี้",
    consent_decline: "ไม่ยินยอมให้ใช้คุกกี้",
    follow_us: "FOLLOW US ON",
  },
  service_box: {
    daily: "เช่ารายวัน",
    monthly: "เช่ารายเดือน",
    short_term: "เช่ารถระยะสั้น",
    short_term_desc: "เช่ารถรายวัน / รายสัปดาห์ / รายเดือน",
    long_term: "เช่ารถระยะยาว",
    long_term_desc: "เช่ารถรายปี - 5 ปี",
    corporate: "เช่ารถสำหรับองค์กร",
    corporate_desc: "รถเช่าสำหรับบริษัท และ องค์กร",
    chauffeur: "เช่ารถพร้อมคนขับ",
    chauffeur_desc: "รถเช่าพร้อมพนักงานขับรถ",
  },
  service: {
    offer_title: "ตัวเลือกบริการรถพร้อมคนขับ",
    offer_subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    offer_1_title: "การันตีราคาดีที่สุด",
    offer_1_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_2_title: "การันตีคุณภาพ",
    offer_2_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_3_title: "ช่วยเหลือลูกค้า 24 ชั่วโมง",
    offer_3_description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed",
    offer_1_title_corporate:
      "ฟรีค่าประกัน และค่าบำรุงรักษาตลอดระยะสัญญา และรถสำรองทดแทนพร้อมให้บริการ",
    offer_2_title_corporate: "ฟรี! ค่าต่อภาษีประจำปี",
    offer_3_title_corporate:
      "บริการช่วยเหลือ Customer Service 1647 , ตลอด 24 ชั่วโมง",
    "long-term": "สนใจเช่าระยะยาว",
    enterprise: "สนใจเช่ารถสำหรับองค์กร",
    chaffeur: "สนใจเช่ารถพร้อมคนขับ",
    short_term_title: "รถเช่าระยะสั้น",
    short_term_description:
      "บริการรถเช่าระยะสั้น รถเช่าขับเอง (รายวัน-รายสัปดาห์-รายเดือน) มาตรฐานสากล จากไทยเร้นท์อะคาร์ การันตีคุณภาพรถ และการบริการโดยทีมงานมืออาชีพ  ไม่ว่าจะเช่ารถสำหรับการท่องเที่ยว เช่ารถเพื่อใช้งานในชีวิตประจำวัน หรือ รถเช่าระหว่างซ่อม ไทยเร้นท์อะคาร์พร้อมให้บริการรถเช่า ครบวงจร ทั่วไทย",
    long_term_title: "รถเช่าระยะยาว",
    long_term_description:
      "บริการรถเช่าระยะยาว รถเช่าขับเอง (รายปี) มาตรฐานสากล จากไทยเร้นท์อะคาร์ การันตีคุณภาพรถ และการบริการโดยทีมงานมืออาชีพ ไม่ว่าจะเช่ารถสำหรับการท่องเที่ยว เช่ารถเพื่อใช้งานในชีวิตประจำวัน หรือ รถเช่าระหว่างซ่อม ไทยเร้นท์อะคาร์พร้อมให้บริการรถเช่า ครบวงจร ทั่วไทย",
    enterprise_title: "รถเช่าสำหรับบริษัท และ องค์กร",
    enterprise_description:
      "ไทยเร้นท์อะคาร์ผู้นำธุรกิจบริการรถยนต์เช่าเพื่อองค์กร สามารถจัดหารถทุกประเภทตามความต้องการใช้งานและรูปแบบที่ลูกค้าต้องการ ด้วยอัตราค่าเช่าคงที่ตลอดอายุสัญญาการเช่า มีให้บริการทั้งเช่ารถเปล่า และเช่ารถพร้อมคนขับให้เลือกตาม ความเหมาะสมโดยอัตราค่าเช่าต่อเดือน จะรวมการบริการเรื่องภาษีประจำปี การจด/ต่อทะเบียน ค่าประกันภัยชั้น 1 ค่าบำรุงรักษาต่างๆและมีรถสำรองทดแทนพร้อมให้บริการภายใน 24 ชั่วโมง (เป็นการบริหารต้นทุน คุ้มค่า)",
    chaffeur_title: "บริการรถเช่า พร้อมคนขับ",
    chaffeur_description:
      "ไทยเร้นท์อะคาร์ พร้อมให้บริการรถเช่าพร้อมคนขับ สัญญารายเดือนและรายปี โดยพนักงานคนขับทีมงานมืออาชีพ พร้อมการบริการระดับสากล  ให้คุณได้รับความสะดวกสบายตลอดการเดินทาง มั่นใจใช้บริการกับ บริษัท ไทยเร้นท์อะคาร์ คอร์ปอเรชั่น จำกัด   45 ปี แห่งผู้นำด้านตลาดรถเช่าในประเทศไทย ให้คุณได้มั่นใจกับการใช้บริการ โดยการบริการมาตรฐานระดับสากล",
    why_thai_rent: "ทำไมต้องเช่ารถ ไทยเร้นท์อะคาร์ ?",
    book_now: "สนใจเช่ารถสำหรับบริษัท และ องค์กร",
    book_now_chaffeur: "สนใจเช่ารถพร้อมคนขับ",
    book_contact: "ติดต่อ  โทร 1647 หรือ 02-318-8888",
    contact_us: "ติดต่อเรา",
  },
  home: {
    service: "บริการของเรา",
    promotion: "โปรโมชั่น",
    partner: {
      desktop: "สิทธิประโยชน์และส่วนลดจากพันธมิตร",
      mobile: "PARTNERS",
    },
    journey: { desktop: "ENJOY YOUR JOURNEY WITH US", mobile: "OUR JOURNEY" },
    journey_sub_title: "เที่ยวทั่วไทย มั่นใจกับไทยเร้นท์อะคาร์",
    testimonials: "รีวิวจากลูกค้า",
    testimonials_sub_title: "CLIENT's REVIEWS",
    testimonials_description:
      "ร่วมเป็นส่วนหนึ่งของไทยเร้นท์อะคาร์ เพียงแท็กรูปใน Instagram @tharentacarclub",
    see_more: "ดูทั้งหมด",
  },
  footer: {
    name: "ชื่อ",
    email: "อีเมล",
    subject: "หัวข้อ",
    message: "กรอกข้อความ",
    send: "ส่ง",
  },
  about_us: {
    about_us: "ABOUT US",
  },
  contact: {
    contact: "CONTACT",
    thairent: "Thai Rent A Car",
    name: "ชื่อ",
    email: "E-mail",
    message: "รายละเอียด … ",
    number: "Number",
    send: "SEND MESSAGE",
    address: "Address",
    phone: "Telephone",
    facebook: "Facebook",
  },
  general: {
    more: "อ่านเพิ่มเติม",
  },
  book_now: {
    book_now: "BOOK NOW",
    pick_up_location: "สถานที่รับรถ",
    please_select: "โปรดเลือก",
    return_location: "สถานที่คืนรถ",
    same_location_as_pick_up: "คืนที่เดียวกับที่รับรถ",
    promotion_code: "รหัสโปรโมชั่น",
    please_input_code: "ใส่รหัสโปรโมชั่น",
    search_car: "ค้นหารถ",
    pick_up_date: "วันที่รับ",
    time: "เวลา",
    return_date: "วันที่คืน",
    refresh: "ค้นหารถ",
  },
  booking_step: {
    step_1: "ขั้นตอนที่ 1",
    step_2: "ขั้นตอนที่ 2",
    step_3: "ขั้นตอนที่ 3",
    step_4: "ขั้นตอนที่ 4",
    step: "ขั้นตอนที่",
    pay_now: "จ่ายทั้งหมด",
    pay_later: "จอง",
    per_day: "ต่อวัน",
    total: "รวม",
    for: "ระยะเวลา",
    days: "วัน",
    choose_your_extra_option: "Chosse Your Extra option",
    thb_per_day: "บาท/วัน",
    extra_charge: "Extra Charge",
    back: "ก่อนหน้า",
    next: "ต่อไป",
    car_rental_summary: "สรุปรายการ",
    rental_information: "รายละเอียดการจอง",
    pick_up: "รับรถ",
    return: "คืนรถ",
    price_detail: "รายละเอียดค่าเช่า",
    partial: "ค่ามัดจำ",
    car_price: "ค่าเช่ารถ",
    extra_option_price: "ค่าเช่าอุปกรณ์เสริม",
    vat: "VAT 7%",
    thb: "บาท",
    payment: "ชำระเงิน",
    extra_charge_detail:
      "ราคานี้รวมค่าธรรมเนียม Peak Period และ Weekend Charge แล้วหากช่วงเวลาการใช้งานรถตรงกับวันที่กำหนด กดเพื่อดูรายละเอียด",
    modal_extra_charge:
      "Peak Period Charge + 500 บาท/วัน. 1-2 มกราคม 2565 /9-17 เมษายน 2565 / 3-11 ธันวาคม 2565 /24-31 ธันวาคม 2565 / 1-2 มกราคม /2566" +
      "Weekend Charge กรุณาดูเงื่อนไขโปรโมชั่นที่เลือกใช้งาน",
    modal_extra_option: `<b>SLDW : Super Loss Damage Waiver</b> ประกันคุ้มครองความเสียหายบางประเภท ไม่รวม ความเสียหายภายในตัวรถยนต์, ยางรถยนต์, กระจกหน้ารถ, อุปกรณ์เสริม และ กุญแจหรือเอกสารสำคัญของรถยนต์หาย เป็นต้น<br/><br/>
       <b>PLDW : Premium Loss Damage Waive</b>  ประกันคุ้มครองความเสียหายบางประเภทที่ครอบคลุมความเสียหายที่อาจเกิดกับ ยางรถยนต์ และ กระจกรถยนต์ ไม่รวม ความเสียหายภายในตัวรถยนต์,อุปกรณ์เสริม และ กุญแจหรือเอกสารสำคัญของรถยนต์หาย เป็นต้น`,
    alert_voucher: "กรุณานำ  Cash Voucher ตัวจริงมาแสดงที่หน้าสาขาในวันรับรถ",
    drop_off_fee: "ค่าคืนรถต่างสาขา",
    print_receipt: "พิมพ์ใบเสร็จ",
    not_found_car_list: `<h3>ขออภัยค่ะ ช่วงเวลาที่คุณเลือกไม่สามารถจองรถได้บนช่องทางเว็ปไซต์<br/>กรุณาติดต่อไทยเร้นท์อะคาร์คอนแทคเซ็นเตอร์ 1647 ขอบคุณค่ะ</h3>
<p>Note: กรุณาเช็คความถูกต้องของข้อมูลเพื่อทำการเช่ารถผ่านช่องทางเว็ปไซต์ ดังนี้</p>
<ul>
<li>กรอกรหัสโปรโมชั่นอย่างถูกต้อง</li>
<li>รหัสโปรโมชั่นที่กรอกยังไม่หมดอายุ</li>
<li>ผู้ขับขี่จะต้องมีอายุไม่ต่ำกว่า 21 ปีบริบูรณ์ และมีใบขับขี่</li></ul>`,
  },
  booking_info: {
    booking_history: "Booking History",
    your_infomation: "YOUR INFORMATION",
    first_name: "ชื่อ",
    middle_name: "",
    last_name: "นามสกุล",
    email: "อีเมล",
    phone: "เบอร์โทรศัพท์",
    tax_invoice: "ต้องการออกใบกำกับภาษี",
    travel_infomation: "TRAVEL INFORMATION",
    flight_info: "เที่ยวบิน",
    flight_number: "รหัสเที่ยวบิน",
    driving_license: "ใบขับขี่",
    card_detail: "Credit Card Detail",
    card_issuer: "Credit Card / Debit Card Issuer",
    card_type: "Card Type",
    credit_card_remark: "ลูกค้าต้องมีบัตรเครดิตสำหรับบล็อควงเงินมัดจำ",
    verify: "ตรวจสอบ",
    english_only: "กรุณากรอกเป็นภาษาอังกฤษเท่านั้น",
    paylater_info: `<h3>PAY Later</h3>
<ul><li>ลูกค้าสามารถจองและชำระค่าเช่าผ่านบัตรเครดิตล่วงหน้า จำนวน 500 บาทสำหรับรถทุกรุ่น (ไม่มีค่าธรรมเนียม)</li>
<li>ลูกค้าสามารถเลื่อนวันที่ทำการจองได้ 1 ครั้ง โดยเปลี่ยนได้ภายใน 30 วัน หลังจากวันที่จอง</li>
<li>หากมีการเปลี่ยนแปลงการจอง ลูกค้าต้องรับผิดชอบค่าเช่าที่เปลี่ยนแปลงในอัตราราคาเช่าของช่วงเวลาที่ลูกค้าเลือก (ราคาเช่าอาจมีการเปลี่ยนแปลงตามช่วงเวลา)</li>
<li>บริษัทขอสงวนสิทธิ์ไม่คืนค่าเช่าล่วงหน้าจำนวน 500 บาทในทุกกรณี</li>
<li>ลูกค้าไม่สามารถทำสัญญาเช่าซ้อนในช่วงเวลาเดียวกัน (ลูกค้า 1 ท่านไม่สามารถเช่ารถเกิน 1 คัน ในช่วงเวลาเดียวกัน)</li></ul>`,
    paynow_info: `
<h3>PAY Now</h3>
<ul><li>ลูกค้าจะได้ราคาคุ้มค่าที่สุด</li>
<li>ลูกค้าสามารถจองและชำระค่าเช่าผ่านบัตรเครดิตเท่านั้น (ไม่มีค่าธรรมเนียม)</li>
<li>ลูกค้าไม่สามารถเปลี่ยนแปลงวัน เวลาและสถานที่ในการรับรถได้</li>
<li>บริษัทขอสงวนสิทธิ์ไม่คืนค่าเช่าหากลูกค้ายกเลิกการจองในทุกกรณี</li>
<li>ลูกค้าไม่สามารถทำสัญญาเช่าซ้อนในช่วงเวลาเดียวกัน (ลูกค้า 1 ท่านไม่สามารถเช่ารถเกิน 1 คัน ในช่วงเวลาเดียวกัน)</li></ul>`,
  },
  car_details: {
    size: "ขนาด",
    type: "ชนิด",
    engine: "เครื่องยนต์",
    kms_free: "ระยะทาง",
    insurances: "ประกัน",
    more_details: "รายละเอียดเพิ่มเติม",
    seat: "จำนวนที่นั่ง",
    door: "จำนวนประตู",
    air_condition: "แอร์",
    transmission: "ระบบเกียร์",
    luggage: "จำนวนกระเป๋า",
    radio_with_cd: "วิทยุและเครื่องเล่น",
    extra_option: "Extra option",
  },
  voucher: {
    voucher: "Voucher",
    fill: "กรอก Voucher No.",
    value: "มูลค่า",
    check: "ตรวจสอบ",
    delete: "ลบ",
    remark: "REMARK",
  },
  term: {
    term: "ข้อตกลงและเงื่อนไข",
    accept_information: "ฉันยอมรับ",
    more_information: "รายละเอียดเพิ่มเติม",
    alert:
      "กรุณาอ่านเงื่อนไขและข้อตกลงในการให้บริการก่อนกดยอมรับเพื่อดำเนินการต่อ",
    popup_title: "เงื่อนไขและข้อกำหนดในการเช่ารถกับไทยเร้นท์อะคาร์",
    qualification: "คุณสมบัติของผู้เช่ารถ มีอายุ 21 ปีบริบูรณ์ขึ้นไป",
    document_title: "เอกสารการเช่ารถ",
    document_desc:
      "1. บัตรประจำตัว\n1.1 คนไทย บัตรประชาชน\n1.2 ชาวต่างชาติหนังสือเดินทาง\n2. ใบขับขี่หรือใบขับขี่สากลที่เป็นภาษาอังกฤษ ซึ่งยังไม่หมดอายุ\n3. บัตรเครดิต ซึ่งออกโดยธนาคารในประเทศหรือต่างประเทศ มีอายุในบัตรคงเหลือมากกว่า 6 เดือน\n*ต้องเป็นบัตรเครดิตที่มีชื่อนามสกุลตรงกันกับบัตรประชาชน หรือ หนังสือเดินทาง (Passport) ของผู้เช่า\nราคาเช่ารถ ไม่รวมค่าน้ำมันอุปกรณ์ค่าเช่าต่าง ๆ และค่าใช้จ่ายเพิ่มเติมอื่น ๆ",
    pre_authorize_title: "ค่ามัดจำ (Pre-Authorization)",
    pre_authorize_desc:
      "- ค่ามัดจำ S,M ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 5,000 บาท ค่ามัดจำ Toyota Cross ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 10,000 บาท ค่ามัดจำ Size L , Mercedes Benz  ผู้เช่ารถต้องชำระค่ามัดจำผ่านบัตรเครดิต เป็นจำนวนเงิน 15,000 บาท",
  },
  journey: {
    banner_title: "แนะนำสถานที่ท่องเที่ยว",
    category: "หมวดหมู่",
    recent_posts: "บทความล่าสุด",
  },
  promotion: {
    promotion: "PROMOTION",
    related: "RELATED PROMOTIONS",
    read_more: "อ่านเพิ่มเติม",
  },
  review: {
    review: "Reviews & Activities",
    review_gallery: "Reviews & Activities Gallery",
  },
  career: {
    career: "CAREER",
    branch: "ประจำสาขา",
    saraly: "เงินเดือน",
    qualification: "คุณสมบัติ",
    apply_message: "ผู้สนใจติดต่อสมัครด้วยตนเอง หรือ ส่ง Resume มาได้ที่",
  },
  partner: {
    partner_thairent: "Partners Thai Rent a Car",
    worldwide: "<p>เช่ารถทั่วโลก</p> <p>Worldwide car rental</p>",
    worldwide_button: "อ่านเพิ่มเติม",
  },
  log_in: {
    log_in: "LOGIN",
    email: "Email",
    password: "Password",
    forgot: "Forgot your password?",
    sign_in: "SING IN",
    or: "OR",
    log_in_facebook: "Login with Facebook?",
    log_in_line: "Login with LINE",
    dont_have_account: "Don’t have an account? ",
    register: "RESGITER",
    first_name: "First Name",
    last_name: "Last Name",
    forgot_message:
      "Insert your account email address\nWe will send you a link to reset your password.",
    reset_password: "RESET PASSWORD",
    required: "กรุณากรอกข้อมูลของท่านให้ครบถ้วนก่อนดำเนินการต่อ",
  },
  faq: {
    faq: "FAQ",
    where_are: "ไทยเร้นท์อะคาร์ มีสาขาที่ไหนบ้าง?",
    central: "ภาคกลาง",
    north: "ภาคเหนือ",
    south: "ภาคใต้",
    east: "ภาคตะวันออก",
    north_east: "ภาคตะวันออกเฉียงเหนือ",
    view_map: "ดูแผนที่",
  },
  profile: {
    profile: "PROFILE",
    user_profile: "User Profile",
    first_name: "First Name",
    last_name: "Lastname",
    middle_name: "Middle name",
    email: "Email",
    address: "Address",
    postcode: "Postcode",
    phone: "Phone",
    nationality: "Nationality",
    country: "Country",
    driving_license: "Driving license number",
    dob: "Date of birth",
    upload: "Upload Driving license",
    id_card: "Identification card",
    lang_message:
      "**ไม่สามารถใส่ Input เป็นภาษาไทยได้ ใส่ได้เฉพาะภาษาอังกฤษเท่านั้น",
    change_password: "CHANGE PASSWORD",
    current: "Current",
    new: "New",
    retype_new: "Retype New",
    forgot: "Forgot your password?",
    save_change: "SAVE CHANGES",
    back: "กลับหน้าหลัก",
    facebook_placeholder: "กรุณากรอกชื่อ Facebook ของท่าน",
    line_placeholder: "กรุณากรอก id line ของท่าน",
    require_field: "กรุณากรอกข้อมูลของท่านให้ครบถ้วนก่อนดำเนินการต่อ",
  },
  error: {
    error_invalid_promotion_code: "โปรโมชั่นโค้ดไม่ถูกต้องกรุณาตรวจสอบอีกครั้ง",
    "required field need to be filled":
      "กรุณากรอกข้อมูลของท่านให้ครบถ้วนก่อนดำเนินการต่อ",
    validate_before_payment: "กรุณากดปุ่ม “ตรวจสอบ” ก่อนทำการชำระเงิน",
    error_start_time_period:
      "Please make your booking at least $1 hour in advance",
    "PO not found": "เลขที่ PO ไม่ถูกต้อง",
    "limit usage": "ขออภัย ขณะนี้สิทธิ์เต็มจำนวนแล้ว",
  },
  modal: {
    fill: "ต้องการกรอกข้อมูล",
    nofill: "ไม่ต้องการกรอกข้อมูล",
  },
  ourservice_table: {
    type_of_insurance:
      "เช่ารถแล้ว เลือกประกันแบบไหนดี ? <br/> (ตัวเลือกเพิ่มเติม)",
    ldw: "ประกันพื้นฐาน",
    sldw: "อุ่นใจ กับความเสียหายส่วนแรก",
    pldw: "ครอบคลุม ทุกความเสียหาย",
    short_term: {
      1: "รถเกิดอุบัติเหตุ (*ไม่รวมความเสียหายจากยางและกระจก) ยกเว้นค่าเสียหายส่วนแรก 5,000 บาท",
      2: "รถเกิดอุบัติเหตุ และมีความเสียหายต่อกระจก และยาง ยกเว้นค่าเสียหายกระจกแตก 5,000 บาท , ยางเสียหาย เก็บตามราคารุ่นยาง ในช่วงเวลานั้น",
      3: "ราคาค่าประกันต่อวัน (ไม่รวม Vat)",
      4: "ราคาค่าประกันต่อเดือน (ไม่รวม Vat)",
    },
    long_term: {
      1: "รถเกิดอุบัติเหตุ (*ไม่รวมความเสียหายจากยางและกระจก) ยกเว้นค่าเสียหายส่วนแรก 5,000 บาท",
      2: "รถเกิดอุบัติเหตุ และมีความเสียหายต่อกระจก และยาง ยกเว้นค่าเสียหายกระจกแตก 5,000 บาท , ยางเสียหาย เก็บตามราคารุ่นยาง ในช่วงเวลานั้น",
      3: "ราคาค่าประกันต่อเดือน (ไม่รวม Vat)",
    },
  },
};
