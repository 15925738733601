import React, { Component } from "react";
import "./css/Confirm.css";
import Filter from "../../Filter";
import { SITE_URL } from "../../constants";

export default class ConfirmContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: ""
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

  }
  render() {
    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <div className="container">
              <div className="ConfirmContentA-settxtcen-default">
                <div className="ConfirmContentA-setmarb-logom">
                  <img
                    className="ConfirmContentA-conw-logom"
                    src={
                      this.state.preFixImg + "images/Confirm/logo-mail@2x.png"
                    }
                    alt="logo"
                  />
                </div>
                <div className="ConfirmContentA-confont-headinfo">
                  <span>รบกวนช่วยยืนยันอีเมลล์ของท่านด้วยค่ะ</span>
                </div>
                <div className="ConfirmContentA-confont-infoa">
                  <span>
                    เพียงแค่กดตรงคำว่า ‘Verify your account now’
                    จากอีเมลล์ที่เราส่งไปให้ตอนที่ท่านสร้างบัญชีผู้ใช้
                  </span>
                  <br />
                  <span>
                    หากท่านต้องการให้เราส่งอีเมลล์พร้อมลิงค์ใหม่ไปให้ก็สามารถทำได้ค่ะ*
                  </span>
                </div>
                <div className="ConfirmContentA-setmarb-btn">
                  <button className="btn btn-danger ConfirmContentA-buildbtn-danger">
                    ส่งลิงค์มาให้ใหม่
                  </button>
                </div>
                <div>
                  <span className="ConfirmContentA-confont-infob">
                    *หากเราส่งลิงค์ใหม่ไปให้ ลิงค์อันเก่าก็จะไม่สามารถใช้งานได้
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <PartnerContentB /> */}
      </div>
    );
  }
}
