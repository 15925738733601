import React, { Component } from "react";
import "./css/Contact.css";
import Filter from "../../Filter";
import { strings } from "../../language/language";
import { PREFIX_CONTENT_ADDRESS, SITE_URL } from "../../constants";
import axios from "axios";
const curLang = localStorage.getItem("lang");
export default class ContactContentA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preFixImg: SITE_URL + "/",
      preFixPath: "",
      isLoading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchContent();
  }

  fetchContent() {
    const apiurl =
      PREFIX_CONTENT_ADDRESS + "api/content/contact?lang=" + curLang;
    axios
      .get(apiurl)
      .then((res) => {
        const { data } = res;
        this.setState({
          content: data.content["_66"].detail,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleSubmitContact(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    this.setState({ isLoading: true });
    axios({
      method: "post",
      url: `${PREFIX_CONTENT_ADDRESS}api/contact_email`,
      data: data,
    }).then(function (response) {
      if (response.data.Success === "Y") {
        alert("Thank you for your message. It has been sent.");
        document.getElementById("contact-form").reset();
        this.setState({ isLoading: false });
      }
    });
  }
  render() {
    return (
      <div style={{ background: "#fff" }}>
        <Filter />
        <div className="wrapper">
          <div className="content-area">
            <div className="container-fluid">
              <div className="container-title ContactContentA-contain-default">
                <h1
                  className="ContactContentA-confont-header"
                  style={{ letterSpacing: "5px" }}
                >
                  <strong>{strings.contact.contact}</strong>
                </h1>
                <div className="ContactContentA-hr-promo ContactContentA-conhr-head" />
              </div>
              <div className="ContactContentA-setmarlr-de">
                <div className="row">
                  <div
                    className="col-md-6 ContactContentA-setmarl-headmo"
                    dangerouslySetInnerHTML={{ __html: this.state.content }}
                  />
                  <div className="col-md-6">
                    <form
                      id="contact-form"
                      onSubmit={(e) => this.handleSubmitContact(e)}
                    >
                      <div className="ContactContentA-conmarb-conform">
                        <input
                          type="email"
                          name="email"
                          required
                          className="form-control ContactContentA-coninput-form ContactContentA-coninput-mail"
                          placeholder={strings.contact.email}
                        />
                      </div>
                      <div className="ContactContentA-conmarb-conform">
                        <input
                          type="text"
                          name="name"
                          required
                          className="form-control ContactContentA-coninput-form ContactContentA-coninput-name"
                          placeholder={strings.contact.name}
                        />
                      </div>
                      <div className="ContactContentA-conmarb-conform">
                        <input
                          type="text"
                          name="subject"
                          required
                          className="form-control ContactContentA-coninput-form ContactContentA-coninput-num"
                          placeholder={strings.footer.subject}
                        />
                      </div>
                      <div className="ContactContentA-conmarb-conform">
                        <textarea
                          name="message"
                          className="form-control ContactContentA-coninput-form"
                          rows="5"
                          id="comment"
                          required
                          placeholder={strings.contact.message}
                        />
                      </div>
                      <div className="ContactContentA-conmarb-conform ContactContentA-conbtn-mobile">
                        <button
                          type="submit"
                          disabled={this.state.isLoading}
                          className="btn btn-primary ContactContentA-mobtn-primary"
                          style={{ fontWeight: "bold" }}
                        >
                          {strings.contact.send}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="container full-width">
              <div className="main-slider">
                {/* <div className="BranchContentA-setbg-opacity" /> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23564.992279472168!2d100.57784799835729!3d13.744751830840745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d66e39ce3fad3%3A0xc9f4d46dcffda544!2z4LmE4LiX4Lii4LmA4Lij4LmJ4LiZ4LiX4LmM4Lit4Liw4LiE4Liy4Lij4LmM!5e0!3m2!1sth!2sth!4v1565686585642!5m2!1sth!2sth"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  style={{
                    border: "0",
                    marginBottom: "-7px",
                  }}
                  allowFullScreen
                  title="Contact Map"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
