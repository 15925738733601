import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { dom } from "@fortawesome/fontawesome-svg-core";
import { strings } from "../../language/language";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";
// import {strings} from "./language/language";
dom.watch();

export default class MenuProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preFixPath: "",
            preFixImg: SITE_URL + "/",
            profile: [],
            currentPwd: "",
            newPwd: "",
            reNewPwd: "",
            profileFile: "",
        };
    }
    componentDidMount() {


    }
    logout() {
        localStorage.removeItem("login_token");
        localStorage.removeItem("member_name");

        window.location.href = SITE_URL;
    }
    render() {

        return (
            <div><div className="col-md-6 ProfileContentA-repadr-prolink">
                <Link
                    className="ProfileContentA-hovercursor"
                    to={
                        this.state.preFixPath +
                        "/profile"
                    }
                    replace
                >
                    <div className="ProfileContentA-hovercursor">
                        <div className={"ProfileContentA-buildlink-head " + (this.props.active === "profile" ? "ProfileContentA-setbgcolor-ac" : "ProfileContentA-setbgcolor-noac")}>
                            <img
                                className="ProfileContentA-setlogo-prolink"
                                src={this.state.preFixImg + "images/Profile/logo-profile.png"}
                                alt="logo"
                            />
                            <span className="ProfileContentA-confont-link ProfileContentA-confont-linkpro" style={{ top: "15px" }}>
                                {strings.profile.user_profile}
                                <br />
                                {this.props.drivercode && (<span className="memberno_txt" style={{ fontSize: "8px" }}>Member No. {this.props.drivercode}</span>)}
                            </span>

                        </div>
                    </div>
                </Link>
            </div>
                <div className="col-md-6 ProfileContentA-repadl-booklink">
                    <Link
                        className="ProfileContentA-hovercursor"
                        to={
                            this.state.preFixPath +
                            "/bookinghistory"
                        }
                        replace
                    >
                        <div className={"ProfileContentA-buildlink-head " + (this.props.active === "history" ? "ProfileContentA-setbgcolor-ac" : "ProfileContentA-setbgcolor-noac")}>
                            <img
                                className="ProfileContentA-setlogo-booklink"
                                src={this.state.preFixImg + "images/Profile/logo-book.png"}
                                alt="logo"
                            />
                            <span className="ProfileContentA-confont-link ProfileContentA-confont-linkbook">
                                {strings.booking_info.booking_history}
                            </span>
                        </div>
                    </Link>
                </div>


                <div className="col-md-6 ProfileContentA-repadl-logout">
                    <a
                        className="ProfileContentA-hovercursor"
                        onClick={this.logout}

                    >
                        <div className="ProfileContentA-buildlink-head ProfileContentA-setbgcolor-noac">
                            <i className="fa fa-lock logout-icon"></i>
                            <span className="logout-text ProfileContentA-confont-link ProfileContentA-confont-linkbook">
                                Logout
                                                </span>
                        </div>
                    </a>
                </div></div>);
    }
}