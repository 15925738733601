import React, { Component, createRef } from "react";
import { Link } from "react-router-dom";
import { strings } from "../../language/language";
import "./css/ServiceBox.css";
import { isMobile } from "react-device-detect";

const services = [
  {
    label: strings.service_box.short_term,
    description: strings.service_box.short_term_desc,
    img: "/images/ServiceBox/short-term.png",
    url: "/ourservice/short-term",
  },
  {
    label: strings.service_box.long_term,
    description: strings.service_box.long_term_desc,
    img: "/images/ServiceBox/long-term.png",
    url: "/ourservice/long-term",
  },
  {
    label: strings.service_box.corporate,
    description: strings.service_box.corporate_desc,
    img: "/images/ServiceBox/corporate.png",
    url: "/ourservice/enterprise",
  },
  {
    label: strings.service_box.chauffeur,
    description: strings.service_box.chauffeur_desc,
    img: "/images/ServiceBox/chauffeur.png",
    url: "/ourservice/chaffeur",
  },
];

export default class ServiceBox extends Component {
  constructor(props) {
    super(props);
    this.boxRef = createRef();

    this.state = {
      services: [],
    };
  }

  componentDidMount() {
    this.setState({ services });
  }

  scroll(scrollOffset) {
    const container = this.boxRef.current;
    const scrollLeft = container.scrollLeft + scrollOffset;
    container.scroll({
      left: scrollLeft,
      behavior: "smooth",
    });
  }

  render() {
    return (
      <div style={{ position: "relative", scrollBehavior: "smooth" }}>
        <div
          ref={this.boxRef}
          className="row horizontal-scroll"
          style={{ marginTop: !isMobile ? "5px" : 0, position: "relative" }}
        >
          {this.state.services.map((s, i) => (
            <Link key={`service-box-${i}`} to={s.url}>
              <div
                id={`service-box-${i}`}
                className="col-sm-3"
                style={{ marginTop: 0 }}
              >
                <div className="Service-box">
                  <button>{s.label}</button>
                  <div className="desc">{s.description}</div>
                  <div
                    className="bg-img"
                    style={{
                      backgroundImage: `url("${s.img}")`,
                    }}
                  ></div>
                </div>
              </div>
            </Link>
          ))}
        </div>

        <div
          className="LeftArrow"
          id="service-box-left-arrow"
          onClick={() => this.scroll(-380)}
        >
          <img
            src="/images/icons8-back-arrow-96.png"
            width={40}
            alt="Left Arrow Icon"
          />
        </div>

        <div
          className="RightArrow"
          id="service-box-right-arrow"
          onClick={() => this.scroll(380)}
        >
          <img
            src="/images/icons8-back-arrow-96.png"
            width={40}
            alt="Left Arrow Icon"
          />
        </div>
      </div>
    );
  }
}
