import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PREFIX_ADDRESS, SITE_URL } from "../../constants";
import "./css/BookingStep6.css";
import axios from "axios";
import Filter from "../../Filter";
import { strings } from "../../language/language";

export default class Book6HeaderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      preFixPath: "",
      preFixImg: SITE_URL + "/",
      bookingNumber: "",
      token: "",
      carpro_booking_number: "",
      carPrice: 0,
      customer_fullname: "",
      isExtra: 0,
      isInsurance: 0,
      isVat: 0,
      isVoucher: 0,
      partial: 0,
      remainAmount: 0,
      totalPrice: 0,
      drop_off: 0,
      po_no: null,
    };
  }
  componentDidMount() {
    const $ = window.$;
    $("#preloader").hide();
    window.scrollTo(0, 0);
    let query = new URLSearchParams(this.props.location.search);
    let token = query.get("thairent_token");
    this.setState({ token: token });

    axios
      .get(`${PREFIX_ADDRESS}api/booking/details?token=` + token)
      .then((res) => {
        const { data } = res;

        if (data.Success === "N") {
          this.props.history.push(`/`);
          return;
        }

        this.setState({
          loaded: true,
          bookingNumber: data.booking.booking_number,
          carPrice: data.booking.rate_carprice,
          isExtra: data.booking.rate_extra,
          isVat: data.booking.rate_vat,
          drop_off: data.booking.rate_drop_off,
          isVoucher: data.booking.rate_voucher,
          remainAmount: data.booking.remain,
          partial: data.booking.partial,
          isInsurance: data.booking.rate_insurance,
          totalPrice: data.booking.rate_total,
          carpro_booking_number: data.booking.carpro_booking_number,
          customer_fullname:
            data.booking.personal_firstname +
            " " +
            data.booking.personal_lastname,
          po_no: data.booking.po_code || null,
        });

        if (data.Success === "Y") {
          // console.log(data);
          // var setFormatTotal = data.rate.total.replace(",","");
          // var defaultTotal = parseFloat(setFormatTotal)
          // var setFormatCarprice = data.rate.car_price.replace(",","");
          // var defaultCarprice = parseFloat(setFormatCarprice);
          // booking_temp.RateNo = data.detail.carrate;
          // localStorage.setItem("booking_temp", JSON.stringify(booking_temp));
          // console.log(booking_temp.carrate);
        } else {
          // alert(data.message);
          // window.location.href = "/";
        }
      })
      .then(() => {
        this.handleRate();
        setTimeout(() => {
          $("#preloader").hide();
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });

    localStorage.removeItem("booking_option");
    localStorage.removeItem("booking_voucher");
    localStorage.removeItem("booking_temp");
  }
  render() {
    return (
      <div>
        <Filter />
        <div className="wrapper">
          <div className="content-area BookHeaderStatus-setpadb-conarea Book6HeaderStatus-setpadb-conarea">
            <div
              className="container BookingStep6-re-contain"
              style={{ marginTop: "50px" }}
            >
              <div className="row Book6ContentA-padmar-default">
                {this.state.loaded && (
                  <div className="col-xs-12 Book6ContentA-repadlr-default Book6ContentA-setmarb-split">
                    <div className="Book6ContentA-set-bghead">
                      <div className="Book6ContentA-head-title">
                        <span>Booking Complete</span>
                      </div>
                    </div>
                    <div className="Book6ContentA-set-bginfo">
                      <div className="Book6ContentA-marb-BookCom-info">
                        <span>
                          Thank you…..{this.state.customer_fullname} for travel
                          with Thai Rent A Car.
                        </span>
                        <br />
                        <span>
                          Reservation No. is {this.state.carpro_booking_number}
                        </span>
                        <br />
                      </div>

                      <div className="Book6ContentA-marb-BookCom-info">
                        <span>
                          Please check your email inbox to get a confirmation
                          letter.
                        </span>
                        <br />
                        <span>
                          (Please present a confirmation letter at Thai Rent A
                          Car counter on
                        </span>
                        <br />
                        <span>
                          a pick up day and also bring your driving license,
                        </span>
                        <br />
                        <span>
                          passport(foreigner) and credit card for
                          Pre-authorized)
                        </span>
                      </div>

                      <div style={{ marginBottom: "20px", color: "#ee4231" }}>
                        * ในกรณีลืมขอใบเสร็จหรือข้อความในใบเสร็จไม่ถูกต้อง
                        กรุณาแจ้งเจ้าหน้าที่หน้าเคาน์เตอร์ไทยเร้นท์อะคาร์ในวันรับรถหรือคืนรถเท่านั้น{" "}
                        <br />
                        หากทำการคืนรถเรียบร้อยแล้วทางบริษัทฯจะไม่สามารถออกใบเสร็จหรือแก้ไขได้ในทุกรณี
                      </div>
                    </div>
                    {/* 111 */}
                    <div className="Book6ContentA-set-bghead">
                      <div className="Book6ContentA-head-title">
                        <span>
                          Confirmation Number :{this.state.bookingNumber}
                          <br />
                          Reservation No. is {this.state.carpro_booking_number}
                        </span>
                      </div>
                    </div>
                    <div className="Book6ContentA-set-bginfo">
                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.carPrice
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>{strings.booking_step.car_price}</span>
                          </span>
                        </div>
                        <div
                          className="Book6ContentA-sub-details"
                          style={{ display: "none" }}
                        >
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>
                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.isExtra
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>
                              {strings.booking_step.extra_option_price}
                            </span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>

                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.isInsurance
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>Insurance</span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>

                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.drop_off
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>{strings.booking_step.drop_off_fee}</span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>

                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.isVat
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>{strings.booking_step.vat}</span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>

                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.isVoucher
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>{strings.voucher.voucher}</span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="Book6ContentA-set-bghead">
                      <div className="Book6ContentA-head-title Book6ContentA-head-titleprice">
                        <span>
                          {this.state.totalPrice
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          THB
                        </span>
                      </div>
                      <div className="Book6ContentA-head-title">
                        <span>{strings.booking_step.total} </span>
                      </div>
                    </div>
                    <div className="Book6ContentA-set-bginfo">
                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.partial
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>{strings.booking_step.partial}</span>
                          </span>
                        </div>
                        <div
                          className="Book6ContentA-sub-details"
                          style={{ display: "none" }}
                        >
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>
                      <div className="Book6ContentA-setmar-info">
                        <div className="Book6ContentA-floatr-price">
                          <span>
                            {this.state.remainAmount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            THB
                          </span>
                        </div>
                        <div>
                          <img
                            className="Book6ContentA-close-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/close-details.png"
                            }
                            alt="Close"
                          />
                          <img
                            className="Book6ContentA-open-details"
                            src={
                              this.state.preFixImg +
                              "images/BookingStep6/open-details.png"
                            }
                            alt="Open"
                          />
                          <span className="Book6ContentA-marbet-imgtxt">
                            <span>Remain</span>
                          </span>
                        </div>
                        <div className="Book6ContentA-sub-details">
                          <ul>
                            <li>-</li>
                            <li>-</li>
                            <li>-</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="text-center">
                      {this.state.totalPrice !== "0.00" && !this.state.po_no ? (
                        <a
                          target="_blank"
                          className="btn btn-submit ripple-effect btn-theme btn-red"
                          href={
                            PREFIX_ADDRESS + "receipt?token=" + this.state.token
                          }
                        >
                          พิมพ์ใบเสร็จ
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
